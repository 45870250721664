import React, { ChangeEvent, useEffect, VFC } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { number, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SliderInput from '../../SliderInput/SliderInput';
import TextInput from '../../TextInput/TextInput';
import Button from '../../common/Button/Button';
import styles from './CustomDurationPicker.module.scss';
import utilStyles from '../../../styles/utils.module.scss';
import { formatLockDuration } from '../../../_helpers';

interface CustomDurationPickerProps {
  value?: number;
  min: number;
  max: number;
  onSubmit: (duration: number) => void;
  onCancel: () => void;
}

const getDurationValidationSchema = (minDuration: number, maxDuration: number) => {
  return object({
    lockDuration: number()
      .required('Field is required')
      .typeError('Must be a full number')
      .test('is-integer', 'Must be a full number', (value) => Number.isInteger(value))
      .min(minDuration, `Min. is ${minDuration}`)
      .max(maxDuration, `Max. is ${maxDuration}`),
  });
};

const CustomDurationPicker: VFC<CustomDurationPickerProps> = ({
  value = 0,
  min,
  max,
  onSubmit,
  onCancel,
}) => {
  const { register, handleSubmit, setValue, formState, getFieldState, getValues } = useForm({
    resolver: yupResolver(getDurationValidationSchema(min, max)),
    mode: 'onTouched',
    defaultValues: {
      lockDuration: `${value < min ? min : value}`,
    },
  });

  useEffect(() => {
    if (Number(getValues('lockDuration')) < min) {
      setValue('lockDuration', `${min}`);
    }
  });

  const duration = dayjs.duration(
    getFieldState('lockDuration').invalid ? 0 : Number(getValues('lockDuration')),
    'days',
  );

  const handleCustomDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('lockDuration', e.target.value, { shouldValidate: true });
  };

  const handleCancelDuration = () => {
    onCancel();
  };

  const handleSubmitDuration = () => {
    handleSubmit(() => {
      onSubmit(Number(getValues('lockDuration')));
    })();
  };

  return (
    <div className={styles.container}>
      <div className={styles.customDuration}>
        <div className={styles.customDurationSlider}>
          <SliderInput
            min={min}
            max={max}
            value={getFieldState('lockDuration').invalid ? min : Number(getValues('lockDuration'))}
            onChange={handleCustomDurationChange}
          />
        </div>
        <div className={styles.customDurationInputSection}>
          <div className={styles.customDurationInputContainer}>
            <TextInput
              {...register('lockDuration')}
              errorMsg={formState.errors?.lockDuration?.message}
            />
            <p className={styles.customDurationInputLabel}>days</p>
          </div>

          <p
            className={classnames(
              styles.customDurationCalculated,
              (Math.floor(duration.asYears()) < 1 || getFieldState('lockDuration').invalid) &&
                utilStyles.hidden,
            )}
          >
            {formatLockDuration(Number(getValues('lockDuration')))}
          </p>
        </div>
      </div>

      <div className={styles.customDurationButtons}>
        <button
          onClick={handleCancelDuration}
          className={styles.customDurationCancelButton}
          type="button"
        >
          Cancel
        </button>
        <Button
          onClick={handleSubmitDuration}
          color="primary"
          disabled={
            getFieldState('lockDuration').invalid && getFieldState('lockDuration').isTouched
          }
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default CustomDurationPicker;
