import React, { useState, VFC } from 'react';
import multiTransactionIcon from '@assets/icons/multi-transaction.svg';
import classNames from 'classnames';
import VaultTile from '../VaultTile/VaultTile';

import styles from './RewardsTile.module.scss';
import { getAdamantTokenName } from '../../../api/Multichain';
import Button from '../../common/Button/Button';
import { useUserContext } from '../../../contexts/UserContext';
import { formatTokenAmount } from '../../../_helpers';

interface Props {
  bgParticles: string;
  vaults: any[];
}

const RewardsTile: VFC<Props> = ({ bgParticles, vaults }) => {
  const { isWalletConnected, vaultFunctions } = useUserContext();
  const [isClaimAndVestPending, setIsClaimAndVestPending] = useState(false);
  const [didUserClaimRewards, setDidUserClaimRewards] = useState(false);

  const pendingVaultsReward = vaults.reduce(
    (acc: number, vault: any) =>
      acc + (vault.pendingReward / 10 ** 18) * (vault.boost > 0 ? 1 + vault.boost : 1),
    0,
  );

  const handleCollectRewardsClick = async () => {
    if (isClaimAndVestPending) return;

    const vaultsAddressesWithRewards = vaults
      .filter((vault: any) => vault.pendingReward > 0)
      .map(({ vaultAddress }) => ({ vaultAddress }));

    if (vaultsAddressesWithRewards.length > 0) {
      setIsClaimAndVestPending(true);

      try {
        await vaultFunctions.claimAndVest(vaultsAddressesWithRewards);
        setDidUserClaimRewards(true);
      } catch {}

      setIsClaimAndVestPending(false);
    }
  };

  return (
    <VaultTile
      bgParticles={bgParticles}
      isConnected={isWalletConnected}
      title="My Rewards"
      connectDesc="to see your rewards"
      buttonElement={
        didUserClaimRewards || pendingVaultsReward > 0 ? (
          <Button
            color="primary"
            className={classNames(styles.button)}
            startEnhancer={<img src={multiTransactionIcon} alt="multi transaction" />}
            onClick={handleCollectRewardsClick}
            disabled={pendingVaultsReward === 0 || isClaimAndVestPending}
          >
            Claim & Vest All
          </Button>
        ) : null
      }
    >
      <div className={styles.value}>
        <p className={styles.amount}>{formatTokenAmount(pendingVaultsReward)}</p>
        <p className={styles.tokenName}>{getAdamantTokenName()}</p>
      </div>
    </VaultTile>
  );
};

export default RewardsTile;
