import FEE_DIST_MULTICALL_ABI from '../../abis/multicall/arbitrum/addy_multicall.json'
import { isZero } from '../../utils';
import { getCompoundingAPY } from '../MathUtil.js'
import { getWETH } from '../Multichain.js';
import { cacheOnlyIfNumber } from '../PriceCache';

const ADDY_MULTICALL = "0x79AE48a9bA7B0EB9403AF7f559d6801455c3b632"

let feeDistTvl = 0;
let addyTotalSupply = 0;
let addyDollarPrice = 0;

export const getLockedArbyVaultBasicAddress = () => {
    return "0x9d5d0cb1B1210d4bf0e0FdCC6aCA1583fA48f0fD";
}
export const getArbyTokenAddress = () => {
    return "0x09ad12552ec45f82bE90B38dFE7b06332A680864";
}

export const getArbitrumFeeDistTvl = () => {
    return feeDistTvl;
}
//2.1m locked tokens
export const getArbyTotalSupply = () => {
    return addyTotalSupply / 10 ** 18 - 2100000;
}
export const getArbyDollarPrice = () => {
    return addyDollarPrice;
}

export const getAddyDataArbitrum = async (web3, account) => {
    var addyData = {};

    const multicallContract = web3.eth.Contract(FEE_DIST_MULTICALL_ABI.abi, ADDY_MULTICALL)
    let addyFeeDistInfo = await multicallContract.methods.getAddyInfoFor(account).call();
    if(!addyFeeDistInfo) {
        return null;
    }
    //console.log(addyFeeDistInfo)

    let myBalance = addyFeeDistInfo.userBalance;
    let addyApproved = addyFeeDistInfo.addyApproved;

    let totalBalance = addyFeeDistInfo.totalBalance;
    let unlockedBalance = addyFeeDistInfo.unlockedBalance;

    let totalSupply = addyFeeDistInfo.totalSupply;
    addyTotalSupply = addyFeeDistInfo.addyTotalSupply; //for front page
    let lockedSupply = addyFeeDistInfo.lockedSupply;

    //Price data
    let addyEthPrice = 0;
    if(!isZero(addyFeeDistInfo.addy_weth.token0Bal)) {
        addyEthPrice = addyFeeDistInfo.addy_weth.token1Bal / addyFeeDistInfo.addy_weth.token0Bal;
    }
    let ethPrice = addyFeeDistInfo.usdc_weth.token0Bal * 10 ** 12 / addyFeeDistInfo.usdc_weth.token1Bal;
    cacheOnlyIfNumber(getWETH(), ethPrice, 1, true);
    addyDollarPrice = addyEthPrice * ethPrice;
    feeDistTvl = totalSupply * addyDollarPrice;

    //Fee dist reward data
    let rewardForDurationAddy = addyFeeDistInfo.rewardForDurationAddy;
    let rewardForDurationWeth = addyFeeDistInfo.rewardForDurationWeth;

    let addyPerYear = (rewardForDurationAddy / lockedSupply * 52); //will use for the other contract
    if(isZero(lockedSupply)) {
        addyPerYear = 0;
    }
    let ethPerYear = rewardForDurationWeth / totalSupply * 52;
    if(isZero(totalSupply)) {
        ethPerYear = 0;
    }
    let ethApr = ethPerYear / addyEthPrice;

    let addyDaily = rewardForDurationAddy / 7e18;
    let ethDaily = rewardForDurationWeth / 7e18;
    //let maticDaily = rewardForDurationWmatic / 7e18;
    let lockedAddyTVL = lockedSupply * addyDollarPrice / 1e18;
    //let lockedBoostTvl = addyFeeDistInfo.lockedStakingDataBasic.totalBalance / 1e18 * addyDollarPrice;

    let compoundedAddyApy = getCompoundingAPY(lockedAddyTVL, addyDollarPrice, addyDaily, 0);
    let compoundedWmaticApy = getCompoundingAPY(feeDistTvl / 1e18, ethPrice, ethDaily, 0);
    let compoundedTotalApy = compoundedAddyApy + compoundedWmaticApy;

    let claimableRewards = addyFeeDistInfo.claimableRewards;
    let claimableAddyAmt = claimableRewards[0][1];
    let claimableQuickAmt = 0; //claimableRewards[1][1];
    //let claimableWmaticAmt = claimableRewards[2][1];
    let claimableWethAmt = claimableRewards[1][1];

    let vestedBalances = addyFeeDistInfo.earningsData[0];
    let vestedBalanceAmount = vestedBalances ? vestedBalances[0]: 0
    let vestedBalanceUnlockDate = vestedBalances ? vestedBalances[1]: 0

    let withdrawableAmount = addyFeeDistInfo.withdrawableAmount; //total of all withdrawable tokens
    let penaltyAmount = addyFeeDistInfo.withdrawablePenaltyAmount; //penalty from withdrawing vested tokens early
    let totalWithdrawable = (withdrawableAmount / 10 ** 18) + (penaltyAmount / 10 ** 18);

    addyData = {
        addyPerEth: addyFeeDistInfo.addyPerProfitEth,
        addyTotalSupply: addyTotalSupply,
        //Dollar prices should always be divided by 1e18 first
        //ETH prices should be left in wei
        addyEthPrice: addyEthPrice,

        //think these might be there to prevent the site from crashing when changing chains?
        wmaticEthPrice: 0,

        myBalance: myBalance,
        addyApproved: addyApproved,

        totalBalance: totalBalance,
        unlockedBalance: unlockedBalance,

        totalSupply: totalSupply,
        lockedSupply: lockedSupply,
        nextLockStart: addyFeeDistInfo.nextLockStart,
        //should iterate thru array tbh
        rewardForDurationAddy: rewardForDurationAddy,
        rewardForDurationQuick: 0, //rewardForDurationQuick,
        rewardForDurationWmatic: 0, //rewardForDurationWmatic,
        rewardForDurationWeth: rewardForDurationWeth,
        addyPerYear: addyPerYear,
        addyPerYearNoBuybacks: addyPerYear,
        quickPerYear: 0,
        quickApy: 0,
        wmaticPerYear: 0,
        wmaticApy: 0,
        ethPerYear: ethPerYear,
        ethApr: ethApr,
        feeSharingApy: ethApr,

        //vestedBalances: vestedBalances,
        earningsData: addyFeeDistInfo.earningsData,
        totalWithdrawable: totalWithdrawable,
        vestedBalanceAmount: vestedBalanceAmount,
        vestedBalanceUnlockDate: vestedBalanceUnlockDate,

        claimableRewards: claimableRewards,
        claimableAddyAmt: claimableAddyAmt,
        claimableQuickAmt: 0, //claimableQuickAmt,
        claimableWmaticAmt: 0, //claimableWmaticAmt,
        claimableWethAmt: claimableWethAmt,

        //lockedBalances: lockedBalances,
        lockedTotal: addyFeeDistInfo.locked_total,
        lockedUnlockable: addyFeeDistInfo.locked_unlockable,
        lockedLocked: addyFeeDistInfo.locked,
        lockData: addyFeeDistInfo.lockData,

        withdrawableAmount: withdrawableAmount,
        penaltyAmount: penaltyAmount,
        withdrawableWithNoPenalty: addyFeeDistInfo.withdrawableWithNoPenalty,

        compoundedAddyApy: compoundedAddyApy,
        compoundedWmaticApy: compoundedWmaticApy, //todo: rename
        compoundedTotalApy: compoundedTotalApy,

        addyApproved_locked: 0, //addyFeeDistInfo.lockedStakingDataPlus.addyApproved,
        totalBalance_locked: 0, //addyFeeDistInfo.lockedStakingDataPlus.totalBalance,
        balanceOf_locked: 0, //addyFeeDistInfo.lockedStakingDataPlus.balanceOf,
        endingTimestamp_locked: 0, //addyFeeDistInfo.lockedStakingDataPlus.endingTimestamp,
        ratio_locked: 0, //addyFeeDistInfo.lockedStakingDataPlus.ratio,
        pendingReward_locked: 0, //addyFeeDistInfo.lockedStakingDataPlus.pendingReward,

        addyApproved_basic: addyFeeDistInfo.lockedStakingDataBasic.addyApproved,
        totalBalance_basic: addyFeeDistInfo.lockedStakingDataBasic.totalBalance,
        balanceOf_basic: addyFeeDistInfo.lockedStakingDataBasic.balanceOf,
        endingTimestamp_basic: addyFeeDistInfo.lockedStakingDataBasic.endingTimestamp,
        pendingRewardA_basic: addyFeeDistInfo.lockedStakingDataBasic.pendingRewardA,
        pendingRewardB_basic: addyFeeDistInfo.lockedStakingDataBasic.pendingRewardB,

        userLocked_lockedStaking: 0,
        user_veAddy: addyFeeDistInfo.user_veAddy,
        user_veAddy_from_lock_plus: 0, //addyFeeDistInfo.user_veAddy_from_lock_plus,
        user_boost: addyFeeDistInfo.user_boost,
        buybackApy: 0 //buybackApy
    }
    //console.log(addyData);

    return addyData;
}

/**/
