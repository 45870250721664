import React, { VFC } from 'react';
import StakeForm from '../../components/StakeForm/StakeForm';
import StakeStatsTiles from '../../components/StakeStatsTiles/StakeStatsTiles';
import StakeSidePanel from '../../components/StakeSidePanel/StakeSidePanel';
import { NetworkID } from '../../constants';
import styles from './StakePage.module.scss';
import { getAdamantTokenName } from '../../api/Multichain';
import { getNativeTokenName } from '../../constants/multichain/network';

export interface StakePageProps {
  addyData: any;
}

const StakePage: VFC<StakePageProps> = ({ addyData }) => {
  const wmaticApr =
    (window.networkId === NetworkID.CRONOS_NETWORK_ID ? addyData.nativeApr : addyData.wmaticApy) *
    100;

  const stakedAmount = addyData.unlockedBalance / 10 ** 18;
  const claimableWmaticAmt = addyData.claimableWmaticAmt / 10 ** 18;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {(stakedAmount > 0 || claimableWmaticAmt > 0) && <StakeStatsTiles addyData={addyData} />}

        <div className={styles.stakeBanner}>
          <p className={styles.stakeBannerTitle}>Stake your {getAdamantTokenName()}</p>
          <p className={styles.stakeBannerDesc}>
            APR <span className={styles.stakeBannerValue}>{wmaticApr.toFixed(2)}%</span> in{' '}
            {getNativeTokenName()}
          </p>
        </div>

        <div className={styles.stakeForm}>
          <StakeForm addyData={addyData} />
        </div>
      </div>

      <StakeSidePanel />
    </div>
  );
};

export default StakePage;
