import React, { VFC, useRef, useCallback, useEffect } from 'react';
import classnames from 'classnames';

import Button from '../common/Button/Button';

import styles from './LinksDropdown.module.scss';
import NavigationLinks from './NavigationLinks';

interface LinksDropdownProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  buttonClassName: string;
  dropdownClassName: string;
}

const LinksDropdown: VFC<LinksDropdownProps> = ({
  isOpen,
  setIsOpen,
  buttonClassName,
  dropdownClassName,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleClickOutsideContainer = useCallback(
    (_target: EventTarget | null) => {
      if (
        containerRef.current &&
        // @ts-ignore
        !containerRef.current.contains(_target)
      ) {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => handleClickOutsideContainer(ev.target));

    return document.removeEventListener('mousedown', (ev) =>
      handleClickOutsideContainer(ev.target),
    );
  }, [handleClickOutsideContainer]);

  return (
    <div ref={containerRef}>
      <Button
        className={classnames(buttonClassName, styles.hamburgerMenuButton)}
        onClick={toggleIsOpen}
      >
        {isOpen ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5838 1.94428C15.9871 1.5047 16.1255 0.958384 15.5838 0.416729C15.0422 -0.124926 14.3789 0.0234838 14.0563 0.416782C13.7337 0.810081 8.00048 6.47262 8.00048 6.47262C8.00048 6.47262 2.58529 0.957083 1.94466 0.416782C1.78819 0.284826 1.07763 -0.242475 0.417806 0.416789C-0.242014 1.07605 0.343291 1.85585 0.417156 1.94428C0.954087 2.58712 6.47299 8.00012 6.47299 8.00012C6.47299 8.00012 1.4966 12.8724 0.417156 14.056C0.120439 14.3813 -0.124805 15.0418 0.417156 15.5835C0.959116 16.1251 1.63203 15.8701 1.94466 15.5835C3.12614 14.5001 8.00048 9.52762 8.00048 9.52762C8.00048 9.52762 13.658 15.218 14.0563 15.5835C14.3219 15.8271 14.948 16.2193 15.5838 15.5835C16.2197 14.9476 15.7534 14.2561 15.5838 14.056C15.0422 13.4168 9.52799 8.00012 9.52799 8.00012C9.52799 8.00012 14.5005 3.12512 15.5838 1.94428Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.0813C0 0.484114 0.484114 0 1.0813 0H19.9187C20.5159 0 21 0.484114 21 1.0813C21 1.67848 20.5159 2.1626 19.9187 2.1626H1.0813C0.484114 2.1626 0 1.67848 0 1.0813Z"
              fill="white"
            />
            <path
              d="M0 7.96094C0 7.36375 0.484114 6.87964 1.0813 6.87964H19.9187C20.5159 6.87964 21 7.36375 21 7.96094C21 8.55812 20.5159 9.04224 19.9187 9.04224H1.0813C0.484114 9.04224 0 8.55812 0 7.96094Z"
              fill="white"
            />
            <path
              d="M0 14.8401C0 14.2429 0.484113 13.7588 1.0813 13.7588H19.9187C20.5159 13.7588 21 14.2429 21 14.8401C21 15.4373 20.5159 15.9214 19.9187 15.9214H1.0813C0.484113 15.9214 0 15.4373 0 14.8401Z"
              fill="white"
            />
          </svg>
        )}
      </Button>

      {isOpen && (
        <div className={classnames(dropdownClassName, styles.linksDropdown)}>
          <NavigationLinks inDropdown clickSideEffect={closeDropdown} />
        </div>
      )}
    </div>
  );
};

export default LinksDropdown;
