let lastTimeCached = {};

export function cacheCoingeckoPrice(tokenId, address, cache) {
    if(lastTimeCached[tokenId] && lastTimeCached[tokenId] + 60000 > Date.now()) {
        return;
    }
    lastTimeCached[tokenId] = Date.now();

    fetch("https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=" + tokenId)
    .then(res => res.text())
    .then((result) => {
        let price = JSON.parse(result)[tokenId]['usd'];
        cache[address] = price;
        cache[address.toLowerCase()] = price;
        console.debug(`Cached price of ${tokenId}: ${price}`)
    },
    (error) => {
        console.debug('Error loading Coingecko data')
    });
}


export function cacheCoingeckoPriceByAddress(address, cache, chain = "polygon-pos") {
    if(lastTimeCached[address] && lastTimeCached[address] + 60000 > Date.now()) {
        return;
    }
    lastTimeCached[address] = Date.now();

    fetch(`https://api.coingecko.com/api/v3/simple/token_price/${chain}?contract_addresses=${address}&vs_currencies=usd`)
    .then(res => res.text())
    .then((result) => {
        if(result) {
            let price = JSON.parse(result)[address.toLowerCase()]['usd'];
            cache[address] = price;
            cache[address.toLowerCase()] = price;
            console.debug(`Cached price of ${address}: ${price}`)
        }
        else {
            console.debug('Unable to load Coingecko data')
        }
    },
    (error) => {
        console.debug('Error loading Coingecko data')
    });
}