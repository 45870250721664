import React, { useEffect, useState, VFC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useWindowScroll } from 'react-use';
import styles from './ScrollToTopButton.module.scss';
import scrollTopIcon from './scroll-top-icon.svg';
import { fadeInOut } from '../../animations/common-variants';

interface Props {
  threshold?: number;
}

const ScrollToTopButton: VFC<Props> = ({ threshold = 300 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const windowScroll = useWindowScroll();

  useEffect(() => {
    setIsVisible(windowScroll.y > threshold);
  }, [threshold, windowScroll.y]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          type="button"
          {...fadeInOut}
          className={styles.button}
          onClick={handleScrollToTop}
        >
          <img src={scrollTopIcon} alt="" />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

export default ScrollToTopButton;
