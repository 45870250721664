import React, { VFC } from 'react';
import classnames from 'classnames';

import Select, { SelectOption } from '../common/Select/Select';
import FilterButton from '../FilterButton/FilterButton';
import SortSelect from '../SortSelect/SortSelect';

import { useVaultsFiltersContext } from '../../contexts/VaultsFiltersContext';

import BoostableIcon from './boostable-icon.svg';
import StablecoinsIcon from './stablecoins-icon.svg';
import styles from './VaultsFilters.module.scss';

const statusFilterOptions: SelectOption[] = [
  { value: 'all', name: 'All' },
  { value: 'active', name: 'Active' },
  { value: 'inactive', name: 'Inactive' },
];

interface VaultsFiltersProps {
  className?: string;
  tokenFilterOptions: SelectOption[];
  platformFilterOptions: SelectOption[];
  myVaults: boolean;
  hasInactiveVaults: boolean;
}

const VaultsFilters: VFC<VaultsFiltersProps> = ({
  className,
  tokenFilterOptions,
  platformFilterOptions,
  myVaults,
  hasInactiveVaults,
}) => {
  const {
    filtersState,
    createOnMultipleSelectChange,
    createOnClearMultipleSelect,
    createOnSelectChange,
    createOnToggleFilterChange,
  } = useVaultsFiltersContext();

  const { token, platform, status, boostable, stablecoins } = filtersState;

  return (
    <div className={classnames(styles.container, className)}>
      <Select
        options={tokenFilterOptions}
        multiple
        label="Token"
        values={token}
        onChange={createOnMultipleSelectChange('token')}
        onClear={createOnClearMultipleSelect('token')}
      />

      <Select
        options={platformFilterOptions}
        multiple
        label="Platform"
        values={platform}
        onChange={createOnMultipleSelectChange('platform')}
        onClear={createOnClearMultipleSelect('platform')}
      />

      {hasInactiveVaults && (
        <Select
          options={statusFilterOptions}
          label="Status"
          values={status}
          onChange={createOnSelectChange('status')}
        />
      )}

      <FilterButton
        icon={BoostableIcon}
        className={classnames(styles.boostableButton, boostable && styles.active)}
        onClick={createOnToggleFilterChange('boostable')}
      >
        Boostable
      </FilterButton>

      <FilterButton
        icon={StablecoinsIcon}
        className={classnames(styles.stablecoinsButton, stablecoins && styles.active)}
        onClick={createOnToggleFilterChange('stablecoins')}
      >
        Stablecoins
      </FilterButton>

      {/* TODO: Temporarily hidden */}
      {/* <FilterButton
        icon={DegenerateIcon}
        className={classnames(styles.degenerateButton, degenerate && styles.active)}
        onClick={createOnToggleFilterChange('degenerate')}
      >
        Degen
      </FilterButton> */}

      <div className={styles.sortWrapper}>
        <SortSelect myVaults={myVaults} />
      </div>
    </div>
  );
};

export default VaultsFilters;
