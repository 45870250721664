import { gql, useQuery } from '@apollo/client';
import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"
import React from 'react';
import { ApolloProvider } from '@apollo/client/react';
import { NetworkID } from '../constants';

let lastTimeCached = {};
let swapFeeApyCache = {};

const sushiPolygonClient = new ApolloClient({
  link: new HttpLink({
      uri: "https://api.thegraph.com/subgraphs/name/sushiswap/matic-exchange"
  }),
  cache: new InMemoryCache(),
})
const sushiArbitrumClient = new ApolloClient({
    link: new HttpLink({
        uri: "https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange "
    }),
    cache: new InMemoryCache(),
})
function getSushiClient() {
  if(window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
    return sushiArbitrumClient;
  }
  return sushiPolygonClient;
}

const QUERY = gql`
query getData($id: String!) {
  pairDayDatas(
    where: { pairAddress: $id },
    first: 2 orderBy: date orderDirection: desc
  ) {
    dailyVolumeUSD
    date
  }
}`;
const SUSHI_QUERY = gql`
query getData($id: String!) {
    pair(id: $id) {
        hourData(first: 24 orderBy: date orderDirection: desc)
        {
            volumeUSD
        }
    }
}`;
const QUICK_TOTAL_VOLUME_QUERY = gql`
query uniswapDayDatas {
  uniswapDayDatas(first: 2 orderBy: date orderDirection: desc) {
    dailyVolumeUSD
    date
  }
}
`;
/*const QUICK_TOTAL_VOLUME_QUERY = gql`
query uniswapFactories {
  uniswapFactories(block: {number: 24484596}, where: {id: "0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32"})
  {
    totalVolumeUSD
  }
}
`;*/

export function TotalDailyVolume({ pool }) {
  const { loading, error, data } = useQuery(QUICK_TOTAL_VOLUME_QUERY, { });

  if (loading) return <p>Loading dQuick data...</p>;
  if (error) return <p>Error loading dQuick data</p>;
  let total;
  try {
    let todayVolume = parseFloat(data.uniswapDayDatas[0].dailyVolumeUSD);
    let yesterdayVolume = parseFloat(data.uniswapDayDatas[1].dailyVolumeUSD);
    let timePassed = Date.now() / 1000 - parseInt(data.uniswapDayDatas[0].date);
    if(timePassed < 0) {
      total = parseFloat(data.uniswapDayDatas[0].dailyVolumeUSD);
    }
    else {
      let yesterdayWeight = (86400 - timePassed) / 86400;
      //(24 hrs - time passed today) / 24 hrs
      total = yesterdayWeight * yesterdayVolume + todayVolume;
    }

    let lpFees = total * 0.0005; //0.3% * 1/6
    if (pool.poolTvl > 0) {
        let apr = lpFees * 365 / pool.poolTvl;
        let apy = 100 * (Math.pow(1 + apr / 3650, 3650) - 1);
        //console.log(total, lpFees, apy, tvl, pool.poolTvl)
        swapFeeApyCache[pool.vaultAddress] = apy;
        lastTimeCached[pool.vaultAddress] = Date.now();
        return <p>LP swap fee APY: {apy.toFixed(2)}%</p>
    }
    return <p>dQuick APY: {total}</p>
  }
  catch {
      return <p>Error loading dQuick data</p>;
  }
}

export function DailyVolume({ id, tvl, pool }) {
  id = "" + id.trim().toLowerCase();

  //in order to prevent people from thinking they need to pay a swap fee in order to migrate vaults
  if(pool.deprecated) {
    return <p>Pair data not available</p>;
  }
  if(pool.tradingFeeApy === -1) {
    return <p>Loading pair data...</p>;
  }
  if(pool.tradingFeeApy) {
    return <p>LP swap fee APY: {pool.tradingFeeApy.toFixed(2)}%</p>
  }
  if(lastTimeCached[pool.vaultAddress] && lastTimeCached[pool.vaultAddress] + 60000 > Date.now()) {
    if(swapFeeApyCache[pool.vaultAddress] === -1 || swapFeeApyCache[pool.vaultAddress] === undefined) {
      return <p>Swap fee data unavailable</p>;
    }
    return <p>LP swap fee APY: {swapFeeApyCache[pool.vaultAddress].toFixed(2)}%</p>
  }

  if (pool.exchange === "sushi") {
    return <ApolloProvider client={getSushiClient()}>
        <DailyVolumeSushi id={pool.lpAddress} tvl={pool.tvl} pool={pool} />
    </ApolloProvider>
  }
  else if (pool.exchange === "quickswap") {
    if(pool.singleAsset) {
      return <TotalDailyVolume pool={pool} />
    }
    const { loading, error, data } = useQuery(QUERY, { variables: { id: id.toLowerCase() } });

    if (loading) return <p>Loading pair data...</p>;
    if (error) return <p>Error loading pair data</p>;

    let total;
    try {
      let todayVolume = parseFloat(data.pairDayDatas[0].dailyVolumeUSD);
      let yesterdayVolume = parseFloat(data.pairDayDatas[1].dailyVolumeUSD);
      let timePassed = Date.now() / 1000 - parseInt(data.pairDayDatas[0].date);
      if(timePassed < 0) {
        total = parseFloat(data.pairDayDatas[0].dailyVolumeUSD);
      }
      else {
        let yesterdayWeight = (86400 - timePassed) / 86400;
        //(24 hrs - time passed today) / 24 hrs
        total = yesterdayWeight * yesterdayVolume + todayVolume;
      }
    }
    catch {
        return <p>Error loading pair data</p>;
    }
    let lpFees = total * 0.003; //0.3%
    if (tvl > 0) {
        let apr = lpFees * 365 / tvl;
        let apy = 100 * (Math.pow(1 + apr / 3650, 3650) - 1);
        //console.log(lpFees, apr)
        swapFeeApyCache[pool.vaultAddress] = apy;
        lastTimeCached[pool.vaultAddress] = Date.now();
        return <p>LP swap fee APY: {apy.toFixed(2)}%</p>
    }

    //console.log(data)
    //console.log(total)
    return <p>{total}</p>
  } else {
    swapFeeApyCache[pool.vaultAddress] = -1;
    lastTimeCached[pool.vaultAddress] = Date.now();
    return <p>Pair data not available</p>;
  }
}

export function DailyVolumeSushi({ id, tvl, pool }) {
    let key = "hourData";
    const { loading, error, data } = useQuery(SUSHI_QUERY, {variables: { id: id.toLowerCase() } });

    if (loading) return <p>Loading pair data...</p>;
    if (error) return <p>Error loading pair data</p>;
    let total = 0.0;
    try {
        data["pair"][key].forEach(element => {
            total += parseFloat(element.volumeUSD);
        });
    }
    catch {
        return <p>Error loading pair data</p>;
    }
    let lpFees = total * 0.003; //0.3%
    if (tvl > 0) {
        let apr = lpFees * 365 / tvl;
        let apy = 100 * (Math.pow(1 + apr / 3650, 3650) - 1);
        //console.log(lpFees, apr, tvl)
        swapFeeApyCache[pool.vaultAddress] = apy;
        lastTimeCached[pool.vaultAddress] = Date.now();
        return <p>LP swap fee APY: {apy.toFixed(2)}%</p>
    }

    //console.log(data)
    //console.log(total)
    return <p>{total}</p>
}

export function getCachedSwapFeeApy(vaultAddress) {
  if(swapFeeApyCache[vaultAddress] === undefined) return -1;
  return swapFeeApyCache[vaultAddress];
}
