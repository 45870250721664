import React, { VFC } from 'react';
import Loading from '../../components/Loading/Loading';
import VestPage from './VestPage';

export interface StakePageContainerProps {
  addyData: any;
}

const VestPageContainer: VFC<StakePageContainerProps> = ({ addyData }) => {
  if (!addyData) {
    return <Loading />;
  }

  return <VestPage addyData={addyData} />;
};

export default VestPageContainer;
