import SUSHI_CHEF_ABI from '../../../../abis/external_rewards/sushi_chef_abi.json'
import DODO_CHEF_ABI from '../../../../abis/external_rewards/dodo_chef_abi.json'

let allocPointCache = {}; //chef address -> poolId -> alloc points
let totalAllocPointCache = {}; //chef address -> totalAllocPoint
let rewardPerBlockCache = {}; //chef address -> rewardPerBlock

const getChefAbi = (poolJsonInfo) => {
    if(poolJsonInfo.platform === "sushi") {
        return SUSHI_CHEF_ABI.abi;
    }
    else if(poolJsonInfo.platform === "dodo") {
        return DODO_CHEF_ABI.abi;
    }
}

export const getAllocPoint = async (poolJsonInfo) => {
    let rewards = poolJsonInfo.stakingAddress;
    let pid = poolJsonInfo.pid;

    if(allocPointCache[rewards] !== undefined && allocPointCache[rewards][pid] !== undefined) {
        return allocPointCache[rewards][pid];
    }
    //console.log(rewards, pid)

    const chefContract = window.web3.eth.Contract(getChefAbi(poolJsonInfo), rewards);
    let info;

    if(poolJsonInfo.platform === "sushi") {
        info = await chefContract.methods.poolInfo(pid).call();
    }
    else if(poolJsonInfo.platform === "dodo") {
        info = await chefContract.methods.poolInfos(pid).call();
    }

    //console.log(info)
    //console.log(info.allocPoint)
    if(allocPointCache[rewards] === undefined) {
        allocPointCache[rewards] = {};
    }
    allocPointCache[rewards][pid] = info.allocPoint;
    console.debug(`Cached allocPoint for ${poolJsonInfo.poolName}: ${info.allocPoint}`)
    return info.allocPoint;
}

export const getTotalAllocPoint = async (poolJsonInfo) => {
    let rewards = poolJsonInfo.stakingAddress;

    if(totalAllocPointCache[rewards]) {
        return totalAllocPointCache[rewards];
    }

    const chefContract = window.web3.eth.Contract(getChefAbi(poolJsonInfo), rewards);
    let res = await chefContract.methods.totalAllocPoint().call();
    totalAllocPointCache[rewards] = res;
    console.debug(`Cached totalAllocPoint for ${poolJsonInfo.poolName}: ${res}`)
    return res;
}

export const getRewardPerBlock = async (poolJsonInfo) => {
    let rewards = poolJsonInfo.stakingAddress;

    if(rewardPerBlockCache[rewards]) {
        return rewardPerBlockCache[rewards];
    }

    const chefContract = window.web3.eth.Contract(getChefAbi(poolJsonInfo), rewards);
    let res;
    if(poolJsonInfo.platform === "sushi") {
        res = await chefContract.methods.sushiPerSecond().call();
    }
    else if(poolJsonInfo.platform === "dodo") {
        res = await chefContract.methods.dodoPerBlock().call();
    }

    rewardPerBlockCache[rewards] = res;
    console.debug(`Cached rewardPerBlock for ${poolJsonInfo.poolName}: ${res}`)
    return res;
}