import React from 'react';

import Button from '../common/Button/Button';
import Link from '../common/Link/Link';
import Tooltip from '../common/Tooltip/Tooltip';
import InlineLoader from '../common/InlineLoader/InlineLoader';

import { currencyFormatter } from '../../_helpers';
import { RoutingPaths } from '../../paths';

import PolygonLogo from './PolygonLogo.svg';
import CertikLogoDark from './CertikLogo-dark.svg';
import CertikLogoLight from './CertikLogo-light.svg';
import styles from './LandingPageHeader.module.scss';
import { useUserContext } from '../../contexts/UserContext';

const mapOrdersOfMagnitude: { [key: string]: string } = {
  '1': 'k',
  '2': 'M',
  '3': 'B',
  '4': 'T',
};

const formatBigValue = (value: number, monetary: boolean = true) => {
  const unit = monetary ? '$' : '';
  if (value < 1000) {
    return monetary ? currencyFormatter(value) : new Intl.NumberFormat().format(value);
  }

  if (value >= 10 ** 15) return `${unit}999T+`;

  const valSeparated = new Intl.NumberFormat('en-US').format(value).split(',');
  const significantNumbers = valSeparated[0];
  const orderOfMagnitude = mapOrdersOfMagnitude[(valSeparated.length - 1).toString()];

  return `${unit}${significantNumbers}${orderOfMagnitude}+`;
};

interface LandingPageHeaderProps {
  isVaultsLoaded: boolean;
  totalValueLocked: number;
  currentTokenName: string;
  marketCap: number;
  monthlyProfits: number;
  usersCount?: number;
  onLearnMoreClick: () => void;
}

const LandingPageHeader: React.VFC<LandingPageHeaderProps> = ({
  isVaultsLoaded,
  totalValueLocked,
  currentTokenName,
  marketCap,
  monthlyProfits,
  usersCount,
  onLearnMoreClick,
}) => {
  const { isDarkMode } = useUserContext();

  return (
    <div>
      <div className={styles.container}>
        <img
          src={
            isDarkMode
              ? '/assets/AdamantLogoWithParticles-dark.svg'
              : '/assets/AdamantLogoWithParticles-light.svg'
          }
          className={styles.adamantLogo}
          alt=""
        />
        <h1 className={styles.title}>Adamant</h1>
        <p className={styles.description}>
          Adamant is a yield optimizer vault that allows users to easily and safely maximize their
          yield farming earnings.
        </p>
        <div className={styles.buttonsWrapper}>
          <Button color="primary" to={RoutingPaths.VAULTS}>
            Start Investing
          </Button>
          <Button onClick={onLearnMoreClick}>Learn more</Button>
        </div>
        <div className={styles.linksWrapper}>
          <Link href="https://polygon.technology/" newTab className={styles.link}>
            <img src={PolygonLogo} alt="Polygon logo" />
            <div className={styles.linkText}>
              <span>Born on</span>
              <span>Polygon</span>
            </div>
          </Link>
          <Link href="https://www.certik.org/projects/adamant" newTab className={styles.link}>
            <img src={isDarkMode ? CertikLogoDark : CertikLogoLight} alt="Certik logo" />
            <div className={styles.linkText}>
              <span>Audited by</span>
              <span>Certik</span>
            </div>
          </Link>
        </div>
        <div className={styles.totalValueLockedWrapper}>
          <h2 className={styles.amount}>
            {isVaultsLoaded ? (
              currencyFormatter(totalValueLocked, { maximumFractionDigits: 0 })
            ) : (
              <InlineLoader />
            )}
          </h2>
          <span className={styles.text}>
            <span>Total Value Locked</span>
            <Tooltip content="Total Value Locked represents the value of all tokens that are currently being staked in Adamant protocol">
              <img
                src={isDarkMode ? '/icons/InfoIcon-dark.svg' : '/icons/InfoIcon-light.svg'}
                alt="Info icon"
              />
            </Tooltip>
          </span>
        </div>
      </div>
      <div className={styles.tilesWrapper}>
        <div className={styles.tilesBackground} />
        <div className={styles.tilesContent}>
          <div className={styles.tile}>
            <span className={styles.amount}>
              {marketCap ? formatBigValue(marketCap) : <InlineLoader />}
            </span>
            <span className={styles.text}>{currentTokenName} Market Cap</span>
          </div>
          <div className={styles.tile}>
            <span className={styles.amount}>
              {monthlyProfits ? formatBigValue(monthlyProfits) : <InlineLoader />}
            </span>
            <span className={styles.text}>{currentTokenName} Monthly Profits</span>
          </div>
          {typeof usersCount === 'number' && (
            <div className={styles.tile}>
              <span className={styles.amount}>{formatBigValue(usersCount, false)}</span>
              <span className={styles.text}>Adamant Users</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPageHeader;
