import React, { VFC } from 'react';
import { useForm } from 'react-hook-form';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import multiTransactionIcon from '@assets/icons/multi-transaction.svg';
import Decimal from 'decimal.js';
import AmountInput from '../AmountInput/AmountInput';
import Button from '../common/Button/Button';
import { tokenAmountValidationSchema } from '../../utils/validators';
import { getAdamantTokenName } from '../../api/Multichain';
import { useUserContext } from '../../contexts/UserContext';
import styles from './StakeForm.module.scss';

export interface StakeFormProps {
  addyData: any;
}

interface StakeFormValues {
  stakeAmount: string;
}

const getStakeFormValidationSchema = (maxAmount: number, tokenName: string) => {
  return object({
    stakeAmount: tokenAmountValidationSchema(maxAmount, {
      isNotEmpty: `Enter the ${tokenName} amount you want to stake`,
    }),
  });
};

const StakeForm: VFC<StakeFormProps> = ({ addyData }) => {
  const { isWalletConnected, showChooseWalletModal, stakeFunctions } = useUserContext();

  const userBalance = addyData.myBalance / 10 ** 18;
  const isTokenApproved = addyData.addyApproved / 10 ** 18 > 0;

  const adamantTokenName = getAdamantTokenName();

  const {
    handleSubmit,
    register,
    setValue,
    formState,
    reset: resetForm,
  } = useForm<StakeFormValues>({
    mode: 'onChange',
    resolver: yupResolver(
      getStakeFormValidationSchema(isWalletConnected ? userBalance : Infinity, adamantTokenName),
    ),
    defaultValues: {
      stakeAmount: '',
    },
  });

  const handleConnectWalletClick = () => {
    showChooseWalletModal();
  };

  const handleInvestAllClick = () => {
    setValue('stakeAmount', `${userBalance}`, { shouldValidate: true });
  };

  const stakeTokenHandler = async (formData: StakeFormValues) => {
    const isStakeMax = new Decimal(formData.stakeAmount).eq(userBalance);
    const handler = isStakeMax ? stakeFunctions.stakeMax : stakeFunctions.stake;
    const stakeAmountValue = isStakeMax ? addyData.myBalance : Number(formData.stakeAmount);
    handler(stakeAmountValue).then(() => resetForm());
  };

  const approveTokenHandler = async () => {
    await stakeFunctions.approve();
  };

  const handleStakeFormSubmit = () => {
    handleSubmit(isTokenApproved ? stakeTokenHandler : approveTokenHandler)();
  };

  return (
    <div className={styles.container}>
      <AmountInput
        autoComplete="off"
        balance={userBalance}
        disabled={formState.isSubmitting}
        onInvestAllClick={handleInvestAllClick}
        errorMsg={formState.errors?.stakeAmount?.message}
        {...register('stakeAmount')}
      />

      {isWalletConnected ? (
        <Button
          color="primary"
          onClick={handleStakeFormSubmit}
          className={styles.submitBtn}
          disabled={formState.isSubmitting || Object.keys(formState.errors).length > 0}
          startEnhancer={
            !isTokenApproved && <img src={multiTransactionIcon} alt="multi transaction" />
          }
        >
          Stake
        </Button>
      ) : (
        <Button
          color="primary"
          onClick={handleConnectWalletClick}
          className={styles.submitBtn}
          disabled={Object.keys(formState.errors).length > 0}
        >
          Connect Wallet
        </Button>
      )}
    </div>
  );
};

export default StakeForm;
