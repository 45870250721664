import { motion } from 'framer-motion';
import React, { VFC } from 'react';
import AddressTile from './AddressTile/AddressTile';

import styles from './VaultCardBodyAddresses.module.scss';
import { getPlatformAddress } from '../../../../constants/platform';

interface Props {
  vault: any;
}

const VaultCardBodyAddresses: VFC<Props> = ({ vault }) => {
  const { platform, vaultAddress, lpAddress, strategyAddress } = vault;

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.container}
    >
      <AddressTile
        name="Platform Link"
        platform={platform}
        address={getPlatformAddress(platform)}
      />
      <AddressTile name="Vault Contract Address" address={vaultAddress} truncate />
      <AddressTile name="Strategy Contract Address" address={strategyAddress} truncate />
      <AddressTile name="LP Token Address" address={lpAddress} truncate />
    </motion.div>
  );
};

export default VaultCardBodyAddresses;
