import Web3 from 'web3';
import { useToast } from '../_services';
import { createTransactionsQueue } from '../utils/transactionUtils';
import * as vaultFunctions from '../api/actions/NewVaultFaunctions';

export const useVaultFunctions = (account: string, web3?: Web3 | null) => {
  const approveTokenLoadingToast = useToast('loading');
  const stakeInVaultLoadingToast = useToast('loading');
  const stakeAllInVaultLoadingToast = useToast('loading');
  const withdrawAllLoadingToast = useToast('loading');
  const claimAndVestLoadingToast = useToast('loading');
  const compoundAllVaultsLoadingToast = useToast('loading');

  const approve = async (params: { lpAddress: string; vaultAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ lpAddress, vaultAddress }) => {
        return async () => vaultFunctions.approve(web3, account, lpAddress, vaultAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: approveTokenLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const claimAndVest = async (params: { vaultAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultAddress }) => {
        return async () => vaultFunctions.claimAndVest(web3, account, vaultAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: claimAndVestLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const withdrawAll = async (params: { vaultAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultAddress }) => {
        return async () => vaultFunctions.withdrawAll(web3, account, vaultAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: withdrawAllLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const stakeInVault = async (params: { vaultAddress: string; amount: number }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultAddress, amount }) => {
        return async () => vaultFunctions.stakeInVault(web3, account, vaultAddress, amount);
      });

      createTransactionsQueue(contracts, {
        toastHook: stakeInVaultLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const stakeAllInVault = async (params: { vaultAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultAddress }) => {
        return async () => vaultFunctions.stakeAllInVault(web3, account, vaultAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: stakeAllInVaultLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const compoundAllVaults = async (params: { strategyContractAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ strategyContractAddress }) => {
        return async () => vaultFunctions.compoundVault(web3, account, strategyContractAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: compoundAllVaultsLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
        finishToastOptions: {
          title: 'All Vaults were compounded',
        },
      });
    });
  };

  return {
    approve,
    claimAndVest,
    stakeInVault,
    stakeAllInVault,
    withdrawAll,
    compoundAllVaults,
  };
};
