import React, { FC, RefObject, useCallback } from 'react';
import { AnimateSharedLayout } from 'framer-motion';
import classnames from 'classnames';
import VaultCard from '../VaultCard/VaultCard';
import { useUserContext } from '../../contexts/UserContext';
import { SortValuesEnum, useVaultsFiltersContext } from '../../contexts/VaultsFiltersContext';

import { ReactComponent as SortArrow } from './sort-dir-arrow.svg';
import styles from './VaultsList.module.scss';

interface Props {
  vaults: any;
}

const VaultsList: FC<Props> = ({ vaults }) => {
  const { isWalletConnected } = useUserContext();
  const {
    filtersState: { sort },
    onSortClick,
  } = useVaultsFiltersContext();

  const getSortArrow = useCallback(
    (currValue: SortValuesEnum) => {
      if (sort.value === currValue) {
        return (
          <SortArrow
            className={classnames(styles.sortArrow, sort.direction === 'ASC' && styles.asc)}
          />
        );
      }
      return null;
    },
    [sort.direction, sort.value],
  );

  const handleCardVaultExpand = useCallback((cardRef: RefObject<HTMLDivElement>) => {
    if (cardRef.current) {
      const cardRect = cardRef.current.getBoundingClientRect();
      window.scrollTo({
        top: cardRect.top + window.scrollY - window.innerHeight / 2 + 350,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={classnames(styles.header, isWalletConnected && styles.walletConnected)}>
          <div className={styles.nameSection}>
            <div className={styles.nameSectionContent}>
              <button
                className={styles.headerBtn}
                type="button"
                onClick={() => onSortClick(SortValuesEnum.VAULT_NAME)}
              >
                Vault Name
                {getSortArrow(SortValuesEnum.VAULT_NAME)}
              </button>
            </div>
          </div>

          <div className={styles.tagSection} />

          <div className={styles.rewardsSection}>
            <div className={styles.rewardsSectionContent}>
              <button
                className={styles.headerBtn}
                type="button"
                onClick={() => onSortClick(SortValuesEnum.REWARDS)}
              >
                Rewards
                {getSortArrow(SortValuesEnum.REWARDS)}
              </button>
            </div>
          </div>

          {isWalletConnected && (
            <div className={styles.investmentsSection}>
              <div className={styles.investmentsSectionContent}>
                <button
                  className={styles.headerBtn}
                  type="button"
                  onClick={() => onSortClick(SortValuesEnum.MY_INVESTMENTS)}
                >
                  My investments
                  {getSortArrow(SortValuesEnum.MY_INVESTMENTS)}
                </button>
              </div>
            </div>
          )}

          <div className={styles.apySection}>
            <div className={styles.apySectionContent}>
              <button
                className={styles.headerBtn}
                type="button"
                onClick={() => onSortClick(SortValuesEnum.APY)}
              >
                APY
                {getSortArrow(SortValuesEnum.APY)}
              </button>
            </div>
          </div>

          <div className={styles.totalLockedSection}>
            <div className={styles.totalLockedSectionContent}>
              <button
                className={styles.headerBtn}
                type="button"
                onClick={() => onSortClick(SortValuesEnum.TVL)}
              >
                Total Value Locked
                {getSortArrow(SortValuesEnum.TVL)}
              </button>
            </div>
          </div>

          <div className={styles.expandSection} />
        </div>

        <div className={styles.list}>
          <AnimateSharedLayout>
            {vaults.map((vault: any) => (
              <VaultCard key={vault.vaultAddress} vault={vault} onExpand={handleCardVaultExpand} />
            ))}
          </AnimateSharedLayout>
        </div>
      </div>
    </div>
  );
};

export default VaultsList;
