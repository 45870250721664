import curve_pools from '../../pools/curve_pools.json'

let lastTimeCached = 0;

//cache: map of staking addresses -> apys
export function cacheCurvePolygonApy(cache) {
    if(lastTimeCached + 60000 > Date.now()) {
        return;
    }

    fetch("https://stats.curve.fi/raw-stats-polygon/apys.json")
    .then(res => res.text())
    .then((result) => {
        let apys = JSON.parse(result)['apy']['total'];
        curve_pools.forEach(pool => {
            if(pool.deprecated) {
                //pass
            }
            else if(pool.ren) {
                cache[pool.stakingAddress.toLowerCase()] = apys['ren'];
            }
            else if(pool.tricrypto) {
                cache[pool.stakingAddress.toLowerCase()] = apys['atricrypto3'];
            }
            else {
                cache[pool.stakingAddress.toLowerCase()] = apys['aave'];
            }
        });
        lastTimeCached = Date.now();
    },
    (error) => {
        console.debug('Error loading Curve Polygon data')
    });
}