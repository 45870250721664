import NEWEST_MULTICALL_ABI from '../../../abis/multicall/vaults/staking_rewards_vault_multicall.json'

import { calculateAprAndApy, calculateAprAndApyDual } from '../../MathUtil.js';
import { cacheOnlyIfNumber, getPoolTVL, getTokenPrice } from '../../PriceCache';
import { getPerformanceFee } from '../../Multichain';

const NEWEST_VAULT_MULTICALL_ADDRESS = "0xA45458D650159d97DbB0438f4eDC6906E3e52f5D"

const WMATIC = "0xFac5C7819fdE19d006244E462Db02ed7Fe346E71";

//Calculates the price of `token0` based on the balances of `token0` and `token1` in a Uniswap LP pool and returns [tvl of pool, tvl of tokens staked in the reward contract]
//`token1` should have been cached already or be a stablecoin, which means that the list of vaults has to be loaded in a certain order
function cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, poolJsonInfo) {
    let lpSupply = vaultInfo.lpSupply;
    let lpStaked = vaultInfo.lpStaked;
    let ratioStaked = lpStaked / lpSupply;

    let totalTokenValue = getPoolTVL(vaultInfo.token0, vaultInfo.token1, vaultInfo.token0Bal, vaultInfo.token1Bal, vaultInfo.decimals0, vaultInfo.decimals1);
    cacheOnlyIfNumber(poolJsonInfo.lpAddress, totalTokenValue / lpSupply * 1e18, 1, true);
    return [totalTokenValue, totalTokenValue * ratioStaked]
}

export const getStakingRewardDataTestnet = async (web3, account, pools, stakingData, stakingDataMap, addyData, priceCache) => {
    let addyPerEth = addyData.addyPerEth;

    const multicallContract = web3.eth.Contract(NEWEST_MULTICALL_ABI.abi, NEWEST_VAULT_MULTICALL_ADDRESS)

    var poolAddresses = [];
    var poolz = [];

    for (let i = 0; i < pools.length; i++) {
        poolAddresses.push(pools[i].vaultAddress);

        //Multicall contract will fail to return data if I try to get too many vaults at once
        if(i > 0 && i % 60 === 0) {
            let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
            //console.log("Loaded " + poolAddresses.length + " vaults")
            poolz = poolz.concat(infoForPools);
            poolAddresses = [];
        }
    }

    if(poolAddresses.length > 0) {
        let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
        //console.log("Loaded " + poolAddresses.length + " vaults")
        poolz = poolz.concat(infoForPools);
    }

    for (let i = 0; i < pools.length; i++) {
        pools[i].vaultAddress = pools[i].vaultAddress.toLowerCase();
        const lpAddress = pools[i].lpAddress.toLowerCase();

        let vaultInfo = poolz[i];
        //console.log(vaultInfo)
        if(vaultInfo == null) {
            return [];
        }

        let amountUserStaked = vaultInfo.amountUserStaked;
        let totalStaked = vaultInfo.totalStaked;
        let lpTokenApproved = vaultInfo.lpTokenApproved;
        let userLpBalance = vaultInfo.userLpBalance;
        let ratio = vaultInfo.ratio;
        let stakingAddress = vaultInfo.stakingRewards;

        let rewardTokenPrice = getTokenPrice(WMATIC);
        let rewardMultiplier = vaultInfo.rewardMultiplier;
        let pendingReward = vaultInfo.pendingReward * (rewardMultiplier / 1000) * addyPerEth;
        let harvestedToken = "WMATIC";
        let rewardRate = vaultInfo.rewardData.length > 0 ? vaultInfo.rewardData[0].rewardRate: 0;
        let rewardRate2 = vaultInfo.rewardData.length > 1 ? vaultInfo.rewardData[1].rewardRate: 0;

        let poolTvl_tvl = cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, pools[i]);
        const poolTvl = poolTvl_tvl[0]
        const tvl = poolTvl_tvl[1]

        if(pools[i].platform === "test" || pools[i].platform === "test2") {
            //use default settings
            rewardTokenPrice = getTokenPrice(WMATIC);
            harvestedToken = "WMATIC";
        }
        else if(pools[i].rewardToken !== undefined) {
            rewardTokenPrice = getTokenPrice(pools[i].rewardToken);
            harvestedToken = pools[i].rewardTokenName;
        }
        else if(!pools[i].deprecated) {
            console.debug("No reward token defined for ", pools[i].token0Name + "/" + pools[i].token1Name)
        }

        vaultInfo.totalPendingReward *= (vaultInfo.rewardMultiplier / 1000);

        let rewardsPerDay = 86400 * rewardRate / 10 ** 18;
        //add 12 hr delay before the notice shows so people stop spamming the chat during downtime
        //check why Date.now() > (vaultInfo.rewardData[0].periodFinish + 43200) * 1000 isn't working
        if(vaultInfo.rewardData.length > 0 && vaultInfo.rewardData[0].periodFinish > 0 && Date.now().toString() > ((vaultInfo.rewardData[0].periodFinish + 43200) * 1000).toString()) {
            rewardsPerDay = 0;
        }

        let boost = vaultInfo.boost != null ? vaultInfo.boost / 1e18: 0;
        let perfFee = pools[i].perfFee !== undefined ? pools[i].perfFee: getPerformanceFee();

        let aprApyData = calculateAprAndApy(rewardTokenPrice, rewardsPerDay, tvl, addyData, perfFee, rewardMultiplier * (1 + boost));
        //todo: handling for pools with 3 rewards, though I've never seen one yet
        if(rewardRate2) {
            let rewardsPerDay2 = 86400 * rewardRate2 / 10 ** 18;
            let rewardToken2;

            if(vaultInfo.rewardData.length > 1) {
                rewardToken2 = vaultInfo.rewardData[1].token.toLowerCase();
            }
            //use 2nd reward token defined in vault json if no 2nd reward token was defined in multicall, make sure the ordering is correct
            if(pools[i].rewardToken2) {
                rewardToken2 = pools[i].rewardToken2.toLowerCase();
            }

            if(rewardToken2 !== "0x0000000000000000000000000000000000000000") {
                aprApyData = calculateAprAndApyDual(rewardTokenPrice, rewardsPerDay, getTokenPrice(rewardToken2), rewardsPerDay2, tvl, addyData, perfFee, rewardMultiplier * (1 + boost));
            }
        }

        const lpPrice = getTokenPrice(lpAddress);

        let vaultData = {
            platform: pools[i].platform,
            exchange: pools[i].exchange,

            //Variables only defined in the json
            nameOverride: pools[i].nameOverride,
            tooltip: formatTooltip(pools[i]),
            highlight: pools[i].highlight,
            forceShow: pools[i].forceShow,
            customExchangeUrl: pools[i].customExchangeUrl,
            deprecatedYieldString: pools[i].deprecatedYieldString,
            harvestTwice: pools[i].harvestTwice,
            showApr: pools[i].showApr,
            addyRewards: pools[i].addyRewards,
            timestamp: pools[i].timestamp,

            //Variables defined in this function
            index: stakingData.length,
            harvestedToken: harvestedToken,

            token0Name: pools[i].token0Name,
            token1Name: pools[i].token1Name,
            token0: pools[i].token0,
            token1: pools[i].token1,
            lpAddress: pools[i].lpAddress,
            lpPrice: lpPrice,
            stakingAddress: stakingAddress.toLowerCase(),
            vaultAddress: pools[i].vaultAddress,
            strategyAddress: pools[i].strategyAddress,
            deprecated: pools[i].deprecated,
            hidden: pools[i].hidden,
            rewardsPerDay: rewardsPerDay,
            poolTvl: poolTvl, //The TVL of the entire liquidity pool
            tvl: tvl, //The vault's TVL
            boost: boost,
            boostable: vaultInfo.boostable,

            pendingReward: pendingReward, //Amount of ADDY for user to claim
            lastDepositTime: vaultInfo.lastDepositTime,

            //new post-Merlin variables
            rewardAllocation: vaultInfo.rewardAllocation,
            totalPendingReward: vaultInfo.totalPendingReward,
            withdrawPenaltyTime: vaultInfo.withdrawPenaltyTime,
            withdrawPenalty: vaultInfo.withdrawPenalty,
            lastHarvestTime: vaultInfo.lastHarvestTime,

            totalStaked: totalStaked,
            valueTotalStaked: totalStaked * lpPrice,
            amountUserStaked: amountUserStaked,
            valueUserStaked: amountUserStaked * lpPrice,
            lpTokenApproved: lpTokenApproved,
            userLpBalance: userLpBalance,
            rewardMultiplier: rewardMultiplier,

            baseApy: aprApyData.baseApy,
            addyTokenApy: aprApyData.addyTokenApy,
            addyFeeShareApy: aprApyData.addyFeeShareApy,
            totalAddyApy: aprApyData.totalAddyApy,
            apy: aprApyData.totalApy,

            baseApr: aprApyData.baseApr,
            addyTokenApr: aprApyData.addyTokenApr,
            aprProfitToStakers: aprApyData.aprProfitToStakers,
            totalAddyApr: aprApyData.totalAddyApr,
            apr: aprApyData.totalApr,

            ratio: ratio
        }
        stakingDataMap[pools[i].vaultAddress] = vaultData;
        stakingData.push(vaultData)
    }
    return stakingData;
}

function formatTooltip(pool) {
    if(pool.tooltip == null) {
        return null;
    }
    return pool.tooltip;
}
