import React, { VFC } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import timeDiffYearsDays from '../../_helpers/timeDiffYearsDays';
import { getAdamantTokenName } from '../../api/Multichain';

import BarBasic from './bar-basic.svg';
import BarPlus from './bar-plus.svg';
import CircleBasic from './circle-basic.svg';
import CirclePlus from './circle-plus.svg';
import styles from './BoostLockPlusInfoBox.module.scss';

interface BoostLockPlusInfoBoxProps {
  addyToLock?: string;
  lockEndTimestamp?: number;
  isLockPlus?: boolean;
  userBoostPointsBasic: number;
  userBoostPointsPlus: number;
  newBoostPoints: number;
  onGoToLockPlus: () => void;
}

const BoostLockPlusInfoBox: VFC<BoostLockPlusInfoBoxProps> = ({
  addyToLock,
  lockEndTimestamp,
  isLockPlus,
  userBoostPointsBasic,
  userBoostPointsPlus,
  newBoostPoints,
  onGoToLockPlus,
}) => {
  if (!addyToLock || !lockEndTimestamp || Number.isNaN(newBoostPoints)) return null;

  const lockEndDate = dayjs.unix(lockEndTimestamp);

  const adamantTokenName = getAdamantTokenName();

  const totalBoostPoints = userBoostPointsBasic + userBoostPointsPlus + newBoostPoints;
  const basicBoostPoints = (userBoostPointsBasic + (isLockPlus ? 0 : newBoostPoints)).toFixed(2);
  const plusBoostPoints = (userBoostPointsPlus + (isLockPlus ? newBoostPoints : 0)).toFixed(2);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.textWrapper, isLockPlus && styles.boostPlus)}>
        <p>
          Your {isLockPlus ? 'Lock Plus' : 'Basic Lock'} will now be{' '}
          <b>
            {addyToLock} {adamantTokenName}
          </b>
          , locked for a duration of <b>{timeDiffYearsDays(lockEndDate)}</b> (unlocking on{' '}
          {lockEndDate.format('D MMMM YYYY')})
        </p>
        <p>
          Your {adamantTokenName} locked in total will give you {totalBoostPoints.toFixed(2)} Boost
          Point
          {totalBoostPoints === 1 ? '' : 's'} ({basicBoostPoints} Basic + {plusBoostPoints} Plus)
        </p>
        {!isLockPlus && (
          <>
            <p>You can get 2x more Boost Points by making this transaction in Lock Plus</p>
            <button type="button" className={styles.linkButton} onClick={onGoToLockPlus}>
              Use Lock Plus instead
            </button>
          </>
        )}
      </div>
      {!isLockPlus && (
        <div className={styles.chartWrapper}>
          <span>For 100 {adamantTokenName}</span>
          <div className={styles.chart}>
            <img src={BarBasic} alt="" />
            <img src={BarPlus} alt="" />
          </div>
          <p className={styles.legend}>
            <img src={CircleBasic} alt="" />
            with Basic Lock
          </p>
          <p className={styles.legend}>
            <img src={CirclePlus} alt="" />
            with Lock Plus
          </p>
        </div>
      )}
    </div>
  );
};

export default BoostLockPlusInfoBox;
