import { string } from 'yup';
import isFloat from 'validator/lib/isFloat';
import Decimal from 'decimal.js';

type CustomErrorMessages = {
  isNotEmpty?: string;
};

export const tokenAmountValidationSchema = (
  maxAmount: number,
  customErrorMessages?: CustomErrorMessages,
) => {
  return string()
    .transform((value, originalValue) => `${originalValue}`.replace(/,/, '.'))
    .test(
      'isNotEmpty',
      customErrorMessages?.isNotEmpty || "Amount can't be empty",
      (value) => value !== '',
    )
    .test('isFloat', 'Amount must be a number', (value) => isFloat(`${value}`))
    .test('isGreaterThanZero', 'Amount must be greater than 0', (value) => {
      try {
        return new Decimal(`${value}`).gt(0);
      } catch (e) {
        return false;
      }
    })
    .test('isLessThanMax', 'Insufficient funds', (value) => {
      try {
        return value ? new Decimal(`${value}`).lessThanOrEqualTo(maxAmount) : true;
      } catch (e) {
        return false;
      }
    });
};
