import React from 'react';
import classnames from 'classnames';
import styles from './Link.module.scss';

interface LinkProps {
  className?: string;
  href: string;
  newTab?: boolean;
  color?: 'primary';
}

const Link: React.FC<LinkProps> = ({ children, className, href, newTab, color }) => (
  <a
    href={href}
    target={newTab ? '_blank' : '_self'}
    rel={newTab ? 'noreferrer noopener' : ''}
    className={classnames(styles.link, color === 'primary' && styles.primary, className)}
  >
    {children}
  </a>
);

export default Link;
