import React, { FC, ReactNode } from 'react';
import styles from './VaultTile.module.scss';

interface Props {
  title: string;
  connectDesc: string;
  isConnected?: boolean;
  bgParticles: string;
  buttonElement?: ReactNode;
}

const VaultTile: FC<Props> = ({
  title,
  isConnected,
  connectDesc,
  buttonElement,
  bgParticles,
  children,
}) => {
  return (
    <div className={styles.tile}>
      <img className={styles.bgImg} src={bgParticles} alt="" />
      <p className={styles.title}>{title}</p>

      {isConnected ? (
        children
      ) : (
        <>
          <p className={styles.connectWalletTitle}>Connect your wallet</p>
          <p className={styles.connectWalletDesc}>{connectDesc}</p>
        </>
      )}

      {isConnected && buttonElement && <div className={styles.buttonElement}>{buttonElement}</div>}
    </div>
  );
};

export default VaultTile;
