import React, { ReactNode, VFC, useCallback } from 'react';
import classnames from 'classnames';
import styles from './Tabs.module.scss';

interface TabsProps {
  className?: string;
  currentTab: number;
  tabs: ReactNode[];
  onTabChange: (newTab: number) => void;
  fullWidth?: boolean;
}

const Tabs: VFC<TabsProps> = ({ currentTab, tabs, onTabChange, className, fullWidth }) => {
  const createHandleTabChange = useCallback(
    (tabIdx: number) => () => {
      onTabChange(tabIdx);
    },
    [onTabChange],
  );

  return (
    <div className={classnames(styles.container, fullWidth && styles.fullWidth, className)}>
      {tabs.map((tab, idx) => (
        <button
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          type="button"
          className={classnames(
            styles.tabBtn,
            currentTab === idx && styles.activeTab,
            fullWidth && styles.fullWidth,
          )}
          onClick={createHandleTabChange(idx)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
