import React, { Component } from 'react';
import { Button, Dropdown, ButtonGroup, DropdownButton } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { getFeeDistTvl, getAdamantTokenTotalSupply, getAdamantTokenDollarPrice, getAdamantTokenName } from '../api/Multichain.js'
import { currencyFormatter } from '../_helpers';
import { NetworkID } from '../constants/index.js'
import FarmCard from '../components/vault/FarmCard'
import AddyWethPool from '../components/AddyWethPoolCard'
import AddySingleStakeCard from '../components/AddySingleStakeCard'

let platformKeyNameMap = { 'quick': "Quickswap",
    'sushi': "Sushiswap",
    'mai': "Mai",
    'ape': "Apeswap",
    'jet': "Jetswap"};

//set of any token trying to peg itself to $1 - put it in this class because it isn't used anywhere else
//should host on a separate json on the server
let stablecoins = new Set([
    //Polygon addresses
    "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", "0xc2132d05d31c914a87c6611c10748aeb04b58e8f","0x8f3cf7ad23cd3cadbd9735aff958023239c6a063", //big 3
    "0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72".toLowerCase(), //PUSD
    "0x692597b009d13c4049a947cab2239b7d6517875f", //UST
    "0xD86b5923F3AD7b585eD81B448170ae026c65ae9a".toLowerCase(), //IRON v2
    "0xa3fa99a148fa48d14ed51d610c367c61876997f1", //MAI
    "0x104592a158490a9228070e0a8e5343b499e125d0", //FRAX
    "0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3".toLowerCase(), //Tricrypto v3
    "0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171".toLowerCase(), //am3CRV
    "0x985D40feDAA3208DAbaCDFDCA00CbeAAc9543949".toLowerCase(), //IRON-IS3USD
    "0x5290Ad3d83476CA6A2b178Cd9727eE1EF72432af".toLowerCase(), //IMUSD
    "0xb4d09ff3dA7f9e9A2BA029cb0A81A989fd7B8f17".toLowerCase(), //IS3USD
    "0xb8ab048D6744a276b2772dC81e406a4b769A5c3D".toLowerCase(), //WUSD
    "0x0527c8c43250279d6eb74da1078193f5316fc9a0".toLowerCase(), //PYD
    "0xB6c473756050dE474286bED418B77Aeac39B02aF".toLowerCase(), //NUSD

    //Arbitrum addresses
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8".toLowerCase(), //USDC
    "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9".toLowerCase(), //USDT
    "0x30dF229cefa463e991e29D42DB0bae2e122B2AC7".toLowerCase(), //MIM-2POOL
    "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A".toLowerCase(), //MIM

    //Cronos addresses
    "0xc21223249ca28397b4b6541dffaecc539bff0c59", //USDC
    "0x66e428c3f67a68878562e79a0234c1f83c208770", //USDT
    "0xf2001b145b43032aaf5ee2884e456ccd805f677d", //DAI

    //Testnet addresses
    "0xb828e46a504d52d149464aee052dc885d8a942a0", //Test USDC
    ]);

class FarmList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            showOnlyMyStakedVaults: false,
            showLpAmounts: false,
            sortedField: 'default',
            platformFilter: "all",
            typeFilter: "all",
            indices: { 'apy': 0, 'totalStaked': 1, 'valueTotalStaked': 1, 'userStaked': 2 },
            keyToString: { 'apy': 'APY', 'totalStaked': 'Total Staked', 'valueTotalStaked': 'Total Staked', 'userStaked': 'User Staked', 'default': 'Default Order' },
            sortConfig: [true, true, true] //apy, total, user
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStakedVaultsCheckbox = this.handleStakedVaultsCheckbox.bind(this);
        this.handleShowingLpAmountsCheckbox = this.handleShowingLpAmountsCheckbox.bind(this);
        this.setSortedField = this.setSortedField.bind(this);
        this.setPlatformFilter = this.setPlatformFilter.bind(this);
        this.setTypeFilter = this.setTypeFilter.bind(this);
    }
    handleChange(event) {
        this.setState({
            searchTerm: event.target.value
        });
    }
    handleStakedVaultsCheckbox(event) {
        this.setState({
            showOnlyMyStakedVaults: event.target.checked
        });
    }
    handleShowingLpAmountsCheckbox(event) {
        this.setState({
            showLpAmounts: event.target.checked
        });
    }
    setSortedField(value) {
        if (value === 'default' || value == null) {
            this.setState({
                sortedField: value,
                sortConfig: [true, true, true]
            });
        }
        else {
            let prevVal = this.state.sortConfig[this.state.indices[value]];
            let newArr = [false, false, false];

            if(this.state.sortedField !== value) {
                newArr[this.state.indices[value]] = true;
            }
            else {
                newArr[this.state.indices[value]] = !prevVal;
            }

            this.setState({
                sortedField: value,
                sortConfig: newArr
            });
        }
    }
    setPlatformFilter(value) {
        this.setState({
            platformFilter: value
        });
    }
    setTypeFilter(value) {
        this.setState({
            typeFilter: value
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        const isLoggedIn = this.props.account !== "0x0000000000000000000000000000000000000001";
        const isAdmin = this.props.account.toLowerCase() === "0xb29cd9c87a624b940335d6d5e1d4aadf7d95beec";

        const pools = this.props.pool;

        let lockedTotal = 0;
        let unlockedBalance = 0;
        let totalLockedAndStaked = 0;
        let adamantTokenName = getAdamantTokenName();

        if(this.props.addyData != null) {
            lockedTotal = this.props.addyData.lockedTotal / 10 ** 18;
            unlockedBalance = this.props.addyData.unlockedBalance / 10 ** 18;
            totalLockedAndStaked = lockedTotal + unlockedBalance;
        }

        let sortedPools = this.getSortedPools(pools);

        return (
            <div id="home">
                <div className="col-12 text-center mb-3 no-select">
                    <h4 className="mb-4">Adamant helps farmers maximize their yields</h4>
                    {!this.props.loadedVaults && <h3>Loading vault data, please wait...</h3>}
                </div>

                {this.props.loadedVaults && sortedPools &&
                    <div className="farms-header no-select">
                        <div className="dashboard">
                            <div className="stat tvl">
                                <span className="value">{this.getTVL(pools, this.props.stakingRewardsData)}</span>
                                <span className="description">Total Deposited Value at Adamant</span>
                            </div>
                            <div className="stat mcap">
                                <span className="value">{currencyFormatter(getAdamantTokenTotalSupply() * getAdamantTokenDollarPrice())}</span>
                                <span className="description">{adamantTokenName}<br />Market Cap</span>
                            </div>
                            <div className="stat perf">
                                <span className="value">{this.getMonthlyAddyStakerProfit(pools)}</span>
                                <span className="description">Monthly Profits to<br />{adamantTokenName} stakers</span>
                            </div>
                        </div>
                        <div className="partners">
                            <div className="social-group">
                                <a href="https://discord.gg/69ureKKYz2" target="_blank" rel="noopener noreferrer">
                                    <img className="icon clickable no-select" src={"/img/DISCORD.png"} alt="discord icon"></img>
                                </a>
                                <a href="https://t.me/adamantfinance" target="_blank" rel="noopener noreferrer">
                                    <img className="icon clickable no-select" src={"/img/TELEGRAM.png"} alt="telegram icon"></img>
                                </a>
                                <a href="https://twitter.com/AdamantVault" target="_blank" rel="noopener noreferrer">
                                    <img className="icon clickable no-select" src={"/img/TWITTER.png"} alt="twitter icon"></img>
                                </a>
                                <NavLink to="/tools"><img className="icon clickable no-select" src={"/img/TOOLS.png"} alt="tools icon"></img></NavLink>
                            </div>
                            <div className="link-group">
                                <a href="https://adamantfinance.medium.com/certik-audit-complete-28f59eef1a23" target="_blank" rel="noopener noreferrer">
                                    <img className="icon clickable no-select" src={"/img/CERTIK.png"} alt="certik icon"></img>
                                </a>
                            </div>
                        </div>
                    </div>
                }

                <div className="farms-search row mb-4">
                    <div className="col-12 row align-items-center" style={{ fontSize: "1.2em", marginRight: "0px", marginLeft: "0px", paddingLeft: "0px", paddingRight: "0px" }}>
                        <div className="col-12 mb-4 reset-button-section-mobile">
                            <Button className="btn-secondary mr-2 mb-4" onClick={(event) => { event.preventDefault(); this.setPlatformFilter("all"); this.setTypeFilter('all'); this.setSortedField('default')}}>Reset Filters</Button>
                        </div>

                        {this.props.networkId === NetworkID.POLYGON_NETWORK_ID && <div id="platform-dropdown" className="col-3 mb-4 dropdown-section">
                            <span className="dropdown-title no-select">
                                Platform
                            </span>
                            <DropdownButton className="poolDropdown" title={this.getPlatformName(this.state.platformFilter)}>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('all'); }}>All</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('quick'); }}>Quickswap</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('sushi'); }}>Sushiswap</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('mai'); }}>Mai</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('gravity'); }}>Gravity</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('curve'); }}>Curve</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('dino'); }}>Dino</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('popsicle'); }}>Popsicle</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('sandbox'); }}>Sandbox</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('iron'); }}>Iron</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('ape'); }}>Apeswap</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('dfyn'); }}>Dfyn</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('cafe'); }}>Cafe</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('cometh'); }}>Cometh</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('elk'); }}>Elk</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('polyquity'); }}>Polyquity</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('jet'); }}>Jetswap</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('polycrystal'); }}>Polycrystal</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('wault'); }}>Wault</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('degen'); }}
                                style={{backgroundColor: "red", borderColor: "red", color: "white"}}>Degen</Dropdown.Item>
                            </DropdownButton>
                        </div>}

                        {this.props.networkId === NetworkID.ARBITRUM_NETWORK_ID && <div id="platform-dropdown" className="col-3 mb-4 dropdown-section">
                            <span className="dropdown-title no-select">
                                Platform
                            </span>
                            <DropdownButton className="poolDropdown" title={this.getPlatformName(this.state.platformFilter)}>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('all'); }}>All</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('curve'); }}>Curve</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('sushi'); }}>Sushiswap</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('swapr'); }}>Swapr</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('dodo'); }}>Dodo</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('degen'); }}
                                style={{backgroundColor: "red", borderColor: "red", color: "white"}}>Degen</Dropdown.Item>
                            </DropdownButton>
                        </div>}

                        {this.props.networkId === NetworkID.CRONOS_NETWORK_ID && <div id="platform-dropdown" className="col-3 mb-4 dropdown-section">
                            <span className="dropdown-title no-select">
                                Platform
                            </span>
                            <DropdownButton className="poolDropdown" title={this.getPlatformName(this.state.platformFilter)}>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('all'); }}>All</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('vvs'); }}>Vvs</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('cronaswap'); }}>Cronaswap</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('chronoswap'); }}>Chronoswap</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('elk'); }}>Elk</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('crodex'); }}>Crodex</Dropdown.Item>
                            </DropdownButton>
                        </div>}

                        {this.props.networkId === NetworkID.POLYGON_TESTNET_ID && <div id="platform-dropdown" className="col-3 mb-4 dropdown-section">
                            <span className="dropdown-title no-select">
                                Platform
                            </span>
                            <DropdownButton className="poolDropdown" title={this.getPlatformName(this.state.platformFilter)}>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('all'); }}>All</Dropdown.Item>
                            <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('test'); }}>Test</Dropdown.Item>
                            </DropdownButton>
                        </div>}

                        <div id="platform-dropdown" className="col-6 mb-4 dropdown-section-mobile">
                            <span className="dropdown-title no-select">
                                Platform
                            </span>
                            <DropdownButton className="poolDropdown" title={this.getPlatformName(this.state.platformFilter)}>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('all'); }}>All</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('quick'); }}>Quickswap</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('sushi'); }}>Sushiswap</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('mai'); }}>QiDao</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('dino'); }}>Dino</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('iron'); }}>Iron</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('curve'); }}>Curve</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('wault'); }}>Wault</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('ape'); }}>Apeswap</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('dfyn'); }}>Dfyn</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('cometh'); }}>Cometh</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('elk'); }}>Elk</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('polyquity'); }}>Polyquity</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('gravity'); }}>Gravity</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('jet'); }}>Jetswap</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('hop'); }}>Hop</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setPlatformFilter('degen'); }}
                                    style={{backgroundColor: "red", borderColor: "red", color: "white"}}>Degen</Dropdown.Item>
                            </DropdownButton>
                        </div>

                        <div id="type-dropdown" className="col-3 mb-4 dropdown-section">
                            <span className="dropdown-title no-select">
                                Vault type
                            </span>
                            <DropdownButton className="poolDropdown" title={this.state.typeFilter}>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setTypeFilter('all'); }}>All</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setTypeFilter('stable lps'); }}>Stable LPs</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setTypeFilter('stables'); }}>Stables</Dropdown.Item>
                            </DropdownButton>
                        </div>

                        <div id="type-dropdown" className="col-6 mb-4 dropdown-section-mobile">
                            <span className="dropdown-title no-select">
                                Vault type
                            </span>
                            <DropdownButton className="poolDropdown" title={this.state.typeFilter}>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setTypeFilter('all'); }}>All</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setTypeFilter('stable lps'); }}>Stable LPs</Dropdown.Item>
                                <Dropdown.Item onClick={(event) => { event.preventDefault(); this.setTypeFilter('stables'); }}>Stables</Dropdown.Item>
                            </DropdownButton>
                        </div>

                        <div className="col-6 mb-4 reset-button-section">
                            <Button className="btn-secondary mr-2 mb-4" onClick={(event) => { event.preventDefault(); this.setPlatformFilter("all"); this.setTypeFilter('all'); this.setSortedField('default')}}>Reset Filters</Button>
                        </div>
                    </div>

                    <div id="search_checkboxes" className="col-6">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="showStakedVaults" onChange={this.handleStakedVaultsCheckbox} />
                            <label className="form-check-label no-select" htmlFor="showStakedVaults">Show only staked</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="showLpAmounts" onChange={this.handleShowingLpAmountsCheckbox} />
                            <label className="form-check-label no-select" htmlFor="showLpAmounts">Show LP amounts</label>
                        </div>
                    </div>

                    <div className="search-bar col-6">
                        <div className="input-group">
                            <input className="form-control noValidate" type="search" aria-label="Search" placeholder="Search" id="search-bar-input" value={this.props.value} onChange={this.handleChange} />
                            <span className="input-group-text border-0" id="search-addon">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <Dropdown as={ButtonGroup} className="poolSortGroup">
                                <Button className="poolSortButton left" variant="secondary" onClick={() => this.setSortedField(this.state.sortedField)}>{this.state.keyToString[this.state.sortedField]}</Button>

                                <Dropdown.Toggle className="poolSortButton" split variant="secondary" id="dropdown-split-basic" />

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.setSortedField('apy')}>APY</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSortedField('valueTotalStaked')}>Total Staked</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSortedField('userStaked')}>User Staked</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSortedField('default')}>Default</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <div className="farms-list-wrapper">
                    <div className="farms-list">
                        <div className="section">
                            {this.props.addyData != null && this.state.platformFilter == "all"
                                && "addy".includes(this.state.searchTerm.toLowerCase()) && (!this.state.showOnlyMyStakedVaults || totalLockedAndStaked > 0) &&
                                <AddySingleStakeCard key="AddySingleStakeCard"
                                    addyData={this.props.addyData}
                                    showLpAmounts={this.state.showLpAmounts}
                                    account={this.props.account}
                                    isLoggedIn={isLoggedIn}
                                    networkId={this.props.networkId}
                            />}

                            {this.props.stakingRewardsData.map((pool, key) => {

                                const amountUserStaked = pool.amountUserStaked;
                                const noAmountStaked = !amountUserStaked || amountUserStaked.eq(0);
                                const stakeTokenName = pool.token0Name + '/' + pool.token1Name;
                                const deprecated = pool.deprecated || pool.rewardsPerDay === 0;
                                const hideNonStakedVault = this.state.showOnlyMyStakedVaults && noAmountStaked;
                                const searchFiltered = (this.state.searchTerm && (!stakeTokenName.toLowerCase().includes(this.state.searchTerm.toLowerCase()))) || !this.shouldShowVault(pool);

                                if (pool.hidden || (noAmountStaked && (deprecated || (pool.hiddenForNonStaked && !isAdmin))) || hideNonStakedVault || searchFiltered) {
                                    return (<div key={"hidden" + key}></div>)
                                }
                                return (
                                    <AddyWethPool key={key}
                                        pool={pool}
                                        showLpAmounts={this.state.showLpAmounts}

                                        account={this.props.account}
                                        isLoggedIn={isLoggedIn}
                                        networkId={this.props.networkId}

                                        showAddyStakingModal={this.props.showAddyStakingModal}
                                    />
                                )
                            })}

                            {sortedPools.map((pool, key) => {

                                const amountUserStaked = pool.amountUserStaked;
                                const noAmountStaked = !amountUserStaked || amountUserStaked.eq(0);
                                const stakeTokenName = pool.token0Name + '/' + pool.token1Name;
                                const deprecated = pool.deprecated || (pool.rewardsPerDay === 0 && !isAdmin);

                                const searchFiltered = this.isFiltered(pool) || !this.shouldShowVault(pool);
                                const hideNonStakedVault = this.state.showOnlyMyStakedVaults && noAmountStaked;

                                if (pool.hidden || searchFiltered || (noAmountStaked && (deprecated || pool.hiddenForNonStaked) && !pool.forceShow) || hideNonStakedVault) {
                                    return (<div key={stakeTokenName + "hidden" + key}></div>)
                                }
                                return (
                                    <FarmCard key={stakeTokenName + key}
                                        pool={pool}
                                        showLpAmounts={this.state.showLpAmounts}

                                        account={this.props.account}
                                        isLoggedIn={isLoggedIn}
                                        networkId={this.props.networkId}

                                        showStakingModal={this.props.showStakingModal}
                                    />
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getWrappedName(tokenName) {
        if(tokenName === "BTC" || tokenName === "ETH" || tokenName === "BNB" || tokenName === "MATIC") {
            return "W" + tokenName;
        }
        return tokenName;
    }
    getUnwrappedName(tokenName) {
        if(tokenName.startsWith("W")) {
            return tokenName.substring(1);
        }
        if(tokenName === "MAI") {
            return "mimatic";
        }
        return tokenName;
    }

    //Names should both be in unwrapped form (BTC/ETH) or both in wrapped form (WBTC/WETH)
    isFiltered(pool) {
        if(this.state.searchTerm) {
            const token0Name = pool.token0Name;
            const token1Name = pool.token1Name;
            const nameOverride = pool.nameOverride;

            const stakeTokenName = token0Name + '/' + token1Name;
            const stakeTokenNameReversed = token1Name + '/' + token0Name;
            const stakeTokenNameWrapped = this.getWrappedName(token0Name) + '/' + this.getWrappedName(token1Name);
            const stakeTokenNameUnwrapped = this.getUnwrappedName(token0Name) + '/' + this.getUnwrappedName(token1Name);
            const stakeTokenNameWrappedReversed = this.getWrappedName(token1Name) + '/' + this.getWrappedName(token0Name);
            const stakeTokenNameUnwrappedReversed  = this.getUnwrappedName(token1Name) + '/' + this.getUnwrappedName(token0Name);

            let searchTerm = this.state.searchTerm.toLowerCase();
            if(nameOverride && nameOverride.toLowerCase().includes(searchTerm)) return this.filterByType(pool);
            if(stakeTokenName.toLowerCase().includes(searchTerm)) return this.filterByType(pool);
            if(stakeTokenNameReversed.toLowerCase().includes(searchTerm)) return this.filterByType(pool);
            if(stakeTokenNameWrapped.toLowerCase().includes(searchTerm)) return this.filterByType(pool);
            if(stakeTokenNameUnwrapped.toLowerCase().includes(searchTerm)) return this.filterByType(pool);
            if(stakeTokenNameWrappedReversed.toLowerCase().includes(searchTerm)) return this.filterByType(pool);
            if(stakeTokenNameUnwrappedReversed.toLowerCase().includes(searchTerm)) return this.filterByType(pool);
            return true;
        }
        return this.filterByType(pool);
    }

    filterByType(pool) {
        //console.log(this.state.typeFilter)
        //stablecoin list will also contain IS3USD and am3USD, different than the stablecoin list used for price calculation
        if(this.state.typeFilter === "stable lps") { //PoolTypes.DualStable
            return !(stablecoins.has(pool.token0.toLowerCase()) && stablecoins.has(pool.token1.toLowerCase()));
        }
        if(this.state.typeFilter === "stables") { //PoolTypes.SingleStable
            return !(stablecoins.has(pool.token0.toLowerCase()) || stablecoins.has(pool.token1.toLowerCase()));
        }
        //this pool type is used for whether only one token should be shown, so it'll show atricrypto
        /*if(this.state.typeFilter === "SINGLE_ASSET") { //PoolTypes.SingleAsset
            return pool.singleAsset;
        }*/
        return false;
    }

    //isn't this redundant because the user can simply type in the token they want in the search bar?
    /*filterByAsset(pool) {
        return false;
    }*/

    clearFilters() {
        this.setState({
            typeFilter: null
        });
        //need to also set the currently selected platform to "All"
    }

    shouldShowVault(pool) {
        if (this.state.platformFilter == "all") {
            return true;
        }
        else if(this.state.platformFilter === "degen") {
            return pool.degen;
        }
        else if(pool.platform === this.state.platformFilter) {
            return true;
        }
        return false;
    }

    isInDeprecatedVault(vaults) {
        for (let i = 0; i < vaults.length; i++) {
            if (vaults[i].deprecated && vaults[i].amountUserStaked > 0) {
                return true;
            }
        }
        return false;
    }

    getTVL(vaults, rewardPools) {
        let total = 0;
        for (const vault of vaults) {
            if (!vault.hidden && vault.lpPrice && !isNaN(vault.lpPrice) && vault.lpPrice > 0) {
                total += vault.totalStaked * vault.lpPrice;
            }
        }
        for (const pool of rewardPools) {
            if (pool.lpPrice && !isNaN(pool.lpPrice) && pool.lpPrice > 0) {
                total += pool.totalShares * pool.lpPrice;
            }
        }
        total += getFeeDistTvl();
        return currencyFormatter(total / 10 ** 18);
    }

    getMonthlyAddyStakerProfit(pools) {
        let total = 0;
        for (const pool of pools) {
            if (!pool.lpPrice || !pool.aprProfitToStakers) continue;

            if (!isNaN(pool.aprProfitToStakers) && pool.aprProfitToStakers > 0 && pool.aprProfitToStakers !== Infinity) {
                total += pool.totalStaked * pool.lpPrice * (pool.aprProfitToStakers / 100.0) / 12.0;
            }
        }
        return currencyFormatter(total / 10 ** 18);
    }

    getSortedPools(pools) {
        let sortedPools = [...pools];
        sortedPools.sort((a, b) => {
            //sort by amount user staked, then total staked
            if (this.state.sortedField === 'default') {
                if (a.valueUserStaked < b.valueUserStaked) {
                    return this.state.sortConfig[this.state.indices['userStaked']] ? 1 : -1;
                }
                if (a.valueUserStaked > b.valueUserStaked) {
                    return this.state.sortConfig[this.state.indices['userStaked']] ? -1 : 1;
                }
                if (a.valueTotalStaked < b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['valueTotalStaked']] ? 1 : -1;
                }
                if (a.valueTotalStaked > b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['valueTotalStaked']] ? -1 : 1;
                }
            }

            if (this.state.sortedField === 'valueTotalStaked') {
                if (a.valueTotalStaked < b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['valueTotalStaked']] ? 1 : -1;
                }
                if (a.valueTotalStaked > b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['valueTotalStaked']] ? -1 : 1;
                }
            }
            else if (this.state.sortedField === 'userStaked') {
                if (a.valueUserStaked < b.valueUserStaked) {
                    return this.state.sortConfig[this.state.indices['userStaked']] ? 1 : -1;
                }
                if (a.valueUserStaked > b.valueUserStaked) {
                    return this.state.sortConfig[this.state.indices['userStaked']] ? -1 : 1;
                }
            }
            else {
                if (a.apy < b.apy) {
                    return this.state.sortConfig[this.state.indices['apy']] ? 1 : -1;
                }
                if (a.apy > b.apy) {
                    return this.state.sortConfig[this.state.indices['apy']] ? -1 : 1;
                }
            }
            return 0;
        });
        return sortedPools;
    }

    getPlatformName(key) {
        if(platformKeyNameMap[key] !== undefined) {
            return platformKeyNameMap[key];
        }
        return key;
    }
}

export default FarmList;
