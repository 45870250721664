import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { approve, stake, stakeLocked } from '../api/actions/StakingRewardFunctions.js'

const noBorderButton = {
    borderStyle: 'none',
    borderWidth: '0'
};

const MIN_LOCK_TIME = 7;
const MAX_LOCK_TIME = 1095;

class LockedStakeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stakeAmount: '0',
            lockDuration: '0',
            checked: false
        };
        this.handleStakeAmountChange = this.handleStakeAmountChange.bind(this);
        this.handleLockDurationChange = this.handleLockDurationChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }
    handleStakeAmountChange(event) {
        this.setState({
            stakeAmount: event.target.value
        });
    }
    handleLockDurationChange(event) {
        this.setState({
            lockDuration: event.target.value
        });
    }
    handleCheckbox(event) {
        this.setState({
            checked: event.target.checked
        });
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const index = this.props.currentPool;
        const pool = this.props.stakingRewardsData[index];
        const userLpBalance = pool.userLpBalance;
        const tokenApproved = pool.lpTokenApproved / 10 ** 18 >= pool.userLpBalance / 10 ** 18;
        let stakeTokenName = pool.singleAsset ? pool.token1Name: (pool.token0Name + '/' + pool.token1Name + ' LP');
        if(pool.nameOverride) stakeTokenName = pool.nameOverride;

        let lockTime = this.getLockTime(this.state.lockDuration);

        return (
            <Modal className="modal-dialog-centered modal-dialog-scrollable" show={this.props.show} onHide={(event) => {
                    this.setState({ stakeAmount: '0', lockDuration: '0', checked: false });
                    this.props.hideModal();
                }}>
                <Modal.Header className="modal-header">
                    <h4 className="mb-0 pb-0">Stake {stakeTokenName}</h4>
                    <button className="close" aria-label="Close"
                        onClick={(event) => {
                            this.setState({ stakeAmount: '0', lockDuration: '0', checked: false });
                            this.props.hideModal();
                        }}>
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="col-12">
                        <LpBalance userLpBalance={userLpBalance} stakeTokenName={stakeTokenName} />

                        <div className="input-group mb-2">
                            <input type="number" min="0" step="0.1" className="form-control noValidate" placeholder="Amount to stake" value={this.props.value} onChange={this.handleStakeAmountChange} />
                        </div>

                        <div className="input-group mb-2">
                            <input type="number" min="7" max="1095" step="1"
                                className="form-control noValidate"
                                placeholder="Lock duration (7 to 1095 days)"
                                value={this.props.value}
                                onChange={this.handleLockDurationChange}
                                disabled = {(this.state.checked) ? "" : "disabled"} />
                        </div>

                        <small className="d-block">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="lockedCheckbox" onChange={this.handleCheckbox} />
                                <label className="form-check-label no-select" for="lockedCheckbox">Stake Locked</label>
                            </div>
                        </small>

                        <p className="text-center mt-3 mb-2">
                            {!tokenApproved && <button className="btn btn-success btn-block mb-2" style={noBorderButton}
                                onClick={(event) => {
                                    event.preventDefault();
                                    approve(this.props.account, pool.stakingRewards, pool.lpAddress);
                                }}
                            >Approve Tokens</button>}

                            <button className={"btn btn-block btn-success mb-2" + (tokenApproved ? "" : " disabled")}
                                onClick={(event) => {
                                    event.preventDefault();
                                    if(this.state.checked) {
                                        if(lockTime !== 0) stakeLocked(this.props.account, pool.stakingRewards, this.state.stakeAmount, lockTime);
                                    }
                                    else {
                                        stake(this.props.account, pool.stakingRewards, this.state.stakeAmount);
                                    }
                                }}
                            >{this.state.checked ? "Lock": "Stake"} {this.state.stakeAmount} {stakeTokenName}</button>

                            <button className={"btn btn-block btn-success" + (tokenApproved ? "" : " disabled")}
                                onClick={(event) => {
                                    event.preventDefault();
                                    if(this.state.checked) {
                                        if(lockTime !== 0) stakeLocked(this.props.account, pool.stakingRewards, pool.userLpBalance, lockTime, true);
                                    }
                                    else {
                                        stake(this.props.account, pool.stakingRewards, pool.userLpBalance, true);
                                    }
                                }}
                            >{this.state.checked ? "Lock": "Stake"} All {stakeTokenName}</button>
                        </p>

                        {this.state.checked && <small className="d-block">
                            {this.getLockDurationString(this.state.lockDuration)}
                        </small>}
                        {this.state.checked && <small className="d-block mb-0">
                            {this.stakingMultiplier(lockTime)}
                        </small>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer py-2">
                    <Button className="btn btn-secondary btn-sm m-0" style={noBorderButton} onClick={(event) => {
                        this.setState({ stakeAmount: '0', lockDuration: '0', checked: false });
                        this.props.hideModal();
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //parses value in days and converts it to seconds
    //returns 0 if NaN or out of range
    getLockTime(value) {
        if(value === undefined || value.length === 0 || value.trim().length === 0) {
            return 0;
        }
        var time = parseInt(value);
        if(isNaN(time) || time < MIN_LOCK_TIME || time > MAX_LOCK_TIME) {
            return 0;
        }
        return time * 86400;
    }

    getLockDurationString(value) {
        if(value === undefined || value.length === 0 || value.trim().length === 0) {
            return "Invalid lock duration";
        }
        var time = parseInt(value);
        if(isNaN(time)) {
            return "Invalid lock duration";
        }
        if(time < MIN_LOCK_TIME) {
            return "Lock duration too low, min duration 7 days";
        }
        if(time > MAX_LOCK_TIME) {
            return "Lock duration too high, max duration 1095 days";
        }
        return "Lock duration: " + time + " days";
    }

    stakingMultiplier(secs) {
        if(secs < 0) return "Staking multiplier: 0";

        const MULTIPLIER_BASE = 1e6;
        //will need to get data from blockchain if I decide to update this
        let locked_stake_max_multiplier = 3000000; // 6 decimals of precision. 1x = 1000000
        let locked_stake_time_for_max_multiplier = 3 * 365 * 86400; // 3 years

        let multiplier = (MULTIPLIER_BASE + (secs * (locked_stake_max_multiplier - MULTIPLIER_BASE)) / (locked_stake_time_for_max_multiplier));
        if (multiplier > locked_stake_max_multiplier) multiplier = locked_stake_max_multiplier;
        return "Staking multiplier: " + (multiplier / MULTIPLIER_BASE).toFixed(4);
    }
}

LockedStakeModal.propTypes = {
    show: PropTypes.bool
};

export default LockedStakeModal;


class LpBalance extends Component {
    render() {
        return (
            <p className="mb-0">
                <b>Balance: </b>
                {(this.props.userLpBalance / 10 ** 18).toFixed(8)} {this.props.stakeTokenName}
            </p>
        );
    }
}
