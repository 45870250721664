import React, { ChangeEvent, Dispatch, SetStateAction, VFC } from 'react';
import Decimal from 'decimal.js';
import { formatTokenAmount } from '../../../_helpers';
import AmountInput from '../../AmountInput/AmountInput';

interface LockAmountInputProps {
  balance: string | null;
  lockAmount: string;
  setLockAmount: Dispatch<SetStateAction<string>>;
  totalLocked?: number;
  errorMsg?: string;
  disabled?: boolean;
}

const LockAmountInput: VFC<LockAmountInputProps> = ({
  disabled,
  balance,
  lockAmount,
  setLockAmount,
  totalLocked,
  errorMsg,
}) => {
  const handleInvestAllTokens = () => {
    if (!balance) return;
    setLockAmount(balance.replace(',', '.'));
  };

  const handleLockAmountInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLockAmount(e.target.value.replace(',', '.'));
  };

  const getPostScriptText = () => {
    if (!totalLocked) {
      return '';
    }

    try {
      return `+ ${totalLocked} = ${formatTokenAmount(
        new Decimal(lockAmount).plus(totalLocked).toString(),
      )}`;
    } catch {
      return `+ ${formatTokenAmount(totalLocked)}`;
    }
  };

  return (
    <AmountInput
      balance={balance}
      disabled={disabled}
      postScriptText={getPostScriptText()}
      onInvestAllClick={handleInvestAllTokens}
      value={lockAmount}
      onChange={handleLockAmountInputChange}
      errorMsg={errorMsg}
    />
  );
};

export default LockAmountInput;
