import stakingABI from '../../abis/locked_vault.json'
import stakingABI_basic from '../../abis/locked_vault_basic.json'
import ERC20ABI from '../../abis/ERC20abi.json'
import { alertService } from '../../_services';
import { getDefaultGasPrice } from './GasHelper.js';
import { NetworkID } from '../../constants';

export const approve = (account, lpAddress, vaultContractAddress) => {
    const erc20 = window.web3.eth.Contract(ERC20ABI.abi, lpAddress, { gasPrice: getDefaultGasPrice()});
    console.debug(lpAddress + " " + vaultContractAddress)

    erc20.methods.approve(vaultContractAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const deposit = (account, vaultContractAddress, amount, duration) => {
    const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice()})

    var p = parseFloat(amount);
    console.debug('Deposit amount: ' + p);
    if (p >= 0.001) {
        vaultContract.methods.deposit(window.web3.utils.toWei(p.toString(), "ether"), duration).send({
            from: account
        }, (e, output) => {
            if (e) {
                alertService.alertLog(e);
            } else {
                alertService.alertTx(output);
            }
        });
    }
    else {
        alertService.error("Amount too small, use the stake all button to avoid rounding errors: " + p);
    }
}

//Separate function so people don't accidentally attempt to lock 0 tokens
export const extendLock = (account, vaultContractAddress, duration, basic = false) => {
    //Special case on Polygon because the locked-plus contract doesn't have the `setLockEndingDate` function
    //On all other networks, the `setLockEndingDate` function is used

    if(basic || window.networkId !== NetworkID.POLYGON_NETWORK_ID) {
        const vaultContract = window.web3.eth.Contract(stakingABI_basic.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice()})

        vaultContract.methods.setLockEndingDate(duration).send({
            from: account
        }, (e, output) => {
            if (e) {
                alertService.alertLog(e);
            } else {
                alertService.alertTx(output);
            }
        });
    }
    else {
        const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice()})

        vaultContract.methods.deposit(0, duration).send({
            from: account
        }, (e, output) => {
            if (e) {
                alertService.alertLog(e);
            } else {
                alertService.alertTx(output);
            }
        });
    }
}

export const depositAll = (account, vaultContractAddress, duration) => {
    const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice()})

    vaultContract.methods.depositAll(duration).send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const exit = (account, vaultContractAddress) => {
    if (!window.web3.utils.isAddress(vaultContractAddress)) {
        alertService.alertLog("Invalid address: " + vaultContractAddress);
        return;
    }

    const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice()})

    vaultContract.methods.withdrawAll().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const emergencyExit = (account, vaultContractAddress) => {
    if (!window.web3.utils.isAddress(vaultContractAddress)) {
        alertService.alertLog("Invalid address: " + vaultContractAddress);
        return;
    }
    const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice()})

    vaultContract.methods.emergencyWithdraw().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const claim = (account, vaultContractAddress) => {
    const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice()})

    vaultContract.methods.claim().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}
