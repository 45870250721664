import React, { VFC } from 'react';
import styles from './BoostCalculation.module.scss';
import { getAdamantTokenName } from '../../../api/Multichain';

interface BoostCalculationProps {
  boostPoints: number;
}

const BoostCalculation: VFC<BoostCalculationProps> = ({ boostPoints }) => {
  if (!boostPoints) {
    return (
      <div className={styles.container}>
        <div className={styles.boostCalcValueContainer}>
          <p className={styles.boostCalcValuePrefix}>get</p>
          <p className={styles.boostCalcValue}>Boost</p>
          <p className={styles.boostCalcValueSuffix}>points</p>
        </div>

        <p className={styles.boostCalcDesc}>
          Type in {getAdamantTokenName()} amount and choose lock duration to calculate your Boost
        </p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.boostCalcValueContainer}>
        <p className={styles.boostCalcValuePrefix}>get</p>
        <p className={styles.boostCalcValue}>{boostPoints.toFixed(2)}</p>
        <p className={styles.boostCalcValueSuffix}>boost point{boostPoints !== 1 ? 's' : ''}</p>
      </div>
    </div>
  );
};

export default BoostCalculation;
