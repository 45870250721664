import FEE_DIST_MULTICALL_ABI from '../../abis/multicall/cronos/addy_multicall.json'
import { isZero } from '../../utils';
import { getCompoundingAPY } from '../MathUtil.js'
import { cacheOnlyIfNumber, getTokenPrice, cacheTokenPrice } from '../PriceCache';

const ADDY_MULTICALL = "0x642251b8c36a8D7690f67dA64814ef2d675fB14e"

let feeDistTvl = 0;
let addyTotalSupply = 0;
let addyDollarPrice = 0;

export const getLockedCaddyVaultBasicAddress = () => {
    return "0x323663B759567BAf744C182634585F7164c3c442";
}
export const getLockedCaddyVaultPlusAddress = () => {
    return "0xD4bcCf04a7CA546D3cfC46205AA7C58EB98c7495";
}
export const getCaddyTokenAddress = () => {
    return "0x09ad12552ec45f82bE90B38dFE7b06332A680864";
}

export const getCronosFeeDistTvl = () => {
    return feeDistTvl;
}

//300m tokens in dev timelock, 300m tokens in airdrop timelock, 275m tokens in regular timelock
export const getCaddyTotalSupply = () => {
    return addyTotalSupply / 10 ** 18 - 875000000;
}
export const getCaddyDollarPrice = () => {
    return addyDollarPrice;
}

const WCRO = "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23";
const CHRONOSWAP_WCRO = "0xca2503482e5D6D762b524978f400f03E38d5F962"

export const getAddyDataCronos = async (web3, account) => {
    var addyData = {};

    const multicallContract = web3.eth.Contract(FEE_DIST_MULTICALL_ABI.abi, ADDY_MULTICALL)
    let addyFeeDistInfo = await multicallContract.methods.getAddyInfoFor(account).call();
    if(!addyFeeDistInfo) {
        return null;
    }
    //console.log(addyFeeDistInfo)

    let balanceOf_locked = addyFeeDistInfo.lockedStakingDataPlus.balanceOf;
    let ratio_locked = addyFeeDistInfo.lockedStakingDataPlus.ratio;
    let userLocked_lockedStaking = balanceOf_locked * ratio_locked / 1e18;

    let myBalance = addyFeeDistInfo.userBalance;
    let addyApproved = addyFeeDistInfo.addyApproved;

    let totalBalance = addyFeeDistInfo.totalBalance;
    let unlockedBalance = addyFeeDistInfo.unlockedBalance;

    let totalSupply = addyFeeDistInfo.totalSupply;
    addyTotalSupply = addyFeeDistInfo.addyTotalSupply; //for front page

    //Starting with Cronos, locked staking works differently
    //Both locked staking contracts stake in the "intermediate" staking contract, which locks 1 ADDY in the fee dist
    //This means I don't have to worry about having to balance the amount of tokens each contract has locked (to ensure each contract gets the amount of ADDY they're supposed to)
    let lockedSupply = Number(addyFeeDistInfo.lockedSupply) + Number(addyFeeDistInfo.lockedStakingDataPlus.totalBalance) + Number(addyFeeDistInfo.lockedStakingDataBasic.totalBalance);
    //console.log(addyFeeDistInfo.lockedSupply /1e18, addyFeeDistInfo.lockedStakingDataBasic.totalBalance /1e18, balanceOf_locked /1e18, ratio_locked /1e18)


    //Price of these tokens are currently fetched by the multicall: CRO, ADDY
    for(let i = 0; i < addyFeeDistInfo.pairData.length; i++) {
        cacheTokenPrice(
            addyFeeDistInfo.pairData[i].token0,
            addyFeeDistInfo.pairData[i].token1,
            addyFeeDistInfo.pairData[i].token0Bal,
            addyFeeDistInfo.pairData[i].token1Bal,
            addyFeeDistInfo.pairData[i].token0Decimals,
            addyFeeDistInfo.pairData[i].token1Decimals
        );
    }

    addyDollarPrice = getTokenPrice(getCaddyTokenAddress());
    let addyNativePrice = addyDollarPrice / getTokenPrice(WCRO);

    cacheOnlyIfNumber(CHRONOSWAP_WCRO, getTokenPrice(WCRO), 1, true);

    //Fee dist reward data
    let rewardForDurationAddy = addyFeeDistInfo.rewardForDurationAddy;
    let rewardForDurationNative = addyFeeDistInfo.rewardForDurationNative;

    let addyPerYear = (rewardForDurationAddy / lockedSupply * 52); //will use for the other contract
    if(isZero(lockedSupply)) {
        addyPerYear = 0;
    }
    let nativePerYear = rewardForDurationNative / totalSupply * 52;
    if(isZero(totalSupply)) {
        nativePerYear = 0;
    }
    let nativeApr = nativePerYear / addyNativePrice;
    if(isZero(addyNativePrice)) {
        nativeApr = 0;
    }

    let addyDaily = rewardForDurationAddy / 7e18;
    let nativeDaily = rewardForDurationNative / 7e18;
    let lockedAddyTVL = lockedSupply * addyDollarPrice / 1e18;
    feeDistTvl = totalSupply * addyDollarPrice;

    let compoundedAddyApy = getCompoundingAPY(lockedAddyTVL, addyDollarPrice, addyDaily, 0);
    let compoundedNativeApy = getCompoundingAPY(feeDistTvl / 1e18, getTokenPrice(WCRO), nativeDaily, 0);
    let compoundedTotalApy = compoundedAddyApy + compoundedNativeApy;

    //APY when compounding native back into ADDY
    //TVL of locked stakers `lockedAddyTVL`, so need to multiply `nativeDaily` by lockedAddyTVL / feeDistTvl to get the amount of native going to locked ADDY stakers
    let addyDailyNativeValue = addyDaily * addyNativePrice; //convert the value of ADDY received daily to the native token
    let nativeToAddyApy = getCompoundingAPY(lockedAddyTVL, getTokenPrice(WCRO), addyDailyNativeValue + nativeDaily * (lockedAddyTVL / (feeDistTvl / 1e18)), 0);

    let claimableRewards = addyFeeDistInfo.claimableRewards;
    let claimableAddyAmt = claimableRewards[0][1];
    let claimableNativeAmt = claimableRewards[1][1];

    let vestedBalances = addyFeeDistInfo.earningsData[0];
    let vestedBalanceAmount = vestedBalances ? vestedBalances[0]: 0
    let vestedBalanceUnlockDate = vestedBalances ? vestedBalances[1]: 0

    let withdrawableAmount = addyFeeDistInfo.withdrawableAmount; //total of all withdrawable tokens
    let penaltyAmount = addyFeeDistInfo.withdrawablePenaltyAmount; //penalty from withdrawing vested tokens early
    let totalWithdrawable = (withdrawableAmount / 10 ** 18) + (penaltyAmount / 10 ** 18);

    addyData = {
        addyPerEth: addyFeeDistInfo.addyPerProfitEth,
        addyTotalSupply: addyTotalSupply,
        //Dollar prices should always be divided by 1e18 first
        //ETH prices should be left in wei
        addyNativePrice: addyNativePrice,

        //think these might be there to prevent the site from crashing when changing chains?
        wmaticEthPrice: 0,

        myBalance: myBalance,
        addyApproved: addyApproved,

        totalBalance: totalBalance,
        unlockedBalance: unlockedBalance,

        totalSupply: totalSupply,
        lockedSupply: lockedSupply,
        nextLockStart: addyFeeDistInfo.nextLockStart,
        //should iterate thru array tbh
        rewardForDurationAddy: rewardForDurationAddy,
        rewardForDurationQuick: 0, //rewardForDurationQuick,
        rewardForDurationWmatic: 0, //rewardForDurationWmatic,
        rewardForDurationNative: rewardForDurationNative,
        addyPerYear: addyPerYear,
        addyPerYearNoBuybacks: addyPerYear,
        quickPerYear: 0,
        quickApy: 0,
        wmaticPerYear: 0,
        wmaticApy: 0,
        nativePerYear: nativePerYear,
        nativeApr: nativeApr,
        feeSharingApy: nativeApr,

        //vestedBalances: vestedBalances,
        earningsData: addyFeeDistInfo.earningsData,
        totalWithdrawable: totalWithdrawable,
        vestedBalanceAmount: vestedBalanceAmount,
        vestedBalanceUnlockDate: vestedBalanceUnlockDate,

        claimableRewards: claimableRewards,
        claimableAddyAmt: claimableAddyAmt,
        claimableQuickAmt: 0, //claimableQuickAmt,
        claimableWmaticAmt: 0, //claimableWmaticAmt,
        claimableNativeAmt: claimableNativeAmt,

        //lockedBalances: lockedBalances,
        lockedTotal: addyFeeDistInfo.locked_total,
        lockedUnlockable: addyFeeDistInfo.locked_unlockable,
        lockedLocked: addyFeeDistInfo.locked,
        lockData: addyFeeDistInfo.lockData,

        withdrawableAmount: withdrawableAmount,
        penaltyAmount: penaltyAmount,
        withdrawableWithNoPenalty: addyFeeDistInfo.withdrawableWithNoPenalty,

        compoundedAddyApy: compoundedAddyApy,
        compoundedNativeApy: compoundedNativeApy,
        compoundedTotalApy: compoundedTotalApy,
        nativeToAddyApy: nativeToAddyApy,

        //Lock Plus data
        addyApproved_locked: addyFeeDistInfo.lockedStakingDataPlus.addyApproved,
        totalBalance_locked: addyFeeDistInfo.lockedStakingDataPlus.totalBalance,
        balanceOf_locked: addyFeeDistInfo.lockedStakingDataPlus.balanceOf,
        endingTimestamp_locked: addyFeeDistInfo.lockedStakingDataPlus.endingTimestamp,
        ratio_locked: addyFeeDistInfo.lockedStakingDataPlus.ratio,
        pendingReward_locked: addyFeeDistInfo.lockedStakingDataPlus.pendingReward,

        //Basic Lock data
        addyApproved_basic: addyFeeDistInfo.lockedStakingDataBasic.addyApproved,
        totalBalance_basic: addyFeeDistInfo.lockedStakingDataBasic.totalBalance,
        balanceOf_basic: addyFeeDistInfo.lockedStakingDataBasic.balanceOf,
        endingTimestamp_basic: addyFeeDistInfo.lockedStakingDataBasic.endingTimestamp,
        pendingRewardA_basic: addyFeeDistInfo.lockedStakingDataBasic.pendingRewardA,
        pendingRewardB_basic: addyFeeDistInfo.lockedStakingDataBasic.pendingRewardB,

        userLocked_lockedStaking: userLocked_lockedStaking,
        user_veAddy: addyFeeDistInfo.user_veAddy,
        user_veAddy_from_lock_plus: addyFeeDistInfo.user_veAddy_from_lock_plus,
        user_veAddy_from_lock_basic: addyFeeDistInfo.user_veAddy_from_lock_basic,
        user_boost: addyFeeDistInfo.user_boost,
        buybackApy: 0 //buybackApy
    }
    //console.log(addyData);

    return addyData;
}

/**/
