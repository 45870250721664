import React, { useRef } from 'react';

import Link from '../../components/common/Link/Link';
import LandingPageHeader from '../../components/LandingPageHeader/LandingPageHeaderContainer';
import TextWithImage from '../../components/TextWithImage/TextWithImage';
import ExplainerSection from '../../components/ExplainerSection/ExplainerSection';
import ComparisonSection from '../../components/ComparisonSection/ComparisonSection';

import MaximizeYieldsDark from './MaximizeYields-dark.svg';
import MaximizeYieldsLight from './MaximizeYields-light.svg';
import StakeAndVestingDark from './StakeAndVesting-dark.svg';
import StakeAndVestingLight from './StakeAndVesting-light.svg';
import JoinCommunityDark from './JoinCommunity-dark.svg';
import JoinCommunityLight from './JoinCommunity-light.svg';
import NotOnlyPolygonDark from './NotOnlyPolygon-dark.svg';
import NotOnlyPolygonLight from './NotOnlyPolygon-light.svg';

import styles from './LandingPage.module.scss';
import { useUserContext } from '../../contexts/UserContext';

interface LandingPageProps {
  pools: any;
  stakingRewardsData: any;
  isVaultsLoaded: boolean;
}

const LandingPage: React.VFC<LandingPageProps> = ({
  pools,
  stakingRewardsData,
  isVaultsLoaded,
}) => {
  const { isDarkMode } = useUserContext();
  const maximizeYieldsScrollRef = useRef<HTMLDivElement>(null);

  const handleLearnMoreClick = () => {
    if (maximizeYieldsScrollRef && maximizeYieldsScrollRef.current) {
      maximizeYieldsScrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.container}>
      <LandingPageHeader
        pools={pools}
        stakingRewardsData={stakingRewardsData}
        isVaultsLoaded={isVaultsLoaded}
        onLearnMoreClick={handleLearnMoreClick}
      />

      <TextWithImage
        image={isDarkMode ? MaximizeYieldsDark : MaximizeYieldsLight}
        title="Maximize Yields with Adamant"
        text={
          <>
            <p>
              Adamant currently offers hundreds of vault contracts across multiple chains.
              <br />
              Users deposit LP (Liquidity Provider) tokens to Adamant&apos;s vaults, which are then
              automatically compounded up to 1000+ times each day into more LP tokens.
            </p>
            <p>
              The power of exponential compound interest allows Adamant&apos;s vaults to drastically
              increase user yields. Users also save gas and avoid the hassle of manually yield
              farming.
            </p>
            <p>
              Adamant&apos;s{' '}
              <Link
                href="https://adamantfinance.gitbook.io/adamant-finance/guides/vaults/vault-performance-fees"
                newTab
                color="primary"
              >
                fees
              </Link>{' '}
              are the lowest on Polygon.
            </p>
          </>
        }
        linkRight={
          <Link href="/vaults" color="primary">
            Go to Vaults
          </Link>
        }
        scrollRef={maximizeYieldsScrollRef}
      />

      <ExplainerSection />

      <TextWithImage
        image={isDarkMode ? StakeAndVestingDark : StakeAndVestingLight}
        title="Stake and Vesting"
        text={
          <>
            <p>
              Stake Adamant&apos;s native token, ADDY, to earn performance fee dividends from
              Adamant&apos;s vaults. Stakers can gain exposure to the returns from the entire
              Polygon DeFi scene without having to manage multiple platforms and farms.
            </p>
            <p>
              Up to 50% of the performance fee is used to buyback ADDY to create deflationary
              pressure on it and increase the price over time.
            </p>
          </>
        }
        linkRight={
          <Link href="/stakeaddy" color="primary">
            Go to Stake
          </Link>
        }
        reversed
      />

      <ComparisonSection />

      <TextWithImage
        image={isDarkMode ? JoinCommunityDark : JoinCommunityLight}
        title="Join our community"
        text={
          <p>
            If you need more information about Adamant, you can join our Discord community or read
            the latest news on our Twitter. On Discord you will find members of our team and meet
            many users who share their experience and knowledge. By participating in the Discord,
            you can also influence the future changes of Adamant.
          </p>
        }
        linkLeft={
          <Link href="https://t.me/adamantfinance" newTab color="primary">
            Visit Telegram
          </Link>
        }
        linkRight={
          <Link href="https://discord.gg/69ureKKYz2" newTab color="primary">
            Open Discord
          </Link>
        }
      />

      <TextWithImage
        image={isDarkMode ? NotOnlyPolygonDark : NotOnlyPolygonLight}
        title="Adamant is multi-chain"
        text={
          <p>
            Adamant is also on Crypto.com&apos;s Cronos chain, where stakers can earn CADDY, the
            Adamant platform token for that chain.
          </p>
        }
        reversed
      />
    </div>
  );
};

export default LandingPage;
