export { ExternalLinks } from './external-links';

export const FEE = 0.029;

export const NetworkID = {
  ETH_MAINNET_ID: 1,
  POLYGON_NETWORK_ID: 137,
  ARBITRUM_NETWORK_ID: 42161,
  CRONOS_NETWORK_ID: 25,
  POLYGON_TESTNET_ID: 80001
};

export const WETH = {
  POLYGON: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  ARBITRUM: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  CRONOS: '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a'
};

export const getArbyTokenAddress = () => {
  return '0x09ad12552ec45f82bE90B38dFE7b06332A680864';
};

export const getAddyTokenAddress = () => {
  return '0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539';
};

export const getCaddyTokenAddress = () => {
    return "0x09ad12552ec45f82bE90B38dFE7b06332A680864";
}

export const getTestnetAddyTokenAddress = () => {
    return "0x16191248a4bb1fC75A38c4b4Ccaef238113fF347";
}
