import React, { ReactNode, useRef } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import ToastMessage from '../components/ToastMessage/ToastMessage';

interface CustomToastOptions extends Omit<ToastOptions, 'closeButton' | 'type'> {
  title: string;
  body: ReactNode;
  actions?: {
    label: string;
    callback: () => void;
  }[];
}

const openToast = (type: 'info' | 'error' | 'loading', options: CustomToastOptions) => {
  const { title, body, actions, ...toastifyOptions } = options;

  const toastId = toast(
    <ToastMessage type={type} title={title} content={body} actions={actions} />,
    {
      ...toastifyOptions,
    },
  );

  const dismiss = () => toast.dismiss(toastId);

  const update = (updateOptions: CustomToastOptions) => {
    toast.update(toastId, {
      ...updateOptions,
      render: (
        <ToastMessage
          type={type}
          title={updateOptions.title}
          content={updateOptions.body}
          actions={updateOptions.actions}
        />
      ),
    });
  };

  return {
    dismiss,
    update,
  };
};

export type Toast = ReturnType<typeof openToast>;

export const showToast = {
  info: (options: CustomToastOptions) => openToast('info', options),
  error: (options: CustomToastOptions) => openToast('error', options),
  loading: (options: CustomToastOptions) => openToast('loading', options),
};

export const useToast = (type: 'info' | 'error' | 'loading') => {
  const toastRef = useRef<Toast | null>(null);

  const show = (options: CustomToastOptions) => {
    toastRef.current = openToast(type, options);
  };

  const dismiss = () => {
    if (toastRef.current) {
      toastRef.current.dismiss();
    }
  };

  const update = (options: CustomToastOptions) => {
    if (toastRef.current) {
      toastRef.current.update(options);
    }
  };

  return {
    show,
    dismiss,
    update,
  };
};
