import React, { VFC } from 'react';
import dayjs from 'dayjs';
import { getAdamantTokenName } from '../../../../api/Multichain';
import Button from '../../../common/Button/Button';
import { RoutingPaths } from '../../../../paths';
import { boostFormatter } from '../../../../_helpers';

import styles from './BoostTileActions.module.scss';

const lockedAmountFormatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 4,
});

interface Props {
  vaults: any[];
  addyData: any;
  lockedAmount: number;
}

const BoostTileActions: VFC<Props> = ({ vaults, addyData, lockedAmount }) => {
  const highestBoost = vaults.reduce(
    (max: number, curr: any) => (curr.boost > max ? curr.boost : max),
    0,
  );

  const unlockTimestamp =
    Math.max(
      addyData.endingTimestamp_basic, // basic lock exists on all chains
      Number.isNaN(addyData.endingTimestamp_lockedLpVault) ||
        addyData.endingTimestamp_lockedLpVault === undefined
        ? 0
        : addyData.endingTimestamp_lockedLpVault,
      Number.isNaN(addyData.endingTimestamp_locked) || addyData.endingTimestamp_locked === undefined
        ? 0
        : addyData.endingTimestamp_locked,
    ) * 1000;
  const unlockDaysLeft = Math.max(0, dayjs(unlockTimestamp).diff(dayjs(), 'days'));

  const adamantTokenName = getAdamantTokenName();

  return lockedAmount && lockedAmount > 0 ? (
    <>
      <div className={styles.value}>
        <p className={styles.prefix}>up to</p>
        <p className={styles.amount}>{boostFormatter(highestBoost)}</p>
        <p className={styles.suffix}>X</p>
      </div>

      <p className={styles.lockedAmount}>
        {lockedAmountFormatter.format(lockedAmount)} {adamantTokenName} locked for {unlockDaysLeft}{' '}
        {unlockDaysLeft === 1 ? 'day' : 'days'}
      </p>
    </>
  ) : (
    <>
      <Button color="primary" to={RoutingPaths.STAKE_ADDY} className={styles.actionButton}>
        <div>
          <span>Boost your earnings</span>
          <span>Lock {adamantTokenName}</span>
        </div>
      </Button>

      <p className={styles.actionDesc}>Increase your rewards up to 2.0x</p>
    </>
  );
};

export default BoostTileActions;
