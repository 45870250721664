import React, {
  VFC,
  useState,
  useCallback,
  SyntheticEvent,
  ReactNode,
  useRef,
  useEffect,
  KeyboardEvent,
} from 'react';
import classnames from 'classnames';

import Checkbox from '../Checkbox/Checkbox';
import RadioButton from '../RadioButton/RadioButton';
import DropdownMenu from '../../DropdownMenu/DropdownMenu';

import styles from './Select.module.scss';

export type SelectOption = {
  value: string;
  name: string;
};

interface SelectProps {
  options: SelectOption[];
  multiple?: boolean;
  label?: ReactNode;
  values: string[];
  onChange: any;
  onClear?: () => void;
}

const Select: VFC<SelectProps> = ({ options, multiple, label, values, onChange, onClear }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentOptions, setCurrentOptions] = useState(options);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const arrowButtonRef = useRef<HTMLButtonElement>(null);

  const toggleOpenDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const openDropdown = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
    setSearchTerm('');
    setCurrentOptions(options);
  }, [options]);

  const handleClickAway = useCallback(
    (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        // @ts-ignore
        !dropdownRef.current.contains(e.target) &&
        ((inputRef.current &&
          // @ts-ignore
          !inputRef.current.contains(e.target) &&
          arrowButtonRef.current &&
          // @ts-ignore
          !arrowButtonRef.current.contains(e.target)) ||
          (buttonRef.current &&
            // @ts-ignore
            !buttonRef.current.contains(e.target)))
      ) {
        closeDropdown();
      }
    },
    [closeDropdown],
  );

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => handleClickAway(ev));

    return document.removeEventListener('mousedown', (ev) => handleClickAway(ev));
  }, [handleClickAway]);

  const onSearchInputChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      setSearchTerm(e.currentTarget.value);
      setCurrentOptions(
        options.filter(({ name }) =>
          name.toLowerCase().includes(e.currentTarget.value.toLowerCase()),
        ),
      );
    },
    [options],
  );

  const handleEscapePress = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      closeDropdown();
    }
  };

  return (
    <div>
      <div className={styles.labelWrapper}>{label}</div>
      <div className={classnames(styles.container, isOpen && styles.open)}>
        {multiple ? (
          <>
            <input
              type="text"
              className={styles.textInput}
              placeholder={values.length > 0 ? `${values.length} Selected...` : 'All'}
              value={searchTerm}
              onChange={onSearchInputChange}
              onFocus={openDropdown}
              onKeyUp={handleEscapePress}
              ref={inputRef}
            />
            <button
              className={classnames(styles.arrowButton, isOpen && styles.open)}
              type="button"
              onClick={toggleOpenDropdown}
              onKeyUp={handleEscapePress}
              ref={arrowButtonRef}
            >
              <img src="/icons/ChevronDown.svg" alt="" />
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={toggleOpenDropdown}
            className={styles.openButton}
            ref={buttonRef}
          >
            {currentOptions.find((option) => option.value === values[0])?.name}
          </button>
        )}
        {isOpen && (
          <DropdownMenu ref={dropdownRef}>
            {currentOptions.length ? (
              currentOptions.map(({ value, name }) => {
                const isChecked = Boolean(values.find((val) => val === value));
                return multiple ? (
                  <Checkbox
                    key={value}
                    value={value}
                    onChange={onChange}
                    checked={isChecked}
                    className={styles.dropdownItem}
                  >
                    {name}
                  </Checkbox>
                ) : (
                  <RadioButton
                    key={value}
                    value={value}
                    onChange={onChange}
                    checked={isChecked}
                    className={styles.dropdownItem}
                  >
                    {name}
                  </RadioButton>
                );
              })
            ) : (
              <span className={styles.noResults}>No results</span>
            )}
            {values.length > 0 && multiple && (
              <div className={styles.clearAllButtonWrapper}>
                <button type="button" className={styles.clearAllButton} onClick={onClear}>
                  clear all
                </button>
              </div>
            )}
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

export default Select;
