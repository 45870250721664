import FEE_DIST_MULTICALL_ABI from '../../abis/multicall/testnet/fee_dist_multicall.json'
import { cacheOnlyIfNumber, cacheTokenPrice, getTokenPrice } from '../PriceCache';
import { getCompoundingAPY } from '../MathUtil.js'

const ADDY_MULTICALL = "0x66b27AaF17009B9D61d151434342134fe3b515d8"

const WMATIC = "0xFac5C7819fdE19d006244E462Db02ed7Fe346E71"
const ADDY = "0x16191248a4bb1fC75A38c4b4Ccaef238113fF347";

let feeDistTvl = 0;
let addyTotalSupply = 0;
let addyDollarPrice = 0;

export const getTestnetLockedPlusAddress = () => {
    return "0x9d5d0cb1B1210d4bf0e0FdCC6aCA1583fA48f0fD";
}
export const getTestnetLockedBasicAddress = () => {
    return "0x1130Af7108DF6C797eb0A6788cD9D25e7312A072";
}
export const getTestnetTokenAddress = () => {
    return "0x16191248a4bb1fC75A38c4b4Ccaef238113fF347";
}

export const getPolygonTestnetFeeDistTvl = () => {
    return feeDistTvl;
}
export const getTestnetTotalSupply = () => {
    return addyTotalSupply / 10 ** 18;
}
export const getTestnetDollarPrice = () => {
    return addyDollarPrice;
}

export const getTestnetAddyData = async (web3, account) => {
    var addyData = {};

    const multicallContract = web3.eth.Contract(FEE_DIST_MULTICALL_ABI.abi, ADDY_MULTICALL)
    let addyFeeDistInfo = await multicallContract.methods.getAddyInfoFor(account).call();
    if(!addyFeeDistInfo) {
        return null;
    }
    //console.log(addyFeeDistInfo)

    //Locked staking plus data
    let balanceOf_locked = addyFeeDistInfo.lockedStakingDataPlus.balanceOf;
    let ratio_locked = addyFeeDistInfo.lockedStakingDataPlus.ratio;
    let userLocked_lockedStaking = balanceOf_locked * ratio_locked / 1e18;

    let myBalance = addyFeeDistInfo.userBalance;
    let addyApproved = addyFeeDistInfo.addyApproved;

    let totalBalance = addyFeeDistInfo.totalBalance;
    let unlockedBalance = addyFeeDistInfo.unlockedBalance;

    let totalSupply = addyFeeDistInfo.totalSupply;
    addyTotalSupply = addyFeeDistInfo.addyTotalSupply; //for front page
    let lockedSupply = addyFeeDistInfo.lockedSupply;

    //Price of these tokens are currently fetched by the multicall: WETH, QUICK, WMATIC, ADDY, MUST, SUSHI, PYQ, WBTC
    for(let i = 0; i < addyFeeDistInfo.pairData.length; i++) {
        cacheTokenPrice(
            addyFeeDistInfo.pairData[i].token0,
            addyFeeDistInfo.pairData[i].token1,
            addyFeeDistInfo.pairData[i].token0Bal,
            addyFeeDistInfo.pairData[i].token1Bal,
            addyFeeDistInfo.pairData[i].token0Decimals,
            addyFeeDistInfo.pairData[i].token1Decimals
        );
    }
    console.debug('Native price:', getTokenPrice(WMATIC), "Addy price:", getTokenPrice(ADDY))

    addyDollarPrice = getTokenPrice(ADDY);
    feeDistTvl = totalSupply * addyDollarPrice; //why isn't this divided by 1e18?

    //Fee dist reward data
    let rewardForDurationAddy = addyFeeDistInfo.rewardForDurationAddy;
    let rewardForDurationWmatic = addyFeeDistInfo.rewardForDurationWmatic;

    let addyPerYearNoBuybacks = (rewardForDurationAddy / lockedSupply * 52); //used for the "Basic Lock" contract
    let addyPerYear = addyPerYearNoBuybacks;
    let wmaticPerYear = rewardForDurationWmatic / totalSupply * 52;
    let wmaticApy = wmaticPerYear * getTokenPrice(WMATIC) / addyDollarPrice;

    let addyDaily = rewardForDurationAddy / 7e18;
    let maticDaily = rewardForDurationWmatic / 7e18;
    let lockedAddyTVL = lockedSupply * addyDollarPrice / 1e18;

    let compoundedAddyApy = getCompoundingAPY(lockedAddyTVL, addyDollarPrice, addyDaily, 0);
    let compoundedWmaticApy = getCompoundingAPY(feeDistTvl / 1e18, getTokenPrice(WMATIC), maticDaily, 0);

    let compoundedTotalApy = compoundedAddyApy + compoundedWmaticApy;

    let claimableRewards = addyFeeDistInfo.claimableRewards;
    let claimableAddyAmt = claimableRewards[0][1];
    let claimableWmaticAmt = claimableRewards[1][1];

    let vestedBalances = addyFeeDistInfo.earningsData[0];
    let vestedBalanceAmount = vestedBalances ? vestedBalances[0]: 0
    let vestedBalanceUnlockDate = vestedBalances ? vestedBalances[1]: 0

    let withdrawableAmount = addyFeeDistInfo.withdrawableAmount; //total of all withdrawable tokens
    let penaltyAmount = addyFeeDistInfo.withdrawablePenaltyAmount; //penalty from withdrawing vested tokens early
    let totalWithdrawable = (withdrawableAmount / 10 ** 18) + (penaltyAmount / 10 ** 18);

    addyData = {
        addyPerEth: addyFeeDistInfo.addyPerProfitEth,
        addyTotalSupply: addyTotalSupply,

        addyEthPrice: addyDollarPrice / getTokenPrice(WMATIC),

        myBalance: myBalance,
        addyApproved: addyApproved,

        totalBalance: totalBalance,
        unlockedBalance: unlockedBalance,

        totalSupply: totalSupply,
        lockedSupply: lockedSupply,
        nextLockStart: addyFeeDistInfo.nextLockStart,
        //should iterate thru array tbh
        rewardForDurationAddy: rewardForDurationAddy,
        rewardForDurationWmatic: rewardForDurationWmatic,
        addyPerYear: addyPerYear,
        addyPerYearNoBuybacks: addyPerYear,
        wmaticPerYear: wmaticPerYear,
        wmaticApy: wmaticApy,
        feeSharingApy: wmaticApy,

        //vestedBalances: vestedBalances,
        earningsData: addyFeeDistInfo.earningsData,
        totalWithdrawable: totalWithdrawable,
        vestedBalanceAmount: vestedBalanceAmount,
        vestedBalanceUnlockDate: vestedBalanceUnlockDate,

        claimableRewards: claimableRewards,
        claimableAddyAmt: claimableAddyAmt,
        claimableWmaticAmt: claimableWmaticAmt,

        //lockedBalances: lockedBalances,
        lockedTotal: addyFeeDistInfo.locked_total,
        lockedUnlockable: addyFeeDistInfo.locked_unlockable,
        lockedLocked: addyFeeDistInfo.locked,
        lockData: addyFeeDistInfo.lockData,

        withdrawableAmount: withdrawableAmount,
        penaltyAmount: penaltyAmount,
        withdrawableWithNoPenalty: addyFeeDistInfo.withdrawableWithNoPenalty,

        compoundedAddyApy: compoundedAddyApy,
        compoundedWmaticApy: compoundedWmaticApy,
        compoundedNativeApy: compoundedWmaticApy,
        compoundedTotalApy: compoundedTotalApy,

        //Lock Plus
        addyApproved_locked: addyFeeDistInfo.lockedStakingDataPlus.addyApproved,
        totalBalance_locked: addyFeeDistInfo.lockedStakingDataPlus.totalBalance,
        balanceOf_locked: addyFeeDistInfo.lockedStakingDataPlus.balanceOf,
        endingTimestamp_locked: addyFeeDistInfo.lockedStakingDataPlus.endingTimestamp,
        //ratio_locked: addyFeeDistInfo.lockedStakingDataPlus.ratio, //not used
        pendingReward_locked: addyFeeDistInfo.lockedStakingDataPlus.pendingReward,
        userLocked_lockedStaking: userLocked_lockedStaking,

        //Basic Lock
        addyApproved_basic: addyFeeDistInfo.lockedStakingDataBasic.addyApproved,
        totalBalance_basic: addyFeeDistInfo.lockedStakingDataBasic.totalBalance,
        balanceOf_basic: addyFeeDistInfo.lockedStakingDataBasic.balanceOf,
        endingTimestamp_basic: addyFeeDistInfo.lockedStakingDataBasic.endingTimestamp,
        pendingRewardA_basic: addyFeeDistInfo.lockedStakingDataBasic.pendingRewardA,
        pendingRewardB_basic: addyFeeDistInfo.lockedStakingDataBasic.pendingRewardB,

        //Boost points aren't implemented in the testnet due to the lack of oracles
        user_veAddy: addyFeeDistInfo.user_veAddy,
        user_veAddy_from_lock_plus: addyFeeDistInfo.user_veAddy_from_lock_plus,
        user_boost: addyFeeDistInfo.user_boost
    }
    //console.log(addyData);

    return addyData;
}

/**/
