import MULTICALL_ABI from '../../abis/multicall/addy_eth_staking_rewards_multicall.json'
import { getCompoundingAPY } from '../MathUtil.js';
import { getAdamantTokenDollarPrice } from '../../api/Multichain.js'
import { getTokenPrice } from '../PriceCache';

const STAKING_REWARDS_MULTICALL = "0xe39F2e12FC0EE298c8B8437C246dFD946Db8d198"

const REWARDS_CONTRACT = "0xA4c1763AEf101958635a0Ed1e0A744f4Bf630B73"
const LOCKED_REWARDS_CONTRACT_QUICK = "0xF7661EE874Ec599c2B450e0Df5c40CE823FEf9d3"

export const getAddyEthStakingRewardsData = async (web3, account, addyData) => {
    var stakingRewardsData = [];
    if(addyData == null) return stakingRewardsData;

    const multicallContract = web3.eth.Contract(MULTICALL_ABI.abi, STAKING_REWARDS_MULTICALL)
    let stakingRewardsInfos = await multicallContract.methods.getInfoForStakingRewards(account, [REWARDS_CONTRACT, LOCKED_REWARDS_CONTRACT_QUICK]).call();

    for (const stakingRewardsInfo of stakingRewardsInfos) {
        let stakingRewards = stakingRewardsInfo.stakingRewards;
        let newPool = stakingRewards.toLowerCase() !== REWARDS_CONTRACT.toLowerCase();

        let amountUserStaked = stakingRewardsInfo.amountUserStaked;
        let unlockedBalance = stakingRewardsInfo.unlockedBalance;
        let totalShares = stakingRewardsInfo.totalShares;
        let lpTokenApproved = stakingRewardsInfo.lpTokenApproved;
        let userLpBalance = stakingRewardsInfo.userLpBalance;

        let pendingReward = stakingRewardsInfo.pendingReward * addyData.addyPerEth;

        let lpSupply = stakingRewardsInfo.lpSupply;
        let lpStaked = stakingRewardsInfo.lpStaked;
        let boostedLpStaked = stakingRewardsInfo.boostedLpStaked;
        let ratioStaked = lpStaked / lpSupply;
        let boostedRatioStaked = boostedLpStaked / lpSupply;

        let rewardRate = stakingRewardsInfo.rewardRate * addyData.addyPerEth;
        let rewardsPerDay = 86400 * rewardRate / 10 ** 18;

        let periodFinish = stakingRewardsInfo.periodFinish;
        let lastUpdateTime = stakingRewardsInfo.lastUpdateTime;

        //Calculate the price of the ADDY/WETH LP token (value of ADDY or ETH in the LP contract * 2 / number of tokens)
        let token0 = stakingRewardsInfo.token0;
        let token1 = stakingRewardsInfo.token1;
        let token1Bal = stakingRewardsInfo.token1Bal;
        let decimals1 = stakingRewardsInfo.decimals1;

        let numToken1 = token1Bal / 10 ** decimals1;
        let valToken1 = numToken1 * getTokenPrice(token1);

        let poolTvl = valToken1 * 2;
        let lpPrice = poolTvl / lpSupply * 10 ** 18;

        const tvl = poolTvl * ratioStaked;
        const boostedTvl = poolTvl * boostedRatioStaked;
        let apr = getAdamantTokenDollarPrice() * rewardsPerDay * 365.0 * 100 / boostedTvl;

        let totalLocked = 0;
        for (const lockedStake of stakingRewardsInfo.lockedStakes) {
            totalLocked += lockedStake.amount / 1e18;
        }

        //91 day vesting period = start compounding at 91 days, which is roughly 4 times per year
        let apy_base = getCompoundingAPY(boostedTvl, getAdamantTokenDollarPrice(), rewardsPerDay, 0, 4);
        let apy_boosted = getCompoundingAPY(boostedTvl, getAdamantTokenDollarPrice(), rewardsPerDay * 3, 0, 4);

        //APY from vested ADDY fee sharing, based on sum of arithmetic sequence formula (1/365, 2/365, ... , 365/365)
        const addyFeeShareApr = apr * addyData.wmaticApy * 0.50136986301369863013698630136986;
        //Total APY from the ADDY token
        const totalAddyApr = apr + addyFeeShareApr;

        let poolData = {
            index: stakingRewardsData.length,
            newPool: newPool,
            deprecated: !newPool,
            hiddenForNonStaked: true,

            token0Name: "ADDY",
            token1Name: "WETH",

            platform: "quickswap",
            exchange: "quickswap",

            amountUserStaked: amountUserStaked,
            unlockedBalance: unlockedBalance,
            totalShares: totalShares,
            lpTokenApproved: lpTokenApproved,
            userLpBalance: userLpBalance,

            pendingReward: pendingReward,

            lpAddress: stakingRewardsInfo.stakingToken,
            lockedStakes: stakingRewardsInfo.lockedStakes,
            totalLocked: totalLocked,
            lpSupply: lpSupply,
            lpStaked: lpStaked,

            stakingRewards: stakingRewards,
            rewardRate: rewardRate,
            rewardsPerDay: rewardsPerDay,

            periodFinish: periodFinish,
            lastUpdateTime: lastUpdateTime,

            token0: token0,
            token1: token1,

            apy_base: apy_base,
            apy_boosted: apy_boosted,

            apr: apr,
            addyFeeShareApr: addyFeeShareApr,
            totalAddyApr: totalAddyApr,
            poolTvl: poolTvl, //The TVL of the entire liquidity pool
            tvl: tvl, //The vault's TVL
            lpPrice: lpPrice
        }
        stakingRewardsData.push(poolData)
    }
    return stakingRewardsData;
}

/**/
