import React, { useState, VFC } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import Button from '../common/Button/Button';
import timeDiffYearsDays from '../../_helpers/timeDiffYearsDays';
import {
  getLockedBasicAddress,
  getLockedPlusAddress,
  getAdamantTokenName,
} from '../../api/Multichain';
import { getNativeTokenName } from '../../constants/multichain/network';
import { NetworkID } from '../../constants';
import { useUserContext } from '../../contexts/UserContext';

import styles from './BoostLockInfoTiles.module.scss';

const getNativeTokenNameWrapper = (networkId: number) => {
  if (networkId === NetworkID.POLYGON_NETWORK_ID) {
    return 'WMATIC';
  }
  return getNativeTokenName();
};

interface BoostLockInfoTilesProps {
  lockedAmount: number;
  lockEndTimestamp: number;
  addyReward?: number;
  isLockPlus?: boolean;
  nativeTokenReward: number;
}

const BoostLockInfoTiles: VFC<BoostLockInfoTilesProps> = ({
  lockedAmount,
  lockEndTimestamp,
  addyReward,
  isLockPlus,
  nativeTokenReward,
}) => {
  const { networkId, boostLockFunctions } = useUserContext();
  const [isClaimPending, setIsClaimPending] = useState(false);
  const hasAddyReward = Boolean(addyReward || addyReward === 0);

  const adamantTokenName = getAdamantTokenName();
  const nativeTokenName = getNativeTokenNameWrapper(networkId);

  if (lockEndTimestamp === 0) {
    return null;
  }

  const lockEndDate = dayjs.unix(lockEndTimestamp);

  const isLockComplete = lockEndDate.isBefore(dayjs());

  const handleClaimAll = async () => {
    if (isClaimPending) return;
    setIsClaimPending(true);

    const vaultContractAddress = isLockPlus ? getLockedPlusAddress() : getLockedBasicAddress();

    try {
      if (isLockComplete && hasAddyReward) {
        await boostLockFunctions.claimAndExit([{ vaultContractAddress }]);
      } else if (isLockComplete && !hasAddyReward) {
        await boostLockFunctions.exit([{ vaultContractAddress }]);
      } else if (isLockComplete) {
        await boostLockFunctions.claim([{ vaultContractAddress }]);
      }
    } finally {
      setIsClaimPending(false);
    }
  };

  return (
    <div className={styles.container}>
      {isLockComplete && (
        <div className={styles.completeHeader}>
          <h3 className={styles.completeTitle}>Your locking period has completed!</h3>
          <p className={styles.completeSubtitle}>
            Now you can claim your {adamantTokenName} and Rewards
          </p>
        </div>
      )}

      <div className={classNames(styles.tilesWrapper, isLockComplete && styles.complete)}>
        {!isLockComplete && (
          <div className={styles.tile}>
            <h4 className={styles.title}>Locked {adamantTokenName}</h4>
            <p className={styles.text}>
              <b>{lockedAmount.toFixed(2)}</b> {adamantTokenName}
            </p>
            <p className={styles.subtext}>
              Unlocking in {timeDiffYearsDays(lockEndDate)} ({lockEndDate.format('D MMMM YYYY')})
            </p>
          </div>
        )}

        <div
          className={classNames(
            styles.tile,
            hasAddyReward && styles.wide,
            isLockComplete && styles.complete,
          )}
        >
          <h4 className={styles.title}>
            {isLockComplete
              ? 'Completed Lock and rewards'
              : `Claimable ${hasAddyReward ? 'rewards and' : ''} performance fees`}
          </h4>
          <p className={styles.text}>
            {(hasAddyReward || isLockComplete) && (
              <>
                <b>{((addyReward || 0) / 1e18 + (isLockComplete ? lockedAmount : 0)).toFixed(2)}</b>{' '}
                {adamantTokenName}
                <span className={styles.plus}>+</span>
              </>
            )}
            <b>{(nativeTokenReward / 1e18).toFixed(2)}</b> {nativeTokenName}
          </p>
          <Button
            className={styles.claimAllButton}
            color="primary"
            disabled={
              (!(addyReward || 0 + (isLockComplete ? lockedAmount : 0)) && !nativeTokenReward) ||
              isClaimPending
            }
            onClick={handleClaimAll}
          >
            Claim All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BoostLockInfoTiles;
