import React, { VFC } from 'react';

import Tabs from '../common/Tabs/Tabs';
import { getAdamantTokenName } from '../../api/Multichain';

import TabStatus, { TabStatusStateEnum } from './TabStatus';
import styles from './BoostTabs.module.scss';

interface BoostTabsProps {
  currentTab: number;
  setCurrentTab: (newTab: number) => void;
  basicTabStatus: TabStatusStateEnum;
  plusTabStatus: TabStatusStateEnum;
}

const BoostTabs: VFC<BoostTabsProps> = ({
  currentTab,
  setCurrentTab,
  basicTabStatus,
  plusTabStatus,
}) => {
  const adamantTokenName = getAdamantTokenName();

  return (
    <Tabs
      currentTab={currentTab}
      tabs={[
        <div className={styles.tabWrapper}>
          <div className={styles.tabHeader}>
            <h4 className={styles.tabTitle}>Basic Lock</h4>
            <TabStatus active={currentTab === 0} currentState={basicTabStatus} />
          </div>
          <p className={styles.tabText}>Increase your earnings from Vaults investments</p>
        </div>,
        <div className={styles.tabWrapper}>
          <div className={styles.tabHeader}>
            <h4 className={styles.tabTitle}>Lock Plus</h4>
            <TabStatus active={currentTab === 1} currentState={plusTabStatus} />
          </div>
          <p className={styles.tabText}>
            Get <b>2x more Boost Points</b> per {adamantTokenName} than in Basic Lock
          </p>
        </div>,
      ]}
      onTabChange={setCurrentTab}
      fullWidth
    />
  );
};

export default BoostTabs;
