import React, { FC, useCallback, useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

import DropdownMenu from '../DropdownMenu/DropdownMenu';
import RadioButton from '../common/RadioButton/RadioButton';
import { useVaultsFiltersContext, SortValuesEnum } from '../../contexts/VaultsFiltersContext';

import { ReactComponent as ChevronDown } from './chevron-down.svg';
import styles from './SortSelect.module.scss';

const options = [
  { value: SortValuesEnum.MY_INVESTMENTS, name: SortValuesEnum.MY_INVESTMENTS },
  { value: SortValuesEnum.TVL, name: SortValuesEnum.TVL },
  { value: SortValuesEnum.APY, name: SortValuesEnum.APY },
  { value: SortValuesEnum.RELEASE_TIMESTAMP, name: SortValuesEnum.RELEASE_TIMESTAMP },
];

interface SortSelectProps {
  myVaults: boolean;
}

const SortSelect: FC<SortSelectProps> = ({ myVaults }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { filtersState, onSortClick } = useVaultsFiltersContext();
  const { sort } = filtersState;
  const [currentSortValue, setCurrentSortValue] = useState<SortValuesEnum | 'Custom'>(sort.value);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClickAway = useCallback((e: MouseEvent) => {
    if (
      dropdownRef.current &&
      // @ts-ignore
      !dropdownRef.current.contains(e.target) &&
      buttonRef.current &&
      // @ts-ignore
      !buttonRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => handleClickAway(ev));

    return document.removeEventListener('mousedown', (ev) => handleClickAway(ev));
  }, [handleClickAway]);

  const optionsToDisplay = options.slice();

  if (!myVaults) {
    optionsToDisplay.shift();
  }

  const handleOnChange = useCallback(
    (value: string) => {
      setCurrentSortValue(value as SortValuesEnum);
      onSortClick(value);
    },
    [onSortClick],
  );

  useEffect(() => {
    if (filtersState.sort.value !== currentSortValue) {
      if (optionsToDisplay.find(({ value }) => value === filtersState.sort.value)) {
        setCurrentSortValue(filtersState.sort.value);
      } else setCurrentSortValue('Custom');
    }
  }, [currentSortValue, filtersState.sort.value, optionsToDisplay]);

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={classnames(styles.button, isOpen && styles.open)}
        onClick={toggleIsOpen}
        ref={buttonRef}
      >
        Sort by <b className={styles.selected}>{currentSortValue}</b>
        <ChevronDown className={classnames(styles.icon, isOpen && styles.open)} />
      </button>
      {isOpen && (
        <DropdownMenu className={styles.dropdown} ref={dropdownRef}>
          {optionsToDisplay.map(({ value, name }) => {
            const isChecked = value === sort.value;
            return (
              <RadioButton
                key={value}
                value={value}
                checked={isChecked}
                onClick={handleOnChange}
                className={styles.dropdownItem}
              >
                {name}
              </RadioButton>
            );
          })}
        </DropdownMenu>
      )}
    </div>
  );
};

export default SortSelect;
