import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Card.module.scss';

export interface CardProps {
  bgImage?: string;
  buttonElement?: ReactNode;
  expandBtn?: boolean;
}

const Card: FC<CardProps> = ({ buttonElement, expandBtn, bgImage, children }) => {
  return (
    <div className={classNames(styles.tile, buttonElement && styles.withButton)}>
      {bgImage && <img className={styles.bgImg} src={bgImage} alt="" />}

      {children}

      {buttonElement && (
        <div className={classNames(styles.buttonElement, expandBtn && styles.expand)}>
          {buttonElement}
        </div>
      )}
    </div>
  );
};

export default Card;
