export const getPlatformAddress = (platformCode: string) => {
  switch (platformCode) {
    case 'sandbox':
      return 'https://www.sandbox.game/';
    case 'quick':
      return 'https://quickswap.exchange/';
    case 'gains':
      return 'https://gains.trade/';
    case 'elk':
      return 'https://elk.finance/';
    case 'dfyn':
      return 'https://dfyn.network/';
    case 'cometh':
      return 'https://www.cometh.io/';
    case 'gogo':
      return 'https://www.gogocoin.io/';
    case 'polyquity':
      return 'https://polyquity.org/';
    case 'tel':
      return 'https://www.telco.in/';
    case 'polycrystal':
      return 'https://www.polycrystal.finance/';
    case 'pup':
      return 'https://www.puppyswap.com/';
    case 'tower':
      return 'https://towerfinance.io/';
    case 'popsicle':
      return 'https://popsicle.finance/';
    case 'mstable':
      return 'https://mstable.org/';
    case 'gravity':
      return 'https://gravityfinance.io/swap/';
    case 'wault':
      return 'https://swap.wault.finance/';
    case 'dino':
      return 'https://dinoswap.exchange/';
    case 'jet':
      return 'https://jetswap.finance/';
    case 'mai':
      return 'https://www.mai.finance/';
    case 'iron':
      return 'https://iron.finance/';
    case 'ape':
      return 'https://apeswap.finance/';
    case 'arcadium':
      return 'https://stadiumarcadium.farm/';
    case 'polypulsar':
      return 'https://www.polypulsar.farm/';
    case 'darkside':
      return 'https://darkside.finance/';
    case 'cafe':
      return 'https://cafeswap.finance/';
    case 'sushi':
      return 'https://sushi.com/';
    case 'curve':
      return 'https://curve.fi/';
    case 'chronoswap':
    case 'cyborg':
      return 'https://chronoswap.org/';
    default:
      return undefined;
  }
};

export const getExchangeUrl = (exchangeCode: string, token0: string, token1: string) => {
  switch (exchangeCode) {
    case 'quick':
      return `https://quickswap.exchange/#/add/${token0}/${token1}`;
    case 'quickswap':
      return `https://quickswap.exchange/#/add/${token0}/${token1}`;
    case 'elk':
      return `https://app.elk.finance/#/add/${token0}/${token1}`;
    case 'dfyn':
      return `https://exchange.dfyn.network/#/add/${token0}/${token1}`;
    case 'cometh':
      return `https://swap.cometh.io/#/add/${token0}/${token1}`;
    case 'polycat':
      return `https://polycat.finance/add/${token0}/${token1}`;
    case 'mstable':
      return 'https://mstable.org/';
    case 'gravity':
      return `https://gravityfinance.io/add/${token0}/${token1}`;
    case 'wault':
      return `https://swap.wault.finance/polygon/#/add/${token0}/${token1}`;
    case 'dino':
      return `https://trade.dinoswap.exchange/#/add/${token0}/${token1}`;
    case 'jet':
      return `https://polygon-exchange.jetswap.finance/#/add/${token0}/${token1}`;
    case 'iron':
      return 'https://iron.finance/';
    case 'ape':
      return `https://app.apeswap.finance/add/${token0}/${token1}`;
    case 'cafe':
      return `https://polygondex.cafeswap.finance/#/add/${token0}/${token1}`;
    case 'sushi':
      return `https://app.sushi.com/add/${token0}/${token1}`;
    case 'sushiswap':
      return `https://app.sushi.com/add/${token0}/${token1}`;
    case 'curve':
      return 'https://curve.fi/';
    case 'swapr':
      return `https://swapr.eth.link/#/add/${token0}/${token1}?chainId=42161`;
    case 'cronaswap':
      return `https://app.cronaswap.org/add/${token0}/${token1}`;
    case 'vvs':
      return `https://vvs.finance/add/${token0}/${token1}`;
    case 'crodex':
      return `https://swap.crodex.app/#/add/${token0}/${token1}`;
    case 'photonswap':
      return `https://photonswap.finance/#/add/${token0}/${token1}`;
    case 'chronoswap':
    case 'cyborg':
      return `https://chronoswap.org/add/${token0}/${token1}`;
    default:
      return '404';
  }
};
