const getTimeOffset = () => {
  const offset = new Date().getTimezoneOffset();

  if (offset === 0) return 'GMT';

  const sign = offset < 0 ? '+' : '-';
  const offsetAbs = Math.abs(offset);
  const offsetMins = offsetAbs % 60;

  return `GMT${sign}${Math.floor(offsetAbs / 60)}${offsetMins ? `:${offsetMins}` : ''}`;
};

export default getTimeOffset;
