import React, { VFC, useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { TabStatusStateEnum } from '../../components/BoostTabs/TabStatus';
import BoostTabs from '../../components/BoostTabs/BoostTabs';
import BoostSidePanel from '../../components/BoostSidePanel/BoostSidePanel';
import BoostTopTiles from '../../components/BoostTopTiles/BoostTopTilesContainer';
import BoostLockInfoTiles from '../../components/BoostLockInfoTiles/BoostLockInfoTiles';
import BoostLockForm from '../../components/BoostLockForm/BoostLockForm';
import BoostLegacy from '../../components/BoostLegacy/BoostLegacy';

import { getNativeTokenName } from '../../constants/multichain/network';
import { getAdamantTokenName } from '../../api/Multichain';
import { useUserContext } from '../../contexts/UserContext';
import { NetworkID } from '../../constants';

import styles from './BoostPage.module.scss';

const getCurrentState = (lockedAmount?: number, endTimestamp?: number) => {
  if (endTimestamp && endTimestamp > 0 && dayjs.unix(endTimestamp).isBefore(dayjs())) {
    return TabStatusStateEnum.COMPLETE;
  }
  if (typeof lockedAmount === 'number' && lockedAmount > 0) {
    return TabStatusStateEnum.LOCKED_ADDY;
  }
  return TabStatusStateEnum.NO_LOCKED_ADDY;
};

interface BoostPageProps {
  userLockedBasic?: number;
  userLockedPlus?: number;
  userBalance: number;
  basicLockEndTimestamp: number;
  plusLockEndTimestamp: number;
  aprNative: number;
  aprAddy: number;
  isLockPlusTokenApproved: boolean;
  isLocBasicTokenApproved: boolean;
  totalLockedBasic: number;
  totalLockedPlus: number;
  userBoostPointsBasic: number;
  userBoostPointsPlus: number;
  boostedInvestments: string;
  pendingAdamantTokenRewardBasic: number;
  pendingNativeTokenRewardPasic: number;
  pendingRewardPlus: number;
  userMaxBoost: number;
  lpBoostLockedAmount: number;
  lpBoostPendingReward: number;
  lpBoostEndTimestamp: number;
}

const BoostPage: VFC<BoostPageProps> = ({
  userLockedBasic,
  userLockedPlus,
  basicLockEndTimestamp,
  plusLockEndTimestamp,
  isLockPlusTokenApproved,
  isLocBasicTokenApproved,
  userBalance,
  aprNative,
  aprAddy,
  totalLockedBasic,
  totalLockedPlus,
  userBoostPointsBasic,
  userBoostPointsPlus,
  boostedInvestments,
  pendingAdamantTokenRewardBasic,
  pendingNativeTokenRewardPasic,
  pendingRewardPlus,
  userMaxBoost,
  lpBoostLockedAmount,
  lpBoostPendingReward,
  lpBoostEndTimestamp,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { networkId } = useUserContext();

  const basicTabStatus = getCurrentState(userLockedBasic, basicLockEndTimestamp);
  const plusTabStatus = getCurrentState(userLockedPlus, plusLockEndTimestamp);

  const [currentTabStatus, setCurrentTabStatus] = useState(basicTabStatus);
  const [isBoostLockFormTouched, setIsBoostLockFormTouched] = useState(false);

  useEffect(() => {
    if (currentTab === 1) {
      setCurrentTabStatus(plusTabStatus);
    } else {
      setCurrentTabStatus(basicTabStatus);
    }
  }, [basicTabStatus, currentTab, plusTabStatus]);

  const [isFormVisible, setIsFormVisible] = useState(
    currentTabStatus === TabStatusStateEnum.NO_LOCKED_ADDY,
  );
  const [isShowFormClicked, setIsShowFormClicked] = useState(false);

  useEffect(() => {
    if (currentTabStatus === TabStatusStateEnum.NO_LOCKED_ADDY) {
      setIsFormVisible(true);
    }

    if (
      currentTabStatus === TabStatusStateEnum.LOCKED_ADDY &&
      !isShowFormClicked &&
      !isBoostLockFormTouched
    ) {
      setIsFormVisible(false);
    }
  }, [isBoostLockFormTouched, currentTabStatus, isShowFormClicked]);

  const showForm = () => {
    setIsShowFormClicked(true);
    setIsFormVisible(true);
  };

  const getTokenName = () => {
    if (networkId === NetworkID.POLYGON_NETWORK_ID) {
      return 'WMATIC';
    }
    return getNativeTokenName();
  };

  const handleBoostLockFormTouched = () => {
    setIsBoostLockFormTouched(true);
  };

  const handleGoToLockPlus = () => {
    setCurrentTab(1);
  };

  const adamantTokenName = getAdamantTokenName();
  const isLockPlus = currentTab === 1;

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <BoostTopTiles
            userLockedBasic={userLockedBasic}
            userLockedPlus={userLockedPlus}
            basicLockEndTimestamp={basicLockEndTimestamp}
            plusLockEndTimestamp={plusLockEndTimestamp}
            userTotalBoostPoints={(userBoostPointsBasic + userBoostPointsPlus).toFixed(2)}
            boostedInvestments={boostedInvestments}
            userMaxBoost={userMaxBoost}
          />

          <BoostTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            basicTabStatus={basicTabStatus}
            plusTabStatus={plusTabStatus}
          />

          {currentTab === 0 && (
            <BoostLockInfoTiles
              lockedAmount={userLockedBasic || 0}
              lockEndTimestamp={basicLockEndTimestamp}
              addyReward={pendingAdamantTokenRewardBasic}
              nativeTokenReward={pendingNativeTokenRewardPasic}
            />
          )}

          {currentTab === 1 && (
            <BoostLockInfoTiles
              isLockPlus
              lockedAmount={userLockedPlus || 0}
              lockEndTimestamp={plusLockEndTimestamp}
              nativeTokenReward={pendingRewardPlus}
            />
          )}

          {isFormVisible && currentTabStatus !== TabStatusStateEnum.COMPLETE && (
            <>
              <div className={styles.header}>
                <h3 className={styles.headerTitle}>Lock {adamantTokenName} for Boost</h3>
                <div>
                  <span className={styles.headerSubText}>
                    APR <b>{(aprNative * 100).toFixed(2)}%</b> in {getTokenName()}
                  </span>
                  <span className={styles.headerSubText}>
                    APR <b>{(aprAddy * 100).toFixed(2)}%</b> in {adamantTokenName}
                  </span>
                </div>
              </div>

              <BoostLockForm
                isLockPlus={isLockPlus}
                userBalance={userBalance}
                userLockedBasic={userLockedBasic}
                userLockedPlus={userLockedPlus}
                isLocBasicTokenApproved={isLocBasicTokenApproved}
                isLockPlusTokenApproved={isLockPlusTokenApproved}
                basicLockEndTimestamp={basicLockEndTimestamp}
                plusLockEndTimestamp={plusLockEndTimestamp}
                onTouched={handleBoostLockFormTouched}
                userBoostPointsBasic={userBoostPointsBasic}
                userBoostPointsPlus={userBoostPointsPlus}
                onGoToLockPlus={handleGoToLockPlus}
              />
            </>
          )}

          {!isFormVisible && currentTabStatus !== TabStatusStateEnum.COMPLETE && (
            <div className={styles.header}>
              <button type="button" className={styles.headerLinkButton} onClick={showForm}>
                Increase your Boost
              </button>
              <p className={styles.headerLinkText}>
                By locking more {adamantTokenName} you can increase your Boost and earn more on your
                Vault investments
              </p>
            </div>
          )}
        </div>

        <BoostLegacy
          lockedAmount={lpBoostLockedAmount}
          pendingReward={lpBoostPendingReward}
          endTimestamp={lpBoostEndTimestamp}
        />
      </div>

      <BoostSidePanel totalLockedBasic={totalLockedBasic} totalLockedPlus={totalLockedPlus} />
    </div>
  );
};

export default BoostPage;
