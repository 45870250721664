import React, { VFC } from 'react';
import classNames from 'classnames';

import Link from '../common/Link/Link';
import { RoutingPaths } from '../../paths';
import { getAdamantTokenName } from '../../api/Multichain';
import { getNativeTokenName } from '../../constants/multichain/network';
import { NetworkID } from '../../constants';
import { useUserContext } from '../../contexts/UserContext';

import Particles from './bottom-particles.svg';
import TrolleyDark from './trolley-dark.svg';
import TrolleyLight from './trolley-light.svg';
import styles from './StakeSidePanel.module.scss';

const getNativeTokenNameWrapper = (networkId: number) => {
  if (networkId === NetworkID.POLYGON_NETWORK_ID) {
    return 'WMATIC';
  }
  return getNativeTokenName();
};

const StakeSidePanel: VFC = () => {
  const { networkId, isDarkMode } = useUserContext();
  const adamantTokenName = getAdamantTokenName();
  const nativeTokenName = getNativeTokenNameWrapper(networkId);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        Stake {adamantTokenName} to earn peformance fees in {nativeTokenName} with no lock-up
        required
      </h4>

      <p className={styles.subtitle}>
        You can withdraw staked {adamantTokenName} whenever you want
      </p>

      <h5 className={styles.sectionHeader}>Increase your earnings!</h5>

      <p className={styles.paragraph}>
        If you want to earn more than just Staking, you can Lock your {adamantTokenName}. This way
        you will also boost the rewards from investments in normal vaults
      </p>

      <div className={styles.linkWrapper}>
        <Link href={RoutingPaths.BOOST} color="primary">
          Lock for Boost
        </Link>
      </div>

      <h5 className={styles.tilesHeader}>Compare your options:</h5>

      <div className={styles.tilesRow}>
        <div className={styles.tile}>
          <h5 className={styles.tileTitle}>Stake</h5>
          <ul className={classNames(styles.tileList, styles.ticks)}>
            <li>Earn performance fees in {nativeTokenName}</li>
          </ul>
        </div>
        <div className={styles.tile}>
          <h5 className={styles.tileTitle}>Lock for Boost</h5>
          <ul className={classNames(styles.tileList, styles.ticks)}>
            <li>Earn performance fees in {nativeTokenName}</li>
            <li>Earn early withdrawal penalty fees</li>
            <li>Boost {adamantTokenName} earnings in Vaults</li>
          </ul>
        </div>
      </div>

      <div className={styles.tilesRow}>
        <div className={styles.tile}>
          <h5 className={styles.tileTitle}>Stake</h5>
          <ul className={styles.tileList}>
            <li>No lock duration</li>
            <li>No early withdrawal penalty</li>
          </ul>
        </div>
        <div className={styles.tile}>
          <h5 className={styles.tileTitle}>Lock for Boost</h5>
          <ul className={styles.tileList}>
            <li>Lock for 91 days - 4 years</li>
            <li>Can&apos;t withdraw at all</li>
          </ul>
        </div>
      </div>

      <p className={styles.textDim}>
        Need more information? Go to Documentation and{' '}
        <Link
          href="https://adamantfinance.gitbook.io/adamant-finance/"
          newTab
          className={styles.greyLink}
        >
          Learn more
        </Link>
      </p>

      <div className={styles.bottomImageWrapper}>
        <img src={isDarkMode ? TrolleyDark : TrolleyLight} alt="" className={styles.trolley} />
        <img src={Particles} alt="" className={styles.particles} />
      </div>
    </div>
  );
};

export default StakeSidePanel;
