import { showToast, useToast } from '../_services';

interface ToastOptions {
  title?: string;
  message?: string;
}

interface CreateTransactionsQueueOptions {
  onStart?: (counter: number) => void;
  onUpdate?: (counter: number) => void;
  onFail?: (counter: number) => void;
  onFinish?: (successfulCount: number, totalCount: number) => void;
  toastHook?: ReturnType<typeof useToast>;
  finishToastOptions?: ToastOptions;
}

export const createTransactionsQueue = (
  promiseFunctions: (() => Promise<void>)[],
  {
    onStart,
    onUpdate,
    onFail,
    onFinish,
    toastHook,
    finishToastOptions,
  }: CreateTransactionsQueueOptions,
) => {
  let counter = 1;

  onStart?.(counter);
  toastHook?.show({
    title: `Transaction in progress (${counter}/${promiseFunctions.length})`,
    body: 'waiting for blockchain',
    autoClose: false,
  });

  const promises = promiseFunctions.map(async (promiseFunction) => {
    try {
      await promiseFunction();
      onUpdate?.(counter);
      toastHook?.update({
        title: `Transaction in progress (${counter + 1}/${promiseFunctions.length})`,
        body: 'waiting for blockchain',
      });
      return true;
    } catch (e) {
      showToast.error({
        title: `Transaction ${counter} failed`,
        body: 'check your wallet for more details',
        autoClose: false,
        actions: [
          {
            label: 'Retry',
            callback: () => {
              createTransactionsQueue([promiseFunction], {
                onStart,
                onUpdate,
                onFail,
                onFinish,
                toastHook,
              });
            },
          },
        ],
      });
      onFail?.(counter);
      onUpdate?.(counter);
      toastHook?.update({
        title: `Transaction in progress (${counter + 1}/${promiseFunctions.length})`,
        body: 'waiting for blockchain',
      });
      return false;
    } finally {
      counter++;
    }
  });

  Promise.all(promises).then((resolved: Awaited<boolean>[]) => {
    const successfulCount = resolved.filter((r) => r).length;
    onFinish?.(successfulCount, resolved.length);
    toastHook?.dismiss();
    if (resolved.length > 1 && successfulCount === 0) {
      showToast.error({
        title: 'Failed',
        body: 'all transactions failed',
      });
    } else if (successfulCount >= 1) {
      showToast.info({
        title: finishToastOptions?.title || 'Completed',
        body:
          finishToastOptions?.message ||
          `${successfulCount}/${resolved.length} transactions successfully completed`,
      });
    }
  });
};
