import stakingRewardsABI from '../../abis/IStakingRewards.json';
import boostHandlerABI from '../../abis/boost_handler.json';
import { alertService } from '../../_services';
import { harvest, claim } from './VaultFunctions.js';
import { getDefaultGasPrice } from './GasHelper';
import { getBoostHandlerAddress } from '../Multichain';

export const harvestAll = (account, stakingData) => {
    for (let i = 0; i < stakingData.length; i++) {
        if (stakingData[i].amountUserStaked > 0) {
            harvest(account, stakingData[i].strategyAddress);
        }
    }
}

export const claimAll = (account, stakingData) => {
    let claimed = 0;
    for (let i = 0; i < stakingData.length; i++) {
        if (stakingData[i].pendingReward > 0) {
            claim(account, stakingData[i].vaultAddress);
            claimed++;
        }
    }
    console.debug("Claimed from # vaults: " + claimed);
}

export const unstakeFromStakingRewards = (account, stakingRewardsAddress) => {
    if (!window.web3.utils.isAddress(stakingRewardsAddress)) {
        alertService.alertLog("Invalid address: " + stakingRewardsAddress);
        return;
    }
    const rewardsContract = window.web3.eth.Contract(stakingRewardsABI.abi, stakingRewardsAddress, { gasPrice: getDefaultGasPrice() })

    rewardsContract.methods.exit().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const getBoostForValueStaked = async (account, vaultAddress, valueStaked) => {
    if (!window.web3.utils.isAddress(vaultAddress)) {
        alertService.alertLog("Invalid address: " + vaultAddress);
        return;
    }
    const contract = window.web3.eth.Contract(boostHandlerABI.abi, getBoostHandlerAddress());

    let boost = await contract.methods.getBoostForValueStaked(account, vaultAddress, valueStaked).call();
    return boost;
}
