/* global BigInt */
import '../css/FeeDist.css'
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faMinusSquare, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { stakeAddy, stakeAddyMax, withdrawFeeDist, claimFeeDistRewards, approveAddyForFeeDist } from '../api/actions/FeeDistFunctions.js'
import { claim, exit } from '../api/actions/LockedVaultFunctions.js'
import { NetworkID } from '../constants/index.js';
import { getNativeTokenName } from '../constants/multichain/network.js';
import { getTestnetAddyData } from '../api/polygon_testnet/AddyData.js';
import { getLockedBasicAddress, getLockedPlusAddress, getAdamantTokenName } from '../api/Multichain';
import Loading from '../components/Loading';

const noBorderButton = {
    borderStyle: 'none',
    borderWidth: '0'
};

class StakeVestTestnet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            web3: this.props.web3,
            quickPrice: 0,
            addyData: undefined,
            stakeAmount: '',
            lockedStakeAmount: '',
            lockedStakeDuration: '',
            expandedVest: false,
            expandedLock: false,
            enableClaimWithPenalty: false,
            lastTimeClickedWithdraw: 0
        };
        this.handleStakeChange = this.handleStakeChange.bind(this);
        this.handleLockedStakeChange = this.handleLockedStakeChange.bind(this);
        this.handleClaimCheckbox = this.handleClaimCheckbox.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)

        if (!this.state.loaded) {
            this.updateInfo();
            this.setState({
                loaded: true
            })
        }
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }


    handleStakeChange(event) {
      this.setState({
        stakeAmount: event.target.value
      });
    }
    handleLockedStakeChange(event) {
      this.setState({
        lockedStakeAmount: event.target.value
      });
    }
    handleLockDurationChange(event) {
      this.setState({
        lockedStakeDuration: event.target.value
      });
    }
    handleClaimCheckbox(event) {
        this.setState({
            enableClaimWithPenalty: event.target.checked
        });
    }

    toggleExpandVest = (event) => {
        if (!this.state.expandedVest) {
            this.setState({ expandedVest: true });
        }
        else {
            this.setState({ expandedVest: false });
        }
    }
    toggleExpandLock = (event) => {
        if (!this.state.expandedLock) {
            this.setState({ expandedLock: true });
        }
        else {
            this.setState({ expandedLock: false });
        }
    }

    render() {
        if(this.props.networkId !== NetworkID.POLYGON_TESTNET_ID) {
            return (
                <div id="loader" className="text-center">
                    <p className="text-center">
                        Please switch to the Polygon Testnet before visiting this page.<br />
                        If you did not mean to visit the Polygon Testnet staking page, please refresh the page.
                    </p>
                </div>
            )
        }
        if(!this.state.addyData) {
            return (
                <Loading />
            )
        }
        const isLoggedIn = this.props.account !== "0x0000000000000000000000000000000000000001";
        const myBalance = this.state.addyData.myBalance / 10 ** 18;
        const addyApproved = this.state.addyData.addyApproved;

        const unlockedBalance = this.state.addyData.unlockedBalance / 10 ** 18;

        //Lock Plus data
        const claimableNativeAmt = this.state.addyData.claimableNativeAmt / 10 ** 18;
        const endingTimestamp_locked = this.state.addyData.endingTimestamp_locked;
        const pendingReward_locked = this.state.addyData.pendingReward_locked / 1e18; //NATIVE

        //Basic Lock data
        const pendingRewardA_basic = this.state.addyData.pendingRewardA_basic / 1e18; //NATIVE
        const pendingRewardB_basic = this.state.addyData.pendingRewardB_basic / 1e18; //Adamant platform token
        const totalUserLocked_boost = this.state.addyData.balanceOf_basic / 1e18;
        const endingTimestamp_basic = this.state.addyData.endingTimestamp_basic;
        const addyPerYearNoBuybacks = this.state.addyData.addyPerYearNoBuybacks;

        const totalUserLocked = this.state.addyData.balanceOf_basic / 1e18 + this.state.addyData.userLocked_lockedStaking / 10 ** 18;

        const totalUserVeAddy = this.state.addyData.user_veAddy;
        const user_veAddy_from_lock_plus = this.state.addyData.user_veAddy_from_lock_plus;
        const user_veAddy_from_lock_basic = totalUserVeAddy - user_veAddy_from_lock_plus;

        const earningsData = this.state.addyData.earningsData;

        const lockData = this.state.addyData.lockData;
        const lockedTotal = this.state.addyData.lockedTotal; //this is in wei

        const addyPerYear = this.state.addyData.addyPerYear;
        const nativeApr = this.state.addyData.wmaticApy;
        const totalSupply = this.state.addyData.totalSupply;
        const lockedSupply = this.state.addyData.lockedSupply;
        const user_boost = this.state.addyData.user_boost / 1e18;

        let withdrawableAmount = this.state.addyData.withdrawableAmount;
        const penaltyAmount = this.state.addyData.penaltyAmount;
        const vestedTotal = this.state.addyData.totalWithdrawable - unlockedBalance; //this is in float
        //this.state.addyData.withdrawableWithNoPenalty = sum of expiredVests + any staked Adamant platform token
        let expiredVests = this.state.addyData.withdrawableWithNoPenalty - this.state.addyData.unlockedBalance;

        return (
            <div className="container my-5" id="stakeVest">
                <div className="eps-window white">
                    <div className="info-message gentle-message">{`Stake your ${getAdamantTokenName()} tokens below and start earning fees and penalty fees. Your vesting ${getAdamantTokenName()} is locked below.`}</div>

                    <Card id="claimAddy" className="mt-4 mb-4" style={{ backgroundColor: "rgb(123 120 120)" }}>
                        <Card.Header className="text-center no-select" style={{fontSize: "1.5rem"}}>Your {getAdamantTokenName()}</Card.Header>

                        <Card.Body>
                            <div className="mt-1 mb-2"><span>{`Current ${getAdamantTokenName()} balance in wallet: `}<b>{myBalance.toFixed(4)}</b></span></div>

                            <div><span>Available to withdraw without penalty: <b>{unlockedBalance.toFixed(4)}</b></span></div>
                            <div id="withdrawUnlocked">
                                {unlockedBalance > 0 && <button className="btn btn-sm btn-primary col-12 col-md-6 mt-2 mb-2" style={noBorderButton}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        if(this.state.lastTimeClickedWithdraw + 5000 < Date.now()) {
                                            withdrawFeeDist(this.props.account, this.state.addyData.unlockedBalance);
                                            this.setState({
                                                lastTimeClickedWithdraw: Date.now()
                                            })
                                        }
                                    }
                                    }>{`Withdraw ${unlockedBalance.toFixed(4)} ${getAdamantTokenName()}`}</button>}
                            </div>

                            <div><span>{`Vesting ${getAdamantTokenName()} available to withdraw:`} <b>{vestedTotal.toFixed(4)}</b> {getAdamantTokenName()} with an early exit penalty of <b>{(penaltyAmount / 10 ** 18).toFixed(4)}</b> {getAdamantTokenName()}</span></div>
                            {withdrawableAmount > 0 && <div>
                                <button className={"btn btn-sm btn-danger col-12 col-md-6 mt-2 mb-2" + (this.state.enableClaimWithPenalty ? "": " disabled")} style={noBorderButton}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        if(withdrawableAmount > 1) {
                                            withdrawableAmount = BigInt(withdrawableAmount) - BigInt(1);
                                        }
                                        withdrawFeeDist(this.props.account, withdrawableAmount);
                                    }
                                    }>Claim all with 50% penalty {vestedTotal.toFixed(4)} {getAdamantTokenName()}</button>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="enableClaim" onChange={this.handleClaimCheckbox} />
                                    <label className="form-check-label no-select" htmlFor="enableClaim">Confirm claiming with 50% penalty</label>
                                </div>
                                <br />
                            </div>}

                            {(claimableNativeAmt > 0) && <div><span>Staking rewards available to withdraw: <b>{claimableNativeAmt.toFixed(4)}</b> {getNativeTokenName()}</span></div>}
                            <div>
                                {(claimableNativeAmt > 0) && <button className="btn btn-sm btn-primary col-12 col-md-5 mt-1 mb-1" style={noBorderButton}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        claimFeeDistRewards(this.props.account);
                                    }
                                    }>Claim Rewards</button>}
                            </div>

                            {pendingRewardA_basic > 0 && <div><span>Basic Lock rewards available to withdraw: <b>{pendingRewardB_basic.toFixed(4)}</b> {getAdamantTokenName()} and <b>{pendingRewardA_basic.toFixed(4)}</b> {getNativeTokenName()}</span></div>}
                            <div>
                                {pendingRewardA_basic > 0 && <button className="btn btn-sm btn-primary col-12 col-md-5 mt-1" style={noBorderButton}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        claim(this.props.account, getLockedBasicAddress());
                                    }
                                    }>Claim Rewards</button>}
                            </div>

                            {pendingReward_locked > 0 && <div><span>Lock Plus rewards available to withdraw: <b>{pendingReward_locked.toFixed(4)}</b> {getNativeTokenName()}</span></div>}
                            <div>
                                {pendingReward_locked > 0 && <button className="btn btn-sm btn-primary col-12 col-md-5 mt-1" style={noBorderButton}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        claim(this.props.account, getLockedPlusAddress());
                                    }
                                    }>Claim Rewards</button>}
                            </div>

                            <br />
                            <ul>
                                <li>Vesting, staked, and locked {getAdamantTokenName()} all earn {getNativeTokenName()} fee-sharing rewards</li>
                                <li>Fee-sharing rewards are not locked or subject to any withdrawal penalties</li>
                                <br />
                                <li>All freshly minted {getAdamantTokenName()} is vesting for three months</li>
                                <li>Exiting a vest early always incurs a 50% penalty no matter how early or late you choose to exit</li>
                                <br />
                                <li>Exit penalties are distributed continuously to locked {getAdamantTokenName()} stakers</li>
                                <li>Locking {getAdamantTokenName()} will also boost the amount of {getAdamantTokenName()} earned from normal vaults by up to 100%</li>
                                <li>It is not possible to directly lock vesting {getAdamantTokenName()}, it needs to be withdrawn first, then locked</li>
                                <br />
                                <li>Vests are grouped by week</li>
                                <ul>
                                    <li>The next group starts on: {new Date(this.state.addyData.nextLockStart * 1000).toLocaleString()} (Local timezone)</li>
                                </ul>
                                <br />
                                <li><a href="https://adamantfinance.gitbook.io/adamant-finance/guides/locking-addy-new" target="_blank" rel="noopener noreferrer">View more info about {getAdamantTokenName()} staking and the boost system <FontAwesomeIcon icon={faExternalLinkAlt} /></a></li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card id="vestedAddyTable" className="mt-4 mb-4" style={{ backgroundColor: "rgb(123 120 120)" }}>
                        <Card.Header className="text-center clickable no-select" style={{fontSize: "1.5rem"}} onClick={this.toggleExpandVest}>
                            <FontAwesomeIcon icon={this.state.expandedVest ? faMinusSquare : faPlusSquare} /> Vesting {getAdamantTokenName()}: {(vestedTotal).toFixed(2)}
                        </Card.Header>

                        {this.state.expandedVest && <Card.Body>
                            <div className="row">
                                {expiredVests > 0 &&
                                <div className="col-12 row align-items-center">
                                    <button className="btn btn-sm btn-primary col-12"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if(this.state.lastTimeClickedWithdraw + 5000 < Date.now()) {
                                                withdrawFeeDist(this.props.account, this.state.addyData.withdrawableWithNoPenalty);
                                                this.setState({
                                                    lastTimeClickedWithdraw: Date.now()
                                                })
                                            }
                                        }
                                        }>Withdraw All Expired Vests and Staked {getAdamantTokenName()}: {(expiredVests / 1e18).toFixed(2)} {getAdamantTokenName()} (Expired Vests) + {(unlockedBalance).toFixed(2)} {getAdamantTokenName()} (Staked)
                                    </button>
                                </div>}

                                <div className="col-1" />
                                <table className="table table-dark col-12 col-md-10">
                                    <thead>
                                        <tr className="pointer">
                                            <th scope="col">Amount</th>
                                            <th scope="col">Early Exit Penalty</th>
                                            <th scope="col">Expiry</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {earningsData.map((data, key) => {
                                            let amount = data[0] / 10 ** 18;
                                            let dateUnlocked = new Date(data[1] * 1000);

                                            return (
                                                <tr key={key}>
                                                    <td>{amount.toFixed(4)} {getAdamantTokenName()}</td>
                                                    <td>{(amount/2).toFixed(4)} {getAdamantTokenName()}</td>
                                                    <td>{dateUnlocked.toUTCString()}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="col-1" />
                            </div>
                        </Card.Body>}
                    </Card>

                    <div>
                        <div id="addyStakeBottom">
                            <Card id="stakeLocked" className="col-12 mb-4" style={{ backgroundColor: "rgb(123 120 120)", paddingLeft: "0", paddingRight: "0"  }}>
                                <Card.Header className="text-center no-select" style={{fontSize: "1.5rem"}}>Lock {getAdamantTokenName()}</Card.Header>

                                <Card.Body>
                                    <div id="globalStats" className="text-center">
                                        <h4 style={{ color: "white" }}>Total {getAdamantTokenName()} Locked: {(lockedSupply / 10 ** 18).toFixed(2)} {getAdamantTokenName()}</h4>
                                        <hr className="addy-stake-hr"/>
                                    </div>

                                    {isLoggedIn && <div id="userStats" className="plain-border">
                                        <h4>User Stats</h4>
                                        <b>User total {getAdamantTokenName()} locked (boost contracts):</b> {totalUserLocked.toFixed(2)}<br />
                                        <b>User total {getAdamantTokenName()} boost points:</b> {(totalUserVeAddy / 10 ** 18).toFixed(2)}<br />
                                        <b>Vault deposit amount eligible for double {getAdamantTokenName()} boost:</b> ${user_boost.toFixed(2)}<br />
                                    </div>}

                                    <div id="addyStakeBottom">
                                        <div className="col-12 col-md-6 mb-4 plain-border" id="basic-lock">
                                            <div>
                                                <h4>Basic Lock</h4>
                                            </div>

                                            <div>
                                                <button className={"btn btn-sm btn-secondary mt-1" + (isLoggedIn ? "" : " disabled")} onClick={(event) => {
                                                    event.preventDefault();
                                                    this.props.showStakingModal(false);
                                                }
                                                }>Open Basic Lock Window</button>
                                            </div>

                                            {isLoggedIn && endingTimestamp_basic > 0 && <div className="mt-1">
                                                <b>User {getAdamantTokenName()} locked:</b> {(this.state.addyData.balanceOf_basic / 10 ** 18).toFixed(2)}<br />
                                                <b>{getAdamantTokenName()} boost points from this contract:</b> {(user_veAddy_from_lock_basic / 10 ** 18).toFixed(2)}<br />
                                                <b>Unlock date:</b> {new Date(endingTimestamp_basic * 1000).toUTCString()}<br />
                                                {endingTimestamp_basic * 1000 < Date.now() && <button className="btn btn-sm btn-secondary mt-1" onClick={(event) => {
                                                    event.preventDefault();
                                                    exit(this.props.account, getLockedBasicAddress());
                                                }
                                                }>Withdraw Unlocked {getAdamantTokenName()}</button>}
                                            </div>}

                                            <hr className="addy-stake-hr"/>
                                            All your {getAdamantTokenName()} in this contract share the same unlock date<br />
                                            Separate lock timer than Lock PLUS contract
                                            <hr className="addy-stake-hr"/>
                                            Earn performance and penalty fees +<br />
                                            <a href="https://adamantfinance.gitbook.io/adamant-finance/guides/locking-addy-new" target="_blank" rel="noopener noreferrer">Boost {getAdamantTokenName()} earned from vaults <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                                            <hr className="addy-stake-hr"/>
                                            Earned {getAdamantTokenName()} can be claimed immediately
                                            <hr className="addy-stake-hr"/>
                                            Minimum locking period: 91 days (3 months)<br />
                                            Maximum locking period: 1460 days (4 years)<br />
                                            <hr className="addy-stake-hr"/>
                                            APR: {(addyPerYearNoBuybacks * 100).toFixed(2)}% in {getAdamantTokenName()} + {(nativeApr * 100).toFixed(2)}% in {getNativeTokenName()}
                                            <hr className="addy-stake-hr"/>
                                            Total Locked: {(this.state.addyData.totalBalance_basic / 10 ** 18).toFixed(2)} {getAdamantTokenName()}
                                        </div>

                                        <div className="col-12 col-md-6 mb-4 plain-border" id="plus-lock">
                                            <div>
                                                <h4>Lock Plus</h4>
                                            </div>

                                            <div>
                                                <button className={"btn btn-sm btn-secondary mt-1" + (isLoggedIn ? "" : " disabled")} onClick={(event) => {
                                                    event.preventDefault();
                                                    this.props.showStakingModal(true);
                                                }
                                                }>Open Lock Plus Window</button>
                                            </div>

                                            {isLoggedIn && endingTimestamp_locked > 0 && <div className="mt-1">
                                                <b>User {getAdamantTokenName()} locked:</b> {(this.state.addyData.userLocked_lockedStaking / 10 ** 18).toFixed(2)}<br />
                                                <b>{getAdamantTokenName()} boost points from this contract:</b> {(user_veAddy_from_lock_plus / 10 ** 18).toFixed(2)}<br />
                                                <b>Unlock date:</b> {new Date(endingTimestamp_locked * 1000).toUTCString()}<br />
                                                {endingTimestamp_locked * 1000 < Date.now() && <button className="btn btn-sm btn-secondary mt-1" onClick={(event) => {
                                                    event.preventDefault();
                                                    exit(this.props.account, getLockedPlusAddress());
                                                }
                                                }>Withdraw Unlocked {getAdamantTokenName()}</button>}
                                            </div>}

                                            <hr className="addy-stake-hr"/>
                                            All your {getAdamantTokenName()} in this contract share the same unlock date<br />
                                            Separate lock timer than Basic Lock contract<br />
                                            2x more boost points/{getAdamantTokenName()} than Basic Lock
                                            <hr className="addy-stake-hr"/>
                                            Earn performance and penalty fees +<br />
                                            Earn {getAdamantTokenName()} from buybacks +<br />
                                            <a href="https://adamantfinance.gitbook.io/adamant-finance/guides/locking-addy-new" target="_blank" rel="noopener noreferrer">Boost {getAdamantTokenName()} earned from vaults <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                                            <hr className="addy-stake-hr"/>
                                            Earned {getAdamantTokenName()} is auto-compounded to increase your boost
                                            <hr className="addy-stake-hr"/>
                                            Minimum locking period: 91 days (3 months)<br />
                                            Maximum locking period: 1460 days (4 years)<br />
                                            <hr className="addy-stake-hr"/>
                                            APY: {this.state.addyData.compoundedAddyApy.toFixed(2)}% {getAdamantTokenName()} + {this.state.addyData.compoundedNativeApy.toFixed(2)}% {getNativeTokenName()}<br />
                                            APR: {(addyPerYear * 100).toFixed(2)}% in {getAdamantTokenName()} + {(nativeApr * 100).toFixed(2)}% in {getNativeTokenName()}
                                            <hr className="addy-stake-hr"/>
                                            Total Locked: {(this.state.addyData.totalBalance_locked / 10 ** 18).toFixed(2)} {getAdamantTokenName()}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                        <Card id="stakeNormal" className="col-12 mb-4" style={{ backgroundColor: "rgb(123 120 120)", paddingLeft: "0", paddingRight: "0" }}>
                            <Card.Header className="text-center no-select" style={{fontSize: "1.5rem"}}>Stake {getAdamantTokenName()}</Card.Header>
                            <Card.Body>
                                <div><span>Deposit:</span></div>
                                <div className="input-group">
                                    <input type="string" className="form-control noValidate" placeholder="Amount to stake" value={this.props.value} onChange={this.handleStakeChange} />
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        {addyApproved > 0 ?
                                        <div>
                                            <button className={"btn btn-sm btn-secondary mt-1" + (myBalance > 0 ? "" : " disabled")} onClick={(event) => {
                                                event.preventDefault();
                                                stakeAddy(this.props.account, this.state.stakeAmount, this.state.addyData.myBalance, false);
                                            }
                                            }>Stake</button>
                                            <button className={"btn btn-sm btn-secondary stake-vest-max mt-1" + (myBalance > 0 ? "" : " disabled")} onClick={(event) => {
                                                event.preventDefault();
                                                stakeAddyMax(this.props.account, this.state.addyData.myBalance, false);
                                            }
                                            }>Stake Max</button>
                                        </div>
                                        :
                                        <div>
                                            <button onClick={(event) => {
                                                event.preventDefault();
                                                approveAddyForFeeDist(this.props.account);
                                            }
                                            }>Approve</button>
                                        </div>
                                        }
                                    </div>
                                    <div className="col-6 mt-2 text-right">
                                        Balance: {myBalance.toFixed(4)}
                                    </div>
                                </div>

                                <hr className="addy-stake-hr"/>
                                Earn performance fees
                                <hr className="addy-stake-hr"/>
                                No lock-up required
                                <hr className="addy-stake-hr"/>
                                APR: {(nativeApr * 100).toFixed(2)}% in {getNativeTokenName()} (fee-sharing only)
                                <hr className="addy-stake-hr"/>
                                Total Staked/Vested/Locked: {(totalSupply / 10 ** 18).toFixed(2)} {getAdamantTokenName()}
                            </Card.Body>
                        </Card>

                    </div>
                </div>
            </div>
        );
    }

    updateInfo() {
        try {
            getTestnetAddyData(this.state.web3, this.props.account).then((addyData) => {
                this.setState({
                    addyData: addyData
                })
                this.setState({
                    loaded: true
                })
                if(!this.timer) {
                    console.debug("Refreshing info");
                    this.timer = setInterval(() => this.updateInfo(), 6000);
                }
            });
        }
        catch {
            console.error("Stats: Failed to load Addy fee dist data")
            this.setState({
                loaded: false
            })
            setTimeout(() => this.updateInfo(), 500)
        }
    }

    getAmountCurrentlyLocked(lockData) {
        let total = 0;
        for (const data of lockData) {
            total += data[0] / 10 ** 18;
        }
        return total;
    }
}

export default StakeVestTestnet;
