import React, { FC } from 'react';

import classNames from 'classnames';
import styles from './TokensIcons.module.scss';

interface Props {
  isSingleAsset?: boolean;
  token1: string;
  token2: string;
}

const TokensIcons: FC<Props> = ({ isSingleAsset, token1, token2 }) => (
  <div className={styles.wrapper}>
    <img className={classNames(styles.icon1, isSingleAsset && styles.single)} src={token1} alt="" />
    {!isSingleAsset && <img className={styles.icon2} src={token2} alt="" />}
  </div>
);

export default TokensIcons;
