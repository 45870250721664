import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './FilterButton.module.scss';

interface FilterButtonProps {
  className?: string;
  icon: string;
  onClick: () => void;
}

const FilterButton: FC<FilterButtonProps> = ({ children, className, icon, onClick }) => {
  return (
    <button type="button" className={classnames(styles.button, className)} onClick={onClick}>
      <div className={styles.iconWrapper}>
        <img src={icon} alt="" />
      </div>
      {children}
    </button>
  );
};

export default FilterButton;
