import React, {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  FocusEvent,
} from 'react';

import classNames from 'classnames';
import mergeRefs from 'react-merge-refs';
import styles from './TextInput.module.scss';
import utilStyles from '../../styles/utils.module.scss';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  withButton?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
  errorMsg?: string;
  startEnhancer?: ReactNode;
  postScriptText?: string;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    withButton,
    buttonText,
    onButtonClick,
    buttonDisabled,
    startEnhancer,
    errorMsg,
    postScriptText,
    onBlur,
    onFocus,
    ...inputProps
  } = props;

  const innerRef = useRef<HTMLInputElement>(null);

  const inputContentWidthHelperRef = useRef<HTMLDivElement>(null);
  const inputPostScriptRef = useRef<HTMLDivElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const [postScriptTextOffset, setPostScriptTextOffset] = useState(0);
  const [inputMaxWidth, setInputMaxWidth] = useState(0);
  const [inputHelperMaxWidth, setInputHelperMaxWidth] = useState(0);

  const updateDimensions = useCallback(() => {
    if (
      inputContainerRef.current &&
      inputPostScriptRef.current &&
      inputContentWidthHelperRef.current
    ) {
      const inputContentWidthHelperRect =
        inputContentWidthHelperRef.current.getBoundingClientRect();
      const inputPostScriptRect = inputPostScriptRef.current.getBoundingClientRect();
      const inputContainerRect = inputContainerRef.current.getBoundingClientRect();

      const inputPostScriptWidth = postScriptText ? inputPostScriptRect.width : 0;

      setPostScriptTextOffset(inputContentWidthHelperRect.width);
      setInputMaxWidth(inputContentWidthHelperRect.width);
      setInputHelperMaxWidth(inputContainerRect.width - inputPostScriptWidth);
    }
  }, [postScriptText]);

  const handleInputContainerClick = useCallback(() => {
    innerRef.current?.focus();
  }, [innerRef]);

  const handleInputFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      inputContainerRef.current?.classList.add(styles.focused);
      onFocus?.(e);
    },
    [onFocus],
  );

  const handleInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      inputContainerRef.current?.classList.remove(styles.focused);
      onBlur?.(e);
    },
    [onBlur],
  );

  useEffect(() => {
    let observer: ResizeObserver;

    if (
      postScriptText &&
      inputContainerRef.current &&
      inputContentWidthHelperRef.current &&
      inputPostScriptRef.current
    ) {
      observer = new ResizeObserver(updateDimensions);
      observer.observe(inputContainerRef.current);
      observer.observe(inputContentWidthHelperRef.current);
      observer.observe(inputPostScriptRef.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, [updateDimensions, postScriptText]);

  useEffect(() => {
    if (!inputContentWidthHelperRef.current) return;

    if (props.value === '') {
      inputContentWidthHelperRef.current.innerText = props.placeholder ?? '';
    }
  }, [props.value, props.placeholder]);

  const hasError = !!errorMsg;

  return (
    <div className={styles.container}>
      <div
        ref={inputContentWidthHelperRef}
        className={styles.inputContentWidthHelper}
        style={{ maxWidth: inputHelperMaxWidth }}
      >
        {inputProps.value}
      </div>

      <div
        className={classNames(styles.inputContainer, inputProps.disabled && utilStyles.disabled)}
      >
        {startEnhancer && <div className={styles.startEnhancer}>{startEnhancer}</div>}

        <div
          ref={inputContainerRef}
          className={styles.mainInputContainer}
          onClick={handleInputContainerClick}
        >
          <div
            ref={inputPostScriptRef}
            className={styles.inputPostScript}
            style={{ left: postScriptTextOffset }}
          >
            {postScriptText}
          </div>

          <input
            ref={mergeRefs([ref, innerRef])}
            {...inputProps}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            disabled={inputProps.disabled}
            className={classNames(styles.input, hasError && styles.inputError)}
            style={{ ...(postScriptText && { maxWidth: inputMaxWidth }) }}
            type="text"
          />
        </div>

        {withButton && (
          <button
            disabled={buttonDisabled}
            onClick={onButtonClick}
            className={classNames(styles.button, buttonDisabled && utilStyles.disabled)}
            type="button"
          >
            {buttonText}
          </button>
        )}
      </div>

      {hasError && <p className={styles.error}>{errorMsg}</p>}
    </div>
  );
});

export default TextInput;
