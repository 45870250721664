import React, { Component } from "react";

export class Loading extends Component {
    render() {
        return (
            <div id="loader" className="text-center">
                <p className="text-center">
                    Loading... If this page fails to load, please change your RPC provider.<br />
                    You can find a list of public RPC providers at <a href="https://docs.matic.network/docs/develop/network-details/network/" target="_blank" rel="noopener noreferrer">https://docs.matic.network/docs/develop/network-details/network/</a><br />
                    <br />
                    If anyone messages you on Discord/Telegram and tells you to "synchronize"/"verify"/etc your wallet, they are trying to scam you.<br />
                    Do not enter your private key or seed/secret phrase anywhere or you will lose your funds.<br />
                    Adamant staff will never DM you first.
                </p>
            </div>
        );
    }
}

export default Loading;