import React, { useEffect, useState, VFC } from 'react';
import classnames from 'classnames';
import styles from './GasPrice.module.scss';
import { useUserContext } from '../../contexts/UserContext';

interface GasPriceProps {
  inNavbar?: boolean;
}

const GasPrice: VFC<GasPriceProps> = ({ inNavbar }) => {
  const { web3 } = useUserContext();
  const [gasPrice, setGasPrice] = useState<number>();

  useEffect(() => {
    if (web3 && web3.eth) {
      web3.eth.getGasPrice().then((price: string) => {
        const priceInGwei = Math.round(Number(price) / 10 ** 9);
        setGasPrice(priceInGwei);
      });
    }
  });

  return (
    <div className={classnames(styles.gasPriceWrapper, inNavbar && styles.inNavbar)}>
      <img src="/icons/GasIcon.svg" alt="" />
      <span className={styles.gasPrice}>{gasPrice || '…'}</span>
    </div>
  );
};

export default GasPrice;
