import React, { VFC } from 'react';
import classNames from 'classnames';

import Link from '../common/Link/Link';
import { RoutingPaths } from '../../paths';
import { getAdamantTokenName } from '../../api/Multichain';

import Particles from './bottom-particles.svg';
import PadlockDark from './padlock-dark.svg';
import PadlockLight from './padlock-light.svg';
import styles from './BoostSidePanel.module.scss';
import { useUserContext } from '../../contexts/UserContext';

interface BoostSidePanelProps {
  totalLockedBasic: number;
  totalLockedPlus: number;
}

const BoostSidePanel: VFC<BoostSidePanelProps> = ({ totalLockedBasic, totalLockedPlus }) => {
  const { isDarkMode } = useUserContext();
  const adamantTokenName = getAdamantTokenName();

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        Locking demonstrates a commitment to the long-term vision of Adamant
      </h4>

      <p className={styles.paragraph}>
        Lock your {adamantTokenName} and get <b>Boost Points</b> to increase your Vault earnings and
        participate in protocol Governance
      </p>

      <p className={styles.sectionHeader}>Discover our types of Locks and their benefits:</p>

      <div className={styles.tilesRow}>
        <div className={styles.tile}>
          <h5 className={styles.tileTitle}>Basic Lock</h5>
          <ul className={classNames(styles.tileList, styles.ticks)}>
            <li>Earn performance and penalty fees</li>
            <li>Boost {adamantTokenName} earned from vaults</li>
          </ul>
        </div>
        <div className={styles.tile}>
          <h5 className={styles.tileTitle}>Lock Plus</h5>
          <ul className={classNames(styles.tileList, styles.ticks)}>
            <li>Earn performance and penalty fees</li>
            <li>Boost {adamantTokenName} earned from vaults</li>
            <li>Earn {adamantTokenName} from buybucks</li>
            <li>2x more Boost Points per {adamantTokenName} than in Basic Lock</li>
          </ul>
        </div>
      </div>

      <div className={styles.tilesRow}>
        <div className={styles.tile}>
          <h5 className={styles.tileTitle}>Basic Lock</h5>
          <ul className={styles.tileList}>
            <li className={styles.grow}>Earned {adamantTokenName} can be claimed immediately</li>
            <li>Same unlock date for all {adamantTokenName} in this contract</li>
            <li>
              Eligibility for Adamant cross-chain expansion benefits (such as possible airdrops or
              performance fees)
            </li>
          </ul>
        </div>
        <div className={styles.tile}>
          <h5 className={styles.tileTitle}>Lock Plus</h5>
          <ul className={styles.tileList}>
            <li>Earned {adamantTokenName} is auto-compounded to increase your boost</li>
            <li>Same unlock date for all {adamantTokenName} in this contract</li>
            <li>
              Eligibility for Adamant cross-chain expansion benefits (such as possible airdrops or
              performance fees)
            </li>
          </ul>
        </div>
      </div>

      <div className={classNames(styles.tilesRow, styles.lastRow)}>
        <div className={classNames(styles.tile, styles.lastRow)}>
          <p>Total locked</p>
          <p>
            {(totalLockedBasic / 1e18).toFixed(2)} {adamantTokenName}
          </p>
        </div>
        <div className={classNames(styles.tile, styles.lastRow)}>
          <p>Total locked</p>
          <p>
            {(totalLockedPlus / 1e18).toFixed(2)} {adamantTokenName}
          </p>
        </div>
      </div>

      <p className={styles.paragraph}>
        You can lock your {adamantTokenName} for 91 days - 4 years. During this time, you will not
        be able to withdraw them
      </p>
      <p className={styles.paragraph}>Each Lock type has a separate lock timer</p>
      <p className={styles.paragraph}>
        If you don&apos;t have any {adamantTokenName} yet, you can earn it through the{' '}
        <Link href={RoutingPaths.VAULTS} color="primary">
          Vaults
        </Link>{' '}
        {/* todo: add link to exchange here */}
        or by purchasing at your favourite decentralized exchange
      </p>

      <p className={styles.sectionHeader}>
        Don&apos;t feel like Locking?{' '}
        <Link href={RoutingPaths.STAKE_ADDY} color="primary">
          Stake your {adamantTokenName}
        </Link>
      </p>

      <p className={styles.textDim}>
        Need more information? Go to Documentation and{' '}
        <Link
          href="https://adamantfinance.gitbook.io/adamant-finance/guides/locking-addy-new"
          newTab
          className={styles.greyLink}
        >
          Learn more
        </Link>
      </p>

      <div className={styles.bottomImageWrapper}>
        <img src={isDarkMode ? PadlockDark : PadlockLight} alt="" className={styles.padlock} />
        <img src={Particles} alt="" className={styles.particles} />
      </div>
    </div>
  );
};

export default BoostSidePanel;
