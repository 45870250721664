import React from 'react';
import Popup from 'reactjs-popup';
import styleVariables from '../../../styles/variables.module.scss';
import styles from './Tooltip.module.scss';

interface TooltipProps {
  content: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => (
  <Popup
    arrowStyle={{ color: styleVariables.colorPopup }}
    position="bottom center"
    on={['hover']}
    offsetY={8}
    trigger={<div>{children}</div>}
  >
    <div className={styles.tooltip}>{content}</div>
  </Popup>
);

export default Tooltip;
