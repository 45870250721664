import React, { useState, VFC } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Modal, { ModalProps } from '../Modal/Modal';
import styles from './WithdrawVestingEarlyModal.module.scss';
import Button from '../common/Button/Button';
import { getAdamantTokenName } from '../../api/Multichain';
import { formatTokenAmount } from '../../_helpers';
import { useUserContext } from '../../contexts/UserContext';
import { getUserVestingGroups } from '../../_helpers/vestingGroups';

export interface WithdrawVestingEarlyModalProps extends ModalProps {
  addyData: any;
}

const WithdrawVestingEarlyModal: VFC<WithdrawVestingEarlyModalProps> = ({
  addyData,
  ...modalProps
}) => {
  const { stakeFunctions } = useUserContext();
  const [isWithdrawPending, setIsWithdrawPending] = useState(false);

  const penaltyAmount = addyData.penaltyAmount / 10 ** 18;
  const unlockedBalance = addyData.unlockedBalance / 10 ** 18;
  const vestingAmount = addyData.totalWithdrawable - unlockedBalance;

  const userVestingGroups = getUserVestingGroups(addyData);

  const nextVestingGroup = userVestingGroups[0] ?? { unlockAmount: 0, unlockTimestamp: 0 };
  const minutesToUnlock = dayjs.unix(nextVestingGroup.unlockTimestamp).diff(dayjs(), 'minutes');
  const displayUnlockTime =
    minutesToUnlock > 0 ? dayjs.unix(nextVestingGroup.unlockTimestamp).fromNow() : '< minute';

  const handleCancelButtonClick = () => {
    if (isWithdrawPending) return;
    modalProps.onClose?.();
  };

  const handleWithdrawButtonClick = () => {
    if (isWithdrawPending) return;
    setIsWithdrawPending(true);
    stakeFunctions
      .withdrawStaked(addyData.withdrawableAmount)
      .finally(() => setIsWithdrawPending(false));
  };

  return (
    <Modal {...modalProps} disableDismiss={isWithdrawPending}>
      <div className={styles.container}>
        <p className={styles.title}>Are you sure you want to withdraw early?</p>

        <div className={styles.valuesTable}>
          <div className={styles.valuesTableRow}>
            <p className={styles.valuesTableRowDesc}>You are about to withdraw early</p>
            <div className={styles.valuesTableValueWrapper}>
              <p className={styles.valuesTableAmount}>{formatTokenAmount(vestingAmount)}</p>
              <p className={styles.valuesTableTokenName}>{getAdamantTokenName()}</p>
            </div>
          </div>

          <div className={styles.valuesTableRow}>
            <p className={styles.valuesTableRowDesc}>It will cost you a penalty of </p>
            <div className={styles.valuesTableValueWrapper}>
              <p className={classNames(styles.valuesTableAmount, styles.penalty)}>
                {formatTokenAmount(penaltyAmount)}
              </p>
              <p className={classNames(styles.valuesTableTokenName, styles.penalty)}>
                {getAdamantTokenName()}
              </p>
            </div>
          </div>
        </div>

        <p className={styles.waitDesc}>
          {displayUnlockTime}, the next vesting will expire and you will be able to withdraw
          <b> {formatTokenAmount(nextVestingGroup.unlockAmount / 10 ** 18)}</b> more{' '}
          {getAdamantTokenName()} without penalty
        </p>

        <div className={styles.buttonsWrapper}>
          <button
            disabled={isWithdrawPending}
            onClick={handleCancelButtonClick}
            className={styles.cancelBtn}
            type="button"
          >
            Cancel
          </button>
          <Button
            disabled={isWithdrawPending}
            onClick={handleWithdrawButtonClick}
            className={styles.withdrawBtn}
          >
            Withdraw early
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawVestingEarlyModal;
