import MULTICALL_ABI from '../../abis/multicall/furnace_rewards_multicall.json'
import { getAdamantTokenDollarPrice } from '../Multichain';
import { getTokenPrice } from '../PriceCache';

const FURNACE_REWARDS_MULTICALL = "0x1600927A4A3ea4fD016cc1Add617fB3600B429aB"

export const getFurnacePoolData = async (web3, account, pools, poolMap, addyData) => {
    var furnaceRewardsData = [];
    if(addyData == null) return furnaceRewardsData;

    const multicallContract = web3.eth.Contract(MULTICALL_ABI.abi, FURNACE_REWARDS_MULTICALL)

    var poolAddresses = [];
    var poolz = [];

    for (let i = 0; i < pools.length; i++) {
        poolAddresses.push(pools[i].vaultAddress);
        if(i > 0 && i % 60 === 0) {
            let infoForPools = await multicallContract.methods.getInfoForStakingRewards(account, poolAddresses).call();
            poolz = poolz.concat(infoForPools);
            poolAddresses = [];
        }
    }

    if(poolAddresses.length > 0) {
        let infoForPools = await multicallContract.methods.getInfoForStakingRewards(account, poolAddresses).call();
        poolz = poolz.concat(infoForPools);
    }

    for (let i = 0; i < pools.length; i++) {
        pools[i].vaultAddress = pools[i].vaultAddress.toLowerCase();
        const stakingRewardsInfo = poolz[i];

        let stakingRewards = stakingRewardsInfo.stakingRewards;

        let amountUserStaked = stakingRewardsInfo.amountUserStaked;
        let totalShares = stakingRewardsInfo.totalShares;
        let lpTokenApproved = stakingRewardsInfo.lpTokenApproved;
        let userLpBalance = stakingRewardsInfo.userLpBalance;

        let pendingReward = stakingRewardsInfo.pendingReward;

        let lpStaked = stakingRewardsInfo.lpStaked;

        let rewardRate = stakingRewardsInfo.rewardRate;
        let periodFinish = stakingRewardsInfo.periodFinish;
        let lastUpdateTime = stakingRewardsInfo.lastUpdateTime;
        let remainingRewards = rewardRate * (stakingRewardsInfo.periodFinish - stakingRewardsInfo.lastUpdateTime);

        let rewardsToken = stakingRewardsInfo.rewardsToken;

        let lpPrice = getAdamantTokenDollarPrice();
        let tvl = lpPrice * lpStaked;

        let remainingRewardValue = remainingRewards * getTokenPrice(rewardsToken);
        let roi;
        if(remainingRewardValue === 0) {
            roi = 0;
        }
        else if(tvl === 0) {
            roi = 0;
        }
        else {
            roi = remainingRewardValue / tvl * 100 - 100;
        }

        let poolData = {
            deprecated: pools[i].deprecated,
            index: furnaceRewardsData.length,
            stakingTokenName: "ADDY",
            rewardsTokenName: pools[i].rewardsTokenName,
            nameOverride: pools[i].nameOverride,
            vaultAddress: pools[i].vaultAddress,
            website: pools[i].website,
            hidden: pools[i].hidden,

            amountUserStaked: amountUserStaked,
            totalShares: totalShares,
            lpTokenApproved: lpTokenApproved,
            userLpBalance: userLpBalance,

            pendingReward: pendingReward,

            lpAddress: stakingRewardsInfo.stakingToken,
            lpStaked: lpStaked,

            stakingRewards: stakingRewards,
            rewardRate: rewardRate,

            periodFinish: periodFinish,
            lastUpdateTime: lastUpdateTime,

            rewardsToken: rewardsToken,

            roi: roi,
            tvl: tvl, //The vault's TVL
            lpPrice: lpPrice
        }
        poolMap[pools[i].vaultAddress] = poolData;
        furnaceRewardsData.push(poolData)
    }
    return furnaceRewardsData;
}

/**/
