import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import { harvestAll, claimAll } from '../api/actions/ToolFunctions.js'
import { emergencyExit, approve } from '../api/actions/VaultFunctions.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

class Tools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '0',
            input2: '0',
            expanded: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
    }
    handleChange(event) {
        this.setState({
            input: event.target.value
        });
    }
    handleChange2(event) {
        this.setState({
            input2: event.target.value
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className="container my-5" id="tools">
                <div className="row">
                    <div className="col-12 text-center my-4">
                        <h1 className="mb-4 underlined">
                            <span>Tools</span>
                        </h1>
                    </div>

                    <Card className="shadow-sm mx-auto mb-4 col-10" id="harvestAll">
                        <div className="card-body">
                            <div className="row text-center justify-content-center align-middle">
                                <h4>Compound All Your Staked Vaults</h4>
                            </div>

                            <div className="col-6 mx-auto mb-4">
                                <Button className="btn btn-success btn-block mb-2"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        harvestAll(this.props.account, this.props.stakingData);
                                    }}
                                >Compound</Button>
                            </div>
                        </div>
                    </Card>

                    <Card className="shadow-sm mx-auto mb-4 col-10" id="claimAll">
                        <div className="card-body">
                            <div className="row text-center justify-content-center align-middle">
                                <h4>Claim ADDY From All Your Staked Vaults</h4>
                            </div>

                            <div className="col-6 mx-auto mb-4">
                                <Button className="btn btn-success btn-block mb-2"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        claimAll(this.props.account, this.props.stakingData);
                                    }}
                                >Claim ADDY</Button>
                            </div>
                        </div>
                    </Card>

                    <Card className="shadow-sm mx-auto mb-4 col-10" id="oldVaultUnstake">
                        <div className="card-body">
                            <div className="row text-center justify-content-center align-middle">
                                <h4>Exit from an old Adamant vault</h4>
                            </div>

                            <div className="col-6 mx-auto mb-4">
                                <div className="input-group">
                                    <input type="string" className="form-control noValidate" placeholder="Address of the old vault" value={this.props.value} onChange={this.handleChange} />
                                </div>
                                <Button className="btn btn-danger btn-block mb-2"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        emergencyExit(this.props.account, this.state.input);
                                    }}
                                >Exit vault</Button>
                            </div>
                        </div>
                    </Card>

                    <Card className="shadow-sm mx-auto mb-4 col-10" id="resetApproval">
                        <div className="card-body">
                            <div className="row text-center justify-content-center align-middle">
                                <h4>Set Token Approval</h4>
                            </div>

                            <div>
                                This function allows the address in the "Vault address" field to spend any amount of "Token address" from your wallet.
                            </div>
                            <div>
                                Do not put an address in the "Vault address" field unless you are certain it is a vault contract address or the fee distribution contract address.
                            </div>

                            <div className="col-6 mx-auto mb-4">
                                <div className="input-group">
                                    <input type="string" className="form-control noValidate" placeholder="Token address" value={this.props.value} onChange={this.handleChange} />
                                </div>
                                <div className="input-group">
                                    <input type="string" className="form-control noValidate" placeholder="Vault address" value={this.props.value} onChange={this.handleChange2} />
                                </div>
                                <Button className="btn btn-success btn-block mb-2"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        approve(this.props.account, this.state.input, this.state.input2);
                                    }}
                                >Set Approval</Button>
                                <div>
                                <a href="https://adamantfinance.gitbook.io/adamant-finance/contract-links" target="_blank" rel="noopener noreferrer">Contract links <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default Tools;