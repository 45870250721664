import React, { Component } from 'react';
import { alertService } from '../_services';
import MIGRATABLE_VAULT_ABI from '../abis/vault_migrator.json'

let admins = new Set(["0xB29Cd9C87a624B940335d6d5e1D4aADf7D95bEeC", "0xbae29B5C066b02D59493Da8bbccC1fAf51529438","0xB2CAaB51633B88Ac8f7794Cc9C578448FFBd0c6F",
                            "0xa1BbD8D39eD536DEa030A32F3F6C5916C845A800", "0xe33Bfd1FcC9Ba62Aa26FDc9B00463061c0181EE3", "0xF2Da8b05f3A9d20e3331326Ee48D19A21842dfeB"]);
let validatedVaults = {};

class ValidateMigration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            loaded: false,
            pools: [],
            validateData: {},
            web3: this.props.web3,
            quickPrice: 0,
            update: true,
            sortedField: 'reward', //if sortConfig[indices[sortedField]] is true, then sort in desc order, if false, then sort in asc order
            indices: { 'reward': 0, 'apy': 1, 'tvl': 3, 'platform': 4 },
            sortConfig: [false, false, false, true, false] //reward, apy, apy, tvl
        };
        this.handleChange = this.handleChange.bind(this);
        this.setSortedField = this.setSortedField.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)

        if (!this.state.loaded) {
            this.updateInfo(this.props.stakingData);
            /*this.setState({
                loaded: true
            })*/
        }
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    handleChange(event) {
        this.setState({
            searchTerm: event.target.value
        });
    }
    setSortedField(value) {
        let prevVal = this.state.sortConfig[this.state.indices[value]];
        let newArr = [false, false, false, false];
        newArr[this.state.indices[value]] = !prevVal;

        this.setState({
            sortedField: value,
            sortConfig: newArr,
            update: true
        });
    }

    render() {
        let isAdmin = admins.has(this.props.account);
        if (!isAdmin) {
            return null;
        }
        /*if (this.state.update && this.props.stakingData != null && this.props.stakingData.length > 0) {
            this.setState({
                pools: this.filterItems(this.getSortedPools(this.props.stakingData), this.state.searchTerm),
                update: false
            })
        }*/

        let sortedPools = this.props.stakingData; //this.state.pools;// this.filterItems(this.getSortedPools(this.props.stakingData), this.state.searchTerm);

        return (
            <div className="container my-5" id="stats">
                <div className="col-6 mx-auto mb-4">
                    <div className="input-group">
                        <input type="string" className="form-control noValidate" placeholder="Search" value={this.props.value} onChange={this.handleChange} />
                    </div>
                </div>

                <table className="table table-dark">
                    <thead>
                        <tr>
                            <th scope="col">Pool</th>
                            <th scope="col">
                                Platform
                            </th>
                            <th scope="col">
                                TVL
                            </th>
                            <th scope="col" colSpan="1">
                                Validated
                            </th>
                            <th scope="col" colSpan="1">

                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {sortedPools.map((pool, key) => {
                            const apy = pool.apy;

                            const stakeTokenName = pool.token0Name + '/' + pool.token1Name;
                            const tvl = pool.valueTotalStaked / 1e18;

                            var formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });

                            if (pool.deprecated || pool.hidden || (pool.platform !== "swapr" && pool.platform !== "dfyn") || (!pool.token0Name.toLowerCase().includes(this.state.searchTerm) && !pool.token1Name.toLowerCase().includes(this.state.searchTerm))) {
                                return (<tr key={pool.vaultAddress + "hidden"}></tr>)
                            }
                            return (
                                <tr className="align-items-center" key={pool.vaultAddress}>
                                    <td>
                                        {/* Token names need to be case sensitive when the website is deployed */}
                                        <div className="poolRowTokenLeft">
                                            <div className="square">
                                                <img src={"/img/" + pool.token0Name + ".png"} alt="token icon"></img>
                                            </div>
                                        </div>
                                        <div className="poolRowTokenRight">
                                            <div className="square">
                                                <img src={"/img/" + pool.token1Name + ".png"} alt="token icon"></img>
                                            </div>
                                        </div>
                                        {stakeTokenName}
                                    </td>
                                    <td>
                                        {pool.deprecated ? (pool.platform + "(D)"): pool.platform}
                                    </td>
                                    <td>
                                        {formatter.format(tvl)}
                                    </td>
                                    <td>
                                        {validatedVaults[pool.strategyAddress] != undefined ? (validatedVaults[pool.strategyAddress] + ""): "Not Loaded"}
                                    </td>
                                    <td>
                                        <button onClick={(event) => {
                                            event.preventDefault();
                                            this.validateVault(pool.strategyAddress);
                                        }}>Validate
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    updateInfo(stakingData) {
        try {
            if(stakingData) {
                for (const pool of stakingData) {
                    if((pool.platform === "swapr" || pool.platform === "dfyn") && !pool.deprecated && validatedVaults[pool.strategyAddress] === undefined) {
                        this.isVaultValidated(pool.strategyAddress).then((res) => {
                            validatedVaults[pool.strategyAddress] = res;
                        });
                    }
                }

                if (!this.timer) {
                    console.log("Refreshing info");
                    this.timer = setInterval(() => this.updateInfo(), 3000);
                }
                this.setState({
                    loaded: true
                })
            }

        }
        catch {
            console.log("Stats: Failed to load data")
            this.setState({
                loaded: false
            })
            setTimeout(() => this.updateInfo(), 2000)
        }
    }

    validateVault(strategyAddress) {
        const contract = window.web3.eth.Contract(MIGRATABLE_VAULT_ABI.abi, strategyAddress)

        contract.methods.validate().send({ from: this.props.account }, (e, output) => {
            if (e) {
                alertService.alertLog(e);
            } else {
                alertService.alertTx(output);
            }
        });
    }

    filterItems(pools, searchTerm) {
        return pools.filter(function (pool) {
            return !(!pool.token0Name.toLowerCase().includes(searchTerm) && !pool.token1Name.toLowerCase().includes(searchTerm));
        })
    }

    isVaultValidated = async (strategyAddress) => {
        const contract = window.web3.eth.Contract(MIGRATABLE_VAULT_ABI.abi, strategyAddress)
        return await contract.methods.validated().call();
    }

    getSortedPools(stakingData) {
        let sortedPools = [...stakingData];
        sortedPools.sort((a, b) => {
            if (this.state.sortedField === 'tvl') {
                if (a.valueTotalStaked < b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['tvl']] ? 1 : -1;
                }
                if (a.valueTotalStaked > b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['tvl']] ? -1 : 1;
                }
            }
            else if (this.state.sortedField === 'reward') {
                if (a.remainingAllocation < b.remainingAllocation) {
                    return this.state.sortConfig[this.state.indices['reward']] ? 1 : -1;
                }
                if (a.remainingAllocation > b.remainingAllocation) {
                    return this.state.sortConfig[this.state.indices['reward']] ? -1 : 1;
                }
            }
            else if (this.state.sortedField === 'platform') {
                if (a.platform < b.platform) {
                    return this.state.sortConfig[this.state.indices['platform']] ? 1 : -1;
                }
                if (a.platform > b.platform) {
                    return this.state.sortConfig[this.state.indices['platform']] ? -1 : 1;
                }
            }
            else {
                return (a.apy - b.apy) * (this.state.sortConfig[this.state.indices['apy']] ? -1 : 1);
            }
            return 0;
        });
        let output = [];
        for (const pool of sortedPools) {
            output.push(pool.remainingAllocation / 1e18);
        }
        //console.log(output)
        return sortedPools;
    }
}

export default ValidateMigration;