import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import { exit } from '../api/actions/VaultFunctions.js';

class PresaleWithdraw extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="container my-5" id="presale">
                <div className="eps-window white">
                    <Card id="exchangeAddy" className="mt-4 mb-4" style={{ backgroundColor: "rgb(123 120 120)" }}>
                        <Card.Body>
                            <div className="info-message gentle-message">
                                Clicking the withdraw button will withdraw all presale tokens at once.
                            </div>

                            <div>
                                <button className="btn btn-sm btn-primary col-12 col-md-6 mt-2 mb-2" onClick={(event) => {
                                    event.preventDefault();
                                    exit(this.props.account, "0x341C6003D7E7d08AAF9580c0F1CE799FecD4b8aC");
                                }
                                }>Withdraw Presale Tokens</button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

export default PresaleWithdraw;