import React, { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import chevronDownIcon from '@assets/icons/chevron-down.svg';

import TokensIcons from './TokensIcons/TokensIcons';
import TokensNames from './TokensNames/TokensNames';
import VaultTag from './VaultTag/VaultTag';

import { useDailyVolume } from '../../../api/GraphApi';
import { getAdamantTokenName } from '../../../api/Multichain';
import { currencyFormatter, formatTokenAmount } from '../../../_helpers';
import { useUserContext } from '../../../contexts/UserContext';
import stablecoins from '../../../constants/stablecoins';
import styles from './VaultCardHeader.module.scss';

interface Props {
  vault: any;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const VaultCardHeader: FC<Props> = ({ vault, isExpanded, setIsExpanded }) => {
  const { isWalletConnected, showLpAmounts } = useUserContext();
  let stakeTokenName = vault.singleAsset
    ? vault.token0Name
    : `${vault.token0Name}/${vault.token1Name} LP`;

  if (vault.nameOverride) {
    stakeTokenName = vault.nameOverride;
  }

  let swapFeeApy = 0;
  const swapFeeApyData = useDailyVolume(vault);

  if (swapFeeApyData.isSuccess && swapFeeApyData.lpApyFee) {
    swapFeeApy = swapFeeApyData.lpApyFee;
  }

  if (vault.tradingFeeApy !== undefined && vault.tradingFeeApy > -1) {
    swapFeeApy = vault.tradingFeeApy;
  }

  if (swapFeeApy > 1000 || swapFeeApy === -1) {
    swapFeeApy = 0;
  }

  const adamantTokenName = getAdamantTokenName();

  const aprValue = vault.apr;
  const apyValue = vault.apy + swapFeeApy;

  const pendingReward = (vault.pendingReward / 10 ** 18) * (vault.boost > 0 ? 1 + vault.boost : 1);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const getEarnString = (addyRewards: any) => {
    return addyRewards ? `${stakeTokenName} & ${adamantTokenName}` : stakeTokenName;
  };

  const isStablecoin =
    stablecoins.has(vault.token0.toLowerCase()) && stablecoins.has(vault.token1.toLowerCase());

  return (
    <div
      className={classNames(
        styles.header,
        vault.deprecated && styles.inactive,
        isWalletConnected && styles.walletConnected,
      )}
      onClick={toggleExpanded}
    >
      <div className={styles.detailsSection}>
        <div className={styles.detailsSectionContent}>
          <div className={styles.vaultIcons}>
            <TokensIcons
              token1={`/img/${vault.token0Name}.png`}
              token2={`/img/${vault.token1Name}.png`}
              isSingleAsset={vault.singleAsset}
            />
          </div>

          <div className={styles.vaultName}>
            <TokensNames
              token1={vault.token0Name}
              token2={vault.token1Name}
              nameOverride={vault.nameOverride}
              platform={vault.platform}
              isSingleAsset={vault.singleAsset}
            />
          </div>
        </div>
      </div>

      <div className={styles.tagSection}>
        <div className={styles.tagSectionContent}>
          {vault.deprecated ? (
            <VaultTag type="inactive" />
          ) : (
            <>
              {vault.boostable && <VaultTag type="boostable" size="default" />}
              {isStablecoin && <VaultTag type="stablecoins" size="default" />}
              {/* TODO: Temporarily hidden */}
              {/* {vault.degen && (
                <VaultTag
                  type="degenerate"
                  size={vault.boostable && vault.degen ? 'small' : 'default'}
                />
              )} */}
            </>
          )}
        </div>
      </div>

      <div className={styles.rewardsSection}>
        <div className={styles.rewardsSectionContent}>
          {vault.amountUserStaked / 10 ** 18 === 0 || !vault.addyRewards
            ? getEarnString(vault.addyRewards)
            : `${pendingReward.toFixed(4)} ${adamantTokenName}`}
        </div>
      </div>

      {isWalletConnected && (
        <div className={styles.investmentSection}>
          <div className={styles.investmentSectionContent}>
            {currencyFormatter(
              (vault.lpPrice * vault.amountUserStaked * (vault.ratio / 10 ** 18)) / 10 ** 18,
            )}

            {showLpAmounts && (
              <p className={styles.lpAmount}>
                {formatTokenAmount((vault.amountUserStaked / 10 ** 18) * (vault.ratio / 10 ** 18))}
              </p>
            )}
          </div>
        </div>
      )}

      <div className={styles.percentageYieldSection}>
        <div className={styles.percentageYieldSectionContent}>
          <p className={styles.percentageYieldAPY}>
            {apyValue > 1000000000 ? '>1 BILLION' : apyValue.toFixed(2)}%
          </p>

          {vault.showApr && (
            <p className={styles.percentageYieldAPR}>(APR {aprValue.toFixed(2)}%)</p>
          )}
        </div>
      </div>

      <div className={styles.totalLockedSection}>
        <div className={styles.totalLockedSectionContent}>
          {currencyFormatter((vault.lpPrice * vault.totalStaked) / 10 ** 18)}

          {showLpAmounts && (
            <p className={styles.lpAmount}>{formatTokenAmount(vault.totalStaked / 10 ** 18)}</p>
          )}
        </div>
      </div>

      <div className={styles.expandSection}>
        <motion.button
          type="button"
          className={styles.expandButton}
          animate={{ rotate: isExpanded ? 180 : 0 }}
          transition={{ type: 'tween' }}
        >
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L7 7L13 1" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </motion.button>
      </div>
    </div>
  );
};

export default VaultCardHeader;
