import { FC, ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RestoreScroll: FC = ({ children }) => {
  const { pathname } = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return children as ReactElement;
};

export default RestoreScroll;
