import FEE_DIST_MULTICALL_ABI from '../../abis/multicall/fee_dist_multicall.json'
import { cacheOnlyIfNumber, cacheTokenPrice, getTokenPrice } from '../PriceCache';
import { getCompoundingAPY } from '../MathUtil.js'

const ADDY_MULTICALL = "0x6171A5B8139F1D69311e23Aaea4252320C951E78"

const QUICK_ADDRESS = "0x831753DD7087CaC61aB5644b308642cc1c33Dc13"
const DQUICK_ADDRESS = "0xf28164A485B0B2C90639E47b0f377b4a438a16B1"
const ELK_ADDRESS = "0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C"
const WETH = "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";
const WMATIC = "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
const DFYN_WMATIC = "0x4c28f48448720e9000907bc2611f73022fdce1fa"

const MUST = "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f";
const ADDY = "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539";
const PUSD = "0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72".toLowerCase();
const WBTC = "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6".toLowerCase();
const DFYN = "0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97".toLowerCase();
const TEL = "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32".toLowerCase();
const SUSHI = "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a";

let feeDistTvl = 0;
let addyTotalSupply = 0;
let addyDollarPrice = 0;

export const getLockedAddyVaultPlusAddress = () => {
    return "0xC5bCD23f21B6288417eB6C760F8AC0fBb4bb8a56";
}
export const getLpVaultAddress = () => {
    return "0xd82a68EcA83D38906c7a3f9996d93D95f8Aa24B5";
}
export const getLockedAddyVaultBasicAddress = () => {
    return "0xfFD82F81513b207fB9D7D7835e178B6193f2cA96";
}
export const getAddyTokenAddress = () => {
    return "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539";
}

export const getPolygonFeeDistTvl = () => {
    return feeDistTvl;
}
//50k ADDY were burned immediately after the presale finished
export const getAddyTotalSupply = () => {
    return addyTotalSupply / 10 ** 18 - 50000;
}
export const getAddyDollarPrice = () => {
    return addyDollarPrice;
}

export const getAddyData = async (web3, account) => {
    var addyData = {};

    const multicallContract = web3.eth.Contract(FEE_DIST_MULTICALL_ABI.abi, ADDY_MULTICALL)
    let addyFeeDistInfo = await multicallContract.methods.getAddyInfoFor(account).call();
    if(!addyFeeDistInfo) {
        return null;
    }
    //console.log(addyFeeDistInfo)

    //Locked staking plus data
    let balanceOf_locked = addyFeeDistInfo.lockedStakingDataPlus.balanceOf;
    let ratio_locked = addyFeeDistInfo.lockedStakingDataPlus.ratio;
    let userLocked_lockedStaking = balanceOf_locked * ratio_locked / 1e18;

    //Locked staking plus (LP vault) data
    //@dev: balance is multiplied by two because 1/2 of tokens are currently vesting in the `VestingStorage` contract
    //Those tokens will be moved to the main contract in 3 months
    let balanceOf_lockedLpVault = addyFeeDistInfo.lockedStakingDataPlus_lpVault.balanceOf * 2;
    let userLocked_lockedLpVault = balanceOf_lockedLpVault * addyFeeDistInfo.lockedStakingDataPlus_lpVault.ratio / 1e18;

    let myBalance = addyFeeDistInfo.userBalance;
    let addyApproved = addyFeeDistInfo.addyApproved;

    let totalBalance = addyFeeDistInfo.totalBalance;
    let unlockedBalance = addyFeeDistInfo.unlockedBalance;

    let totalSupply = addyFeeDistInfo.totalSupply;
    addyTotalSupply = addyFeeDistInfo.addyTotalSupply; //for front page
    let lockedSupply = addyFeeDistInfo.lockedSupply;

    //Price of these tokens are currently fetched by the multicall: WETH, QUICK, WMATIC, ADDY, MUST, SUSHI, PYQ, WBTC
    for(let i = 0; i < addyFeeDistInfo.pairData.length; i++) {
        cacheTokenPrice(
            addyFeeDistInfo.pairData[i].token0,
            addyFeeDistInfo.pairData[i].token1,
            addyFeeDistInfo.pairData[i].token0Bal,
            addyFeeDistInfo.pairData[i].token1Bal,
            addyFeeDistInfo.pairData[i].token0Decimals,
            addyFeeDistInfo.pairData[i].token1Decimals
        );
    }
    console.debug('Native price:', getTokenPrice(WMATIC), 'ETH price:', getTokenPrice(WETH))

    addyDollarPrice = getTokenPrice(ADDY);
    feeDistTvl = totalSupply * addyDollarPrice; //why isn't this divided by 1e18?


    //Fee dist reward data
    let rewardForDurationAddy = addyFeeDistInfo.rewardForDurationAddy;
    let rewardForDurationQuick = addyFeeDistInfo.rewardForDurationQuick;
    let rewardForDurationWmatic = addyFeeDistInfo.rewardForDurationWmatic;

    //1628620865000 = Approx timestamp of when the Gravity furnace pool was released (when tokens started being sent from the furnace to Lock Plus)
    let timeSinceBuybacksStarted = Date.now() - 1628620865000;
    let buybackPerDay = addyFeeDistInfo.buybackTotal / (timeSinceBuybacksStarted / 86400000);

    let addyPerYearNoBuybacks = (rewardForDurationAddy / lockedSupply * 52); //used for the "Basic Lock" contract
    let addyPerYear = addyPerYearNoBuybacks + (buybackPerDay / lockedSupply * 365);
    let quickPerYear = 0; //The fee dist contract no longer distributes QUICK
    let quickApy = quickPerYear * getTokenPrice(QUICK_ADDRESS) / addyDollarPrice;
    let wmaticPerYear = rewardForDurationWmatic / totalSupply * 52;
    let wmaticApy = wmaticPerYear * getTokenPrice(WMATIC) / addyDollarPrice;

    let addyDaily = rewardForDurationAddy / 7e18 + buybackPerDay / 1e18;
    let maticDaily = rewardForDurationWmatic / 7e18;
    let lockedAddyTVL = lockedSupply * addyDollarPrice / 1e18;
    let lockedBoostTvl = addyFeeDistInfo.lockedStakingDataPlus.totalBalance / 1e18 * addyDollarPrice;

    let buybackApy = getCompoundingAPY(lockedBoostTvl, addyDollarPrice, buybackPerDay / 1e18, 0);

    let compoundedAddyApy = getCompoundingAPY(lockedAddyTVL, addyDollarPrice, addyDaily, 0);
    let compoundedWmaticApy = getCompoundingAPY(feeDistTvl / 1e18, getTokenPrice(WMATIC), maticDaily, 0);

    let compoundedTotalApy = compoundedAddyApy + compoundedWmaticApy;

    let claimableRewards = addyFeeDistInfo.claimableRewards;
    let claimableAddyAmt = claimableRewards[0][1];
    let claimableQuickAmt = 0; //The fee dist contract no longer distributes QUICK
    let claimableWmaticAmt = claimableRewards[2][1];

    let vestedBalances = addyFeeDistInfo.earningsData[0];
    let vestedBalanceAmount = vestedBalances ? vestedBalances[0]: 0
    let vestedBalanceUnlockDate = vestedBalances ? vestedBalances[1]: 0

    let withdrawableAmount = addyFeeDistInfo.withdrawableAmount; //total of all withdrawable tokens
    let penaltyAmount = addyFeeDistInfo.withdrawablePenaltyAmount; //penalty from withdrawing vested tokens early
    let totalWithdrawable = (withdrawableAmount / 10 ** 18) + (penaltyAmount / 10 ** 18);

    cacheOnlyIfNumber(DQUICK_ADDRESS, getTokenPrice(QUICK_ADDRESS) * addyFeeDistInfo.dQUICKForQUICK / 1e18, 1, true);
    cacheOnlyIfNumber(DFYN_WMATIC, getTokenPrice(WMATIC), 1, true);

    addyData = {
        addyPerEth: addyFeeDistInfo.addyPerProfitEth,
        addyTotalSupply: addyTotalSupply,

        addyEthPrice: addyDollarPrice / getTokenPrice(WETH),

        //used to calculate amount of pending ADDY for users
        //`wmaticEthPrice` is no longer in terms of wei
        wmaticEthPrice: getTokenPrice(WMATIC) / getTokenPrice(WETH),

        myBalance: myBalance,
        addyApproved: addyApproved,

        totalBalance: totalBalance,
        unlockedBalance: unlockedBalance,

        totalSupply: totalSupply,
        lockedSupply: lockedSupply,
        nextLockStart: addyFeeDistInfo.nextLockStart,
        //should iterate thru array tbh
        rewardForDurationAddy: rewardForDurationAddy,
        rewardForDurationQuick: rewardForDurationQuick,
        rewardForDurationWmatic: rewardForDurationWmatic,
        addyPerYear: addyPerYear,
        addyPerYearNoBuybacks: addyPerYearNoBuybacks,
        quickPerYear: quickPerYear,
        quickApy: quickApy,
        wmaticPerYear: wmaticPerYear,
        wmaticApy: wmaticApy,
        feeSharingApy: quickApy + wmaticApy,

        //vestedBalances: vestedBalances,
        earningsData: addyFeeDistInfo.earningsData,
        totalWithdrawable: totalWithdrawable,
        vestedBalanceAmount: vestedBalanceAmount,
        vestedBalanceUnlockDate: vestedBalanceUnlockDate,

        claimableRewards: claimableRewards,
        claimableAddyAmt: claimableAddyAmt,
        claimableQuickAmt: claimableQuickAmt,
        claimableWmaticAmt: claimableWmaticAmt,

        //lockedBalances: lockedBalances,
        lockedTotal: addyFeeDistInfo.locked_total,
        lockedUnlockable: addyFeeDistInfo.locked_unlockable,
        lockedLocked: addyFeeDistInfo.locked,
        lockData: addyFeeDistInfo.lockData,

        withdrawableAmount: withdrawableAmount,
        penaltyAmount: penaltyAmount,
        withdrawableWithNoPenalty: addyFeeDistInfo.withdrawableWithNoPenalty,

        compoundedAddyApy: compoundedAddyApy,
        compoundedWmaticApy: compoundedWmaticApy,
        compoundedTotalApy: compoundedTotalApy,

        //Lock Plus
        addyApproved_locked: addyFeeDistInfo.lockedStakingDataPlus.addyApproved,
        totalBalance_locked: addyFeeDistInfo.lockedStakingDataPlus.totalBalance,
        balanceOf_locked: addyFeeDistInfo.lockedStakingDataPlus.balanceOf,
        endingTimestamp_locked: addyFeeDistInfo.lockedStakingDataPlus.endingTimestamp,
        //ratio_locked: addyFeeDistInfo.lockedStakingDataPlus.ratio, //not used
        pendingReward_locked: addyFeeDistInfo.lockedStakingDataPlus.pendingReward,
        userLocked_lockedStaking: userLocked_lockedStaking,

        //Lock Plus (LP vault)
        //approval info isn't needed for the LP vault, since users won't normally deposit into it
        totalBalance_lockedLpVault: addyFeeDistInfo.lockedStakingDataPlus_lpVault.totalBalance,
        //balanceOf_lockedLpVault: addyFeeDistInfo.lockedStakingDataPlus_lpVault.balanceOf, //not used
        endingTimestamp_lockedLpVault: addyFeeDistInfo.lockedStakingDataPlus_lpVault.endingTimestamp,
        pendingReward_lockedLpVault: addyFeeDistInfo.lockedStakingDataPlus_lpVault.pendingReward,
        userLocked_lockedLpVault: userLocked_lockedLpVault,

        //Basic Lock
        addyApproved_basic: addyFeeDistInfo.lockedStakingDataBasic.addyApproved,
        totalBalance_basic: addyFeeDistInfo.lockedStakingDataBasic.totalBalance,
        balanceOf_basic: addyFeeDistInfo.lockedStakingDataBasic.balanceOf,
        endingTimestamp_basic: addyFeeDistInfo.lockedStakingDataBasic.endingTimestamp,
        pendingRewardA_basic: addyFeeDistInfo.lockedStakingDataBasic.pendingRewardA,
        pendingRewardB_basic: addyFeeDistInfo.lockedStakingDataBasic.pendingRewardB,

        user_veAddy: addyFeeDistInfo.user_veAddy,
        user_veAddy_from_lock_plus: addyFeeDistInfo.user_veAddy_from_lock_plus,
        user_boost: addyFeeDistInfo.user_boost,
        buybackApy: buybackApy
    }
    //console.log(addyData);

    return addyData;
}

/**/
