import React, { VFC } from 'react';
import ReactModal, { Styles } from 'react-modal';
import Button from '../common/Button/Button';
import { useUserContext } from '../../contexts/UserContext';
import styles from './WalletModal.module.scss';

const customStyles: Styles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    backdropFilter: 'blur(8px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100vw',
  },
  content: {
    position: 'relative',
    top: 0,
    left: 0,
    backgroundColor: 'var(--color-popup)',
    border: 'none',
    borderRadius: '20px',
    width: '600px',
    maxWidth: '90%',
    height: '350px',
    maxHeight: '90%',
    padding: '40px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

interface WalletModalProps {
  connectMetamaskWallet: () => void;
  connectCryptoDeFiWallet: () => void;
}

const WalletModal: VFC<WalletModalProps> = ({ connectMetamaskWallet, connectCryptoDeFiWallet }) => {
  const { isChooseWalletModalOpen, hideChooseWalletModal } = useUserContext();

  return (
    <ReactModal
      isOpen={isChooseWalletModalOpen}
      style={customStyles}
      onRequestClose={hideChooseWalletModal}
    >
      <Button className={styles.closeButton} onClick={hideChooseWalletModal}>
        <img src="/icons/CloseIcon.svg" alt="Close icon" />
      </Button>
      <h4 className={styles.sectionTitle}>Choose wallet</h4>
      <div className={styles.buttonsWrapper}>
        <Button className={styles.optionButton} onClick={connectMetamaskWallet}>
          <div className={styles.optionButtonContent}>
            <img
              src="/icons/MetamaskLogo.svg"
              alt="Metamask logo"
              className={styles.optionButtonLogo}
            />
            <span className={styles.optionButtonText}>MetaMask</span>
          </div>
        </Button>
        <Button className={styles.optionButton} onClick={connectCryptoDeFiWallet}>
          <div className={styles.optionButtonContent}>
            <img
              src="/icons/CryptoComLogo.svg"
              alt="Metamask logo"
              className={styles.optionButtonLogo}
            />
            <span className={styles.optionButtonText}>Crypto.com</span>
            <span className={styles.optionButtonSubtext}>DeFi Wallet</span>
          </div>
        </Button>
      </div>
    </ReactModal>
  );
};

export default WalletModal;
