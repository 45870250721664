import { calculateAprAndApy, calculateAprAndApyWithDepositFee, getCompoundingAPY } from '../../MathUtil.js'
import CURVE_MULTICALL_ABI from '../../../abis/multicall/arbitrum/vaults/curve_multicall.json'
import { isZero } from '../../../utils'
import { cacheCoingeckoPrice } from '../../external_json/CoingeckoData.js';
import { cacheCurveArbitrumApy } from '../../external_json/CurveArbitrum.js';
import { FEE } from '../../../constants';
import { cacheOnlyIfNumber, getTokenPrice } from '../../PriceCache';

let tradingFeeCache = {};

const MULTICALL_ADDRESS = "0xe6924b4bBd9C9e76a5e2957752a9880Fd3248857";

const CRV = "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978";
const WBTC = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";
const CRV_DEFAULT_PRICE = 2.50;

export const getCurveDataArbitrum = async (web3, account, pools, stakingData, stakingDataMap, addyData, priceCache) => {

    if(!getTokenPrice(CRV)) {
        cacheOnlyIfNumber(CRV, CRV_DEFAULT_PRICE, 1);
    }

    cacheCoingeckoPrice("curve-dao-token", CRV, priceCache);
    cacheCurveArbitrumApy(tradingFeeCache);

    const multicallContract = web3.eth.Contract(CURVE_MULTICALL_ABI.abi, MULTICALL_ADDRESS)

    var poolAddresses = [];
    var poolz = [];
    for (let i = 0; i < pools.length; i++) {
        poolAddresses.push(pools[i].vaultAddress);
    }

    if(poolAddresses.length > 0) {
        let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
        poolz = poolz.concat(infoForPools);
    }

    let addyPerEth = addyData.addyPerEth;

    for (let i = 0; i < pools.length; i++) {
        pools[i].vaultAddress = pools[i].vaultAddress.toLowerCase();
        try {
            let vaultInfo = poolz[i];

            let totalStaked = 0;
            let amountUserStaked = 0;
            let lpTokenApproved = 0;
            let userLpBalance = 0;
            let ratio = 0;
            let stakingAddress = pools[i].stakingAddress;

            let rewardsPerDay = 86400 * vaultInfo.curvePoolData.rewardData[0].rewardRate / 1e18;
            let pendingReward = vaultInfo.pendingReward * addyPerEth * vaultInfo.rewardMultiplier / 1000;

            totalStaked = vaultInfo.totalShares;
            amountUserStaked = vaultInfo.amountUserStaked;

            lpTokenApproved = vaultInfo.lpTokenApproved;
            userLpBalance = vaultInfo.userLpBalance;

            if (totalStaked > 0) {
                ratio = vaultInfo.ratio;
            }

            let harvestedToken = "CRV";

            let crvPrice = getTokenPrice(CRV);
            let lpPrice = vaultInfo.curvePoolData.virtualPrice / 1e18;
            let tvl = vaultInfo.lpStaked * lpPrice / 10 ** 18;
            let underlyingTokenData = vaultInfo.curvePoolData.underlyingTokenData;

            if(pools[i].tricrypto) {
                let am3crv_value = underlyingTokenData.balance0 / 10 ** underlyingTokenData.decimals0 * underlyingTokenData.price0 / 1e18;
                let wbtc_value = underlyingTokenData.balance1 / 10 ** underlyingTokenData.decimals1 * underlyingTokenData.price1 / 1e18;
                let weth_value = underlyingTokenData.balance2 / 10 ** underlyingTokenData.decimals2 * underlyingTokenData.price2 / 1e18;
                tvl = am3crv_value + wbtc_value + weth_value;
                lpPrice = tvl / vaultInfo.lpSupply * 1e18;
                tvl *= vaultInfo.lpStaked / vaultInfo.lpSupply;
            }
            else if(pools[i].twopool) {
                tvl *= vaultInfo.lpStaked / vaultInfo.lpSupply;
            }
            else if(pools[i].ren) {
                tvl *= vaultInfo.lpStaked / vaultInfo.lpSupply;
                if(getTokenPrice(WBTC) !== undefined) {
                    tvl *= getTokenPrice(WBTC);
                }
            }

            const boost = vaultInfo.boost != null ? vaultInfo.boost / 1e18: 0;
            const aprApyData = calculateAprAndApy(crvPrice, rewardsPerDay, tvl, addyData, FEE, vaultInfo.rewardMultiplier * (1 + boost));
            let tradingFeeApy = tradingFeeCache[pools[i].stakingAddress.toLowerCase()] ? tradingFeeCache[pools[i].stakingAddress.toLowerCase()] * 100: -1;

            let vaultData = {
                platform: pools[i].platform,
                exchange: pools[i].exchange,
                tooltip: pools[i].tooltip,
                customExchangeUrl: pools[i].customExchangeUrl,

                harvestedToken: harvestedToken,
                harvestTwice: pools[i].harvestTwice,
                harvestBefore: pools[i].harvestBefore,
                timestamp: pools[i].timestamp,

                addyRewards: pools[i].addyRewards,
                singleAsset: pools[i].singleAsset,
                index: stakingData.length,
                token0Name: pools[i].token0Name,
                token1Name: pools[i].token1Name,
                token0: pools[i].token0,
                token1: pools[i].token1,
                lpAddress: pools[i].lpAddress.toLowerCase(),
                lpPrice: lpPrice,
                stakingAddress: stakingAddress.toLowerCase(),
                vaultAddress: pools[i].vaultAddress,
                strategyAddress: pools[i].strategyAddress,
                deprecated: pools[i].deprecated,
                hidden: pools[i].hidden,
                pendingReward: pendingReward,
                lastDepositTime: vaultInfo.lastDepositTime,
                forceShow: pools[i].forceShow,
                nameOverride: pools[i].nameOverride,
                boost: boost,
                boostable: vaultInfo.boostable,
                hiddenForNonStaked: pools[i].hiddenForNonStaked,
                depositDisabled: pools[i].depositDisabled,

                //new post-Merlin variables
                rewardAllocation: vaultInfo.rewardAllocation,
                totalPendingReward: vaultInfo.totalPendingReward,
                withdrawPenaltyTime: vaultInfo.withdrawPenaltyTime,
                withdrawPenalty: vaultInfo.withdrawPenalty,

                rewardsPerDay: rewardsPerDay,

                totalStaked: totalStaked,
                valueTotalStaked: totalStaked * lpPrice,
                amountUserStaked: amountUserStaked,
                valueUserStaked: amountUserStaked * lpPrice,
                lpTokenApproved: lpTokenApproved,
                userLpBalance: userLpBalance,

                baseApy: aprApyData.baseApy,
                addyTokenApy: aprApyData.addyTokenApy,
                addyFeeShareApy: aprApyData.addyFeeShareApy,
                totalAddyApy: aprApyData.totalAddyApy,
                apy: aprApyData.totalApy,

                baseApr: aprApyData.baseApr,
                addyTokenApr: aprApyData.addyTokenApr,
                aprProfitToStakers: aprApyData.aprProfitToStakers,
                totalAddyApr: aprApyData.totalAddyApr,
                apr: aprApyData.totalApr,

                tradingFeeApy: tradingFeeApy,

                ratio: ratio
            }
            stakingDataMap[pools[i].vaultAddress] = vaultData;
            stakingData.push(vaultData)
        }
        catch {
            console.error("Failed to load Curve pool: " + i)
        }
    }
    return stakingData;
}
