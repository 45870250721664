import React, { useState, VFC } from 'react';
import { useForm } from 'react-hook-form';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Decimal from 'decimal.js';
import classNames from 'classnames';
import multiTransactionIcon from '@assets/icons/multi-transaction.svg';
import styles from './VaultCardBodyActions.module.scss';
import utilStyles from '../../../../../styles/utils.module.scss';
import Button from '../../../../common/Button/Button';
import ExternalLink from '../../../../common/Link/Link';
import TextInput from '../../../../TextInput/TextInput';
import { useUserContext } from '../../../../../contexts/UserContext';
import { getAdamantTokenName } from '../../../../../api/Multichain';
import { tokenAmountValidationSchema } from '../../../../../utils/validators';
import { formatTokenAmount } from '../../../../../_helpers';
import { getExchangeUrl } from '../../../../../constants/platform';

interface Props {
  vault: any;
}

const getInvestValidationSchema = (maxAmount: number) => {
  return object({
    amount: tokenAmountValidationSchema(maxAmount),
  });
};

const VaultCardBodyActions: VFC<Props> = ({ vault }) => {
  const { isWalletConnected, vaultFunctions, showChooseWalletModal } = useUserContext();
  const [isStakeInVaultPending, setIsStakeInVaultPending] = useState(false);
  const [isWithdrawAllPending, setIsWithdrawAllPending] = useState(false);
  const [isClaimAndVestPending, setIsClaimAndVestPending] = useState(false);

  const isTokenApproved = Number(vault.lpTokenApproved) > 0;
  let exchangeURL = getExchangeUrl(vault.exchange, vault.token0, vault.token1);
  if (vault.customExchangeUrl) {
    exchangeURL = vault.customExchangeUrl;
  }

  const userBalance = new Decimal(vault.userLpBalance / 10 ** 18);
  const userPendingReward = new Decimal(
    (vault.pendingReward / 10 ** 18) * (vault.boost > 0 ? 1 + vault.boost : 1),
  );

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: {
      errors: stakeFormErrors,
      isValid: isStakeFormValid,
      isSubmitted: isStakeFormSubmitted,
    },
  } = useForm({
    resolver: yupResolver(getInvestValidationSchema(vault.userLpBalance / 10 ** 18)),
    mode: 'onSubmit',
  });

  const handleInvestAll = () => {
    setValue('amount', userBalance.toNumber());
    trigger('amount');
  };

  const handleConnectWallet = () => {
    showChooseWalletModal();
  };

  const handleInvestSubmit = async (data: any) => {
    if (isStakeInVaultPending) return;
    setIsStakeInVaultPending(true);

    try {
      if (!isTokenApproved) {
        await vaultFunctions.approve([
          { lpAddress: vault.lpAddress, vaultAddress: vault.vaultAddress },
        ]);
      }

      const isInvestAll = userBalance.equals(data.amount);
      if (isInvestAll) {
        await vaultFunctions.stakeAllInVault([{ vaultAddress: vault.vaultAddress }]);
      } else {
        await vaultFunctions.stakeInVault([
          { vaultAddress: vault.vaultAddress, amount: data.amount },
        ]);
      }

      setValue('amount', '');
    } finally {
      setIsStakeInVaultPending(false);
    }
  };

  const handleWithdrawAll = () => {
    if (isWithdrawAllPending) return;
    setIsWithdrawAllPending(true);

    vaultFunctions.withdrawAll([{ vaultAddress: vault.vaultAddress }]).finally(() => {
      setIsWithdrawAllPending(false);
    });
  };

  const handleClaimAndVest = () => {
    if (isClaimAndVestPending) return;
    setIsClaimAndVestPending(true);

    vaultFunctions.claimAndVest([{ vaultAddress: vault.vaultAddress }]).finally(() => {
      setIsClaimAndVestPending(false);
    });
  };

  const getInvestTokenName = (vault: any) => {
    return vault.nameOverride
      ? vault.nameOverride
      : `${vault.singleAsset ? vault.token0Name : `${vault.token0Name}/${vault.token1Name}`} LP`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.investmentsSection}>
        <p className={styles.investmentsSectionTitle}>My Investments</p>
        <div className={styles.investmentsSectionValue}>
          <p className={styles.investmentsSectionAmount}>
            {formatTokenAmount((vault.amountUserStaked / 10 ** 18) * (vault.ratio / 10 ** 18))}
          </p>
          <p className={styles.investmentsSectionToken}>{getInvestTokenName(vault)}</p>
        </div>

        {isWalletConnected && (
          <Button
            onClick={handleWithdrawAll}
            kind="outlined"
            color="primary"
            disabled={vault.amountUserStaked / 10 ** 18 === 0 || isWithdrawAllPending}
            className={styles.investmentsSectionButton}
          >
            Withdraw All
          </Button>
        )}
      </div>

      <form onSubmit={handleSubmit(handleInvestSubmit)} className={styles.depositSection}>
        {isWalletConnected && (
          <p className={styles.depositSectionTitle}>
            <button
              type="button"
              onClick={handleInvestAll}
              disabled={isStakeInVaultPending}
              className={classNames(
                styles.depositSectionBalanceButton,
                isStakeInVaultPending && utilStyles.disabled,
              )}
            >
              Balance
            </button>
            : {formatTokenAmount(userBalance.toString())} {getInvestTokenName(vault)}{' '}
            <ExternalLink newTab href={exchangeURL} className={styles.depositSectionTitleLink}>
              {Number(userBalance) > 0 ? 'Need more?' : 'Get LP'}
            </ExternalLink>
          </p>
        )}

        <div className={styles.depositSectionInput}>
          <TextInput
            withButton
            buttonText="MAX"
            onButtonClick={handleInvestAll}
            placeholder="0.0"
            disabled={isStakeInVaultPending}
            buttonDisabled={!isWalletConnected || isStakeInVaultPending}
            errorMsg={stakeFormErrors.amount?.message}
            autoComplete="off"
            {...register('amount')}
          />
        </div>

        <p className={styles.depositSectionButtonDesc}>This vault is auto-compounding</p>
        {isWalletConnected ? (
          <Button
            type="submit"
            disabled={isStakeInVaultPending || (isStakeFormSubmitted && !isStakeFormValid)}
            className={styles.depositSectionButton}
            startEnhancer={
              !isTokenApproved ? <img src={multiTransactionIcon} alt="multi transaction" /> : null
            }
          >
            Invest
          </Button>
        ) : (
          <Button
            type="button"
            color="primary"
            onClick={handleConnectWallet}
            className={styles.depositSectionConnectButton}
          >
            <div>
              <span>Want to Invest in this Vault?</span>
              <span>Connect your Wallet</span>
            </div>
          </Button>
        )}
      </form>

      <div className={styles.rewardsSection}>
        <p className={styles.rewardsSectionTitle}>Rewards</p>
        <div className={styles.rewardsSectionValue}>
          <p className={styles.rewardsSectionAmount}>
            {formatTokenAmount(userPendingReward.toString())}
          </p>
          <p className={styles.rewardsSectionToken}>{getAdamantTokenName()}</p>
        </div>

        {isWalletConnected && (
          <Button
            className={styles.rewardsSectionButton}
            color="primary"
            onClick={handleClaimAndVest}
            disabled={userPendingReward.toNumber() === 0 || isClaimAndVestPending}
          >
            Claim & Vest
          </Button>
        )}
      </div>
    </div>
  );
};

export default VaultCardBodyActions;
