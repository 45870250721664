import React, { VFC } from 'react';
import dayjs from 'dayjs';
import { useUserContext } from '../../contexts/UserContext';
import BoostTopTiles from './BoostTopTiles';
import { boostFormatter } from '../../_helpers';

const getNextLockEndDate = (
  basicLockEndTimestamp: number | undefined,
  plusLockEndTimestamp: number | undefined,
): dayjs.Dayjs | undefined => {
  if (
    basicLockEndTimestamp === undefined ||
    plusLockEndTimestamp === undefined ||
    (basicLockEndTimestamp === 0 && plusLockEndTimestamp === 0)
  ) {
    return undefined;
  }

  if (basicLockEndTimestamp === 0 || plusLockEndTimestamp === 0) {
    return dayjs.unix(basicLockEndTimestamp || plusLockEndTimestamp);
  }

  return dayjs.unix(basicLockEndTimestamp).isBefore(dayjs.unix(plusLockEndTimestamp))
    ? dayjs.unix(basicLockEndTimestamp)
    : dayjs.unix(plusLockEndTimestamp);
};

interface BoostTopTilesContainerProps {
  userLockedBasic?: number;
  userLockedPlus?: number;
  basicLockEndTimestamp?: number;
  plusLockEndTimestamp?: number;
  userTotalBoostPoints: string;
  boostedInvestments: string;
  userMaxBoost: number;
}

const BoostTopTilesContainer: VFC<BoostTopTilesContainerProps> = ({
  userLockedBasic,
  userLockedPlus,
  basicLockEndTimestamp,
  plusLockEndTimestamp,
  userTotalBoostPoints,
  boostedInvestments,
  userMaxBoost,
}) => {
  const { isWalletConnected } = useUserContext();

  const userLockedTotal = (userLockedBasic || 0) + (userLockedPlus || 0);

  const nextLockEndDate = getNextLockEndDate(basicLockEndTimestamp, plusLockEndTimestamp);

  if (!isWalletConnected || userLockedTotal === 0 || !nextLockEndDate) {
    return null;
  }

  return (
    <BoostTopTiles
      totalLockedAmount={userLockedTotal}
      nextLockEndDate={nextLockEndDate}
      boostMultiplier={userMaxBoost ? boostFormatter(userMaxBoost) : '2.0'}
      boostPoints={userTotalBoostPoints}
      boostedInvestments={boostedInvestments}
    />
  );
};

export default BoostTopTilesContainer;
