let lastTimeCached = 0;
let apr = 1;

export function getGainsFarmData() {
    if(lastTimeCached && lastTimeCached + 60000 > Date.now()) {
        return apr;
    }
    lastTimeCached = Date.now();

    fetch("https://gains-farm-v2-mainnet.herokuapp.com/apr")
    .then(res => res.text())
    .then((result) => {
        apr = JSON.parse(result)['apr'];
        console.debug(`Cached apr: ${apr}`)
        return apr;
    },
    (error) => {
        console.debug('Error loading Gains.farm data')
    });
    return 0;
}