import React from 'react';
import LandingPageHeader from './LandingPageHeader';

import {
  getFeeDistTvl,
  getAdamantTokenTotalSupply,
  getAdamantTokenDollarPrice,
  getAdamantTokenName,
} from '../../api/Multichain.js';

const getTVL = (vaults: any, rewardPools: any) => {
  let total = 0;
  for (const vault of vaults) {
    if (!vault.hidden && vault.lpPrice && !isNaN(vault.lpPrice) && vault.lpPrice > 0) {
      total += vault.totalStaked * vault.lpPrice;
    }
  }
  for (const pool of rewardPools) {
    if (pool.lpPrice && !isNaN(pool.lpPrice) && pool.lpPrice > 0) {
      total += pool.totalShares * pool.lpPrice;
    }
  }
  total += getFeeDistTvl();
  return total / 10 ** 18;
};

const getMonthlyAddyStakerProfit = (pools: any) => {
  let total = 0;
  for (const pool of pools) {
    if (!pool.lpPrice || !pool.aprProfitToStakers) continue;

    if (
      !isNaN(pool.aprProfitToStakers) &&
      pool.aprProfitToStakers > 0 &&
      pool.aprProfitToStakers !== Infinity
    ) {
      total += (pool.totalStaked * pool.lpPrice * (pool.aprProfitToStakers / 100.0)) / 12.0;
    }
  }
  return total / 10 ** 18;
};

interface LandingPageHeaderContainerProps {
  pools: any;
  stakingRewardsData: any;
  isVaultsLoaded: boolean;
  onLearnMoreClick: () => void;
}

const LandingPageHeaderContainer: React.VFC<LandingPageHeaderContainerProps> = ({
  pools,
  stakingRewardsData,
  isVaultsLoaded,
  onLearnMoreClick,
}) => (
  <LandingPageHeader
    isVaultsLoaded={isVaultsLoaded}
    totalValueLocked={getTVL(pools, stakingRewardsData)}
    currentTokenName={getAdamantTokenName()}
    marketCap={getAdamantTokenTotalSupply() * getAdamantTokenDollarPrice()}
    monthlyProfits={getMonthlyAddyStakerProfit(pools)}
    onLearnMoreClick={onLearnMoreClick}
  />
);

export default LandingPageHeaderContainer;
