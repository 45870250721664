import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import styles from './Button.module.scss';

interface ButtonProps {
  children?: ReactNode;
  color?: 'primary';
  kind?: 'contained' | 'outlined';
  className?: string;
  onClick?: () => void;
  to?: string;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  endEnhancer?: ReactNode;
  startEnhancer?: ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      color,
      kind = 'contained',
      className,
      onClick,
      to,
      href,
      type,
      disabled,
      startEnhancer,
      endEnhancer,
    },
    ref,
  ) => {
    const history = useHistory();

    const handleClick = () => {
      if (to) {
        history.push(to);
        return;
      }
      if (href) {
        window.open(href, '_blank', 'noopener noreferrer');
      }
      if (onClick) {
        onClick();
      }
    };

    return (
      <button
        className={classnames(
          styles.button,
          color === 'primary' && styles.primary,
          kind === 'outlined' && styles.outlined,
          className,
        )}
        disabled={disabled}
        onClick={handleClick}
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type ?? 'button'}
      >
        {startEnhancer}
        <span className={styles.text}>{children}</span>
        {endEnhancer}
      </button>
    );
  },
);

export default Button;
