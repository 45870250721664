const stablecoins = new Set(
  [
    // Polygon addresses
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063', // big 3
    '0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72', // PUSD
    '0x692597b009d13c4049a947cab2239b7d6517875f', // UST
    '0xD86b5923F3AD7b585eD81B448170ae026c65ae9a', // IRON v2
    '0xa3fa99a148fa48d14ed51d610c367c61876997f1', // MAI
    '0x104592a158490a9228070e0a8e5343b499e125d0', // FRAX
    '0xdAD97F7713Ae9437fa9249920eC8507e5FbB23d3', // Tricrypto v3
    '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171', // am3CRV
    '0x985D40feDAA3208DAbaCDFDCA00CbeAAc9543949', // IRON-IS3USD
    '0x5290Ad3d83476CA6A2b178Cd9727eE1EF72432af', // IMUSD
    '0xb4d09ff3dA7f9e9A2BA029cb0A81A989fd7B8f17', // IS3USD
    '0xb8ab048D6744a276b2772dC81e406a4b769A5c3D', // WUSD
    '0x0527c8c43250279d6eb74da1078193f5316fc9a0', // PYD
    '0xB6c473756050dE474286bED418B77Aeac39B02aF', // NUSD

    // Arbitrum addresses
    '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', // USDC
    '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', // USDT
    '0x30dF229cefa463e991e29D42DB0bae2e122B2AC7', // MIM-2POOL
    '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A', // MIM

    // Cronos addresses
    '0xc21223249ca28397b4b6541dffaecc539bff0c59', // USDC
    '0x66e428c3f67a68878562e79a0234c1f83c208770', // USDT
    '0xf2001b145b43032aaf5ee2884e456ccd805f677d', // DAI

    // Testnet addresses
    '0xb828e46a504d52d149464aee052dc885d8a942a0', // Test USDC
  ].map((value) => value.toLowerCase()),
);

export default stablecoins;
