import React, { forwardRef, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './DropdownMenu.module.scss';

interface DropdownMenuProps {
  children: ReactNode;
  className?: string;
}

const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>((props, ref) => {
  const { children, className } = props;

  return (
    <div className={classnames(styles.dropdown, className)} ref={ref}>
      {children}
    </div>
  );
});

export default DropdownMenu;
