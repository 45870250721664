import React, { VFC } from 'react';
import Loading from '../../components/Loading/Loading';
import { currencyFormatter } from '../../_helpers';
import BoostPage from './BoostPage';

interface BoostPageContainerProps {
  addyData?: any;
  vaults: any[];
}

const BoostPageContainer: VFC<BoostPageContainerProps> = ({ addyData, vaults }) => {
  if (!addyData) {
    return <Loading />;
  }

  const userBoostVaults = vaults.filter((vault) => vault.valueUserStaked > 0 && vault.boostable);
  const boostedInvestments = userBoostVaults.reduce((acc, curr) => acc + curr.valueUserStaked, 0);

  const userMaxBoost = userBoostVaults.reduce((acc, curr) => Math.max(acc, curr.boost), 0);

  return (
    <BoostPage
      userLockedBasic={addyData.balanceOf_basic / 1e18 || undefined}
      userLockedPlus={addyData.userLocked_lockedStaking / 1e18 || undefined}
      userBalance={addyData.myBalance / 1e18}
      isLockPlusTokenApproved={!!Number(addyData.addyApproved_locked / 1e18)}
      isLocBasicTokenApproved={!!Number(addyData.addyApproved_basic / 1e18)}
      basicLockEndTimestamp={Number(addyData.endingTimestamp_basic)}
      plusLockEndTimestamp={Number(addyData.endingTimestamp_locked)}
      aprAddy={addyData.addyPerYearNoBuybacks}
      aprNative={addyData.wmaticApy || addyData.nativeApr}
      totalLockedBasic={addyData.totalBalance_basic}
      totalLockedPlus={addyData.totalBalance_locked}
      userBoostPointsPlus={addyData.user_veAddy_from_lock_plus / 1e18}
      userBoostPointsBasic={
        addyData.user_veAddy / 1e18 - addyData.user_veAddy_from_lock_plus / 1e18
      }
      boostedInvestments={currencyFormatter(boostedInvestments / 1e18)}
      pendingAdamantTokenRewardBasic={Number(addyData.pendingRewardB_basic)}
      pendingNativeTokenRewardPasic={Number(addyData.pendingRewardA_basic)}
      pendingRewardPlus={Number(addyData.pendingReward_locked)}
      userMaxBoost={userMaxBoost}
      lpBoostLockedAmount={addyData.userLocked_lockedLpVault / 1e18 || 0}
      lpBoostPendingReward={addyData.pendingReward_lockedLpVault / 1e18 || 0}
      lpBoostEndTimestamp={Number(addyData.endingTimestamp_lockedLpVault || 0)}
    />
  );
};

export default BoostPageContainer;
