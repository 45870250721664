import React, { ReactNode, VFC } from 'react';

import { motion } from 'framer-motion';
import classNames from 'classnames';
import styles from './ToastMessage.module.scss';
import closeIcon from './close-icon.svg';
import errorIcon from './error-icon.svg';
import infoIcon from './info-icon.svg';
import loadingIcon from './loading-icon.png';

interface Props {
  title: string;
  content?: ReactNode;
  type: 'info' | 'error' | 'loading';
  actions?: {
    label: string;
    callback: () => void;
  }[];
}

const propsMap = {
  info: {
    icon: infoIcon,
    className: styles.typeInfo,
  },
  loading: {
    icon: loadingIcon,
    className: styles.typeInfo,
  },
  error: {
    icon: errorIcon,
    className: styles.typeError,
  },
};

const ToastMessage: VFC<Props> = ({ type, title, content, actions }) => {
  const isLoading = type === 'loading';

  return (
    <div className={classNames(styles.toast, propsMap[type].className)}>
      <div className={styles.main}>
        {isLoading ? (
          <motion.img
            initial={{ rotate: 0 }}
            animate={{ rotate: 360 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: 'linear',
            }}
            className={styles.icon}
            src={propsMap[type].icon}
            alt=""
          />
        ) : (
          <img className={styles.icon} src={propsMap[type].icon} alt="" />
        )}

        <div>
          <p className={styles.message}>{title}</p>
          <div className={styles.content}>{content}</div>
        </div>

        <div className={styles.close}>
          <button className={styles.closeBtn} type="button">
            <img src={closeIcon} alt="" />
          </button>
        </div>
      </div>

      {actions && (
        <div className={styles.actions}>
          {actions.map((action) => (
            <button
              key={action.label}
              className={styles.actionBtn}
              type="button"
              onClick={action.callback}
            >
              {action.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ToastMessage;
