import React, { ReactNode } from 'react';
import classnames from 'classnames';
import SectionTitle from '../SectionTitle/SectionTitle';
import styles from './TextWithImage.module.scss';

interface TextWithImageProps {
  image: string;
  title: string;
  text: ReactNode;
  linkLeft?: ReactNode;
  linkRight?: ReactNode;
  reversed?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

const TextWithImage: React.VFC<TextWithImageProps> = ({
  image,
  title,
  text,
  linkLeft,
  linkRight,
  reversed,
  scrollRef,
}) => (
  <div className={classnames(styles.container, reversed && styles.reversed)} ref={scrollRef}>
    <div className={styles.imageWrapper}>
      <img src={image} alt="" className={styles.image} />
    </div>
    <div>
      <SectionTitle>{title}</SectionTitle>
      <div className={styles.text}>{text}</div>
      <div className={styles.linksWrapper}>
        <div>{linkLeft}</div>
        <div>{linkRight}</div>
      </div>
    </div>
  </div>
);

export default TextWithImage;
