import React, { VFC } from 'react';
import Card, { CardProps } from '../../Card/Card';
import styles from './VestingStatsTile.module.scss';
import { formatTokenAmount } from '../../../_helpers';

export interface VestingStatsTileProps extends Omit<CardProps, 'expandBtn'> {
  title: string;
  value: number;
  tokenName: string;
  desc?: string;
}

const VestingStatsTile: VFC<VestingStatsTileProps> = (props) => {
  const { title, value, tokenName, desc, ...cardProps } = props;

  return (
    <Card {...cardProps} expandBtn>
      <p className={styles.title}>{title}</p>
      <div className={styles.valueWrapper}>
        <p className={styles.amount}>{formatTokenAmount(value)}</p>
        <p className={styles.tokenName}>{tokenName}</p>
      </div>
      {desc && <p className={styles.desc}>{desc}</p>}
    </Card>
  );
};

export default VestingStatsTile;
