import React, { Component } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import '../../../css/pancake.css'

class VaultLabel extends Component {
    getTooltip(tooltip) {
        return props => (
            <Tooltip className="tooltip-yield" {...props}>{tooltip}</Tooltip>
        );
    };

    render() {
        return (
            <div className="label">
                <span>
                    {this.props.poolName}
                    {this.props.pool.degen ?
                        <OverlayTrigger placement="top" overlay={this.props.overlay}>
                            <div className="degenIcon">
                                <div className="square">
                                    <img src={"/img/degen-logo.png"} alt="token icon"></img>
                                </div>
                            </div>
                        </OverlayTrigger>
                        : ""}
                    {this.props.tooltip != null ?
                        <OverlayTrigger placement="top" overlay={this.getTooltip(this.props.tooltip)}>
                            <div className="degenIcon">
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                        </OverlayTrigger>
                        : ""}
                </span>
                <div className="tag-group">
                    {<div className="tag">
                        {this.getFormattedPlatformName(this.props.pool.platform)}
                    </div>}
                    {!this.props.pool.maximizer && this.props.pool.boostable && <div className="tag">
                        Boostable
                    </div>}
                    {this.props.pool.maximizer && <div className="tag" style={{color: dinoColor}}>
                        Maximizer
                    </div>}
                </div>
                {this.props.pool.maximizer && this.props.pool.boostable && <div className="tag-group">
                    <div className="tag">
                        Boostable
                    </div>
                </div>}
            </div>
        );
    }

    //should put this in another class
    getFormattedPlatformName(platform) {
        if(platform === "mmfinance") {
            return "MM Finance";
        }
        return platform;
    }

    getColor(pool) {
        switch (pool.platform) {
            case "dino":
                return dinoColor;
            case "sushi":
                return sushiColor;
            case "wault":
                return waultColor;
            case "quick":
                return quickColor;
            default:
                break;
        }
        return {};
    }
}

export default VaultLabel;

const quickColor = {
    color: "rgb(33, 114, 229)",
    border: "2px solid rgb(33, 114, 229)"
};
const dinoColor = {
    color: "rgb(182, 204, 117)",
    border: "2px solid rgb(182, 204, 117)"
};
const sushiColor = {
    color: "#27b0e6",
    border: "2px solid #fa52a0"
};
const waultColor = {
    color: "#8247e5",
    border: "2px solid #8247e5"
};

