import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';
import styles from './Modal.module.scss';
import utilStyles from '../../styles/utils.module.scss';
import closeIcon from './close-icon.svg';

export interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  disableDismiss?: boolean;
}

const InnerModal: FC<ModalProps> = ({ isOpen, onClose, disableDismiss, children }) => {
  const handleCloseButtonClick = () => {
    if (disableDismiss) return;
    onClose?.();
  };

  const handleBackdropClick = () => {
    if (disableDismiss) return;
    onClose?.();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={styles.modal}>
        <div className={styles.backdropOverlay} onClick={handleBackdropClick} />
        <div className={styles.innerModal}>
          <button
            type="button"
            disabled={disableDismiss}
            onClick={handleCloseButtonClick}
            className={classNames(styles.closeButton, disableDismiss && utilStyles.disabled)}
          >
            <img src={closeIcon} alt="Close" />
          </button>

          {children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const Modal: FC<ModalProps> = (props) => {
  return createPortal(
    <InnerModal {...props} />,
    document.querySelector('#modal') as HTMLDivElement,
  );
};

export default Modal;
