import React, { MouseEvent, forwardRef, InputHTMLAttributes, useCallback, useState } from 'react';
import classnames from 'classnames';
import styles from './SliderInput.module.scss';

interface SliderInputProps extends InputHTMLAttributes<HTMLInputElement> {}

const SliderInput = forwardRef<HTMLInputElement, SliderInputProps>((props, ref) => {
  const { onMouseDown, onMouseUp, value, min = 0, max = 100, ...inputProps } = props;

  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      setIsDragging(true);
      onMouseDown?.(e);
    },
    [onMouseDown],
  );

  const handleMouseUp = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      setIsDragging(false);
      onMouseUp?.(e);
    },
    [onMouseUp],
  );

  const percentageProgress = ((Number(value) - Number(min)) / (Number(max) - Number(min))) * 100;

  return (
    <div className={styles.container}>
      <div className={styles.currentValueTrack}>
        <div
          className={classnames(styles.currentValue, isDragging && styles.active)}
          style={{ left: `${percentageProgress}%` }}
        >
          {value}
        </div>
      </div>

      <div className={styles.rangeInputContainer}>
        <div className={styles.rangeInputTrack}>
          <span
            className={styles.rangeInputBacktrack}
            style={{ transform: `translateX(-${100 - percentageProgress}%)` }}
          />
        </div>
        <input
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          className={classnames(styles.rangeInput, isDragging && styles.active)}
          value={value}
          min={min}
          max={max}
          {...inputProps}
          type="range"
          ref={ref}
        />
      </div>

      <div className={styles.valueLabels}>
        <p className={styles.valueLabel}>{min}</p>
        <p className={styles.valueLabel}>{max}</p>
      </div>
    </div>
  );
});

export default SliderInput;
