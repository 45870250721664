import React, { FC } from 'react';
import VaultTile from '../VaultTile/VaultTile';

import styles from './InvestmentsTile.module.scss';
import { useUserContext } from '../../../contexts/UserContext';

import { currencyFormatter } from '../../../_helpers';

interface Props {
  bgParticles: string;
  vaults: any[];
}

const InvestmentsTile: FC<Props> = ({ bgParticles, vaults }) => {
  const { isWalletConnected } = useUserContext();

  const vaultsWithInvests = vaults.filter((vault: any) => vault.valueUserStaked > 0);
  const investedInVaults = vaultsWithInvests.reduce(
    (acc: number, curr: any) =>
      acc + (curr.lpPrice * curr.amountUserStaked * (curr.ratio / 10 ** 18)) / 10 ** 18,
    0,
  );

  return (
    <VaultTile
      title="My Investments"
      connectDesc="to see a summary of your investments"
      isConnected={isWalletConnected}
      bgParticles={bgParticles}
    >
      <p className={styles.value}>{currencyFormatter(investedInVaults)}</p>

      {vaultsWithInvests.length > 0 && (
        <p className={styles.desc}>
          In {vaultsWithInvests.length} {vaultsWithInvests.length === 1 ? 'Vault' : 'Vaults'}
        </p>
      )}
    </VaultTile>
  );
};

export default InvestmentsTile;
