import React from 'react';
import '../css/Main.css'
import { NavLink } from "react-router-dom";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getAdamantTokenName, getAdamantTokenDollarPrice } from '../api/Multichain.js'
import { getNativeTokenName } from '../constants/multichain/network.js';
import { NetworkID } from '../constants';
import { nFormatter } from '../utils';

class AddySingleStakeCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '0',
            expanded: false
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({
            input: event.target.value
        });
    }
    toggleExpander = (event) => {
        if (!this.state.expanded) {
            this.setState({ expanded: true });
        }
        else {
            this.setState({ expanded: false });
        }
    }

    render() {
        const addyData = this.props.addyData;
        let adamantTokenName = getAdamantTokenName();

        //const feeSharingApy = (addyData.feeSharingApy * 100);
        //const addyPerYear = (addyData.addyPerYear * 100);
        const compoundedTotalApy = addyData.compoundedTotalApy;
        const compoundedAddyApy = addyData.compoundedAddyApy;
        const compoundedNativeApy = addyData.compoundedWmaticApy !== undefined ? addyData.compoundedWmaticApy: addyData.compoundedNativeApy;
        const nativeToAddyApy = addyData.nativeToAddyApy; //APY when compounding native back into ADDY

        const totalStaked = addyData.totalSupply;

        const lockedTotal = addyData.lockedTotal / 10 ** 18;
        const unlockedBalance = addyData.unlockedBalance / 10 ** 18;
        const balanceOf_locked = addyData.userLocked_lockedStaking / 10 ** 18;
        const balanceOf_basic = addyData.balanceOf_basic / 10 ** 18;
        const vestedTotal = addyData.totalWithdrawable - unlockedBalance; //this is in float
        const totalLockedAndStaked = lockedTotal + unlockedBalance + balanceOf_locked + balanceOf_basic + (vestedTotal);

        const lpPrice = getAdamantTokenDollarPrice();

        let poolYieldString = (compoundedTotalApy > 1000000000 ? ">1 BILLION": nFormatter(compoundedTotalApy, 2)) + "% APY";
        //let poolYieldString = (compoundedTotalApy).toFixed(2) + "% APY";
        let poolYieldTooltipString = "APY:\r\n" +
                                    compoundedNativeApy.toFixed(2) + "%: Fee-sharing dividends\r\n"
                                    + compoundedAddyApy.toFixed(2) + `%: ${adamantTokenName} (if locking)\r\n\r\n`
                                    + `Locking ${adamantTokenName} also boosts ${adamantTokenName} earned from normal vaults by up to 100%.`;

        if(nativeToAddyApy !== undefined) {
            if(nativeToAddyApy < 1000000000) {
                poolYieldString = `${nFormatter(compoundedTotalApy, 2)}% → ${nFormatter(nativeToAddyApy, 2)}% APY`;
            }

            poolYieldTooltipString = "APY:\r\n" +
                compoundedNativeApy.toFixed(2) + "%: Fee-sharing dividends\r\n"
                + compoundedAddyApy.toFixed(2) + `%: ${adamantTokenName} (if locking)\r\n`
                + nativeToAddyApy.toFixed(2) + `%: Total APY if compounding ${getNativeTokenName()} into ${adamantTokenName} and locking\r\n\r\n`
                + `Locking ${adamantTokenName} also boosts ${adamantTokenName} earned from normal vaults by up to 100%.`;
        }

        let description = "Dividends";
        let earnString = `${getNativeTokenName()} + ${adamantTokenName}`;

        var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', });
        const renderTooltip = props => (
          <Tooltip className="tooltip-yield" {...props}>{poolYieldTooltipString}</Tooltip>
        );

        return (
            <NavLink className={"farms-card-item highlight no-select clickable collapsed"} to="/stakeaddy">
                <div className="icon">
                    <div className="card-icon no-select">
                        <div className="poolRowTokenLeft">
                          <div className="square">
                            <img src={"/img/ADDY.png"} alt="token icon"></img>
                          </div>
                        </div>
                    </div>
                </div>

                <div className="label">
                    <span>Stake/Lock {adamantTokenName} to Boost Vault Earnings and Earn Dividends</span>
                </div>

                <div className="rates">
                    <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <span className="apy">{poolYieldString}</span>
                    </OverlayTrigger>
                    <span className="description">{description}</span>
                </div>

                <div className="details return">
                    <span className="label">Earn</span>
                    <span className="value">{earnString}</span>
                </div>

                <div className="details balance">
                    <span className="label">User Staked</span>
                    <span className="value">
                        {this.props.showLpAmounts ?
                            (totalLockedAndStaked).toFixed(4) + " " + adamantTokenName:
                            formatter.format(lpPrice * totalLockedAndStaked)
                        }
                    </span>
                </div>

                <div className="details total">
                    <span className="label">Total Staked</span>
                    <span className="value">
                        {this.props.showLpAmounts ?
                            (totalStaked / 10 ** 18).toFixed(4) + " " + adamantTokenName:
                            formatter.format(lpPrice * totalStaked / 10 ** 18)
                        }
                    </span>
                </div>
            </NavLink>
        );
    }
}

export default AddySingleStakeCard;
