import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { getLockDurationOptions, getLockTokensValidationSchema } from './utils';
import { useUserContext } from '../../contexts/UserContext';
import {
  getAdamantTokenAddress,
  getLockedBasicAddress,
  getLockedPlusAddress,
} from '../../api/Multichain';

interface BoostFormFields {
  lockAmount: string;
  lockDuration: number | null;
}

const getIsExtend = (
  isLockPlus: boolean,
  lockAmount: string,
  userLockedBasic: number | undefined,
  userLockedPlus: number | undefined,
) => {
  if (!isLockPlus && lockAmount === '' && userLockedBasic !== undefined && userLockedBasic > 0) {
    return true;
  }

  if (isLockPlus && lockAmount === '' && userLockedPlus !== undefined && userLockedPlus > 0) {
    return true;
  }

  return false;
};

export const useBoostLockForm = (
  isLockPlus: boolean,
  userBalance: number,
  isLockPlusTokenApproved: boolean,
  isLocBasicTokenApproved: boolean,
  basicLockEndTimestamp?: number,
  plusLockEndTimestamp?: number,
  userLockedBasic?: number,
  userLockedPlus?: number,
) => {
  const { isWalletConnected, showChooseWalletModal, boostLockFunctions } = useUserContext();

  const [lockAmount, setLockAmount] = useState<string>('');
  const [lockDuration, setLockDuration] = useState<number | null>(null);

  const lockDurationOptions = getLockDurationOptions(
    isLockPlus ? plusLockEndTimestamp : basicLockEndTimestamp,
  );

  const isTokenApproved = isLockPlus ? isLockPlusTokenApproved : isLocBasicTokenApproved;
  const hasLockedBasic = userLockedBasic !== undefined && userLockedBasic > 0;
  const hasLockedPlus = userLockedPlus !== undefined && userLockedPlus > 0;
  const isExtend = getIsExtend(isLockPlus, lockAmount, userLockedBasic, userLockedPlus);

  const { setValue, formState, handleSubmit, reset } = useForm<BoostFormFields>({
    resolver: yupResolver(
      getLockTokensValidationSchema(isWalletConnected ? Number(userBalance) : Infinity, isExtend),
    ),
  });

  useEffect(() => {
    setValue('lockAmount', lockAmount, { shouldValidate: formState.isSubmitted });
  }, [setValue, lockAmount, formState.isSubmitted]);

  useEffect(() => {
    setValue('lockDuration', lockDuration, { shouldValidate: formState.isSubmitted });
  }, [setValue, lockDuration, formState.isSubmitted]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setLockAmount('');
      setLockDuration(null);
      reset();
    }
  }, [formState.isSubmitSuccessful, reset]);

  useEffect(() => {
    // check if after wallet connect, selected lock duration is still valid
    if (lockDuration === null) {
      return;
    }

    const lowestPossibleDuration = lockDurationOptions[0];
    if (lockDuration < lowestPossibleDuration) {
      setLockDuration(null);
    }
  }, [lockDuration, lockDurationOptions]);

  const handleDeposit = async (formData: BoostFormFields) => {
    const vaultAddress = isLockPlus ? getLockedPlusAddress() : getLockedBasicAddress();
    const lockDurationSeconds = dayjs.duration(formData.lockDuration!, 'days').asSeconds();
    const lockAmountValue = new Decimal(formData.lockAmount);
    const isDepositAll = new Decimal(userBalance).equals(lockAmountValue);

    if (!isTokenApproved) {
      await boostLockFunctions.approve([
        { lpAddress: getAdamantTokenAddress(), vaultContractAddress: vaultAddress },
      ]);
    }

    if (isDepositAll) {
      await boostLockFunctions.depositAll([
        {
          vaultContractAddress: vaultAddress,
          durationSeconds: lockDurationSeconds,
        },
      ]);
    } else {
      await boostLockFunctions.deposit([
        {
          amount: lockAmountValue.toString(),
          durationSeconds: lockDurationSeconds,
          vaultContractAddress: vaultAddress,
        },
      ]);
    }
  };

  const handleExtend = async (formData: BoostFormFields) => {
    const vaultAddress = isLockPlus ? getLockedPlusAddress() : getLockedBasicAddress();
    const lockDurationSeconds = dayjs.duration(formData.lockDuration!, 'days').asSeconds();

    await boostLockFunctions.extend([
      {
        isBasic: !isLockPlus,
        durationSeconds: lockDurationSeconds,
        vaultContractAddress: vaultAddress,
      },
    ]);
  };

  const submitSuccessHandler = async (formData: BoostFormFields) => {
    if (!userBalance || formState.isSubmitting) return;

    if (isExtend) {
      await handleExtend(formData);
    } else {
      await handleDeposit(formData);
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    if (!isWalletConnected) {
      e.preventDefault();
      showChooseWalletModal();
      return;
    }

    handleSubmit(submitSuccessHandler)(e);
  };

  return {
    isExtend,
    hasLockedBasic,
    hasLockedPlus,

    userBalance,
    lockDurationOptions,
    isTokenApproved,

    handleFormSubmit,
    isFormValid: formState.isValid,
    formErrors: formState.errors,
    isSubmitted: formState.isSubmitted,
    isSubmitting: formState.isSubmitting,
    isSubmitSuccessful: formState.isSubmitSuccessful,

    lockAmount,
    setLockAmount,
    lockDuration,
    setLockDuration,
  };
};
