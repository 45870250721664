import { NetworkID } from '../constants/index.js'
import { getAddresses, concatArrays } from '../utils';
import { getPriceCache } from './PriceCache.js';
//Polygon
import { getAddyData } from './polygon/AddyData.js'
import { getStakingRewardDataPolygon } from './polygon/vaults/StakingRewardDataPolygon.js'
import { getMasterChefStakingDataPolygon } from './polygon/vaults/MasterChefDataPolygon.js'
import { getAddyEthStakingRewardsData } from './polygon/AddyEthData.js'
import { getCurveStakingData } from './polygon/vaults/CurveData.js'
import { getFurnacePoolData } from './polygon/FurnacePoolData.js'
//Arbitrum
import { getAddyDataArbitrum } from './arbitrum/AddyData.js'
import { getMasterChefDataArbitrum } from './arbitrum/vaults/MasterChefDataArbitrum.js'
import { getCurveDataArbitrum } from './arbitrum/vaults/CurveDataArbitrum.js'
import { getStakingRewardsVaultDataArbitrum } from './arbitrum/vaults/StakingRewardDataArbitrum.js'
//Cronos
import { getAddyDataCronos } from './cronos/AddyData.js'
import { getMasterChefStakingDataCronos } from './cronos/vaults/MasterChefDataCronos.js'
import { getStakingRewardDataCronos } from './cronos/vaults/StakingRewardDataCronos.js';
//Testnet
import { getTestnetAddyData } from './polygon_testnet/AddyData.js';
import { getStakingRewardDataTestnet } from './polygon_testnet/vaults/StakingRewardDataTestnet.js';



//Polygon furnace pools
import furnace_pools from '../pools/furnace_pools.json'

//Polygon vaults/pools
import matic_pools_new from '../pools/matic_pools_new.json'
import polyquity_pools from '../pools/staking_rewards/polyquity_pools.json'
import quick_pools_boosted from '../pools/staking_rewards/quick_pools_boosted.json'
import quick_other_boosted from '../pools/staking_rewards/quick_other_boosted.json'
import dquick_pools from '../pools/staking_rewards/dquick_pools.json'
import tel_pools from '../pools/staking_rewards/tel_pools.json'
import elk_pools from '../pools/staking_rewards/elk_pools.json'
import cometh_pools from '../pools/staking_rewards/cometh_pools.json'

import whale_pools from '../pools/whale_pools.json'
import dfyn_pools from '../pools/staking_rewards/dfyn_pools.json'
import wault_pools from '../pools/master_chef/wault_pools.json'
import ape_pools from '../pools/master_chef/ape_pools.json'
import jet_pools from '../pools/master_chef/jet_pools.json'
import qi_pools from '../pools/master_chef/qi_pools.json'
import iron_pools from '../pools/master_chef/iron_pools.json'
import dino_pools from '../pools/master_chef/dino_pools.json'
import arcadium_pools from '../pools/master_chef/arcadium_pools.json'
import gravity_pools from '../pools/master_chef/gravity_pools.json'
import pulsar_pools from '../pools/master_chef/pulsar_pools.json'
import darkside_pools from '../pools/master_chef/darkside_pools.json'
import sushi_pools from '../pools/master_chef/sushi_pools.json'
import cafe_pools from '../pools/master_chef/cafe_pools.json'
import curve_pools from '../pools/curve_pools.json'

//old pools
import dfyn_pools_old from '../pools/deprecated/staking_rewards/dfyn_pools.json'
//import cometh_pools_old from '../pools/deprecated/staking_rewards/cometh_pools.json'
import quick_pools_boosted_old from '../pools/deprecated/staking_rewards/quick_pools_boosted.json'
import quick_pools_old from '../pools/deprecated/staking_rewards/quick_pools_old.json' //old QUICK-paired vaults

import dino_pools_old from '../pools/deprecated/master_chef/dino_pools.json'
import ape_pools_old from '../pools/deprecated/master_chef/ape_pools.json'
import wault_pools_old from '../pools/deprecated/master_chef/wault_pools.json'
import jet_pools_old from '../pools/deprecated/master_chef/jet_pools.json'
import iron_pools_old from '../pools/deprecated/master_chef/iron_pools.json'
import whale_pools_old from '../pools/deprecated/whale_pools.json'
import sushi_pools_old from '../pools/deprecated/sushi_pools.json'

//Arbitrum vaults/pools
import arbitrum_sushi_pools from '../pools/arbitrum/master_chef/sushi_pools.json'
import mim_pools from '../pools/arbitrum/master_chef/mim_pools.json'
import dodo_pools from '../pools/arbitrum/master_chef/dodo_pools.json'
import arbitrum_curve_pools from '../pools/arbitrum/curve_pools.json'
import swapr_pools from '../pools/arbitrum/staking_rewards/swapr_pools.json'

//Need to refactor so that this file doesn't need to know about ALL the vault/pool files
//Cronos vaults
import cronaswap_pools from '../pools/cronos/master_chef/cronaswap_pools.json'
import vvs_pools from '../pools/cronos/master_chef/vvs_pools.json'
import chronoswap_pools from '../pools/cronos/master_chef/chronoswap_pools.json'
import elk_pools_cronos from '../pools/cronos/staking_rewards/elk_pools.json'
import crodex_pools from '../pools/cronos/staking_rewards/crodex_pools.json'

//Testnet vaults
import testnet_staking_reward_pools from '../pools/testnet/testnet_staking_reward_pools.json'

let combinedArrays = {};

export async function getStakingData(networkId, web3, account) {
  if(networkId === NetworkID.POLYGON_NETWORK_ID) {
    var stakingData = [];
    var stakingDataMap = {};
    let addyData = await getAddyData(web3, account);
    if(!addyData) {
      return null;
    }

    if(!combinedArrays[networkId]) {
      concatArrays(whale_pools, gravity_pools)
      concatArrays(whale_pools, wault_pools)
      concatArrays(whale_pools, dino_pools)
      concatArrays(whale_pools, jet_pools)
      concatArrays(whale_pools, qi_pools)
      concatArrays(whale_pools, iron_pools)

      concatArrays(whale_pools, ape_pools)
      concatArrays(whale_pools, arcadium_pools)
      concatArrays(whale_pools, pulsar_pools)
      concatArrays(whale_pools, darkside_pools)
      concatArrays(whale_pools, cafe_pools)
      concatArrays(whale_pools, sushi_pools)

      concatArrays(whale_pools, whale_pools_old)
      concatArrays(whale_pools, ape_pools_old)
      concatArrays(whale_pools, dino_pools_old)
      concatArrays(whale_pools, wault_pools_old)
      concatArrays(whale_pools, jet_pools_old)
      concatArrays(whale_pools, iron_pools_old)
      concatArrays(whale_pools, sushi_pools_old)


      concatArrays(matic_pools_new, quick_other_boosted)
      concatArrays(matic_pools_new, quick_pools_boosted)
      concatArrays(matic_pools_new, dquick_pools)

      concatArrays(matic_pools_new, elk_pools)
      concatArrays(matic_pools_new, dfyn_pools)
      concatArrays(matic_pools_new, cometh_pools)

      concatArrays(matic_pools_new, polyquity_pools)
      concatArrays(matic_pools_new, tel_pools)

      concatArrays(matic_pools_new, dfyn_pools_old)
      concatArrays(matic_pools_new, quick_pools_old)
      concatArrays(matic_pools_new, quick_pools_boosted_old)

      combinedArrays[networkId] = true;
    }

    //IStakingRewards vaults
    await getStakingRewardDataPolygon(web3, account, matic_pools_new, stakingData, stakingDataMap, addyData, getPriceCache());

    //Vaults for Master Chef reward pools
    await getMasterChefStakingDataPolygon(web3, account, whale_pools, stakingData, stakingDataMap, addyData, getPriceCache());

    //Curve vaults
    await getCurveStakingData(web3, account, curve_pools, stakingData, stakingDataMap, addyData);

    let furnaceRewardsData = await getFurnacePoolData(web3, account, furnace_pools, stakingDataMap, addyData);
    let stakingRewardsData = await getAddyEthStakingRewardsData(web3, account, addyData); //will be removed once all users exchange their LP/withdraw

    return [stakingData, stakingRewardsData, furnaceRewardsData, addyData, stakingDataMap];
  }
  else if(networkId === NetworkID.ARBITRUM_NETWORK_ID) {
    var stakingData = [];
    var stakingDataMap = {};

    let addyData = await getAddyDataArbitrum(web3, account);
    if(!addyData) {
      return null;
    }

    if(!combinedArrays[networkId]) {
      concatArrays(arbitrum_sushi_pools, mim_pools)
      concatArrays(arbitrum_sushi_pools, dodo_pools)
      combinedArrays[networkId] = true;
    }

    await getMasterChefDataArbitrum(web3, account, arbitrum_sushi_pools, stakingData, stakingDataMap, addyData, getPriceCache());
    await getCurveDataArbitrum(web3, account, arbitrum_curve_pools, stakingData, stakingDataMap, addyData, getPriceCache());
    await getStakingRewardsVaultDataArbitrum(web3, account, swapr_pools, stakingData, stakingDataMap, addyData, getPriceCache());

    return [stakingData, [], undefined, addyData, stakingDataMap];
  }
  else if(networkId === NetworkID.CRONOS_NETWORK_ID) {
    var stakingData = [];
    var stakingDataMap = {};

    let addyData = await getAddyDataCronos(web3, account);
    if(!addyData) {
      return null;
    }

    if(!combinedArrays[networkId]) {
      concatArrays(cronaswap_pools, vvs_pools)
      concatArrays(cronaswap_pools, chronoswap_pools)
      concatArrays(crodex_pools, elk_pools_cronos)
      combinedArrays[networkId] = true;
    }

    await getMasterChefStakingDataCronos(web3, account, cronaswap_pools, stakingData, stakingDataMap, addyData, getPriceCache());
    await getStakingRewardDataCronos(web3, account, crodex_pools, stakingData, stakingDataMap, addyData, getPriceCache());

    return [stakingData, [], undefined, addyData, stakingDataMap];
  }
  else if(networkId === NetworkID.POLYGON_TESTNET_ID) {
    var stakingData = [];
    var stakingDataMap = {};

    let addyData = await getTestnetAddyData(web3, account);
    if(!addyData) {
      return null;
    }

    if(!combinedArrays[networkId]) {
      combinedArrays[networkId] = true;
    }

    await getStakingRewardDataTestnet(web3, account, testnet_staking_reward_pools, stakingData, stakingDataMap, addyData, getPriceCache());

    return [stakingData, [], undefined, addyData, stakingDataMap];
  }
}
