import React, { Component } from "react";
import { isZero } from '../../../utils'

class StakedBalance extends Component {
    getMyTokensStakedString(balance) {
        if(isZero(balance)) {
            return "0";
        }
        if(balance / 10 ** 18 > 10 ** -7) {
            return (balance / 10 ** 18).toFixed(8);
        }
        return balance + "";
    }

    render() {
        let balance = this.props.amountUserStaked * this.props.ratio;
        let small = balance > 0 && balance / 10 ** 18 < 10 ** -7 ;

        return (
            <div className="pool-row-desc">
                <span className="big">{this.getMyTokensStakedString(balance)}</span>
                {small ?
                    <div>My {this.props.stakeTokenName} Staked (* 10<sup>-18</sup>)</div>:
                    <div>My {this.props.stakeTokenName} Staked</div>
                }
            </div>
        );
    }
}

export default StakedBalance;
