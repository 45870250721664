import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { getBlockExplorerData } from '../constants/multichain/network.js';

const alertSubject = new Subject();
const defaultId = 'default-alert';

export const alertService = {
    onAlert,
    success,
    error,
    info,
    warn,
    alert,
    clear,
    alertLog,
    alertTx
};

export const alertType = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning'
}

// enable subscribing to alerts observable
function onAlert(id = defaultId) {
    return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
}

// convenience methods
function success(message, options) {
    alert({ ...options, type: alertType.success, message });
}

function error(message, options) {
    alert({ ...options, type: alertType.error, message });
}

function info(message, options) {
    alert({ ...options, type: alertType.info, message });
}

function warn(message, options) {
    alert({ ...options, type: alertType.warning, message });
}

// core alert method
function alert(alert) {
    alert.id = alert.id || defaultId;
    alertSubject.next(alert);
}

// clear alerts
function clear(id = defaultId) {
    alertSubject.next({ id });
}

function alertLog(e) {
    console.debug(e);
    if (e.code && e.message) {
        if(e.message.includes("User rejected the transaction")) {
            error("Error " + e.code + ": " + e.message + " Mobile Metamask has issues with this website.", { autoClose: true });
        }
        else {
            error("Error " + e.code + ": " + e.message, { autoClose: true });
        }
    }
    else {
        error("Error: " + e, { autoClose: true });
    }
}

function alertTx(o) {
    console.debug(o);
    let data = getBlockExplorerData();
    let link = data.link + o;
    let explorer = data.name;
    info("<a href=\"" + link + "\" target=\"_blank\" rel=\"noopener noreferrer\">Sent transaction: " + explorer + "</a>", { autoClose: true });
}
