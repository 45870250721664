import NEWEST_MULTICALL_ABI from '../../../abis/multicall/vaults/staking_rewards_vault_multicall.json'

import { calculateAprAndApy, calculateAprAndApyDual } from '../../MathUtil.js';
import { getGainsFarmData } from '../../external_json/GainsFarmData';
import { cacheOnlyIfNumber, getPoolTVL, getPriceCache, getTokenPrice } from '../../PriceCache';
import { getPerformanceFee } from '../../Multichain';
import { cacheCoingeckoPriceByAddress } from '../../external_json/CoingeckoData';

const NEWEST_VAULT_MULTICALL_ADDRESS = "0xdffb2EAdEEEEff2494731674111b7b2Ef8A1Ce59"

const QUICK_ADDRESS = "0x831753DD7087CaC61aB5644b308642cc1c33Dc13"
const DQUICK_ADDRESS = "0xf28164A485B0B2C90639E47b0f377b4a438a16B1"
const ELK_ADDRESS = "0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C"
const WETH = "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";
const WMATIC = "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
const DFYN_WMATIC = "0x4c28f48448720e9000907bc2611f73022fdce1fa"

const MUST = "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f";
const ADDY = "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539";
const PUSD = "0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72".toLowerCase();
const WBTC = "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6".toLowerCase();
const DFYN = "0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97".toLowerCase();
const TEL = "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32".toLowerCase();
const MAI = "0xa3fa99a148fa48d14ed51d610c367c61876997f1";

//Calculates the price of `token0` based on the balances of `token0` and `token1` in a Uniswap LP pool and returns [tvl of pool, tvl of tokens staked in the reward contract]
//`token1` should have been cached already or be a stablecoin, which means that the list of vaults has to be loaded in a certain order
function cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, poolJsonInfo) {
    //don't attempt to cache the price for single asset vaults
    //otherwise the price will decrease each loop due to `totalTokenValue / lpSupply * 1e18`
    if(poolJsonInfo.singleAsset) return [0, 0];

    let lpSupply = vaultInfo.lpSupply;
    let lpStaked = vaultInfo.lpStaked;
    let ratioStaked = lpStaked / lpSupply;

    let totalTokenValue = getPoolTVL(vaultInfo.token0, vaultInfo.token1, vaultInfo.token0Bal, vaultInfo.token1Bal, vaultInfo.decimals0, vaultInfo.decimals1);
    cacheOnlyIfNumber(poolJsonInfo.lpAddress, totalTokenValue / lpSupply * 1e18, 1, true);
    return [totalTokenValue, totalTokenValue * ratioStaked]
}

export const getStakingRewardDataPolygon = async (web3, account, pools, stakingData, stakingDataMap, addyData, priceCache) => {
    let addyPerEth = addyData.addyPerEth;

    const multicallContract = web3.eth.Contract(NEWEST_MULTICALL_ABI.abi, NEWEST_VAULT_MULTICALL_ADDRESS)

    var poolAddresses = [];
    var poolz = [];

    for (let i = 0; i < pools.length; i++) {
        poolAddresses.push(pools[i].vaultAddress);

        //Multicall contract will fail to return data if I try to get too many vaults at once
        if(i > 0 && i % 60 === 0) {
            let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
            //console.log("Loaded " + poolAddresses.length + " vaults")
            poolz = poolz.concat(infoForPools);
            poolAddresses = [];
        }
    }

    if(poolAddresses.length > 0) {
        let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
        //console.log("Loaded " + poolAddresses.length + " vaults")
        poolz = poolz.concat(infoForPools);
    }

    for (let i = 0; i < pools.length; i++) {
        pools[i].vaultAddress = pools[i].vaultAddress.toLowerCase();
        const lpAddress = pools[i].lpAddress.toLowerCase();

        let vaultInfo = poolz[i];
        //console.log(vaultInfo)
        if(vaultInfo == null) {
            return [];
        }

        let amountUserStaked = vaultInfo.amountUserStaked;
        let totalStaked = vaultInfo.totalStaked;
        let lpTokenApproved = vaultInfo.lpTokenApproved;
        let userLpBalance = vaultInfo.userLpBalance;
        let ratio = vaultInfo.ratio;
        let stakingAddress = vaultInfo.stakingRewards;

        let rewardTokenPrice = getTokenPrice(DQUICK_ADDRESS);
        let rewardMultiplier = vaultInfo.rewardMultiplier;
        let pendingReward = vaultInfo.pendingReward * (rewardMultiplier / 1000) * addyData.wmaticEthPrice * addyPerEth;
        let harvestedToken = "QUICK";
        let rewardRate = vaultInfo.rewardData.length > 0 ? vaultInfo.rewardData[0].rewardRate: 0;
        let rewardRate2 = vaultInfo.rewardData.length > 1 ? vaultInfo.rewardData[1].rewardRate: 0;

        //@dev `rewardData.amount` currently isn't being used for anything besides the amount of LP staked for gains.farm vaults, since the GNS pool stakes in the QS pool
        if(vaultInfo.rewardData.length > 0 && vaultInfo.rewardData[0].amount > 0 && pools[i].platform === "gains") {
            vaultInfo.lpStaked = vaultInfo.rewardData[0].amount;
        }

        let poolTvl_tvl = cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, pools[i]);
        const poolTvl = poolTvl_tvl[0]
        const tvl = poolTvl_tvl[1]

        if(pools[i].platform === "quick") { //QS-incentivized Mai pools fall under this category
            if(pools[i].singleAsset) {
                //dQuick/Quick single staking
                tvl = getTokenPrice(pools[i].lpAddress) * vaultInfo.lpStaked / 1e18;
                poolTvl = getTokenPrice(pools[i].lpAddress) * vaultInfo.lpSupply / 1e18;
                rewardTokenPrice = getTokenPrice(vaultInfo.rewardData[0].token)
                if(!rewardTokenPrice) {
                    rewardTokenPrice = cacheCoingeckoPriceByAddress(vaultInfo.rewardData[0].token, getPriceCache())
                }
                rewardRate *= 10 ** (18 - vaultInfo.rewardData[0].decimals)
            }
            else {
                //use default settings
                rewardTokenPrice = getTokenPrice(DQUICK_ADDRESS);
                harvestedToken = "QUICK";
            }
        }
        else if(pools[i].platform === "cometh") {
            rewardTokenPrice = getTokenPrice(MUST);
            harvestedToken = "MUST";
        }
        else if(pools[i].platform === "elk") {
            rewardTokenPrice = getTokenPrice(ELK_ADDRESS);
            harvestedToken = "ELK";
        }
        else if(pools[i].platform === "dfyn") {
            //vaultInfo.rewardData[0].token usually isn't used unless there's multiple rewards
            if(vaultInfo.rewardData[0].token !== "0x0000000000000000000000000000000000000000") {
                rewardTokenPrice = getTokenPrice(vaultInfo.rewardData[0].token)
            }
            else {
                rewardTokenPrice = getTokenPrice(DFYN)
            }
            harvestedToken = "DFYN";
        }
        else if(pools[i].platform === "tel") {
            harvestedToken = "TEL";
            rewardTokenPrice = getTokenPrice(TEL)
            rewardRate *= 1e16; //tel only has 2 decimals
        }
        else if(pools[i].platform === "gains") {
            harvestedToken = "GNS + QUICK";
            let apr = getGainsFarmData();
            //convert APR from GNS tokens to reward/second so it can be handled below
            rewardRate2 = tvl * (apr / 1) / 100 / 365 / 86400 * 1e18 / getTokenPrice(pools[i].rewardToken2);
        }
        else if(pools[i].rewardToken !== undefined) {
            rewardTokenPrice = getTokenPrice(pools[i].rewardToken);
            harvestedToken = pools[i].rewardTokenName;
        }
        else if(!pools[i].deprecated) {
            console.debug("No reward token defined for ", pools[i].token0Name + "/" + pools[i].token1Name)
        }

        vaultInfo.totalPendingReward *= (vaultInfo.rewardMultiplier / 1000);

        let rewardsPerDay = 86400 * rewardRate / 10 ** 18;
        //add 12 hr delay before the notice shows so people stop spamming the chat during downtime
        //check why Date.now() > (vaultInfo.rewardData[0].periodFinish + 43200) * 1000 isn't working
        if(vaultInfo.rewardData.length > 0 && vaultInfo.rewardData[0].periodFinish > 0 && Date.now().toString() > ((vaultInfo.rewardData[0].periodFinish + 43200) * 1000).toString()) {
            rewardsPerDay = 0;
        }

        let boost = vaultInfo.boost != null ? vaultInfo.boost / 1e18: 0;
        let perfFee = pools[i].perfFee !== undefined ? pools[i].perfFee: getPerformanceFee();

        let aprApyData = calculateAprAndApy(rewardTokenPrice, rewardsPerDay, tvl, addyData, perfFee, rewardMultiplier * (1 + boost));
        //todo: handling for pools with 3 rewards, though I've never seen one yet
        if(rewardRate2) {
            let rewardsPerDay2 = 86400 * rewardRate2 / 10 ** 18;
            let rewardToken2;

            if(vaultInfo.rewardData.length > 1) {
                rewardToken2 = vaultInfo.rewardData[1].token.toLowerCase();
            }
            //use 2nd reward token defined in vault json if no 2nd reward token was defined in multicall, make sure the ordering is correct
            if(pools[i].rewardToken2) {
                rewardToken2 = pools[i].rewardToken2.toLowerCase();
            }

            if(rewardToken2 !== "0x0000000000000000000000000000000000000000") {
                aprApyData = calculateAprAndApyDual(rewardTokenPrice, rewardsPerDay, getTokenPrice(rewardToken2), rewardsPerDay2, tvl, addyData, perfFee, rewardMultiplier * (1 + boost));
            }
        }

        const lpPrice = getTokenPrice(lpAddress);

        let vaultData = {
            platform: pools[i].platform,
            exchange: pools[i].exchange,

            //Variables only defined in the json
            nameOverride: pools[i].nameOverride,
            tooltip: formatTooltip(pools[i]),
            highlight: pools[i].highlight,
            forceShow: pools[i].forceShow,
            customExchangeUrl: pools[i].customExchangeUrl,
            deprecatedYieldString: pools[i].deprecatedYieldString,
            harvestBefore: pools[i].harvestBefore,
            showApr: pools[i].showApr,
            addyRewards: pools[i].addyRewards,
            singleAsset: pools[i].singleAsset,
            timestamp: pools[i].timestamp,

            //Variables defined in this function
            index: stakingData.length,
            harvestedToken: harvestedToken,

            token0Name: pools[i].token0Name,
            token1Name: pools[i].token1Name,
            token0: pools[i].token0,
            token1: pools[i].token1,
            lpAddress: pools[i].lpAddress,
            lpPrice: lpPrice,
            stakingAddress: stakingAddress.toLowerCase(),
            vaultAddress: pools[i].vaultAddress,
            strategyAddress: pools[i].strategyAddress,
            deprecated: pools[i].deprecated,
            hidden: pools[i].hidden,
            rewardsPerDay: rewardsPerDay,
            poolTvl: poolTvl, //The TVL of the entire liquidity pool
            tvl: tvl, //The vault's TVL
            boost: boost,
            boostable: vaultInfo.boostable,

            pendingReward: pendingReward, //Amount of ADDY for user to claim
            lastDepositTime: vaultInfo.lastDepositTime,

            //new post-Merlin variables
            rewardAllocation: vaultInfo.rewardAllocation,
            totalPendingReward: vaultInfo.totalPendingReward,
            withdrawPenaltyTime: vaultInfo.withdrawPenaltyTime,
            withdrawPenalty: vaultInfo.withdrawPenalty,
            lastHarvestTime: vaultInfo.lastHarvestTime,

            totalStaked: totalStaked,
            valueTotalStaked: totalStaked * lpPrice,
            amountUserStaked: amountUserStaked,
            valueUserStaked: amountUserStaked * lpPrice,
            lpTokenApproved: lpTokenApproved,
            userLpBalance: userLpBalance,
            rewardMultiplier: rewardMultiplier,

            baseApy: aprApyData.baseApy,
            addyTokenApy: aprApyData.addyTokenApy,
            addyFeeShareApy: aprApyData.addyFeeShareApy,
            totalAddyApy: aprApyData.totalAddyApy,
            apy: aprApyData.totalApy,

            baseApr: aprApyData.baseApr,
            addyTokenApr: aprApyData.addyTokenApr,
            aprProfitToStakers: aprApyData.aprProfitToStakers,
            totalAddyApr: aprApyData.totalAddyApr,
            apr: aprApyData.totalApr,

            ratio: ratio
        }
        stakingDataMap[pools[i].vaultAddress] = vaultData;
        stakingData.push(vaultData)
    }
    return stakingData;
}

function formatTooltip(pool) {
    if(pool.tooltip == null) {
        return null;
    }
    if(pool.platform === "polyquity") {
        return pool.tooltip.replace("{priceOfPusd}", getTokenPrice(PUSD).toFixed(4));
    }
    if(pool.token0Name === "MAI" || pool.token1Name === "MAI") {
        return pool.tooltip.replace("{priceOfMai}", getTokenPrice(MAI).toFixed(4));
    }
    return pool.tooltip;
}
