import React, { VFC } from 'react';
import { Link } from 'react-router-dom';
import adamantLogoDark from './adamant-logo-dark.svg';
import adamantLogoLight from './adamant-logo-light.svg';
import styles from './FooterBrand.module.scss';
import { useUserContext } from '../../../contexts/UserContext';

const FooterBrand: VFC = () => {
  const { isDarkMode } = useUserContext();

  return (
    <div>
      <Link to="/" className={styles.logo}>
        <img
          className={styles.logoImg}
          src={isDarkMode ? adamantLogoDark : adamantLogoLight}
          alt="Adamant"
        />
        <p className={styles.logoText}>Adamant</p>
      </Link>
      <p className={styles.desc}>
        Yield optimizer vault that provides users with an easy and safe way to automatically
        compound their tokens on the Matic/Polygon network and maximize their yields
      </p>
    </div>
  );
};

export default FooterBrand;
