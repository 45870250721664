import { useState } from 'react';
import { useUserContext } from '../../../contexts/UserContext';

export const useStakeTile = (addyData: any) => {
  const { stakeFunctions } = useUserContext();
  const [isWithdrawPending, setIsWithdrawPending] = useState(false);

  const withdraw = () => {
    if (isWithdrawPending) return;
    setIsWithdrawPending(true);
    stakeFunctions
      .withdrawStaked(addyData.unlockedBalance)
      .finally(() => setIsWithdrawPending(false));
  };

  const stakedAmount = addyData.unlockedBalance / 10 ** 18;

  return {
    isWithdrawPending,
    stakedAmount,
    withdraw,
  };
};
