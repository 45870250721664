import React, { VFC } from 'react';
import classnames from 'classnames';
import Link from '../common/Link/Link';
import { NetworkID } from '../../constants/index';
import { currencyFormatter } from '../../_helpers';
import { getAdamantTokenDollarPrice } from '../../api/Multichain';
import styles from './TokenPrice.module.scss';

const quickswapUrl =
  'https://quickswap.exchange/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539';
const sushiUrl =
  'https://app.sushi.com/swap?inputCurrency=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&outputCurrency=0x09ad12552ec45f82bE90B38dFE7b06332A680864';
const cronaswapUrl =
  'https://app.cronaswap.org/swap?inputCurrency=0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23&outputCurrency=0x09ad12552ec45f82bE90B38dFE7b06332A680864';

interface TokenPriceProps {
  inNavbar?: boolean;
}

const TokenPrice: VFC<TokenPriceProps> = ({ inNavbar }) => {
  let exchangeUrl = quickswapUrl;

  if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
    exchangeUrl = sushiUrl;
  } else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
    exchangeUrl = cronaswapUrl;
  }
  return (
    <Link
      href={exchangeUrl}
      className={classnames(styles.tokenPriceWrapper, inNavbar && styles.inNavbar)}
      newTab
    >
      <img src="/icons/AdamantTokenIcon.svg" alt="" />
      <span className={styles.tokenPrice}>
        {currencyFormatter(getAdamantTokenDollarPrice(), {
          maximumSignificantDigits:
            window.networkId === NetworkID.CRONOS_NETWORK_ID ? 5 : undefined,
        })}
      </span>
    </Link>
  );
};

export default TokenPrice;
