import React, { VFC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper';
import { useStakeTile } from './hooks/useStakeTile';
import StakeStatsTile from './StakeStatsTile/StakeStatsTile';
import Button from '../common/Button/Button';
import { getAdamantTokenName } from '../../api/Multichain';
import { usePerformanceFeesTile } from './hooks/usePerformanceFeesTile';
import { getNativeTokenName } from '../../constants/multichain/network';

const breakpoints: {
  [width: number]: SwiperOptions;
  [ratio: string]: SwiperOptions;
} = {
  769: {
    slidesPerView: 2,
    spaceBetween: 28,
    enabled: false,
  },
};

export interface StakeStatsTilesProps {
  addyData: any;
}

const StakeStatsTiles: VFC<StakeStatsTilesProps> = ({ addyData }) => {
  const stakeTile = useStakeTile(addyData);
  const performanceFeesTile = usePerformanceFeesTile(addyData);

  return (
    <div>
      <Swiper
        enabled
        centeredSlides
        centeredSlidesBounds
        slidesPerView={1.4}
        spaceBetween={28}
        breakpoints={breakpoints}
      >
        <SwiperSlide>
          <StakeStatsTile
            title={`Staked ${getAdamantTokenName()}`}
            tokenName={getAdamantTokenName()}
            value={stakeTile.stakedAmount}
            buttonElement={
              <Button
                color="primary"
                onClick={stakeTile.withdraw}
                disabled={stakeTile.stakedAmount === 0 || stakeTile.isWithdrawPending}
              >
                Withdraw
              </Button>
            }
          />
        </SwiperSlide>

        <SwiperSlide>
          <StakeStatsTile
            title="Claimable performance fees"
            value={performanceFeesTile.claimableWmaticAmt}
            tokenName={getNativeTokenName()}
            buttonElement={
              <Button
                color="primary"
                onClick={performanceFeesTile.claim}
                disabled={
                  performanceFeesTile.isClaimPending || performanceFeesTile.claimableWmaticAmt === 0
                }
              >
                Claim All
              </Button>
            }
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default StakeStatsTiles;
