import React, { VFC, Fragment, useState } from 'react';
import { BigNumber } from 'ethers';
import dayjs from 'dayjs';
import Tooltip from '../common/Tooltip/Tooltip';
import { getAdamantTokenName } from '../../api/Multichain';
import getTimeOffset from '../../_helpers/getTimeOfffset';
import styles from './VestingTable.module.scss';

const DEFAULT_ELEMENTS_COUNT = 5;

const formatDateDiff = (date: dayjs.Dayjs) => {
  const daysDiff = date.diff(dayjs(), 'days');

  if (daysDiff < 1) {
    return date.fromNow(true);
  }

  return `${daysDiff} day${daysDiff > 1 ? 's' : ''}`;
};

interface VestingTableProps {
  earningsData?: {
    amount: BigNumber;
    unlockTime: BigNumber;
  }[];
}

const VestingTable: VFC<VestingTableProps> = ({ earningsData }) => {
  const [isViewAllClicked, setIsViewAllClicked] = useState(false);
  const adamantTokenName = getAdamantTokenName();

  if (!earningsData || earningsData?.length === 0) return null;

  const earningsDataToDisplay = earningsData.slice(
    0,
    isViewAllClicked ? undefined : DEFAULT_ELEMENTS_COUNT,
  );

  const handleClickViewAll = () => {
    setIsViewAllClicked(true);
  };

  const timeZone = `${getTimeOffset()} time zone`;

  return (
    <>
      <h3 className={styles.title}>Vesting Schedule</h3>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <colgroup>
            <col className={styles.firstCol} />
            <col className={styles.secondCol} />
            <col className={styles.thirdCol} />
          </colgroup>
          <thead>
            <tr>
              <th>Expires in</th>
              <th className={styles.expirationHeaderCell}>
                <span>Local expiration time</span>
                <Tooltip content={timeZone}>
                  <img src="/icons/InfoIcon.svg" alt="Info icon" />
                </Tooltip>
              </th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {earningsDataToDisplay.map(({ amount, unlockTime }) => {
              const unlockEndDate = dayjs.unix(Number(unlockTime));

              return (
                <Fragment key={Number(unlockTime)}>
                  <tr className={styles.tableRow}>
                    <td>{formatDateDiff(unlockEndDate)}</td>
                    <td>{unlockEndDate.format('D MMMM YYYY [at] hh:mm a')}</td>
                    <td>
                      {(Number(amount) / 1e18).toFixed(4)} {adamantTokenName}
                    </td>
                  </tr>
                  <tr className={styles.spacerRow}>
                    <td />
                    <td />
                    <td />
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {!isViewAllClicked && earningsData.length > DEFAULT_ELEMENTS_COUNT && (
        <div className={styles.viewAllButtonWrapper}>
          <button type="button" className={styles.viewAllButton} onClick={handleClickViewAll}>
            View all
          </button>
        </div>
      )}
    </>
  );
};

export default VestingTable;
