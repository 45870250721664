import { cacheCurvePolygonApy } from '../../external_json/CurvePolygon.js';
import CURVE_MULTICALL_ABI from '../../../abis/multicall/vaults/curve_multicall.json'
import { calculateAprAndApy, calculateAprAndApyDual } from '../../MathUtil.js'
import { getTokenPrice } from '../../PriceCache';
import { getPerformanceFee } from '../../Multichain.js';

let tradingFeeCache = {};

const MULTICALL_ADDRESS = "0xbE19B9e920B9eeC3CadF6582F016ddC992EB2731";

export const getCurveStakingData = async (web3, account, pools, stakingData, stakingDataMap, addyData) => {

    cacheCurvePolygonApy(tradingFeeCache);

    const multicallContract = web3.eth.Contract(CURVE_MULTICALL_ABI.abi, MULTICALL_ADDRESS)

    var poolAddresses = [];
    var poolz = [];
    for (let i = 0; i < pools.length; i++) {
        poolAddresses.push(pools[i].vaultAddress);
    }

    if(poolAddresses.length > 0) {
        let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
        poolz = poolz.concat(infoForPools);
    }

    let addyPerEth = addyData.addyPerEth;

    for (let i = 0; i < pools.length; i++) {
        pools[i].vaultAddress = pools[i].vaultAddress.toLowerCase();
        try {
            let vaultInfo = poolz[i];

            let totalStaked = vaultInfo.totalShares;
            let amountUserStaked = vaultInfo.amountUserStaked;
            let lpTokenApproved = vaultInfo.lpTokenApproved;
            let userLpBalance = vaultInfo.userLpBalance;
            let stakingAddress = pools[i].stakingAddress;
            let pendingReward = vaultInfo.pendingReward * addyData.wmaticEthPrice * addyPerEth * vaultInfo.rewardMultiplier / 1000;
            let ratio = totalStaked > 0 ? vaultInfo.ratio: 0;
            let harvestedToken = "CRV + WMATIC";
            let lpPrice = vaultInfo.curvePoolData.virtualPrice / 1e18;
            let tvl = vaultInfo.lpStaked * lpPrice / 10 ** 18;

            //For some pools, using `virtualPrice` is enough to get the value of the LP token
            //However, most pools will require me to calculate the value of the LP token from the token balances
            if(!pools[i].useVirtualPrice) {
                tvl = 0;
                for(let j = 0; j < vaultInfo.curvePoolData.tokenData.length; j++) {
                    tvl += vaultInfo.curvePoolData.tokenData[j].balance / 10 ** vaultInfo.curvePoolData.tokenData[j].decimals * vaultInfo.curvePoolData.tokenData[j].price / 1e18;
                }
                lpPrice = tvl / vaultInfo.lpSupply * 1e18;
                tvl *= vaultInfo.lpStaked / vaultInfo.lpSupply;
            }

            let rewardData = vaultInfo.curvePoolData.rewardData;
            const boost = vaultInfo.boost != null ? vaultInfo.boost / 1e18: 0;

            let rewardsPerDay = rewardData[0].rewardRate * 86400 / 1e18;

            let aprApyData = calculateAprAndApy(getTokenPrice(rewardData[0].token), rewardData[0].rewardRate * 86400 / 1e18, tvl, addyData, getPerformanceFee(), vaultInfo.rewardMultiplier * (1 + boost));
            if(rewardData.length > 1) {
                aprApyData = calculateAprAndApyDual(
                    getTokenPrice(rewardData[0].token),
                    rewardData[0].rewardRate * 86400 / 1e18,
                    getTokenPrice(rewardData[1].token),
                    rewardData[1].rewardRate * 86400 / 1e18,
                    tvl,
                    addyData,
                    getPerformanceFee(),
                    vaultInfo.rewardMultiplier * (1 + boost)
                );
            }

            let tradingFeeApy = tradingFeeCache[pools[i].stakingAddress.toLowerCase()] ? tradingFeeCache[pools[i].stakingAddress.toLowerCase()] * 100: -1;

            let vaultData = {
                platform: pools[i].platform,
                exchange: pools[i].exchange,
                tooltip: pools[i].tooltip,
                customExchangeUrl: pools[i].customExchangeUrl,

                harvestedToken: harvestedToken,
                harvestTwice: pools[i].harvestTwice,
                harvestBefore: pools[i].harvestBefore,
                addyRewards: pools[i].addyRewards,
                timestamp: pools[i].timestamp,

                singleAsset: pools[i].singleAsset,
                index: stakingData.length,
                token0Name: pools[i].token0Name,
                token1Name: pools[i].token1Name,
                token0: pools[i].token0,
                token1: pools[i].token1,
                lpAddress: pools[i].lpAddress.toLowerCase(),
                lpPrice: lpPrice,
                stakingAddress: stakingAddress.toLowerCase(),
                vaultAddress: pools[i].vaultAddress,
                strategyAddress: pools[i].strategyAddress,
                deprecated: pools[i].deprecated,
                hidden: pools[i].hidden,
                pendingReward: pendingReward,
                lastDepositTime: vaultInfo.lastDepositTime,
                forceShow: pools[i].forceShow,
                nameOverride: pools[i].nameOverride,
                boost: boost,
                boostable: vaultInfo.boostable,
                hiddenForNonStaked: pools[i].hiddenForNonStaked,
                depositDisabled: pools[i].depositDisabled,

                //new post-Merlin variables
                rewardAllocation: vaultInfo.rewardAllocation,
                totalPendingReward: vaultInfo.totalPendingReward,
                withdrawPenaltyTime: vaultInfo.withdrawPenaltyTime,
                withdrawPenalty: vaultInfo.withdrawPenalty,

                rewardsPerDay: rewardsPerDay,

                totalStaked: totalStaked,
                valueTotalStaked: totalStaked * lpPrice,
                amountUserStaked: amountUserStaked,
                valueUserStaked: amountUserStaked * lpPrice,
                lpTokenApproved: lpTokenApproved,
                userLpBalance: userLpBalance,

                baseApy: aprApyData.baseApy,
                addyTokenApy: aprApyData.addyTokenApy,
                addyFeeShareApy: aprApyData.addyFeeShareApy,
                totalAddyApy: aprApyData.totalAddyApy,
                apy: aprApyData.totalApy,

                baseApr: aprApyData.baseApr,
                addyTokenApr: aprApyData.addyTokenApr,
                aprProfitToStakers: aprApyData.aprProfitToStakers,
                totalAddyApr: aprApyData.totalAddyApr,
                apr: aprApyData.totalApr,

                tradingFeeApy: tradingFeeApy,

                ratio: ratio
            }
            stakingDataMap[pools[i].vaultAddress] = vaultData;
            stakingData.push(vaultData)
        }
        catch {
            console.error("Failed to load Curve pool: " + pools[i].nameOverride)
        }
    }
    return stakingData;
}
