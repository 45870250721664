import React, { VFC } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import ProgressCircle from './ProgressCircle/ProgressCircle';
import styles from './VestingTimer.module.scss';
import { getAdamantTokenName } from '../../api/Multichain';
import { formatTokenAmount } from '../../_helpers';
import { RoutingPaths } from '../../paths';
import { getUserVestingGroups } from '../../_helpers/vestingGroups';

export interface VestingTimerProps {
  addyData: any;
}

const getExpireDisplayTime = (timestamp: number) => {
  const now = dayjs();
  const expireTime = dayjs.unix(timestamp);

  const monthsDiff = expireTime.diff(now, 'months');
  if (monthsDiff > 0) {
    const daysDiff = expireTime.diff(now, 'days');
    return `${daysDiff} ${daysDiff > 1 ? 'days' : 'day'}`;
  }

  if (expireTime.diff(now, 'minutes') < 0) {
    return `< 1 min`;
  }

  return expireTime.fromNow(true);
};

const VestingTimer: VFC<VestingTimerProps> = ({ addyData }) => {
  const userVestingGroups = getUserVestingGroups(addyData);

  if (userVestingGroups.length === 0) {
    return (
      <div className={styles.noVestingAmount}>
        <p className={styles.noVestingAmountTitle}>
          You have no {getAdamantTokenName()} in Vesting for now
        </p>
        <p className={styles.noVestingAmountDesc}>
          Invest in <Link to={RoutingPaths.VAULTS}>Vaults </Link>
          and claim your rewards to Vest them
        </p>
      </div>
    );
  }

  const nextUnlockAmount = userVestingGroups[0].unlockAmount;
  const nextUnlockTimestamp = userVestingGroups[0].unlockTimestamp;

  const expireDisplayTime = getExpireDisplayTime(nextUnlockTimestamp);

  const expireLeft = dayjs.unix(nextUnlockTimestamp).diff(dayjs(), 'seconds');
  const expireDuration = dayjs.duration(90, 'days').asSeconds();
  const expireProgress = 1 - expireLeft / expireDuration;

  return (
    <div>
      <ProgressCircle progress={expireProgress}>
        <div className={styles.circleContent}>
          <p className={styles.expireText}>Next vesting expires in</p>
          <p className={styles.expireTime}>{expireDisplayTime}</p>
          <p className={styles.rewardText}>giving you</p>
          <div className={styles.rewardContainer}>
            <p className={styles.rewardValue}>{formatTokenAmount(nextUnlockAmount, 4)}</p>
            <p className={styles.rewardToken}>{getAdamantTokenName()}</p>
          </div>
        </div>
      </ProgressCircle>
    </div>
  );
};

export default VestingTimer;
