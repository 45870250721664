import React from 'react';
import EmptyVaults from './empty-vaults.svg';
import styles from './VaultsEmpty.module.scss';

const VaultsEmpty = () => {
  return (
    <div className={styles.container}>
      <img src={EmptyVaults} alt="" />
      <h3 className={styles.title}>Adamant has no active Vaults at this moment</h3>
    </div>
  );
};

export default VaultsEmpty;
