import React, { useState, useCallback, useEffect, useRef, VFC } from 'react';
import classnames from 'classnames';
import Button from '../common/Button/Button';
import styles from './OptionsDropdown.module.scss';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { useUserContext } from '../../contexts/UserContext';

interface OptionsDropdownProps {
  inNavbar?: boolean;
  buttonClassName: string;
  dropdownClassName: string;
}

const OptionsDropdown: VFC<OptionsDropdownProps> = ({
  inNavbar,
  buttonClassName,
  dropdownClassName,
}) => {
  const { showLpAmounts, setShowLpAmounts, isDarkMode, setIsDarkMode } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const createHandleOptionsButtonClick = (url: string) => () => {
    window.open(url, '_blank', 'noopener noreferrer');
    setIsOpen(false);
  };

  const handleShowLpAmountToggle = (isChecked: boolean) => {
    setShowLpAmounts(isChecked);
  };

  const handleDarkModeToggle = (isLight: boolean) => {
    setIsDarkMode(isLight);
  };

  const handleClickOutsideContainer = useCallback((_target: EventTarget | null) => {
    if (
      containerRef.current &&
      // @ts-ignore
      !containerRef.current.contains(_target)
    ) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => handleClickOutsideContainer(ev.target));

    return document.removeEventListener('mousedown', (ev) =>
      handleClickOutsideContainer(ev.target),
    );
  }, [handleClickOutsideContainer]);

  return (
    <div ref={containerRef}>
      <Button
        className={classnames(buttonClassName, styles.ellipsisButton, inNavbar && styles.inNavbar)}
        onClick={toggleIsOpen}
      >
        <svg
          className={classnames(styles.optionsDropdownButton, isOpen && styles.active)}
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.0013 14.0846C13.5996 14.0846 14.0846 13.5996 14.0846 13.0013C14.0846 12.403 13.5996 11.918 13.0013 11.918C12.403 11.918 11.918 12.403 11.918 13.0013C11.918 13.5996 12.403 14.0846 13.0013 14.0846Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.0013 6.4987C13.5996 6.4987 14.0846 6.01367 14.0846 5.41536C14.0846 4.81706 13.5996 4.33203 13.0013 4.33203C12.403 4.33203 11.918 4.81706 11.918 5.41536C11.918 6.01367 12.403 6.4987 13.0013 6.4987Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.9993 21.6667C13.5977 21.6667 14.0827 21.1816 14.0827 20.5833C14.0827 19.985 13.5977 19.5 12.9993 19.5C12.401 19.5 11.916 19.985 11.916 20.5833C11.916 21.1816 12.401 21.6667 12.9993 21.6667Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>
      {isOpen && (
        <div className={classnames(dropdownClassName, styles.optionsDropdown)}>
          <div className={styles.group}>
            <div className={styles.groupRow}>
              <p className={styles.groupRowLabel}>Dark mode</p>
              <ToggleSwitch checked={isDarkMode} onChange={handleDarkModeToggle} />
            </div>
          </div>

          <div className={styles.group}>
            <p className={styles.groupTitle}>ADVANCED</p>
            <div className={styles.groupRow}>
              <p className={styles.groupRowLabel}>Show LP amounts</p>
              <ToggleSwitch checked={showLpAmounts} onChange={handleShowLpAmountToggle} />
            </div>
          </div>

          <div className={classnames(styles.group, styles.buttonsGroup)}>
            <Button
              onClick={createHandleOptionsButtonClick(
                'https://adamantfinance.gitbook.io/adamant-finance/',
              )}
            >
              Documentation
            </Button>
            <Button onClick={createHandleOptionsButtonClick('https://discord.gg/69ureKKYz2')}>
              Discord
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionsDropdown;
