import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { approve, stakeInPool, stakeMaxInPool, harvest, harvestAndStakeMax } from '../api/actions/VaultFunctions.js'
import { isZero } from '../utils'

const noBorderButton = {
    borderStyle: 'none',
    borderWidth: '0'
};

class StakeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '0',
            checked: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTooltipCheckbox = this.handleTooltipCheckbox.bind(this);
    }
    handleChange(event) {
        this.setState({
            input: event.target.value
        });
    }
    handleTooltipCheckbox(event) {
        this.setState({
            checked: event.target.checked
        });
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const index = this.props.currentPool;
        let vaultAddress = this.props.currentVaultAddress;
        if(vaultAddress) {
            vaultAddress = vaultAddress.toLowerCase();
        }
        //index arg is -1 in FurnacePool, so if vaultAddress is null then don't attempt to check stakingData, since furnace pools aren't added to it
        else if(index === -1) {
            return null;
        }

        const pool = vaultAddress && this.props.stakingDataMap[vaultAddress] ? this.props.stakingDataMap[vaultAddress]: this.props.stakingData[index];

        const userLpBalance = pool.userLpBalance;
        const tokenApproved = pool.lpTokenApproved / 10 ** 18 >= pool.userLpBalance / 10 ** 18;
        let stakeTokenName = pool.singleAsset ? pool.token1Name: (pool.token0Name + '/' + pool.token1Name + ' LP');
        if(pool.stakingTokenName) {
            stakeTokenName = pool.stakingTokenName;
        }
        else if(pool.nameOverride) {
            stakeTokenName = pool.nameOverride;
        }

        const withdrawPenaltyTimeDays = !pool.withdrawPenaltyTime ? 0: pool.withdrawPenaltyTime / 3600 / 24;
        const withdrawPenalty = !pool.withdrawPenalty ? 0: pool.withdrawPenalty / 100;
        let headerTitle = pool.rewardsTokenName ? `Smelt ${stakeTokenName} into ${pool.rewardsTokenName}`: `Stake ${stakeTokenName}`;

        return (
            <Modal className="modal-dialog-centered modal-dialog-scrollable" show={this.props.show} onHide={(event) => {
                    this.setState({ input: '0' });
                    this.props.hideModal();
                }}>
                <Modal.Header className="modal-header">
                    <h4 className="mb-0 pb-0">{headerTitle}</h4>
                    <button className="close" aria-label="Close"
                        onClick={(event) => {
                            this.setState({
                                input: '0'
                            });
                            this.props.hideModal();
                        }}>
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="col-12">
                        <LpBalance userLpBalance={userLpBalance} stakeTokenName={stakeTokenName} />
                        {withdrawPenalty > 0 && <small className="d-block mb-1">{withdrawPenalty}% fee for early withdrawals within {withdrawPenaltyTimeDays} days</small>}
                        {pool.amountUserStaked > 0 && <small className="d-block mb-1">Depositing will reset the early withdrawal timer.</small>}
                        {pool.depositFee && <small className="d-block mb-1">
                            {pool.depositFee}% pool deposit fee
                        </small>}
                        <div className="input-group">
                            <input type="number" min="0" step="0.1" className="form-control noValidate" placeholder="Amount to stake" value={this.props.value} onChange={this.handleChange} />
                        </div>
                        <p className="text-center mt-3 mb-2">
                            {!tokenApproved && <button className="btn btn-success btn-block mb-2" style={noBorderButton}
                                onClick={(event) => {
                                    event.preventDefault();
                                    approve(this.props.account, pool.lpAddress, pool.vaultAddress);
                                }}
                            >Approve Tokens</button>}
                            <button className={"btn btn-block btn-success mb-2" + (tokenApproved && (this.state.checked || pool.amountUserStaked > 0 || pool.tooltip == null) ? "" : " disabled")} style={noBorderButton}
                                onClick={(event) => {
                                    event.preventDefault();
                                    if(pool.harvestBefore) {
                                        harvest(this.props.account, pool.strategyAddress);
                                    }
                                    stakeInPool(this.props.account, pool.vaultAddress, this.state.input);
                                }}
                            >Stake {this.state.input} Tokens</button>
                            <button className={"btn btn-block btn-success" + (tokenApproved && (this.state.checked || pool.amountUserStaked > 0 || pool.tooltip == null) ? "" : " disabled")} style={noBorderButton}
                                onClick={(event) => {
                                    event.preventDefault();
                                    if(pool.harvestBefore) {
                                        //harvest(this.props.account, pool.strategyAddress);
                                        harvestAndStakeMax(this.props.account, pool.vaultAddress, pool.strategyAddress);
                                    }
                                    else {
                                        stakeMaxInPool(this.props.account, pool.vaultAddress);
                                    }
                                }}
                            >Stake All Tokens</button>
                        </p>
                        {pool.beta && <small className="d-block mb-2">
                            This vault is in beta. Invest at your own risk.
                        </small>}
                        {pool.pendingReward > 0 && <small className="d-block mb-0">
                            Your pending ADDY rewards will be automatically claimed and vested into the fee distribution contract.
                        </small>}
                        {pool.tooltip && <small className="d-block mb-0" style={{"whiteSpace": "pre-wrap"}}>
                            {pool.tooltip}
                        </small>}
                        {pool.tooltip && isZero(pool.amountUserStaked) && <small className="d-block mb-0">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="tooltipCheckbox" onChange={this.handleTooltipCheckbox} />
                                <label className="form-check-label no-select" for="tooltipCheckbox">I confirm I have read and understand the text above.</label>
                            </div>
                        </small>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer py-2">
                    <Button className="btn btn-secondary btn-sm m-0" style={noBorderButton} onClick={(event) => {
                        this.setState({ input: '0' });
                        this.props.hideModal();
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

StakeModal.propTypes = {
    show: PropTypes.bool
};

export default StakeModal;


class LpBalance extends Component {
    render() {
        return (
            <p className="mb-0">
                <b>Balance: </b>
                {(this.props.userLpBalance / 10 ** 18).toFixed(8)} {this.props.stakeTokenName}
            </p>
        );
    }
}
