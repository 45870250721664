import React, { FC, useCallback, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import VaultStatsTiles from '../../components/VaultStatsTiles/VaultStatsTiles';
import VaultsList from '../../components/VaultsList/VaultsList';
import MyVaultsEmpty from '../../components/MyVaultsEmpty/MyVaultsEmpty';
import Tabs from '../../components/common/Tabs/Tabs';
import VaultsFilters from '../../components/VaultsFilters/VaultsFilters';
import { SelectOption } from '../../components/common/Select/Select';
import VaultsFiltersNoResults from '../../components/VaultsFiltersNoResults/VaultsFiltersNoResults';
import VaultsEmpty from '../../components/VaultsEmpty/VaultsEmpty';
import { useUserContext } from '../../contexts/UserContext';
import { SortValuesEnum, useVaultsFiltersContext } from '../../contexts/VaultsFiltersContext';
import styles from './VaultsPage.module.scss';
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton';

const getTokenFilterOptions = (vaultsList: any): SelectOption[] => {
  const vaultsMap: any = {};
  vaultsList.forEach((vault: any) => {
    vaultsMap[vault.token0Name] = '';
    vaultsMap[vault.token1Name] = '';
  });
  return Object.keys(vaultsMap)
    .sort((a, b) => a.localeCompare(b))
    .map((vault) => ({
      name: vault,
      value: vault,
    }));
};

const getPlatformFilterOptions = (vaultsList: any): SelectOption[] => {
  const vaultsMap: any = {};
  vaultsList.forEach((vault: any) => {
    vaultsMap[vault.platform] = '';
  });
  return Object.keys(vaultsMap)
    .sort((a, b) => a.localeCompare(b))
    .map((vault) => ({
      name: `${vault.charAt(0).toUpperCase()}${vault.slice(1)}`,
      value: vault,
    }));
};

export interface VaultsPageProps {
  vaultsLoaded: boolean;
  vaults: any;
  vaultsToDisplay: any[];
  myVaults: any[];
  addyData: any;
  isCompounding: boolean;
  onCompoundAll: () => void;
  hasInactiveVaults: boolean;
}

const VaultsPage: FC<VaultsPageProps> = ({
  vaults,
  vaultsToDisplay,
  myVaults,
  vaultsLoaded,
  addyData,
  isCompounding,
  onCompoundAll,
  hasInactiveVaults,
}) => {
  const [currentVaultsTab, setCurrentVaultsTab] = useState(0);
  const { isWalletConnected } = useUserContext();
  const { isFiltered, onSortClick } = useVaultsFiltersContext();

  const handleTabChange = useCallback(
    (tabIdx: number) => {
      setCurrentVaultsTab(tabIdx);
      onSortClick(tabIdx === 1 ? SortValuesEnum.MY_INVESTMENTS : SortValuesEnum.TVL);
    },
    [onSortClick],
  );

  const handleGoToVaults = useCallback(() => {
    handleTabChange(0);
  }, [handleTabChange]);

  const tokenFilterOptions = useMemo(
    () => getTokenFilterOptions(vaultsToDisplay),
    [vaultsToDisplay],
  );

  const platformFilterOptions = useMemo(
    () => getPlatformFilterOptions(vaultsToDisplay),
    [vaultsToDisplay],
  );

  return (
    <>
      <ScrollToTopButton />

      <VaultStatsTiles addyData={addyData} vaults={vaults} />

      <div className={styles.vaultsList}>
        <AnimatePresence exitBeforeEnter>
          {vaultsLoaded && (
            <motion.div key={0} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Tabs
                currentTab={currentVaultsTab}
                tabs={[
                  'All',
                  `My Vaults${
                    isWalletConnected && myVaults.length > 0 ? ` (${myVaults.length})` : ''
                  }`,
                ]}
                onTabChange={handleTabChange}
                className={styles.vaultsTabs}
              />

              {currentVaultsTab === 1 &&
                myVaults.length > 0 &&
                (isCompounding ? (
                  <p className={styles.compoundVaultsLoading}>Loading…</p>
                ) : (
                  <p className={styles.compoundVaults}>
                    All the Vaults are auto-compounding but you can{' '}
                    <button
                      type="button"
                      className={styles.compoundVaultsButton}
                      onClick={onCompoundAll}
                    >
                      Compound all your Vaults
                    </button>{' '}
                    now
                  </p>
                ))}

              <VaultsFilters
                className={styles.vaultsFilters}
                tokenFilterOptions={tokenFilterOptions}
                platformFilterOptions={platformFilterOptions}
                myVaults={currentVaultsTab === 1}
                hasInactiveVaults={hasInactiveVaults}
              />
              {currentVaultsTab === 0 && vaultsToDisplay.length > 0 && (
                <VaultsList vaults={vaultsToDisplay} />
              )}
              {currentVaultsTab === 0 &&
                vaultsToDisplay.length === 0 &&
                (isFiltered ? <VaultsFiltersNoResults /> : <VaultsEmpty />)}
              {currentVaultsTab === 1 && (myVaults.length === 0 || !isWalletConnected) && (
                <MyVaultsEmpty onGoToVaults={handleGoToVaults} />
              )}
              {currentVaultsTab === 1 && myVaults.length > 0 && <VaultsList vaults={myVaults} />}
            </motion.div>
          )}

          {!vaultsLoaded && (
            <motion.div key={1} initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <div className={styles.loaderContainer}>Loading Vaults...</div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default VaultsPage;
