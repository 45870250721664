import React, { useCallback, FC, VFC } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { RoutingPaths } from '../../paths';
import { ReactComponent as BoostUnderline } from './boost-underline.svg';
import styles from './NavigationLinks.module.scss';

interface NavigationLinksProps {
  inDropdown?: boolean;
  clickSideEffect: () => void;
}

const NavigationLinks: VFC<NavigationLinksProps> = ({ inDropdown, clickSideEffect }) => {
  const { push } = useHistory();

  const createOnNavLinkClick = useCallback(
    (path: string) => () => {
      clickSideEffect();
      push(path);
    },
    [clickSideEffect, push],
  );

  interface NavListItemProps {
    name?: string;
    to: string;
    className?: string;
  }

  const NavListItem: FC<NavListItemProps> = useCallback(
    ({ name, to, className, children }) => {
      return (
        <li>
          <NavLink
            className={classNames(styles.navLink, className)}
            activeClassName={styles.activeNavLink}
            to={to}
            onClick={createOnNavLinkClick(to)}
          >
            {name || children}
          </NavLink>
        </li>
      );
    },
    [createOnNavLinkClick],
  );

  return (
    <ul className={classNames(styles.linksList, inDropdown && styles.inDropdown)}>
      <NavListItem to={RoutingPaths.VAULTS} name="Vaults" />
      <NavListItem to={RoutingPaths.VESTING} name="Vesting" />
      <NavListItem to={RoutingPaths.STAKE} name="Stake" />
      <NavListItem
        to={RoutingPaths.BOOST}
        className={classNames(styles.boost, inDropdown && styles.inDropdown)}
      >
        Boost
        <BoostUnderline className={styles.underline} />
      </NavListItem>
    </ul>
  );
};

export default NavigationLinks;
