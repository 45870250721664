export const useCurrentVestingTile = (addyData: any) => {
  const unlockedBalance = addyData.unlockedBalance / 10 ** 18;
  const vestingAmount = addyData.totalWithdrawable - unlockedBalance;
  const penaltyAmount = addyData.penaltyAmount / 10 ** 18;

  return {
    unlockedBalance,
    vestingAmount,
    penaltyAmount,
  };
};
