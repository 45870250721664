import FEE_DIST_ABI from '../../abis/fee_dist_abi.json'
import ERC20ABI from '../../abis/ERC20abi.json'
import { alertService } from '../../_services';
import { getDefaultGasPrice } from './GasHelper.js';
import { getAdamantTokenAddress, getFeeDistAddress } from '../Multichain';
import { deposit } from './LockedVaultFunctions.js';

export const stakeAddy = (account, amount, myBalance, locked) => {
    if (amount === null || isNaN(amount)) return;

    const feeDistContract = window.web3.eth.Contract(FEE_DIST_ABI.abi, getFeeDistAddress(), { gasPrice: getDefaultGasPrice() });

    var p = parseFloat(amount);
    console.debug('Deposit amount: ' + p);
    if (p >= 0.001) {
        let amount = window.web3.utils.toWei(p.toString(), "ether");
        if (myBalance - amount < 0) {
            alertService.error("Trying to stake more than current balance: " + p);
        }
        else {
            feeDistContract.methods.stake(amount, locked).send({
                from: account
            }, (e, output) => {
                if (e) {
                    alertService.alertLog(e);
                } else {
                    alertService.alertTx(output);
                }
            });
        }
    }
    else {
        alertService.error("Amount too small, use the stake max button to avoid rounding errors: " + p);
    }
}

//Amount is in wei
export const stakeAddyMax = (account, amount, locked) => {
    const feeDistContract = window.web3.eth.Contract(FEE_DIST_ABI.abi, getFeeDistAddress(), { gasPrice: getDefaultGasPrice() })

    feeDistContract.methods.stake(amount, locked).send({ from: account }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

let blocked = false;

export const withdrawFeeDist = (account, amount) => {
    amount = amount.toString()
    const feeDistContract = window.web3.eth.Contract(FEE_DIST_ABI.abi, getFeeDistAddress(), { gasPrice: getDefaultGasPrice() })

    if(blocked) {
        console.debug("blocked");
        return;
    }

    blocked = true;
    feeDistContract.methods.withdraw(amount).send({ from: account }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
            blocked = false;
        } else {
            alertService.alertTx(output);
            blocked = false;
        }
    });
}

export const exitFeeDist = (account) => {
    const feeDistContract = window.web3.eth.Contract(FEE_DIST_ABI.abi, getFeeDistAddress(), { gasPrice: getDefaultGasPrice() })

    feeDistContract.methods.exit().send({ from: account }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const claimFeeDistRewards = (account) => {
    const feeDistContract = window.web3.eth.Contract(FEE_DIST_ABI.abi, getFeeDistAddress(), { gasPrice: getDefaultGasPrice() })

    feeDistContract.methods.getReward().send({ from: account }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const withdrawExpiredLocks = (account) => {
    const feeDistContract = window.web3.eth.Contract(FEE_DIST_ABI.abi, getFeeDistAddress(), {gas: '10000000',  gasPrice: getDefaultGasPrice() })

    feeDistContract.methods.getReward().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            feeDistContract.methods.withdrawExpiredLocks().send({
                from: account
            }, (e2, output2) => {
                if (e2) {
                    alertService.alertLog(e2);
                } else {
                    alertService.alertTx(output2);
                }
            });
        }
    });
}

export const migrateExpiredLocks = (account, vaultContractAddress, amount) => {
    const feeDistContract = window.web3.eth.Contract(FEE_DIST_ABI.abi, getFeeDistAddress(), {gas: '10000000',  gasPrice: getDefaultGasPrice() })

    const MIN_LOCK_TIME = 91;
    const MIN_LOCK_TIME_SECONDS = MIN_LOCK_TIME * 86400;

    feeDistContract.methods.getReward().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            feeDistContract.methods.withdrawExpiredLocks().send({
                from: account
            }, (e2, output2) => {
                if (e2) {
                    alertService.alertLog(e2);
                } else {
                    deposit(account, vaultContractAddress, amount, MIN_LOCK_TIME_SECONDS);
                }
            });
        }
    });
}

export const approveAddyForFeeDist = (account) => {
    const erc20 = window.web3.eth.Contract(ERC20ABI.abi, getAdamantTokenAddress(), { gasPrice: getDefaultGasPrice() });

    erc20.methods.approve(getFeeDistAddress(), "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}
