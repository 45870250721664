import React, { VFC } from 'react';
import { useCopyToClipboard } from 'react-use';
import Identicon from 'react-blockies';
import copyAddressIcon from './copy-address-icon.svg';

import styles from './AddressTile.module.scss';
import { showToast } from '../../../../../_services';

interface Props {
  name: string;
  address?: string;
  platform?: string;
  truncate?: boolean;
}

const truncateAddress = (address: string) => {
  return `${address.substring(0, 10)}…${address.substring(address.length - 10)}`;
};

const AddressTile: VFC<Props> = ({ name, address, platform, truncate }) => {
  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  const handleCopyAddress = () => {
    if (address) {
      copyToClipboard(address);
      showToast.info({
        title: 'Copied',
        body: 'Address copied to clipboard',
        autoClose: 1500,
      });
    }
  };

  return (
    <div className={styles.container}>
      {platform ? (
        <img className={styles.platformIcon} src={`/img/${platform?.toUpperCase()}.png`} alt="" />
      ) : (
        address && (
          <Identicon
            seed={address.toLowerCase()}
            className={styles.icon}
            size={8}
            scale={8}
            bgColor="#FFF"
          />
        )
      )}

      <p className={styles.name}>{name}</p>

      <button
        type="button"
        className={styles.address}
        title="Copy to clipboard"
        onClick={handleCopyAddress}
      >
        {address ? (
          <>
            {truncate ? truncateAddress(address) : address}
            <img src={copyAddressIcon} alt="Copy to clipboard" />
          </>
        ) : (
          'Unknown'
        )}
      </button>
    </div>
  );
};

export default AddressTile;
