import React, { VFC } from 'react';
import { ExternalLinks } from '../../../constants';
import styles from './FooterSocials.module.scss';

const FooterSocials: VFC = () => (
  <div className={styles.list}>
    <a className={styles.item} href={ExternalLinks.discord} target="_blank" rel="noreferrer">
      <svg
        className={styles.icon}
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_863_10127)">
          <path
            d="M23.6635 10.9895C22.6685 7.57061 21.5463 5.8041 21.5282 5.77241C21.4642 5.69434 19.8785 3.79668 16.0565 2.39307L15.5515 3.76807C17.3697 4.43584 18.6597 5.25049 19.3691 5.77686C17.2511 5.14439 14.7315 4.75703 12.5 4.75703C10.2685 4.75703 7.74888 5.14439 5.63091 5.77686C6.34028 5.25054 7.63022 4.43584 9.44844 3.76807L8.94341 2.39307C5.12158 3.79668 3.53584 5.69434 3.47187 5.77241C3.45376 5.8041 2.33154 7.57061 1.33652 10.9895C0.377539 14.2842 0.0132324 18.9321 0 19.1185C0.0854492 19.2521 2.05078 22.6075 7.40688 22.6075L8.7645 20.6416C7.24351 20.2377 5.79316 19.5865 4.46553 18.7027L5.2772 17.4834C7.41904 18.909 9.91665 19.6626 12.5 19.6626C15.0833 19.6626 17.581 18.909 19.7228 17.4834L20.5345 18.7027C19.2069 19.5865 17.7565 20.2377 16.2355 20.6416L17.5931 22.6075C22.9492 22.6075 24.9146 19.2521 25 19.1185C24.9868 18.9321 24.6225 14.2842 23.6635 10.9895ZM9.11338 14.8216H7.64854V12.3802H9.11338V14.8216ZM17.3515 14.8216H15.8866V12.3802H17.3515V14.8216Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_863_10127">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>

    <a className={styles.item} href={ExternalLinks.telegram} target="_blank" rel="noreferrer">
      <svg
        className={styles.icon}
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_863_10118)">
          <path
            d="M9.8092 15.8136L9.39565 21.6303C9.98732 21.6303 10.2436 21.3761 10.5509 21.0709L13.3248 18.4199L19.0727 22.6293C20.1269 23.2168 20.8696 22.9074 21.154 21.6595L24.9269 3.98031L24.9279 3.97926C25.2623 2.42093 24.3644 1.81156 23.3373 2.19385L1.16024 10.6845C-0.353306 11.272 -0.330389 12.1157 0.902944 12.498L6.57274 14.2616L19.7425 6.02093C20.3623 5.61051 20.9259 5.8376 20.4623 6.24801L9.8092 15.8136Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_863_10118">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>

    <a className={styles.item} href={ExternalLinks.twitter} target="_blank" rel="noreferrer">
      <svg
        className={styles.icon}
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_863_10116)">
          <path
            d="M22.4312 7.40937C23.4479 6.6875 24.3031 5.78646 25 4.74896V4.74792C24.0698 5.15521 23.0802 5.42604 22.0469 5.55729C23.1094 4.92292 23.9208 3.92604 24.3021 2.725C23.3115 3.31562 22.2177 3.73229 21.0521 3.96562C20.1115 2.96354 18.7708 2.34375 17.3083 2.34375C14.4708 2.34375 12.1865 4.64687 12.1865 7.46979C12.1865 7.87604 12.2208 8.26667 12.3052 8.63854C8.04479 8.43021 4.27396 6.38854 1.74062 3.27708C1.29896 4.04479 1.03854 4.92292 1.03854 5.86771C1.03854 7.64271 1.95208 9.21562 3.31562 10.1271C2.49167 10.1115 1.68437 9.87187 1 9.49583V9.55208C1 12.0427 2.77604 14.1115 5.10625 14.5885C4.68854 14.7031 4.23438 14.7573 3.7625 14.7573C3.43437 14.7573 3.10312 14.7385 2.79271 14.6698C3.45625 16.699 5.34167 18.1917 7.58125 18.2406C5.8375 19.6042 3.62292 20.426 1.22604 20.426C0.80625 20.426 0.403125 20.4073 0 20.3562C2.27083 21.8198 4.96042 22.6562 7.8625 22.6562C16.9073 22.6562 22.8021 15.1104 22.4312 7.40937Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_863_10116">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>

    <a className={styles.item} href={ExternalLinks.medium} target="_blank" rel="noreferrer">
      <svg
        className={styles.icon}
        width="26"
        height="13"
        viewBox="0 0 26 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6.25" cy="6.25" r="6.25" fill="white" />
        <ellipse cx="17.7873" cy="6.25" rx="3.36538" ry="6.25" fill="white" />
        <ellipse cx="24.0397" cy="6.25" rx="0.961538" ry="6.25" fill="white" />
      </svg>
    </a>
  </div>
);

export default FooterSocials;
