import { ApolloClient, gql, InMemoryCache, useQuery } from '@apollo/client';

const quickSwapClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap06',
  cache: new InMemoryCache(),
});

const sushiSwapClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/sushiswap/matic-exchange',
  cache: new InMemoryCache(),
});

const useQuickSwapQuery = (vault: any) => {
  return useQuery(
    gql`
      query pair($id: String!) {
        pairDayDatas(where: { pairAddress: $id }, first: 2, orderBy: date, orderDirection: desc) {
          dailyVolumeUSD
          date
        }
      }
    `,
    {
      client: quickSwapClient,
      variables: { id: vault.lpAddress.trim().toLowerCase() },
      fetchPolicy: 'no-cache',
    },
  );
};

const useQuickSwapTotalVolumeQuery = (vault: any) => {
  return useQuery(
    gql`
      query uniswapDayDatas {
        uniswapDayDatas(first: 2, orderBy: date, orderDirection: desc) {
          dailyVolumeUSD
          date
        }
      }
    `,
    {
      client: quickSwapClient,
      variables: {},
      fetchPolicy: 'no-cache',
    },
  );
};

const useSushiSwapQuery = (vault: any) => {
  return useQuery(
    gql`
      query pair($id: String!) {
        pair(id: $id) {
          hourData(first: 24, orderBy: date, orderDirection: desc) {
            volumeUSD
          }
        }
      }
    `,
    {
      client: sushiSwapClient,
      variables: { id: vault.lpAddress.trim().toLowerCase() },
      fetchPolicy: 'no-cache',
    },
  );
};

export const useDailyVolume = (vault: any) => {
  const quickSwapQuery = useQuickSwapQuery(vault);
  const sushiSwapQuery = useSushiSwapQuery(vault);

  let isLoading = false;
  let lpApyFee: number | null = null;

  if (vault.exchange === 'quickswap') {
    if (vault.singleAsset) {
      const totalVolumeQuery = useQuickSwapTotalVolumeQuery(vault);
      isLoading = totalVolumeQuery.loading;
      let total;
      try {
        const todayVolume = parseFloat(totalVolumeQuery.data.uniswapDayDatas[0].dailyVolumeUSD);
        const yesterdayVolume = parseFloat(totalVolumeQuery.data.uniswapDayDatas[1].dailyVolumeUSD);
        const timePassed =
          Date.now() / 1000 - parseInt(totalVolumeQuery.data.uniswapDayDatas[0].date);
        if (timePassed < 0) {
          total = parseFloat(totalVolumeQuery.data.uniswapDayDatas[0].dailyVolumeUSD);
        } else {
          const yesterdayWeight = (86400 - timePassed) / 86400;
          // (24 hrs - time passed today) / 24 hrs
          total = yesterdayWeight * yesterdayVolume + todayVolume;
        }

        const lpFees = total * 0.0005; // 0.3% * 1/6
        if (vault.poolTvl > 0) {
          const apr = (lpFees * 365) / vault.poolTvl;
          lpApyFee = 100 * ((1 + apr / 3650) ** 3650 - 1);
        }
      } catch (e) {}
    } else if (quickSwapQuery.data) {
      isLoading = quickSwapQuery.loading;

      const tvl = vault.poolTvl ? vault.poolTvl : vault.tvl;

      try {
        let total;
        const todayVolume = parseFloat(quickSwapQuery.data.pairDayDatas[0].dailyVolumeUSD);
        const yesterdayVolume = parseFloat(quickSwapQuery.data.pairDayDatas[1].dailyVolumeUSD);
        const timePassed =
          Date.now() / 1000 - parseInt(quickSwapQuery.data.pairDayDatas[0].date, 10);
        if (timePassed < 0) {
          total = parseFloat(quickSwapQuery.data.pairDayDatas[0].dailyVolumeUSD);
        } else {
          const yesterdayWeight = (86400 - timePassed) / 86400;
          total = yesterdayWeight * yesterdayVolume + todayVolume;
        }

        const lpFees = total * 0.003; // 0.3%
        if (tvl > 0) {
          const apr = (lpFees * 365) / tvl;
          lpApyFee = 100 * ((1 + apr / 3650) ** 3650 - 1);
        }
      } catch (e) {}
    }
  } else if (vault.exchange === 'sushi') {
    isLoading = sushiSwapQuery.loading;

    if (sushiSwapQuery.data) {
      const total = sushiSwapQuery.data.pair?.hourData?.reduce((acc: number, curr: any) => {
        return curr.volumeUSD ? acc + parseFloat(curr.volumeUSD) : acc;
      }, 0);

      const lpFees = total * 0.003; // 0.3%
      if (vault.tvl > 0) {
        const apr = (lpFees * 365) / vault.tvl;
        lpApyFee = 100 * ((1 + apr / 3650) ** 3650 - 1);
      }
    }
  } else {
    // handle other exchanges
  }

  const isSuccess = !isLoading && lpApyFee !== null;

  return {
    isLoading,
    isSuccess,
    lpApyFee,
  };
};
