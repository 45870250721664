import { useState } from 'react';
import { useUserContext } from '../../../contexts/UserContext';

export const useClaimableRewardsTile = (addyData: any) => {
  const { stakeFunctions } = useUserContext();
  const [isClaimPending, setIsClaimPending] = useState(false);

  const claimableWmatic = addyData.claimableAddyAmt / 10 ** 18;

  const claim = () => {
    if (isClaimPending) return;
    setIsClaimPending(true);
    stakeFunctions.claimRewards().finally(() => setIsClaimPending(false));
  };

  return {
    isClaimPending,
    claimableWmatic,
    claim,
  };
};
