export const ExternalLinks = {
  discord: 'https://discord.gg/69ureKKYz2',
  telegram: 'https://t.me/adamantfinance',
  twitter: 'https://twitter.com/AdamantVault',
  medium: 'https://adamantfinance.medium.com',
  sourceCode: 'https://github.com/eepdev/adamantfinance',
  audit: 'https://adamantfinance.medium.com/certik-audit-complete-28f59eef1a23',
  docs: 'https://adamantfinance.gitbook.io/adamant-finance',
  faq: 'https://adamantfinance.gitbook.io/adamant-finance/faq',
  governance: 'https://snapshot.org/#/adamant.eth',
  vote: 'https://snapshot.org/#/adamant.eth',
  legacy: 'https://addy.finance',
};
