import React, { useEffect, useState, VFC } from 'react';
import classNames from 'classnames';
import styles from './ToggleSwitch.module.scss';

export interface ToggleSwitchProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  size?: 'small' | 'large';
  uncheckedText?: string;
  checkedText?: string;
}

const sizeStylesMap = {
  small: styles.small,
  large: styles.large,
};

const ToggleSwitch: VFC<ToggleSwitchProps> = ({
  checked,
  onChange,
  size = 'small',
  uncheckedText,
  checkedText,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    onChange?.(newState);
  };

  return (
    <button
      type="button"
      role="checkbox"
      aria-checked={isChecked}
      onClick={handleChange}
      className={classNames(styles.container, isChecked && styles.checked, sizeStylesMap[size])}
    >
      <div className={styles.thumbContainer}>
        <div className={styles.thumbTrack}>
          <div className={styles.thumb} />
        </div>
      </div>

      <div className={styles.textContainer}>
        <span className={classNames(styles.text, styles.apyText)}>{checkedText}</span>
        <span className={classNames(styles.text, styles.aprText)}>{uncheckedText}</span>
      </div>
    </button>
  );
};

export default ToggleSwitch;
