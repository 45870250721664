import stakingRewardsABI from '../../abis/IStakingRewards.json'
import ERC20ABI from '../../abis/ERC20abi.json'
import { alertService } from '../../_services';
import { getDefaultGasPrice } from './GasHelper.js';

const ADDY_ETH_LP = "0xa5BF14BB945297447fE96f6cD1b31b40d31175CB" //todo replace with multichain function

export const getReward = (account, rewardsContractAddress) => {
    const rewardsContract = window.web3.eth.Contract(stakingRewardsABI.abi, rewardsContractAddress, { gasPrice: getDefaultGasPrice()})

    rewardsContract.methods.getReward().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const approve = (account, rewardsContractAddress, lpAddress = ADDY_ETH_LP) => {
    const erc20 = window.web3.eth.Contract(ERC20ABI.abi, lpAddress, { gasPrice: getDefaultGasPrice()});

    erc20.methods.approve(rewardsContractAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const withdraw = (account, rewardsContractAddress, amount) => {
    const rewardsContract = window.web3.eth.Contract(stakingRewardsABI.abi, rewardsContractAddress, { gasPrice: getDefaultGasPrice()})

    rewardsContract.methods.withdraw(amount).send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const exit = (account, rewardsContractAddress) => {
    const rewardsContract = window.web3.eth.Contract(stakingRewardsABI.abi, rewardsContractAddress, { gasPrice: getDefaultGasPrice()})

    rewardsContract.methods.exit().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const stake = (account, rewardsContractAddress, amount, wei = false) => {
    const rewardsContract = window.web3.eth.Contract(stakingRewardsABI.abi, rewardsContractAddress, { gasPrice: getDefaultGasPrice()})

    if(wei) {
        rewardsContract.methods.stake(amount).send({
            from: account
        }, (e, output) => {
            if (e) {
                alertService.alertLog(e);
            } else {
                alertService.alertTx(output);
            }
        });
    }
    else {
        var p = parseFloat(amount);
        console.debug('Deposit amount: ' + p);
        if (p >= 0.001) {
            rewardsContract.methods.stake(window.web3.utils.toWei(p.toString(), "ether")).send({
                from: account
            }, (e, output) => {
                if (e) {
                    alertService.alertLog(e);
                } else {
                    alertService.alertTx(output);
                }
            });
        }
    }
}


export const stakeLocked = (account, rewardsContractAddress, amount, secs, wei = false) => {
    const rewardsContract = window.web3.eth.Contract(stakingRewardsABI.abi, rewardsContractAddress, { gasPrice: getDefaultGasPrice()})

    if(wei) {
        rewardsContract.methods.stakeLocked(amount, secs).send({
            from: account
        }, (e, output) => {
            if (e) {
                alertService.alertLog(e);
            } else {
                alertService.alertTx(output);
            }
        });
    }
    else {
        var p = parseFloat(amount);
        console.debug('Deposit amount: ' + p);
        if (p >= 0.001) {
            rewardsContract.methods.stakeLocked(window.web3.utils.toWei(p.toString(), "ether"), secs).send({
                from: account
            }, (e, output) => {
                if (e) {
                    alertService.alertLog(e);
                } else {
                    alertService.alertTx(output);
                }
            });
        }
    }
}

export const withdrawLocked = (account, rewardsContractAddress, kek_id) => {
    const rewardsContract = window.web3.eth.Contract(stakingRewardsABI.abi, rewardsContractAddress, { gasPrice: getDefaultGasPrice()})

    rewardsContract.methods.withdrawLocked(kek_id).send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const migrateLockedStake = (account, rewardsContractAddress, kek_id) => {
    const rewardsContract = window.web3.eth.Contract(stakingRewardsABI.abi, rewardsContractAddress, { gasPrice: getDefaultGasPrice()})

    rewardsContract.methods.migrateLockedStake(kek_id).send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}
