import Web3 from 'web3';
import stakingABI from '../../abis/staking_abi.json';
import strategyABI from '../../abis/strategy_abi.json';
import ERC20ABI from '../../abis/ERC20abi.json';
import { getDefaultGasPrice } from './GasHelper';

export const claimAndVest = async (web3: Web3, account: string, vaultAddress: string) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(stakingABI.abi as any, vaultAddress, {
      gasPrice: getDefaultGasPrice(),
    } as any);

    vaultContract.methods
      .claim()
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const withdrawAll = async (web3: Web3, account: string, vaultAddress: string) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(stakingABI.abi as any, vaultAddress, {
      gasPrice: getDefaultGasPrice(),
    } as any);

    vaultContract.methods
      .withdrawAll()
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const stakeInVault = async (
  web3: Web3,
  account: string,
  vaultAddress: string,
  amount: number,
) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(stakingABI.abi as any[], vaultAddress);
    const p = parseFloat(`${amount}`).toFixed(18);

    vaultContract.methods
      .deposit(web3.utils.toWei(p, 'ether'))
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const stakeAllInVault = (web3: Web3, account: string, vaultAddress: string) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = window.web3.eth.Contract(stakingABI.abi as any[], vaultAddress, {
      gasPrice: getDefaultGasPrice(),
    });

    vaultContract.methods
      .depositAll()
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const compoundVault = async (
  web3: Web3,
  account: string,
  strategyContractAddress: string,
) => {
  return new Promise<void>((resolve, reject) => {
    const strategyContract = new web3.eth.Contract(
      strategyABI.abi as any,
      strategyContractAddress,
      { gasPrice: getDefaultGasPrice() } as any,
    );

    strategyContract.methods
      .harvest()
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const approve = async (
  web3: Web3,
  account: string,
  lpAddress: string,
  vaultAddress: string,
) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(ERC20ABI.abi as any[], lpAddress);

    vaultContract.methods
      .approve(
        vaultAddress,
        '115792089237316195423570985008687907853269984665640564039457584007913129639935',
      )
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};
