import React, { VFC } from 'react';
import Link from '../common/Link/Link';
import LoadingImageDark from './LoadingImage-dark.svg';
import LoadingImageLight from './LoadingImage-light.svg';
import styles from './Loading.module.scss';
import { useUserContext } from '../../contexts/UserContext';

const Loading: VFC = () => {
  const { isDarkMode } = useUserContext();

  return (
    <div className={styles.container}>
      <img
        src={isDarkMode ? LoadingImageDark : LoadingImageLight}
        alt=""
        className={styles.image}
      />
      <h2 className={styles.title}>Loading…</h2>
      <p className={styles.text}>
        If this page fails to load please change the RPC settings in your wallet. Look through the
        list of{' '}
        <Link
          href="https://docs.polygon.technology/docs/develop/network-details/network/"
          color="primary"
          newTab
        >
          alternative RPC URLs
        </Link>{' '}
        for the Polygon network.
      </p>
      <div className={styles.textBox}>
        <div className={styles.textBoxHeader}>
          <img
            src={isDarkMode ? '/icons/LockIcon-dark.svg' : '/icons/LockIcon-light.svg'}
            className={styles.textBoxIcon}
            alt=""
          />
          <h3 className={styles.textBoxTitle}>Be Careful!</h3>
        </div>
        <p className={styles.textBoxContent}>
          If anyone messages you on Discord or Telegram and tells you to <b>synchronize</b>,{' '}
          <b>verify</b>, etc. your wallet - they are trying to scam you. Do not enter your private
          key or seed, secret phrase anywhere or you will lose your funds.
        </p>
        <p className={styles.textBoxContent}>Adamant staff will never DM you first.</p>
      </div>
    </div>
  );
};

export default Loading;
