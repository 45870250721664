import React, { FC } from 'react';
import bgParticles from './proges-circle-particles.png';
import styles from './ProgressCircle.module.scss';

export interface ProgressCircleProps {
  progress?: number;
}

const ProgressCircle: FC<ProgressCircleProps> = ({ children, progress = 0 }) => {
  const radius = 261.85 / 2;
  const circumference = radius * 2 * Math.PI;
  const value = progress > 1 ? 1 : progress;
  const offset = circumference - value * circumference;

  return (
    <div className={styles.container}>
      <div className={styles.innerContent}>{children}</div>

      <div className={styles.bgParticlesWrapper}>
        <img className={styles.bgParticles} src={bgParticles} alt="" />
      </div>

      <svg
        width="282"
        height="282"
        viewBox="0 0 282 282"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={styles.circleProgress}
          d="M10.0769 141C10.0769 175.723 23.8706 209.024 48.4234 233.577C72.9763 258.129 106.277 271.923 141 271.923C175.723 271.923 209.024 258.129 233.577 233.577C258.129 209.024 271.923 175.723 271.923 141C271.923 106.277 258.129 72.9762 233.577 48.4234C209.024 23.8706 175.723 10.0769 141 10.0769C106.277 10.0769 72.9763 23.8706 48.4234 48.4234C23.8706 72.9762 10.0769 106.277 10.0769 141"
          stroke="url(#paint0_linear_2_4)"
          strokeWidth="20"
          strokeLinecap="round"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={-offset}
        />
        <defs>
          <linearGradient
            id="paint0_linear_2_4"
            x1="141"
            y1="10.0769"
            x2="141"
            y2="271.923"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C68422" />
            <stop offset="1" stopColor="#F3D159" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default ProgressCircle;
