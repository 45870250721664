import Web3 from 'web3';
import * as boostLockFunctions from '../api/actions/NewBoostLockFunctions';
import { createTransactionsQueue } from '../utils/transactionUtils';
import { useToast } from '../_services';

export const useBoostLockFunctions = (account: string, web3?: Web3 | null) => {
  const approveTokensLoadingToast = useToast('loading');
  const depositLoadingToast = useToast('loading');
  const depositAllLoadingToast = useToast('loading');
  const exitLoadingToast = useToast('loading');
  const emergencyExitLoadingToast = useToast('loading');
  const claimLoadingToast = useToast('loading');
  const extendLoadingToast = useToast('loading');
  const claimAndExitLoadingToast = useToast('loading');

  const approve = async (params: { lpAddress: string; vaultContractAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ lpAddress, vaultContractAddress }) => {
        return async () =>
          boostLockFunctions.approve(web3, account, lpAddress, vaultContractAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: approveTokensLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const deposit = async (
    params: { amount: number | string; durationSeconds: number; vaultContractAddress: string }[],
  ) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ amount, durationSeconds, vaultContractAddress }) => {
        return async () =>
          boostLockFunctions.deposit(web3, account, amount, durationSeconds, vaultContractAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: depositLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const depositAll = async (
    params: { vaultContractAddress: string; durationSeconds: number }[],
  ) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultContractAddress, durationSeconds }) => {
        return async () =>
          boostLockFunctions.depositAll(web3, account, vaultContractAddress, durationSeconds);
      });

      createTransactionsQueue(contracts, {
        toastHook: depositAllLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const exit = async (params: { vaultContractAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultContractAddress }) => {
        return async () => boostLockFunctions.exit(web3, account, vaultContractAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: exitLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const emergencyExit = async (params: { vaultContractAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultContractAddress }) => {
        return async () => boostLockFunctions.emergencyExit(web3, account, vaultContractAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: emergencyExitLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const claim = async (params: { vaultContractAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultContractAddress }) => {
        return async () => boostLockFunctions.claim(web3, account, vaultContractAddress);
      });

      createTransactionsQueue(contracts, {
        toastHook: claimLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const extend = (
    params: { vaultContractAddress: string; durationSeconds: number; isBasic: boolean }[],
  ) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = params.map(({ vaultContractAddress, durationSeconds, isBasic }) => {
        return async () =>
          boostLockFunctions.extendLock(
            web3,
            account,
            durationSeconds,
            vaultContractAddress,
            isBasic,
          );
      });

      createTransactionsQueue(contracts, {
        toastHook: extendLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const claimAndExit = async (params: { vaultContractAddress: string }[]) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts: (() => Promise<void>)[] = [];
      params.forEach(({ vaultContractAddress }) => {
        contracts.push(async () => boostLockFunctions.claim(web3, account, vaultContractAddress));
        contracts.push(async () => boostLockFunctions.exit(web3, account, vaultContractAddress));
      });

      createTransactionsQueue(contracts, {
        toastHook: claimAndExitLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  return {
    approve,
    deposit,
    depositAll,
    exit,
    claim,
    claimAndExit,
    emergencyExit,
    extend,
  };
};
