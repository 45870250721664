import React, { FC } from 'react';

import styles from './TokensNames.module.scss';

interface Props {
  isSingleAsset?: boolean;
  nameOverride?: string;
  token1: string;
  token2: string;
  platform: string;
}

const TokensNames: FC<Props> = ({ isSingleAsset, token1, token2, nameOverride, platform }) => (
  <div className={styles.wrapper}>
    <p className={styles.names}>
      {nameOverride || (isSingleAsset ? `${token1} LP` : `${token1}/${token2}`)}
    </p>
    <p className={styles.platform}>{platform}</p>
  </div>
);

export default TokensNames;
