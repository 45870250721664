import React, { Component } from 'react';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import FurnacePool from '../components/FurnacePoolCard'
import Loading from '../components/Loading';
import { isZero } from '../utils';
import { NetworkID } from '../constants';

class FurnacePoolList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            showOnlyMyStakedVaults: false,
            showLpAmounts: true,
            sortedField: 'default',
            poolFilter: null,
            indices: { 'apy': 0, 'totalStaked': 1, 'valueTotalStaked': 1, 'userStaked': 2 },
            keyToString: { 'apy': 'APY', 'totalStaked': 'Total Staked', 'valueTotalStaked': 'Total Staked', 'userStaked': 'User Staked', 'default': 'Default Order' },
            sortConfig: [true, true, true] //apy, total, user
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStakedVaultsCheckbox = this.handleStakedVaultsCheckbox.bind(this);
        this.handleShowingLpAmountsCheckbox = this.handleShowingLpAmountsCheckbox.bind(this);
        this.setSortedField = this.setSortedField.bind(this);
        this.setPoolFilter = this.setPoolFilter.bind(this);
    }
    handleChange(event) {
        this.setState({
            searchTerm: event.target.value
        });
    }
    handleStakedVaultsCheckbox(event) {
        this.setState({
            showOnlyMyStakedVaults: event.target.checked
        });
    }
    handleShowingLpAmountsCheckbox(event) {
        this.setState({
            showLpAmounts: event.target.checked
        });
    }
    setSortedField(value) {
        if (value === 'default' || value == null) {
            this.setState({
                sortedField: value,
                sortConfig: [true, true, true]
            });
        }
        else {
            let prevVal = this.state.sortConfig[this.state.indices[value]];
            let newArr = [false, false, false];
            newArr[this.state.indices[value]] = !prevVal;

            this.setState({
                sortedField: value,
                sortConfig: newArr
            });
        }
    }
    setPoolFilter(value) {
        this.setState({
            poolFilter: value,
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        const isLoggedIn = this.props.account !== "0x0000000000000000000000000000000000000001";
        const pools = this.props.furnaceRewardsData;
        if(pools === undefined) {
            if(this.props.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
                return (
                    <div id="loader" className="text-center">
                        <p className="text-center">
                            Furnace Pools are only available on the Polygon network.
                        </p>
                    </div>
                )
            }
            return <Loading />
        }

        let sortedPools = pools; //this.getSortedPools(pools);

        return (
            <div id="home">
                <div className="col-12 text-center mb-3 no-select">
                    <h1 className="mb-4 underlined">
                        <span>Furnace Pools</span>
                    </h1>
                    <h4>
                        Smelt your ADDY into other tokens
                    </h4>
                </div>

                <div className="info-message gentle-message mb-3 col-8 no-select">
                    By entering these pools, you understand and agree that 100% of your deposited ADDY will be <b>burned</b> in order to earn the reward tokens over the remaining duration of the pool.<br />
                </div>

                {false && "implement this later" && <div className="farms-search row mb-4">
                    <div id="search_checkboxes" className="col-6">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="showStakedVaults" onChange={this.handleStakedVaultsCheckbox} />
                            <label className="form-check-label no-select" htmlFor="showStakedVaults">Show only staked</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="showLpAmounts" onChange={this.handleShowingLpAmountsCheckbox} />
                            <label className="form-check-label no-select" htmlFor="showLpAmounts">Show LP amounts</label>
                        </div>
                    </div>

                    <div className="search-bar col-6">
                        <div className="input-group">
                            <input className="form-control noValidate" type="search" aria-label="Search" placeholder="Search" id="search-bar-input" value={this.props.value} onChange={this.handleChange} />
                            <span className="input-group-text border-0" id="search-addon">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <Dropdown as={ButtonGroup} className="poolSortGroup">
                                <Button className="poolSortButton left" variant="secondary" onClick={() => this.setSortedField(this.state.sortedField)}>{this.state.keyToString[this.state.sortedField]}</Button>

                                <Dropdown.Toggle className="poolSortButton" split variant="secondary" id="dropdown-split-basic" />

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.setSortedField('apy')}>APY</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSortedField('valueTotalStaked')}>Total Staked</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSortedField('userStaked')}>User Staked</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.setSortedField('default')}>Default</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>}

                <div className="farms-list-wrapper">
                    <div className="farms-list">
                        <div className="section">
                            {sortedPools.map((pool, key) => {

                                const amountUserStaked = pool.amountUserStaked;
                                const noAmountStaked = !amountUserStaked || amountUserStaked.eq(0);
                                const stakeTokenName = pool.token0Name + '/' + pool.token1Name;
                                const deprecated = pool.deprecated;

                                const searchFiltered = this.isFiltered(pool.token0Name, pool.token1Name, pool.nameOverride) || !this.shouldShowVault(pool);
                                const hideNonStakedVault = this.state.showOnlyMyStakedVaults && noAmountStaked;

                                if (pool.hidden || searchFiltered || (deprecated && isZero(pool.pendingReward) && !pool.forceShow) || hideNonStakedVault) {
                                    return (<div key={stakeTokenName + "hidden" + key}></div>)
                                }
                                return (
                                    <FurnacePool key={stakeTokenName + key}
                                        pool={pool}
                                        showLpAmounts={this.state.showLpAmounts}

                                        account={this.props.account}
                                        isLoggedIn={isLoggedIn}
                                        networkId={this.props.networkId}

                                        showStakingModal={this.props.showStakingModal}
                                    />
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getWrappedName(tokenName) {
        if(tokenName === "BTC" || tokenName === "ETH" || tokenName === "BNB" || tokenName === "MATIC") {
            return "W" + tokenName;
        }
        return tokenName;
    }
    getUnwrappedName(tokenName) {
        if(tokenName.startsWith("W")) {
            return tokenName.substring(1);
        }
        return tokenName;
    }

    //Names should both be in unwrapped form (BTC/ETH) or both in wrapped form (WBTC/WETH)
    isFiltered(token0Name, token1Name, nameOverride) {
        if(this.state.searchTerm) {
            const stakeTokenName = token0Name + '/' + token1Name;
            const stakeTokenNameReversed = token1Name + '/' + token0Name;
            const stakeTokenNameWrapped = this.getWrappedName(token0Name) + '/' + this.getWrappedName(token1Name);
            const stakeTokenNameUnwrapped = this.getUnwrappedName(token0Name) + '/' + this.getUnwrappedName(token1Name);
            const stakeTokenNameWrappedReversed = this.getWrappedName(token1Name) + '/' + this.getWrappedName(token0Name);
            const stakeTokenNameUnwrappedReversed  = this.getUnwrappedName(token1Name) + '/' + this.getUnwrappedName(token0Name);

            let searchTerm = this.state.searchTerm.toLowerCase();
            if(nameOverride && nameOverride.toLowerCase().includes(searchTerm)) return false;
            if(stakeTokenName.toLowerCase().includes(searchTerm)) return false;
            if(stakeTokenNameReversed.toLowerCase().includes(searchTerm)) return false;
            if(stakeTokenNameWrapped.toLowerCase().includes(searchTerm)) return false;
            if(stakeTokenNameUnwrapped.toLowerCase().includes(searchTerm)) return false;
            if(stakeTokenNameWrappedReversed.toLowerCase().includes(searchTerm)) return false;
            if(stakeTokenNameUnwrappedReversed.toLowerCase().includes(searchTerm)) return false;
            return true;
        }
        return false;
    }

    shouldShowVault(pool) {
        if (this.state.poolFilter == null) {
            return true;
        }
        else if(this.state.poolFilter === "degen") {
            return pool.degen;
        }
        else if(pool.platform === this.state.poolFilter) {
            return true;
        }
        return false;
    }

    getSortedPools(pools) {
        let sortedPools = [...pools];
        sortedPools.sort((a, b) => {
            //sort by amount user staked, then total staked
            if (this.state.sortedField === 'default') {
                if (a.valueUserStaked < b.valueUserStaked) {
                    return this.state.sortConfig[this.state.indices['userStaked']] ? 1 : -1;
                }
                if (a.valueUserStaked > b.valueUserStaked) {
                    return this.state.sortConfig[this.state.indices['userStaked']] ? -1 : 1;
                }
                if (a.valueTotalStaked < b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['valueTotalStaked']] ? 1 : -1;
                }
                if (a.valueTotalStaked > b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['valueTotalStaked']] ? -1 : 1;
                }
            }

            if (this.state.sortedField === 'valueTotalStaked') {
                if (a.valueTotalStaked < b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['valueTotalStaked']] ? 1 : -1;
                }
                if (a.valueTotalStaked > b.valueTotalStaked) {
                    return this.state.sortConfig[this.state.indices['valueTotalStaked']] ? -1 : 1;
                }
            }
            else if (this.state.sortedField === 'userStaked') {
                if (a.valueUserStaked < b.valueUserStaked) {
                    return this.state.sortConfig[this.state.indices['userStaked']] ? 1 : -1;
                }
                if (a.valueUserStaked > b.valueUserStaked) {
                    return this.state.sortConfig[this.state.indices['userStaked']] ? -1 : 1;
                }
            }
            else {
                if (a.apy < b.apy) {
                    return this.state.sortConfig[this.state.indices['apy']] ? 1 : -1;
                }
                if (a.apy > b.apy) {
                    return this.state.sortConfig[this.state.indices['apy']] ? -1 : 1;
                }
            }
            return 0;
        });
        return sortedPools;
    }
}

export default FurnacePoolList;
