import React, { useCallback, useState, useRef } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import Button from '../common/Button/Button';
import { RoutingPaths } from '../../paths';
import { useUserContext } from '../../contexts/UserContext';
import { truncateKey } from '../../_helpers';

import AdamantLogoDark from './logo-dark.svg';
import AdamantLogoLight from './logo-light.svg';
import GasPrice from './GasPrice';
import TokenPrice from './TokenPrice';
import OptionsDropdown from './OptionsDropdown';
import NavigationLinks from './NavigationLinks';
import LinksDropdown from './LinksDropdown';
import styles from './Navbar.module.scss';

const Navbar: React.VFC = () => {
  const [isLinksDropdownOpen, setIsLinksDropdownOpen] = useState(false);
  const startInvestingButtonRef = useRef<HTMLButtonElement>(null);
  const { push } = useHistory();
  const location = useLocation();
  const { isWalletConnected, account, showChooseWalletModal, isDarkMode } = useUserContext();

  const isLandingPage = location.pathname === RoutingPaths.LANDING_PAGE;

  const handleStartInvestingClick = useCallback(() => {
    setIsLinksDropdownOpen(false);
    push(RoutingPaths.VAULTS);
  }, [push]);

  const closeLinksDropdown = useCallback(() => {
    setIsLinksDropdownOpen(false);
  }, []);

  return (
    <>
      <nav className={styles.container}>
        <NavLink className={styles.brandWrapper} to={RoutingPaths.LANDING_PAGE}>
          <img
            src={isDarkMode ? AdamantLogoDark : AdamantLogoLight}
            className={styles.logoWrapper}
            alt=""
          />

          <span className={styles.brandText}>Adamant</span>
        </NavLink>

        <div className={styles.contentRight}>
          <NavigationLinks clickSideEffect={closeLinksDropdown} />

          {!isLandingPage && <TokenPrice inNavbar />}

          {!isLandingPage && <GasPrice inNavbar />}

          {isLandingPage ? (
            <Button
              onClick={handleStartInvestingClick}
              color="primary"
              className={classnames(styles.ctaButton, isLinksDropdownOpen && styles.visible)}
              ref={startInvestingButtonRef}
            >
              Start Investing
            </Button>
          ) : (
            <Button
              kind="outlined"
              color="primary"
              className={classnames(styles.connectWalletButton)}
              onClick={showChooseWalletModal}
            >
              {isWalletConnected ? truncateKey(account) : 'Connect Wallet'}
            </Button>
          )}

          <LinksDropdown
            isOpen={isLinksDropdownOpen}
            setIsOpen={setIsLinksDropdownOpen}
            buttonClassName={styles.dropdownButton}
            dropdownClassName={styles.dropdown}
          />

          {!isLandingPage && (
            <OptionsDropdown
              buttonClassName={styles.dropdownButton}
              dropdownClassName={styles.dropdown}
              inNavbar
            />
          )}
        </div>
      </nav>

      {!isLandingPage && (
        <div className={styles.bottomBar}>
          <div className={styles.contentLeft}>
            <TokenPrice />
            <GasPrice />
          </div>
          <OptionsDropdown
            buttonClassName={styles.dropdownButton}
            dropdownClassName={styles.dropdown}
          />
        </div>
      )}
    </>
  );
};

export default Navbar;
