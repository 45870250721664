let priceCache = {};

//Peg the big 3 stables at $1 (USDC, USDT, DAI)
//Polygon, Arbitrum, Cronos addresses
//0xb828e46a504d52d149464aee052dc885d8a942a0 is a testnet "USDC" token
let stableCoins = new Set(["0x2791bca1f2de4661ed88a30c99a7a9449aa84174", "0xc2132d05d31c914a87c6611c10748aeb04b58e8f","0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
"0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9", "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8", "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
"0xc21223249ca28397b4b6541dffaecc539bff0c59", "0x66e428c3f67a68878562e79a0234c1f83c208770", "0xf2001b145b43032aaf5ee2884e456ccd805f677d",
"0xb828e46a504d52d149464aee052dc885d8a942a0"]);

export const cacheOnlyIfNumber = (token, numerator, denominator, force = true) => {
    let price = numerator/denominator;
    if((!priceCache[token] || force) && price > 0 && !isNaN(price) && price !== Infinity) {
        /*if(!priceCache[token]) {
            console.debug("Caching price for " + token.toString() + ": " + price)
        }*/

        //don't update the cache if the token is a stablecoin, unless the price is 1
        if(!stableCoins.has(token) || price === 1) {
            priceCache[token] = price;
            priceCache[token.toLowerCase()] = price;
        }
    }
}

//This should be done when switching chains, because certain tokens on different chains might have the same address
export const clearPriceCache = () => {
    priceCache = {};
}

export const getPriceCache = () => {
    return priceCache;
}

export const getTokenPrice = (address) => {
    return priceCache[address.toLowerCase()];
}

//Assumptions:
//if stablePair, then token1 is the stablecoin
//The price of token1 should be cached before this function is called
function _cachePriceOfToken(unknownToken, knownToken, numToken0, numToken1, decimals0, decimals1, force = true) {
    numToken0 /= 10 ** decimals0;
    numToken1 /= 10 ** decimals1;

    numToken1 *= priceCache[knownToken];
    cacheOnlyIfNumber(unknownToken, numToken1, numToken0, force);
}

//Vault data should be ordered in a way such that token1 will always have been cached first
export const cacheTokenPrice = (token0, token1, numToken0, numToken1, decimals0, decimals1, force = true) => {
    token0 = token0.toLowerCase();
    token1 = token1.toLowerCase();

    //Don't attempt to cache the token price for single-asset vaults
    if(token0 === token1) {
        return;
    }

    //kinda redundant if stablecoin prices are cached before this is called
    if(stableCoins.has(token0)) cacheOnlyIfNumber(token0, 1, 1, true);
    if(stableCoins.has(token1)) cacheOnlyIfNumber(token1, 1, 1, true);

    //This might be possible if the pool tokens are flipped
    if(getTokenPrice(token1) === undefined) {
        _cachePriceOfToken(token1, token0, numToken1, numToken0, decimals1, decimals0, force);
    }
    else if(getTokenPrice(token1) !== undefined) {
        _cachePriceOfToken(token0, token1, numToken0, numToken1, decimals0, decimals1, force);
    }
    else {
        console.error("Price is undefined: ", token0);
    }
}

//Returns the TVL of a pool based on the balances and price of both tokens, and also caches the price of token0
export const getPoolTVL = (token0, token1, numToken0, numToken1, decimals0, decimals1, force = true) => {
    cacheTokenPrice(token0, token1, numToken0, numToken1, decimals0, decimals1, force);
    return 2 * numToken1 * getTokenPrice(token1) / 10 ** decimals1;
}
