import dayjs from 'dayjs';
import { number, object } from 'yup';
import { BoostLock } from '../../constants/boost-lock';
import { tokenAmountValidationSchema } from '../../utils/validators';

export const getLockTokensValidationSchema = (maxAmount: number, isExtend: boolean) => {
  return object({
    ...(!isExtend && { lockAmount: tokenAmountValidationSchema(maxAmount) }),
    lockDuration: number().required(),
  });
};

export const getLockDurationOptions = (endingTimestamp?: number) => {
  const lockDurationDefaultOptions = [...BoostLock.predefinedDaysDurations].sort((a, b) => a - b);
  const lowestLockDuration = lockDurationDefaultOptions[0] ?? BoostLock.minDaysDuration;
  const highestLockDuration =
    lockDurationDefaultOptions[lockDurationDefaultOptions.length - 1] ?? BoostLock.maxDaysDuration;

  if (!endingTimestamp) {
    return lockDurationDefaultOptions;
  }

  const unlockTimestamp = endingTimestamp * 1000;

  const unlockDays = dayjs(unlockTimestamp).diff(dayjs(), 'days');

  // if it's max duration, return only one lock option
  if (unlockDays === highestLockDuration) {
    return [highestLockDuration];
  }

  // set minimum lock option to unlockDays, and remove all options with less days to unlock
  if (unlockDays > lowestLockDuration) {
    const options = [unlockDays, ...lockDurationDefaultOptions].filter((d) => d >= unlockDays);
    return Array.from(new Set(options));
  }

  // if its min duration, return all lock options
  return lockDurationDefaultOptions;
};
