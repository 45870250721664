import React, { Component } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

class RatesLabel extends Component {
    render() {
        return (
            <div className="rates">
                <OverlayTrigger placement="top" overlay={this.props.overlay}>
                    <span className={this.props.deprecated ? "deprecated": "apy"}>{this.props.poolYieldString} <FontAwesomeIcon icon={faInfoCircle} className="mobileOnly" /></span>
                </OverlayTrigger>
                {this.props.pool.showApr && !this.props.deprecated && <OverlayTrigger placement="top" overlay={this.props.overlay}>
                    <span className={this.props.deprecated ? "deprecated": "apr"}>{this.props.poolRateString}</span>
                </OverlayTrigger>}
                <OverlayTrigger placement="top" overlay={this.props.vaultTypeTooltip}>
                    <span className="description">{this.props.description}</span>
                </OverlayTrigger>
            </div>
        );
    }
}

export default RatesLabel;