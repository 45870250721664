import React, { Component } from "react";
import { isZero } from '../../../utils'

class LpBalance extends Component {
    getMyLpBalanceString(userLpBalance) {
        if(isZero(userLpBalance)) {
            return "0";
        }
        if(userLpBalance / 10 ** 18 > 10 ** -7) {
            return (userLpBalance / 10 ** 18).toFixed(8);
        }
        return userLpBalance + "";
    }

    render() {
        let small = this.props.userLpBalance > 0 && this.props.userLpBalance / 10 ** 18 < 10 ** -7 ;

        return (
            <div className="pool-row-desc">
                <span className="big">{this.getMyLpBalanceString(this.props.userLpBalance)}</span>
                {small ?
                    <div>My {this.props.stakeTokenName} Balance (* 10<sup>-18</sup>)</div>:
                    <div>My {this.props.stakeTokenName} Balance</div>
                }
            </div>
        );
    }
}

export default LpBalance;
