import React, { VFC } from 'react';
import classNames from 'classnames';

import { getAdamantTokenName } from '../../api/Multichain';

import { ReactComponent as PlusIcon } from './plus-icon.svg';
import { ReactComponent as LockIcon } from './lock-icon.svg';
import { ReactComponent as TickIcon } from './tick-icon.svg';
import styles from './TabStatus.module.scss';

export enum TabStatusStateEnum {
  NO_LOCKED_ADDY = 'NO_LOCKED_ADDY',
  LOCKED_ADDY = 'LOCKED_ADDY',
  COMPLETE = 'COMPLETE',
}

const getMapState = (currentTokenName: string) => ({
  [TabStatusStateEnum.NO_LOCKED_ADDY]: {
    color: styles.red,
    text: `No ${currentTokenName} locked yet`,
    icon: <PlusIcon />,
  },
  [TabStatusStateEnum.LOCKED_ADDY]: {
    color: styles.green,
    text: `Locked ${currentTokenName}`,
    icon: <LockIcon />,
  },
  [TabStatusStateEnum.COMPLETE]: {
    color: styles.yellow,
    text: 'Locking Complete',
    icon: <TickIcon />,
  },
});

interface TabStatusProps {
  active: boolean;
  currentState: TabStatusStateEnum;
}

const TabStatus: VFC<TabStatusProps> = ({ active, currentState }) => {
  const adamantTokenName = getAdamantTokenName();
  const mapState = getMapState(adamantTokenName)[currentState];

  return (
    <div className={classNames(styles.container, active && styles.active, mapState.color)}>
      <div className={styles.iconWrapper}>{mapState.icon}</div>
      {!active && <span>{mapState.text}</span>}
    </div>
  );
};

export default TabStatus;
