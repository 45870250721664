import React, { FC, useState } from 'react';

import { motion } from 'framer-motion';
import styles from './VaultCardBody.module.scss';
import VaultCardBodyMain from './VaultCardBodyMain/VaultCardBodyMain';
import VaultCardBodyAddresses from './VaultCardBodyAddresses/VaultCardBodyAddresses';
import infoIcon from './info-icon.svg';
import closeIcon from './close-icon.svg';

interface Props {
  vault: any;
}

const VaultCardBody: FC<Props> = ({ vault }) => {
  const [showAddresses, setShowAddresses] = useState(false);

  const handleToggleAddresses = () => {
    setShowAddresses(!showAddresses);
  };

  return (
    <motion.div layout className={styles.body}>
      <motion.button
        layout
        type="button"
        className={styles.toggleAddressesBtn}
        onClick={handleToggleAddresses}
      >
        <img src={showAddresses ? closeIcon : infoIcon} alt="" />
      </motion.button>

      {showAddresses ? (
        <motion.div layout>
          <VaultCardBodyAddresses vault={vault} />
        </motion.div>
      ) : (
        <motion.div layout>
          <VaultCardBodyMain vault={vault} />
        </motion.div>
      )}
    </motion.div>
  );
};

export default VaultCardBody;
