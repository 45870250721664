import React, { VFC, useMemo, useState } from 'react';
import VaultsPage, { VaultsPageProps } from './VaultsPage';
import { useUserContext } from '../../contexts/UserContext';
import {
  VaultsFiltersContextProvider,
  useVaultsFiltersContext,
  VaultsFiltersState,
  SortValuesEnum,
} from '../../contexts/VaultsFiltersContext';
import stablecoins from '../../constants/stablecoins';

const mapSortValues = {
  [SortValuesEnum.TVL]: 'valueTotalStaked',
  [SortValuesEnum.APY]: 'apy',
  [SortValuesEnum.MY_INVESTMENTS]: 'valueUserStaked',
  [SortValuesEnum.VAULT_NAME]: 'token0Name',
  [SortValuesEnum.REWARDS]: 'pendingReward',
  [SortValuesEnum.RELEASE_TIMESTAMP]: 'timestamp',
};

const getWrappedName = (tokenName: string) => {
  if (tokenName === 'BTC' || tokenName === 'ETH' || tokenName === 'BNB' || tokenName === 'MATIC') {
    return `W${tokenName}`;
  }
  return tokenName;
};

const getUnwrappedName = (tokenName: string) => {
  if (tokenName.startsWith('W')) {
    return tokenName.substring(1);
  }
  if (tokenName === 'MAI') {
    return 'mimatic';
  }
  return tokenName;
};

const filterVaults = (allVaults: any[], account: string, filters: VaultsFiltersState) => {
  const isAdmin = account.toLowerCase() === '0xb29cd9c87a624b940335d6d5e1d4aadf7d95beec';

  return (
    allVaults
      .filter((vault: any) => {
        const { amountUserStaked } = vault;

        const noAmountStaked = Number(amountUserStaked) === 0;
        const deprecated = vault.deprecated || (vault.rewardsPerDay === 0 && !isAdmin);

        return !(
          vault.hidden ||
          (noAmountStaked && (deprecated || vault.hiddenForNonStaked) && !vault.forceShow)
        );
      })
      // Filter by token
      .filter((vault: any) => {
        if (filters.token.length === 0) {
          return true;
        }
        return filters.token.find(
          (curr) =>
            curr === vault.token0Name ||
            curr === vault.token1Name ||
            curr === getWrappedName(vault.token0Name) ||
            curr === getWrappedName(vault.token1Name) ||
            curr === getUnwrappedName(vault.token0Name) ||
            curr === getUnwrappedName(vault.token1Name),
        );
      })
      // Filter by platform
      .filter((vault: any) => {
        if (filters.platform.length === 0) {
          return true;
        }
        return filters.platform.find((curr) => curr === vault.platform);
      })
      // Filter by status
      .filter((vault: any) => {
        if (filters.status[0] === 'active') {
          return !vault.deprecated;
        }
        if (filters.status[0] === 'inactive') {
          return vault.deprecated;
        }
        return true;
      })
      // Filter by boostable
      .filter((vault: any) => !filters.boostable || vault.boostable)
      // Filter by degenerate
      .filter((vault: any) => !filters.degenerate || vault.degen)
      // Filter by stablecoins
      .filter(
        (vault: any) =>
          !filters.stablecoins ||
          (stablecoins.has(vault.token0.toLowerCase()) &&
            stablecoins.has(vault.token1.toLowerCase())),
      )
      // Sort
      .sort((a, b) => {
        if (filters.sort.value === SortValuesEnum.VAULT_NAME) {
          return filters.sort.direction === 'DESC'
            ? b[mapSortValues[filters.sort.value]].localeCompare(
                a[mapSortValues[filters.sort.value]],
              )
            : a[mapSortValues[filters.sort.value]].localeCompare(
                b[mapSortValues[filters.sort.value]],
              );
        }
        if (filters.sort.value === SortValuesEnum.RELEASE_TIMESTAMP) {
          const aVal =
            a[mapSortValues[filters.sort.value]] === undefined
              ? 0
              : a[mapSortValues[filters.sort.value]];
          const bVal =
            b[mapSortValues[filters.sort.value]] === undefined
              ? 0
              : b[mapSortValues[filters.sort.value]];
          return filters.sort.direction === 'DESC' ? bVal - aVal : aVal - bVal;
        }
        return filters.sort.direction === 'DESC'
          ? b[mapSortValues[filters.sort.value]] - a[mapSortValues[filters.sort.value]]
          : a[mapSortValues[filters.sort.value]] - b[mapSortValues[filters.sort.value]];
      })
  );
};

type VaultsPageContainerProps = Omit<
  VaultsPageProps,
  'vaultsToDisplay' | 'myVaults' | 'isCompounding' | 'onCompoundAll' | 'hasInactiveVaults'
>;

const VaultsPageContainer: VFC<VaultsPageContainerProps> = (props) => {
  const { filtersState } = useVaultsFiltersContext();
  const { account, vaultFunctions } = useUserContext();
  const [isCompounding, setIsCompounding] = useState(false);
  const { vaults } = props;

  const vaultsToDisplay = useMemo(
    () => filterVaults(vaults, account, filtersState),
    [account, filtersState, vaults],
  );
  const myVaults = useMemo(
    () => vaultsToDisplay.filter((vault) => vault.valueUserStaked),
    [vaultsToDisplay],
  );
  const hasInactiveVaults = myVaults.some((vault: any) => Boolean(vault.deprecated));

  const compoundAllVaults = () => {
    if (isCompounding) return;
    setIsCompounding(true);

    const params = myVaults.map(({ strategyAddress }) => ({
      strategyContractAddress: strategyAddress,
    }));

    vaultFunctions.compoundAllVaults(params).finally(() => {
      setIsCompounding(false);
    });
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <VaultsPage
      vaultsToDisplay={vaultsToDisplay}
      myVaults={myVaults}
      isCompounding={isCompounding}
      onCompoundAll={compoundAllVaults}
      hasInactiveVaults={hasInactiveVaults}
      {...props}
    />
  );
};

export default (props: VaultsPageContainerProps) => (
  <VaultsFiltersContextProvider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <VaultsPageContainer {...props} />
  </VaultsFiltersContextProvider>
);
