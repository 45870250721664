import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import { formatTokenAmount } from '../../_helpers';
import styles from './AmountInput.module.scss';
import utilStyles from '../../styles/utils.module.scss';
import { getAdamantTokenName } from '../../api/Multichain';
import { RoutingPaths } from '../../paths';
import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface AmountInputProps extends TextInputProps {
  balance?: string | number | null;
  disabled?: boolean;
  onInvestAllClick?: () => void;
}

const AmountInput = forwardRef<HTMLInputElement, AmountInputProps>((props, ref) => {
  const { balance, disabled, onInvestAllClick, ...inputProps } = props;
  const { isWalletConnected } = useUserContext();

  return (
    <div className={styles.container}>
      {isWalletConnected && (
        <div className={styles.balancePurchaseContainer}>
          {balance !== null && balance !== undefined && (
            <div className={styles.balance}>
              <button
                disabled={disabled}
                className={classNames(styles.balanceButton, disabled && utilStyles.disabled)}
                onClick={onInvestAllClick}
                type="button"
              >
                Balance
              </button>
              <p className={styles.balanceValue}>&nbsp;{formatTokenAmount(balance)}&nbsp;</p>
              <p className={styles.balanceToken}>{getAdamantTokenName()}</p>
            </div>
          )}

          {Number(balance) === 0 && (
            <p className={styles.purchaseAddy}>
              No {getAdamantTokenName()}? Earn it through the{' '}
              <Link to={RoutingPaths.VAULTS}>Vaults</Link> or by purchasing at your favourite
              decentralized exchange
            </p>
          )}
        </div>
      )}

      <div className={styles.inputContainer}>
        <TextInput
          ref={ref}
          {...inputProps}
          disabled={disabled}
          withButton
          buttonText="MAX"
          buttonDisabled={disabled || !balance || !isWalletConnected}
          onButtonClick={onInvestAllClick}
          placeholder="0.0"
          startEnhancer={
            <img className={styles.tokenIcon} src="/assets/ADDY-token-icon.svg" alt="" />
          }
        />
      </div>
    </div>
  );
});

export default AmountInput;
