import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import * as serviceWorker from './serviceWorker';
import App from './App';
import RestoreScroll from './utils/RestoreScroll';
import { UserContextProvider } from './contexts/UserContext';

const client = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap06',
  cache: new InMemoryCache(),
});

dayjs.extend(relativeTime);
dayjs.extend(duration);

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <RestoreScroll>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </RestoreScroll>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
