import React, { VFC } from 'react';
import styles from './VestingInfoBox.module.scss';
import { getAdamantTokenName } from '../../api/Multichain';

const VestingInfoBox: VFC = () => {
  const adamantTokenName = getAdamantTokenName();

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        Freshly minted {adamantTokenName} is considered to be vesting for 90 days. Your vesting{' '}
        {adamantTokenName} will still earn performance fees during that time. After 90 days are
        complete, your vesting {adamantTokenName} will become unlocked and may be withdrawn freely.
        It is possible to withdraw your {adamantTokenName} early, but doing so will incur a 50%
        early vesting withdrawal penalty
      </p>
    </div>
  );
};

export default VestingInfoBox;
