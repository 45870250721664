import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { DeFiWeb3Connector } from 'deficonnect';
import ReactModal from 'react-modal';
import { ToastContainer } from 'react-toastify';

import 'swiper/swiper.min.css';
import 'react-toastify/dist/ReactToastify.css';

import './css/Main.css';
import './css/FarmHeader.css';
import './css/bunny.css';
import './css/Ellipsis.css';
import './css/Alert.css';
import './styles/index.scss';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Loading from './components/Loading/Loading';
import PageLayout from './components/PageLayout/PageLayout';
import WalletModal from './components/WalletModal/WalletModal';
import StakeModal from './components/StakeModal';
import LockedStakeModal from './components/LockedStakeModal';
import LockedStakeModalAddy from './components/LockedStakeModalAddy';
import { Alert2 } from './components/alert/Alert';

import Tools from './pages/Tools';
import StakeVest from './pages/StakeVest';
import StakeVestArbitrum from './pages/StakeVestArbitrum';
import FurnacePoolList from './pages/FurnacePoolList';
import Presale from './pages/Presale';
import PresaleWithdraw from './pages/PresaleWithdraw';
import ValidateMigration from './pages/ValidateMigration';
import LandingPage from './pages/LandingPage/LandingPage';
import Page404 from './pages/Page404/Page404';
import OldFarmList from './pages/FarmList';
import VaultsPage from './pages/VaultsPage/VaultsPageContainer';
import BoostPage from './pages/BoostPage/BoostPageContainer';
import VestPage from './pages/VestPage/VestPageContainer';

import { alertService } from './_services';
import { getStakingData } from './api';
import { clearPriceCache } from './api/PriceCache';
import { NetworkID } from './constants';
import { getProvider } from './constants/multichain/network';
import { RoutingPaths } from './paths';
import { UserContext } from './contexts/UserContext';
import StakePage from './pages/StakePage/StakePageContainer';

const defaultNetwork = NetworkID.POLYGON_NETWORK_ID;
const supportedNetworks = new Set([
  NetworkID.POLYGON_NETWORK_ID,
  NetworkID.ARBITRUM_NETWORK_ID,
  NetworkID.CRONOS_NETWORK_ID,
  NetworkID.POLYGON_TESTNET_ID,
]);
const DEFAULT_ADDRESS = '0x7C3E6C2544E3e4462E44530e9cDA5e745C831C0B'; // using polygon-rpc returns this as the first address
// let lastAction = 0;

ReactModal.setAppElement(document.getElementById('root'));

const DeFiConnector = new DeFiWeb3Connector({
  supportedChainIds: [25, 137],
  rpc: {
    25: 'https://evm-cronos.crypto.org',
    137: 'https://polygon-rpc.com',
  },
  pollingInterval: 15000,
});

const isEthereumDefiWallet = () =>
  window.ethereum.bridge !== undefined &&
  window.ethereum.bridge.startsWith('https://wallet-connect.crypto.com');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: '',
      networkId: 1,

      loadedVaults: false,
      hasMetamask: false,
      hasDefiWallet: false,
      loading: true,
      web3: null,
      backupRPC: null,

      stakeTokenName: '',
      rewardTokenName: '',

      stakingData: [],
      stakingRewardsData: [],
      stakingDataMap: {},

      // For the normal vault staking modal
      currentPool: 0,
      currentVaultAddress: '',
      stakeModalOpen: false,

      // For the native ADDY LP staking modals
      currentAddyPool: 0,
      currentAddyPoolAddress: '',
      addyPoolStakeModalOpen: false,

      // For the locked ADDY token staking modal
      lockedAddyStakingModalOpen: false,
    };
    // Staking modals
    this.showStakingModal = this.showStakingModal.bind(this);
    this.hideStakingModal = this.hideStakingModal.bind(this);
    this.showAddyStakingModal = this.showAddyStakingModal.bind(this);
    this.hideAddyStakingModal = this.hideAddyStakingModal.bind(this);
    this.showLockedStakeModalAddy = this.showLockedStakeModalAddy.bind(this);
    this.hideLockedStakeModalAddy = this.hideLockedStakeModalAddy.bind(this);
  }

  async componentDidMount() {
    await this.loadWeb3();
    await this.loadBlockchainData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { userContext } = this.props;
    const { account, networkId } = this.state;
    const { account: prevAccount, networkId: prevNetworkId } = prevState;

    userContext.setWeb3(this.state.web3);

    if (account !== prevAccount) {
      userContext.setAccount(account);
    }

    if (networkId !== prevNetworkId) {
      userContext.setNetworkId(networkId);
    }
  }

  componentWillUnmount() {
    if (this.watch) {
      this.watch.unsubscribe();
    }
    if (this.loop) {
      clearInterval(this.loop);
      this.loop = void 0;
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  getAccount = async () => {
    const { hasDefiWallet, web3 } = this.state;

    if (hasDefiWallet) {
      const accounts = web3._currentProvider.connection.accounts;
      if (!accounts.length) {
        return null;
      }
      return accounts[0];
    }

    const accounts = await web3.eth.getAccounts();
    if (!accounts.length) {
      return null;
    }

    return accounts[0];
  };

  async getStakingDataFor(networkId) {
    const { account } = this.state;
    return getStakingData(networkId, window.web3, account);
  }

  connectCryptoDeFiWallet = () => {
    DeFiConnector.activate().then(async (e) => {
      const provider = await DeFiConnector.getProvider();
      const web3 = new Web3(provider);
      window.provider = provider;
      window.web3 = web3;

      this.setState({
        web3,
        account: e.account,
        hasDefiWallet: true,
      });
      const { userContext } = this.props;
      userContext.hideChooseWalletModal();
      this.updateInfo();
    });
  };

  connectMetamaskWallet = () => {
    const { hasDefiWallet } = this.state;

    if (window.ethereum) {
      if (isEthereumDefiWallet() || hasDefiWallet) {
        alertService.error('Please disconnect from Crypto.com DeFi Wallet first.');
        return;
      }
      const web3 = new Web3(window.ethereum);
      window.ethereum.enable().then((e) => {
        if (e && web3) {
          web3.eth.net.getId().then((networkId) => {
            if (!supportedNetworks.has(networkId)) {
              alertService.error('Please connect to the Matic network before using this website.');
            } else {
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              window.provider = provider;

              window.web3 = web3;
              alertService.success(`Successfully connected account: ${e[0].substr(0, 10)}...`, {
                autoClose: true,
              });

              this.setState({
                web3,
                account: e[0],
              });
              const { userContext } = this.props;
              userContext.hideChooseWalletModal();
              this.updateInfo();
            }
          });
        }
      });
    } else {
      alertService.alertLog(
        'Non-Ethereum browser detected. Many functions will not be available if you do not have Metamask installed.',
      );
    }
  };

  // Modals
  showStakingModal(poolIndex, vaultAddress) {
    this.setState({
      stakeModalOpen: true,
      currentPool: poolIndex,
      currentVaultAddress: vaultAddress,
    });
  }

  hideStakingModal() {
    this.setState({
      stakeModalOpen: false,
      currentPool: 0,
      currentVaultAddress: '',
    });
  }

  showAddyStakingModal(poolIndex, vaultAddress) {
    this.setState({
      currentAddyPool: poolIndex,
      addyPoolStakeModalOpen: true,
      currentAddyPoolAddress: vaultAddress,
    });
  }

  hideAddyStakingModal() {
    this.setState({
      currentAddyPool: 0,
      addyPoolStakeModalOpen: false,
      currentAddyPoolAddress: '',
    });
  }

  showLockedStakeModalAddy(isBoostPlus) {
    this.setState({
      lockedAddyStakingModalOpen: true,
      isBoostPlus,
    });
  }

  hideLockedStakeModalAddy() {
    this.setState({
      lockedAddyStakingModalOpen: false,
    });
  }

  updateInfo() {
    const { web3, networkId, account } = this.state;

    web3.eth.net.getId().then((_networkId) => {
      if (_networkId !== networkId) {
        console.debug(`Detected network change: ${_networkId}`);

        try {
          clearPriceCache();
          this.loadBlockchainData().then(() => {
            console.debug('Reloaded blockchain data');
          });
        } catch {
          console.error('Failed to update info when switching networks');
        }
      }
    });

    this.getAccount().then((myAddress) => {
      if (myAddress) {
        if (myAddress !== account) {
          console.debug('Found new account: '.concat(myAddress));
          this.setState({ account: myAddress });

          try {
            this.loadBlockchainData().then(() => {
              console.debug('Reloaded blockchain data');
            });
          } catch {
            console.error('Failed to update info when switching networks');
          }
        }
      }
    });

    try {
      this.getStakingDataFor(networkId).then((stakingData) => {
        if (stakingData) {
          this.setState({
            stakingData: stakingData[0],
            stakingRewardsData: stakingData[1],
            furnaceRewardsData: stakingData[2],
            addyData: stakingData[3],
            stakingDataMap: stakingData[4],
          });
        } else {
          console.error('Failed to update info');
        }
      });
    } catch {
      console.error('Failed to update info');
    }
  }

  async loadWeb3() {
    if (window.ethereum) {
      if (isEthereumDefiWallet()) {
        DeFiConnector.activate().then(async (e) => {
          const provider = await DeFiConnector.getProvider();
          const web3 = new Web3(provider);
          window.provider = provider;
          window.web3 = web3;

          this.setState({
            web3,
            account: e.account,
            hasDefiWallet: true,
          });
          const { userContext } = this.props;
          userContext.hideChooseWalletModal();
          return;
        });
      }

      const web3 = new Web3(window.ethereum);
      const networkId = await web3.eth.net.getId();
      window.networkId = networkId;

      if (!supportedNetworks.has(networkId)) {
        alertService.error(
          'Please connect to a supported network. Supported networks: Polygon, Arbitrum, Cronos',
        );
        window.web3 = new Web3(new Web3.providers.HttpProvider(getProvider(defaultNetwork)));
        this.setState({
          web3: window.web3,
        });
      } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        window.web3 = new Web3(window.ethereum);
        window.provider = provider;

        this.setState({
          hasMetamask: true,
          web3: window.web3,
        });
      }
    } else if (window.web3 != null) {
      console.debug('Using current web3 provider');
      const web3 = new Web3(window.web3.currentProvider);
      const networkId = await web3.eth.net.getId();

      if (!supportedNetworks.has(networkId)) {
        alertService.error(
          'Please connect to a supported network. Supported networks: Polygon, Arbitrum, Cronos',
        );
        window.web3 = new Web3(new Web3.providers.HttpProvider(getProvider(defaultNetwork)));
        this.setState({
          web3: window.web3,
        });
      } else {
        window.web3 = new Web3(window.web3.currentProvider);
        this.setState({
          hasMetamask: true,
          web3: window.web3,
        });
      }
    } else {
      window.web3 = new Web3(new Web3.providers.HttpProvider(getProvider(defaultNetwork)));
      this.setState({
        web3: window.web3,
      });
    }
  }

  async loadBlockchainData() {
    const { web3 } = window;
    const account = await this.getAccount();
    let myAddress = '0x0000000000000000000000000000000000000001';

    if (!account) {
      console.debug('No accounts found');
    } else if (account === DEFAULT_ADDRESS) {
      console.debug('No valid accounts found');
    } else {
      console.debug(`Found account: ${account}`);
      myAddress = account;
    }
    this.setState({
      account: myAddress,
      web3,
    });

    const networkId = await web3.eth.net.getId();
    const backupRPC = new Web3(new Web3.providers.HttpProvider(getProvider(networkId)));
    window.networkId = networkId;

    this.setState({
      networkId,
      backupRPC,
    });

    if (supportedNetworks.has(networkId)) {
      this.setState({ loading: false });

      try {
        const stakingData = await this.getStakingDataFor(networkId);
        this.setState({
          stakingData: stakingData[0],
          stakingRewardsData: stakingData[1],
          furnaceRewardsData: stakingData[2],
          addyData: stakingData[3],
          stakingDataMap: stakingData[4],
          loadedVaults: true,
        });
      } catch (e) {
        console.error(`Failed to get staking data for network: ${networkId}`, e);
        await this.loadBlockchainData();
        return;
      }
    } else {
      alertService.alertLog(
        'Unable to find any pools on the current network, please make sure you are connected to the Matic network.\n' +
          'If you just changed networks, please refresh the page.',
      );
    }

    this.timer = setInterval(() => this.updateInfo(), 6000);
  }

  render() {
    const {
      stakeModalOpen,
      account,
      stakingData,
      stakingDataMap,
      currentPool,
      currentVaultAddress,
      stakeTokenName,
      rewardTokenName,
      addyPoolStakeModalOpen,
      stakingRewardsData,
      currentAddyPool,
      currentAddyPoolAddress,
      lockedAddyStakingModalOpen,
      addyData,
      isBoostPlus,
      web3,
      loading,
      networkId,
      loadedVaults,
      furnaceRewardsData,
    } = this.state;

    return (
      <>
        <ToastContainer
          hideProgressBar
          pauseOnFocusLoss
          draggable
          pauseOnHover
          position="bottom-right"
          closeButton={null}
          autoClose={3000}
        />

        <StakeModal
          show={stakeModalOpen}
          account={account}
          stakingData={stakingData}
          stakingDataMap={stakingDataMap}
          currentPool={currentPool}
          currentVaultAddress={currentVaultAddress}
          stakeTokenName={stakeTokenName}
          rewardTokenName={rewardTokenName}
          hideModal={this.hideStakingModal}
        />

        <LockedStakeModal
          show={addyPoolStakeModalOpen}
          account={account}
          stakingRewardsData={stakingRewardsData}
          currentPool={currentAddyPool}
          currentAddyPoolAddress={currentAddyPoolAddress}
          hideModal={this.hideAddyStakingModal}
        />

        <LockedStakeModalAddy
          show={lockedAddyStakingModalOpen}
          account={account}
          addyData={addyData}
          isBoostPlus={isBoostPlus}
          hideModal={this.hideLockedStakeModalAddy}
        />

        <WalletModal
          connectMetamaskWallet={this.connectMetamaskWallet}
          connectCryptoDeFiWallet={this.connectCryptoDeFiWallet}
        />

        {loading ? (
          <PageLayout>
            <Loading />
          </PageLayout>
        ) : (
          <>
            <Alert2 />

            <PageLayout>
              <Switch>
                <Route path={RoutingPaths.STAKE_ADDY}>
                  <StakeVest
                    web3={web3}
                    account={account}
                    networkId={networkId}
                    showStakingModal={this.showLockedStakeModalAddy}
                  />
                </Route>

                <Route path="/arbitrumstake">
                  <StakeVestArbitrum
                    web3={web3}
                    account={account}
                    networkId={networkId}
                    showStakingModal={this.showLockedStakeModalAddy}
                  />
                </Route>

                <Route path="/tools">
                  <Tools account={account} stakingData={stakingData} />
                </Route>

                <Route path="/furnace">
                  <FurnacePoolList
                    account={account}
                    networkId={networkId}
                    loadedVaults={loadedVaults}
                    furnaceRewardsData={furnaceRewardsData}
                    addyData={addyData}
                    showStakingModal={this.showStakingModal}
                  />
                </Route>

                <Route path="/presalewithdraw">
                  <PresaleWithdraw account={account} />
                </Route>

                <Route path="/presale">
                  <Presale account={account} />
                </Route>

                <Route path="/migrate">
                  <ValidateMigration
                    account={account}
                    stakingData={stakingData}
                    stakingRewardsData={stakingRewardsData}
                  />
                </Route>

                <Route path={RoutingPaths.VAULTS}>
                  <VaultsPage
                    vaults={stakingData}
                    vaultsLoaded={loadedVaults}
                    addyData={addyData}
                  />
                </Route>

                <Route path="/vaults-legacy">
                  {/* TODO to delete */}
                  {/* Left for reference */}
                  <OldFarmList
                    account={account}
                    networkId={networkId}
                    loadedVaults={loadedVaults}
                    stakeTokenName={stakeTokenName}
                    rewardTokenName={rewardTokenName}
                    pool={stakingData}
                    stakingRewardsData={stakingRewardsData}
                    addyData={addyData}
                    showStakingModal={this.showStakingModal}
                    showAddyStakingModal={this.showAddyStakingModal}
                  />
                </Route>

                <Route path={RoutingPaths.LANDING_PAGE} exact>
                  <LandingPage
                    pools={stakingData}
                    stakingRewardsData={stakingRewardsData}
                    isVaultsLoaded={loadedVaults}
                  />
                </Route>

                <Route path={RoutingPaths.BOOST}>
                  <BoostPage addyData={addyData} vaults={stakingData} />
                </Route>

                <Route path={RoutingPaths.STAKE}>
                  <StakePage addyData={addyData} />
                </Route>

                <Route path={RoutingPaths.VESTING}>
                  <VestPage addyData={addyData} />
                </Route>

                <Route path="*">
                  <Page404 />
                </Route>
              </Switch>
            </PageLayout>
          </>
        )}
        <Footer />
      </>
    );
  }
}

export default () => (
  <UserContext.Consumer>
    {(userContextData) => <App userContext={userContextData} />}
  </UserContext.Consumer>
);
