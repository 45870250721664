import Web3 from 'web3';
import { useToast } from '../_services';
import * as stakeFunctions from '../api/actions/NewStakeFunctions';
import { createTransactionsQueue } from '../utils/transactionUtils';

export const useStakeFunctions = (account: string, web3?: Web3 | null) => {
  const approveTokenLoadingToast = useToast('loading');
  const stakeLoadingToast = useToast('loading');
  const stakeMaxLoadingToast = useToast('loading');
  const withdrawStakedLoadingToast = useToast('loading');
  const claimRewardsLoadingToast = useToast('loading');

  const approve = async () => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = [async () => stakeFunctions.approve(web3, account)];

      createTransactionsQueue(contracts, {
        toastHook: approveTokenLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const stake = async (amount: number) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = [async () => stakeFunctions.stake(web3, account, amount)];

      createTransactionsQueue(contracts, {
        toastHook: stakeLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const stakeMax = async (amount: number) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = [async () => stakeFunctions.stakeMax(web3, account, amount)];

      createTransactionsQueue(contracts, {
        toastHook: stakeMaxLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const withdrawStaked = async (amount: number) => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = [async () => stakeFunctions.withdrawStaked(web3, account, amount)];

      createTransactionsQueue(contracts, {
        toastHook: withdrawStakedLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  const claimRewards = async () => {
    return new Promise<void>((resolve, reject) => {
      if (!web3) {
        reject();
        return;
      }

      const contracts = [async () => stakeFunctions.claimRewards(web3, account)];

      createTransactionsQueue(contracts, {
        toastHook: claimRewardsLoadingToast,
        onFinish: () => resolve(),
        onFail: () => reject(),
      });
    });
  };

  return {
    stake,
    approve,
    stakeMax,
    claimRewards,
    withdrawStaked,
  };
};
