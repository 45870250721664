import { useState } from 'react';
import { useUserContext } from '../../../contexts/UserContext';

export const useCompletedVestTile = (addyData: any) => {
  const [isClaimPending, setIsClaimPending] = useState(false);
  const { stakeFunctions } = useUserContext();

  const completedVestAmount =
    addyData.withdrawableWithNoPenalty / 10 ** 18 - addyData.unlockedBalance / 10 ** 18;

  const claim = () => {
    if (isClaimPending) return;
    setIsClaimPending(true);
    stakeFunctions
      .withdrawStaked(addyData.withdrawableWithNoPenalty)
      .finally(() => setIsClaimPending(false));
  };

  return {
    completedVestAmount,
    isClaimPending,
    claim,
  };
};
