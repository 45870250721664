import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'

class TokenIcons extends Component {
    render() {
        return (
            <div className="icon">
                {!this.props.pool.singleAsset && <div className="card-icon no-select">
                    <FontAwesomeIcon className="poolExpand" icon={this.props.expanded ? faMinusSquare : faPlusSquare} />
                    <div className="poolRowTokenLeft">
                        <div className="square">
                            <img src={"/img/" + this.props.pool.token0Name + ".png"} alt="token icon"></img>
                        </div>
                    </div>
                    <div className="poolRowTokenRight">
                        <div className="square">
                            <img src={"/img/" + this.props.pool.token1Name + ".png"} alt="token icon"></img>
                        </div>
                    </div>
                </div>}
                
                {this.props.pool.singleAsset && <div className="card-icon no-select">
                    <FontAwesomeIcon className="poolExpand" icon={this.props.expanded ? faMinusSquare : faPlusSquare} />
                    <div className="poolRowTokenLeft">
                        <div className="square">
                            <img src={"/img/" + this.props.pool.token0Name + ".png"} alt="token icon"></img>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default TokenIcons;