import React, { VFC } from 'react';
import VestingTimer from '../../components/VestingTimer/VestingTimer';
import VestingStatsTiles from '../../components/VestingStatsTiles/VestingStatsTiles';
import VestingInfoBox from '../../components/VestingInfoBox/VestingInfoBox';
import VestingGroupsInfoBox from '../../components/VestingGroupsInfoBox/VestingGroupsInfoBox';
import VestingTable from '../../components/VestingTable/VestingTable';
import VestingConnectWallet from '../../components/VestingConnectWallet/VestingConnectWallet';
import ComparisonSection from '../../components/ComparisonSection/ComparisonSection';
import { useUserContext } from '../../contexts/UserContext';
import styles from './VestPage.module.scss';

export interface StakePageProps {
  addyData: any;
}

const VestPage: VFC<StakePageProps> = ({ addyData }) => {
  const { isWalletConnected } = useUserContext();

  return (
    <div className={styles.container}>
      {isWalletConnected && (
        <div className={styles.timerSection}>
          <VestingTimer addyData={addyData} />
        </div>
      )}

      {isWalletConnected && (
        <div className={styles.statsTilesSection}>
          <VestingStatsTiles addyData={addyData} />
        </div>
      )}

      <div className={styles.vestingInfoboxSection}>
        <VestingInfoBox />
      </div>

      <VestingConnectWallet />

      <div className={styles.groupInfoboxSection}>
        <VestingGroupsInfoBox addyData={addyData} />
      </div>

      <VestingTable earningsData={addyData.earningsData} />

      <ComparisonSection vestingPage />
    </div>
  );
};

export default VestPage;
