import React, { useState, VFC } from 'react';
import dayjs from 'dayjs';

import Link from '../common/Link/Link';
import { getLpVaultAddress } from '../../api/polygon/AddyData';
import { useUserContext } from '../../contexts/UserContext';

import styles from './BoostLegacy.module.scss';

interface BoostLegacyProps {
  lockedAmount: number;
  pendingReward: number;
  endTimestamp: number;
}

const BoostLegacy: VFC<BoostLegacyProps> = ({ lockedAmount, pendingReward, endTimestamp }) => {
  const { boostLockFunctions } = useUserContext();
  const [isClaimPending, setIsClaimPending] = useState(false);

  const handleClaimLpBoost = () => {
    setIsClaimPending(true);
    boostLockFunctions.claim([{ vaultContractAddress: getLpVaultAddress() }]).finally(() => {
      setIsClaimPending(false);
    });
  };

  const hasLpBoostEnded = dayjs.unix(endTimestamp).isBefore(dayjs());

  if (!lockedAmount) return null;

  return (
    <div>
      <h4 className={styles.title}>LP Exchange Boost</h4>
      <p className={styles.subtitle}>
        ADDY locked in return of your participation in ADDY/WETH LP Vault
      </p>
      <p>
        {hasLpBoostEnded ? (
          <>
            Your locking period has completed! Claim all Rewards on{' '}
            <Link href="https://addy.finance/" newTab color="primary">
              Legacy Website
            </Link>
          </>
        ) : (
          <>
            If you&apos;ve invested in <b>ADDY/WETH LP Vault</b> your tokens have been exchanged for
            ADDY locked in LP Exchange Boost. You&apos;ll be able to withdraw all your ADDY when the
            lock is complete.
            <br />
            In the meantime, you will earn rewards in <b>ADDY</b> and <b>WMATIC</b>. Earned ADDY is
            auto-compounded to increase your <b>Boost</b> while WMATIC performance fees can be
            claimed immediately.
          </>
        )}
      </p>
      {!hasLpBoostEnded && (
        <div className={styles.bottomBar}>
          <span>
            <b>{pendingReward.toFixed(2)}</b> in WMATIC
          </span>
          <button
            className={styles.claimButton}
            type="button"
            disabled={pendingReward === 0 || isClaimPending}
            onClick={handleClaimLpBoost}
          >
            Claim
          </button>
        </div>
      )}
    </div>
  );
};

export default BoostLegacy;
