import { calculateAprAndApy, calculateAprAndApyDual } from '../../MathUtil.js'
import MASTER_CHEF_MULTICALL_ABI from '../../../abis/multicall/vaults/master_chef_multicall_arbitrum.json'
import ERC20_ABI from '../../../abis/ERC20abi.json'
import { isZero } from '../../../utils'
import { FEE } from '../../../constants';
import { getWETH } from '../../Multichain.js';
import { cacheCoingeckoPrice } from '../../external_json/CoingeckoData.js';
import { getAllocPoint, getTotalAllocPoint, getRewardPerBlock } from './cache/MasterChefDataCache.js';
import { cacheOnlyIfNumber, getPoolTVL, getTokenPrice } from '../../PriceCache';
import { getAvgBlockTime } from '../../BlockTime.js';

const MASTER_CHEF_MULTICALL_ADDRESS = "0xCC3ca8daB9637E867beC85071e74863120Ee11F9";
const SECONDS_PER_DAY = 86400;

function cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, poolJsonInfo) {
    let lpSupply = vaultInfo.lpSupply;
    let lpStaked = vaultInfo.lpStaked;
    let ratioStaked = lpStaked / lpSupply;

    cachedDecimals[vaultInfo.token0.toLowerCase()] = vaultInfo.decimals0;
    cachedDecimals[vaultInfo.token1.toLowerCase()] = vaultInfo.decimals1;

    let totalTokenValue = getPoolTVL(vaultInfo.token0, vaultInfo.token1, vaultInfo.token0Bal, vaultInfo.token1Bal, vaultInfo.decimals0, vaultInfo.decimals1);
    cacheOnlyIfNumber(poolJsonInfo.lpAddress, totalTokenValue / lpSupply * 1e18, 1, true);
    return [totalTokenValue, totalTokenValue * ratioStaked]
}

//have to deal with both Curve and Master Chef things at once, doesn't neatly fit into any existing class
const getMimPoolTvl = async (vaultInfo, poolJsonInfo) => {
    const CURVE_2POOL = "0xbF7E49483881C76487b0989CD7d9A8239B20CA41";
    const MIM = "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A";

    const curve2PoolContract = window.web3.eth.Contract(ERC20_ABI.abi, CURVE_2POOL);
    const mimContract = window.web3.eth.Contract(ERC20_ABI.abi, MIM);
    let num2Pool = await curve2PoolContract.methods.balanceOf(poolJsonInfo.lpAddress).call();
    let numMim = await mimContract.methods.balanceOf(poolJsonInfo.lpAddress).call();
    let virtualPrice2Pool = vaultInfo.rewardData[0].extra0;

    let lpSupply = vaultInfo.lpSupply;
    let lpStaked = vaultInfo.lpStaked;
    let ratioStaked = lpStaked / lpSupply;

    let val2Pool = num2Pool / 1e18 * virtualPrice2Pool / 1e18;
    let valMim = numMim * (getTokenPrice(MIM) != undefined ? getTokenPrice(MIM): 1) / 1e18
    let tvl = (valMim + val2Pool);

    let lpPrice = tvl / (lpSupply  / 1e18);
    //console.log(val2Pool, valMim)
    //console.log(tvl, lpPrice, tvl * ratioStaked)
    cacheOnlyIfNumber(poolJsonInfo.lpAddress, lpPrice, 1, true);

    return [tvl, tvl * ratioStaked]
}

let cachedSupply = {};
let cachedStaked = {};
let cachedDecimals = {};
let cachedTokens = {};

//Note: poolJsonInfo.poolAddress is different than poolJsonInfo.lpAddress
//poolJsonInfo.poolAddress is for the contract where the underlying tokens are (which is usually lpAddress in Uniswap forks)
//poolJsonInfo.lpAddress is for the receipt token being staked
const getDodoPoolTvl = async (vaultInfo, poolJsonInfo) => {
    const underlyingTokenContract = window.web3.eth.Contract(ERC20_ABI.abi, poolJsonInfo.underlying);
    let numToken = await underlyingTokenContract.methods.balanceOf(poolJsonInfo.poolAddress).call();
    //should cache decimals to reduce # of RPC calls
    let decimals;

    if(cachedDecimals[poolJsonInfo.underlying.toLowerCase()]) {
        decimals = cachedDecimals[poolJsonInfo.underlying.toLowerCase()];
    }
    else {
        decimals = await underlyingTokenContract.methods.decimals().call();
        cachedDecimals[poolJsonInfo.underlying.toLowerCase()] = decimals;
    }
    cachedTokens[poolJsonInfo.poolAddress.toLowerCase() + poolJsonInfo.underlying.toLowerCase()] = numToken;

    if(poolJsonInfo.underlying2) {
        const underlyingTokenContract2 = window.web3.eth.Contract(ERC20_ABI.abi, poolJsonInfo.underlying2);
        let numToken2;// = await underlyingTokenContract2.methods.balanceOf(poolJsonInfo.poolAddress).call();

        if(cachedTokens[poolJsonInfo.poolAddress.toLowerCase() + poolJsonInfo.underlying2.toLowerCase()]) {
            numToken2 = cachedTokens[poolJsonInfo.poolAddress.toLowerCase() + poolJsonInfo.underlying2.toLowerCase()];
        }
        else {
            numToken2 = await underlyingTokenContract2.methods.balanceOf(poolJsonInfo.poolAddress).call();
        }

        const lpContract2 = window.web3.eth.Contract(ERC20_ABI.abi, poolJsonInfo.lpAddress2);
        let lpSupply2 = 0;
        let lp2Staked = 0;

        //Use cached LP2 supply if the other half of the pair has cached it
        if(cachedSupply[poolJsonInfo.lpAddress2]) {
            lpSupply2 = cachedSupply[poolJsonInfo.lpAddress2];
        }
        else {
            lpSupply2 = await lpContract2.methods.totalSupply().call();
        }
        //Use cached LP2 amount staked if the other half of the pair has cached it
        if(cachedStaked[poolJsonInfo.lpAddress2]) {
            lp2Staked = cachedStaked[poolJsonInfo.lpAddress2];
        }
        else {
            lp2Staked = await lpContract2.methods.balanceOf(poolJsonInfo.stakingAddress).call();
        }

        cachedSupply[poolJsonInfo.lpAddress] = vaultInfo.lpSupply;
        cachedStaked[poolJsonInfo.lpAddress] = vaultInfo.lpStaked;

        let lpSupply = vaultInfo.lpSupply / 1 + lpSupply2 / 1;
        let lpStaked = vaultInfo.lpStaked / 1 + lp2Staked / 1;

        let ratioStaked = lpStaked / lpSupply;

        //need to handle different decimals
        let tvl = (numToken / 1 + numToken2 / 1) / (10 ** decimals);
        if(!poolJsonInfo.stablePair) {
            tvl *= getTokenPrice(poolJsonInfo.underlying);
        }

        let lpPrice = tvl / (lpSupply / 1e18);
        cacheOnlyIfNumber(poolJsonInfo.lpAddress, lpPrice, 1, true);
        //console.log(poolJsonInfo.nameOverride, tvl, lpSupply / 1, lpPrice)

        //set TVL to the amount in the LP token's side of the pool
        tvl = numToken / (10 ** decimals);

        return [tvl, tvl * ratioStaked]
    }
    else {
        let lpSupply = vaultInfo.lpSupply;
        let lpStaked = vaultInfo.lpStaked;
        let ratioStaked = lpStaked / lpSupply;

        let tvl = numToken / (10 ** decimals);
        if(!poolJsonInfo.stablePair) {
            tvl *= getTokenPrice(poolJsonInfo.underlying);
        }

        let lpPrice = tvl / (lpSupply / 1e18);
        cacheOnlyIfNumber(poolJsonInfo.lpAddress, lpPrice, 1, true);

        return [tvl, tvl * ratioStaked]
    }
}

export const getMasterChefDataArbitrum = async (web3, account, pools, stakingData, stakingDataMap, addyData, priceCache) => {

    const multicallContract = web3.eth.Contract(MASTER_CHEF_MULTICALL_ABI.abi, MASTER_CHEF_MULTICALL_ADDRESS)

    var poolAddresses = [];
    var poolz = [];

    //Rewards based on block time use ETH mainnet block time
    //let avgBlockTime = SECONDS_PER_DAY / 6700;

    /*try {
        avgBlockTime = await getAvgBlockTime();
    }
    catch {
        console.debug("Failed to load block time")
    }*/

    cacheCoingeckoPrice("dodo", "0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581", priceCache);

    for (let i = 0; i < pools.length; i++) {
        poolAddresses.push(pools[i].vaultAddress);

        //Multicall contract will fail to return data if I try to get too many vaults at once
        if(i > 0 && i % 15 === 0) {
            let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
            //console.log("Loaded " + poolAddresses.length + " vaults")
            poolz = poolz.concat(infoForPools);
            poolAddresses = [];
        }
    }

    if(poolAddresses.length > 0) {
        let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
        //console.log("Loaded " + poolAddresses.length + " vaults")
        poolz = poolz.concat(infoForPools);
    }

    for (let i = 0; i < pools.length; i++) {
        pools[i].vaultAddress = pools[i].vaultAddress.toLowerCase();
        try {
            const lpAddress = pools[i].lpAddress;

            let vaultInfo = poolz[i];

            let totalStaked = vaultInfo.totalStaked;
            let amountUserStaked = vaultInfo.amountUserStaked;
            let lpTokenApproved = vaultInfo.lpTokenApproved;
            let userLpBalance = vaultInfo.userLpBalance;
            let ratio = 0; //ratio of LP/vault tokens
            let allocPoint = pools[i].allocPoint;
            let totalAllocPoint = pools[i].totalAllocPoint;

            if (totalStaked > 0) {
                ratio = vaultInfo.ratio;
            }

            let poolTvl;
            let tvl;
            if(pools[i].platform === "curve") { //may need to handle multiple curve vaults
                let poolTvl_tvl = await getMimPoolTvl(vaultInfo, pools[i]);
                poolTvl = poolTvl_tvl[0]
                tvl = poolTvl_tvl[1]
            }
            else if(pools[i].platform === "dodo") {
                let poolTvl_tvl = await getDodoPoolTvl(vaultInfo, pools[i]);
                poolTvl = poolTvl_tvl[0]
                tvl = poolTvl_tvl[1]
                allocPoint = await getAllocPoint(pools[i]) / 1e18;
                totalAllocPoint = await getTotalAllocPoint(pools[i]) / 1e18;
            }
            else {
                let poolTvl_tvl = cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, pools[i]);
                poolTvl = poolTvl_tvl[0]
                tvl = poolTvl_tvl[1]
            }

            //Rewards based on block time use ETH mainnet block time
            let blocksPerDay = 6700;
            let harvestedToken = "ERROR";
            let pendingReward = vaultInfo.pendingReward * addyData.addyPerEth * (vaultInfo.rewardMultiplier / 1000);
            vaultInfo.totalPendingReward *= (vaultInfo.rewardMultiplier / 1000);
            let rewardPerBlock = 0;
            let rewardPerBlock2 = 0;
            let rewardTokenPrice = 0;
            let rewardTokenPrice2 = 0;

            if(vaultInfo.rewardData !== undefined && vaultInfo.rewardData.length > 0 && vaultInfo.rewardData[0].totalAllocPoint > 0) {
                totalAllocPoint = vaultInfo.rewardData[0].totalAllocPoint;
                allocPoint = vaultInfo.rewardData[0].allocPoint;
                rewardPerBlock = vaultInfo.rewardData[0].rewardPerBlock / 1e18;
            }

            if(pools[i].platform === "sushi") {
                rewardTokenPrice = getTokenPrice(pools[i].rewardToken);
                harvestedToken = pools[i].rewardTokenName;
                blocksPerDay = SECONDS_PER_DAY * rewardPerBlock; //sushi is reward/second

                if(pools[i].rewardToken2) {
                    rewardTokenPrice2 = getTokenPrice(pools[i].rewardToken2);
                    rewardPerBlock2 = vaultInfo.rewardData[1].rewardPerBlock / 1e18;
                    harvestedToken += " + " + pools[i].rewardTokenName2;
                }
            }
            else if(pools[i].platform === "curve") {
                rewardTokenPrice = getTokenPrice(pools[i].rewardToken);
                harvestedToken = pools[i].rewardTokenName;
                blocksPerDay = SECONDS_PER_DAY * rewardPerBlock;
            }
            else if(pools[i].platform === "dodo") {
                rewardPerBlock = await getRewardPerBlock(pools[i]);
                rewardTokenPrice = getTokenPrice(pools[i].rewardToken);
                harvestedToken = pools[i].rewardTokenName;
                blocksPerDay *= rewardPerBlock / 1e18;
            }

            let rewardsPerDay = blocksPerDay;
            if(totalAllocPoint > 0) {
                rewardsPerDay *= (allocPoint / totalAllocPoint);
            }

            let boost = vaultInfo.boost != null ? vaultInfo.boost / 1e18: 0;
            let aprApyData = calculateAprAndApy(rewardTokenPrice, rewardsPerDay, tvl, addyData, FEE, vaultInfo.rewardMultiplier * (1 + boost));
            if(rewardPerBlock2) {
                aprApyData = calculateAprAndApyDual(rewardTokenPrice, rewardsPerDay, rewardTokenPrice2, rewardPerBlock2 * 86400, tvl, addyData, FEE, vaultInfo.rewardMultiplier * (1 + boost));
            }

            const lpPrice = getTokenPrice(lpAddress.toLowerCase());

            let vaultData = {
                platform: pools[i].platform,
                exchange: pools[i].exchange,

                harvestedToken: harvestedToken,
                harvestTwice: pools[i].harvestTwice,
                customExchangeUrl: pools[i].customExchangeUrl,
                tooltip: formatTooltip(pools[i]),
                deprecatedYieldTooltipString: pools[i].deprecatedYieldTooltipString,
                showApr: pools[i].showApr,
                highlight: pools[i].highlight,
                degen: pools[i].degen,
                timestamp: pools[i].timestamp,

                addyRewards: pools[i].addyRewards,
                depositFee: pools[i].depositFee,
                singleAsset: pools[i].singleAsset,
                harvestBefore: pools[i].harvestBefore,
                nameOverride: pools[i].nameOverride,
                hiddenForNonStaked: pools[i].hiddenForNonStaked,
                depositDisabled: pools[i].depositDisabled,

                index: stakingData.length,
                token0Name: pools[i].token0Name,
                token1Name: pools[i].token1Name,
                token0: pools[i].token0,
                token1: pools[i].token1,
                lpAddress: pools[i].lpAddress.toLowerCase(),
                lpPrice: lpPrice,
                stakingAddress: pools[i].stakingAddress.toLowerCase(), //is this even used?
                vaultAddress: pools[i].vaultAddress,
                strategyAddress: pools[i].strategyAddress,
                deprecated: pools[i].deprecated,
                hidden: pools[i].hidden,
                rewardsPerDay: rewardsPerDay,
                forceShow: pools[i].forceShow,
                pendingReward: pendingReward,
                poolTvl: poolTvl,
                tvl: tvl,
                boost: boost,
                boostable: vaultInfo.boostable,

                //new post-Merlin variables
                rewardAllocation: vaultInfo.rewardAllocation,
                totalPendingReward: vaultInfo.totalPendingReward,
                withdrawPenaltyTime: vaultInfo.withdrawPenaltyTime,
                withdrawPenalty: vaultInfo.withdrawPenalty,

                totalStaked: totalStaked,
                valueTotalStaked: totalStaked * lpPrice,
                amountUserStaked: amountUserStaked,
                valueUserStaked: amountUserStaked * lpPrice,
                lpTokenApproved: lpTokenApproved,
                userLpBalance: userLpBalance,
                lastDepositTime: vaultInfo.lastDepositTime,
                harvestUntil: vaultInfo.harvestUntil,
                rewardMultiplier: vaultInfo.rewardMultiplier,

                baseApy: aprApyData.baseApy,
                addyTokenApy: aprApyData.addyTokenApy,
                addyFeeShareApy: aprApyData.addyFeeShareApy,
                totalAddyApy: aprApyData.totalAddyApy,
                apy: aprApyData.totalApy,

                baseApr: aprApyData.baseApr,
                addyTokenApr: aprApyData.addyTokenApr,
                aprProfitToStakers: aprApyData.aprProfitToStakers,
                totalAddyApr: aprApyData.totalAddyApr,
                apr: aprApyData.totalApr,

                ratio: ratio
            }
            stakingDataMap[pools[i].vaultAddress] = vaultData;
            stakingData.push(vaultData)
        }
        catch {
            console.error("Failed to load MasterChef pool: " + i)
            console.error(pools[i])
        }
    }
    return stakingData;
}

function formatTooltip(pool) {
    if(pool.tooltip == null) {
        return null;
    }
    return pool.tooltip;
}