import React, { FC, useCallback } from 'react';
import classnames from 'classnames';
import RadioButtonChecked from './radio-button-checked.svg';
import RadioButtonDefault from './radio-button-default.svg';
import styles from './RadioButton.module.scss';

interface RadioButtonProps {
  className?: string;
  value: string;
  checked: boolean;
  onChange?: (value: string) => void;
  onClick?: (value: string) => void;
}

const RadioButton: FC<RadioButtonProps> = ({
  className,
  children,
  value,
  checked,
  onChange,
  onClick,
}) => {
  const handleRadioButtonChange = useCallback(() => {
    if (onChange) {
      onChange(value);
    }
  }, [onChange, value]);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(value);
    }
  }, [onClick, value]);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={classnames(styles.container, className)}>
      <input
        type="radio"
        onChange={handleRadioButtonChange}
        checked={checked}
        onClick={handleOnClick}
      />
      <div className={styles.imageWrapper}>
        <img src={checked ? RadioButtonChecked : RadioButtonDefault} alt="" />
      </div>
      {children}
    </label>
  );
};

export default RadioButton;
