import Decimal from 'decimal.js';

export const currencyFormatter = (value: number | bigint, options?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    ...options,
  }).format(value);

export const truncateKey = (key: string) =>
  `${key.substring(0, 5)}…${key.substring(key.length - 4)}`;

export const formatLockDuration = (daysDuration: number) => {
  const yearsDuration = Math.floor(daysDuration / 365);

  if (yearsDuration < 1) {
    return `${daysDuration} ${daysDuration === 1 ? 'day' : 'days'}`;
  }

  const daysLeft = daysDuration % 365;

  if (daysLeft === 0) {
    return `${yearsDuration} ${yearsDuration === 1 ? 'year' : 'years'}`;
  }

  return `${yearsDuration} ${yearsDuration === 1 ? 'year' : 'years'} ${daysLeft} ${
    daysLeft === 1 ? 'day' : 'days'
  }`;
};

export const formatTokenAmount = (tokenAmount: number | string, precision: number = 8) => {
  const decimal = new Decimal(tokenAmount);
  if (decimal.toNumber() === 0 || decimal.isInt()) {
    return decimal.toFixed(2);
  }

  return Number(decimal.toPrecision(precision));
};

export const boostFormatter = (value: number) =>
  new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 3,
  }).format(value + 1);
