import Web3 from 'web3';
import { getDefaultGasPrice } from './GasHelper';
import ERC20ABI from '../../abis/ERC20abi.json';
import { getAdamantTokenAddress, getFeeDistAddress } from '../Multichain';
import FEE_DIST_ABI from '../../abis/fee_dist_abi.json';

export const approve = (web3: Web3, account: string) => {
  return new Promise<void>((resolve, reject) => {
    const contract = new web3.eth.Contract(ERC20ABI.abi as any[], getAdamantTokenAddress(), {
      gasPrice: getDefaultGasPrice(),
    } as any);

    contract.methods
      .approve(
        getFeeDistAddress(),
        '115792089237316195423570985008687907853269984665640564039457584007913129639935',
      )
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

/**
 * Accepts tokens amount and converts it to wei
 */
export const stake = (web3: Web3, account: string, amount: number) => {
  return new Promise<void>((resolve, reject) => {
    const contract = new web3.eth.Contract(FEE_DIST_ABI.abi as any[], getFeeDistAddress(), {
      gasPrice: getDefaultGasPrice(),
    } as any);

    const weiAmount = window.web3.utils.toWei(amount.toString(), 'ether');

    contract.methods
      .stake(weiAmount, false)
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

/**
 * Accepts tokens amount, only for investing all tokens
 */
export const stakeMax = (web3: Web3, account: string, amount: number) => {
  return new Promise<void>((resolve, reject) => {
    const contract = new web3.eth.Contract(FEE_DIST_ABI.abi as any[], getFeeDistAddress(), {
      gasPrice: getDefaultGasPrice(),
    } as any);

    contract.methods
      .stake(amount, false)
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const withdrawStaked = (web3: Web3, account: string, amount: number) => {
  return new Promise<void>((resolve, reject) => {
    const contract = new web3.eth.Contract(FEE_DIST_ABI.abi as any[], getFeeDistAddress(), {
      gasPrice: getDefaultGasPrice(),
    } as any);

    contract.methods
      .withdraw(amount)
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const claimRewards = (web3: Web3, account: string) => {
  return new Promise<void>((resolve, reject) => {
    const contract = new web3.eth.Contract(FEE_DIST_ABI.abi as any[], getFeeDistAddress(), {
      gasPrice: getDefaultGasPrice(),
    } as any);

    contract.methods
      .getReward()
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};
