import React, { VFC } from 'react';
import classnames from 'classnames';
import styles from './DurationButtons.module.scss';
import utilStyles from '../../../styles/utils.module.scss';
import { formatLockDuration } from '../../../_helpers';

interface DurationButtonsProps {
  value: number;
  options: number[];
  disabled: boolean;
  isCustomDuration: boolean;
  onDurationPickerOpen: () => void;
  isCustomDurationPickerOpen: boolean;
  onDurationPick: (duration: number) => void;
}

const DurationButtons: VFC<DurationButtonsProps> = ({
  options,
  value,
  disabled,
  isCustomDuration,
  onDurationPickerOpen,
  isCustomDurationPickerOpen,
  onDurationPick,
}) => {
  return (
    <div className={styles.container}>
      {options?.map((duration: number) => (
        <button
          type="button"
          key={duration}
          onClick={() => onDurationPick?.(duration)}
          disabled={disabled}
          className={classnames(
            styles.durationButton,
            duration === value && styles.selected,
            disabled && utilStyles.disabled,
          )}
        >
          {formatLockDuration(duration)}
        </button>
      ))}

      {options && options?.length > 1 && (
        <button
          type="button"
          disabled={disabled}
          onClick={onDurationPickerOpen}
          className={classnames(
            styles.durationButton,
            disabled && utilStyles.disabled,
            isCustomDuration && styles.selected,
            isCustomDurationPickerOpen && styles.active,
          )}
        >
          {isCustomDuration ? formatLockDuration(value ?? 0) : 'Custom'}
        </button>
      )}
    </div>
  );
};

export default DurationButtons;
