import MULTICALL_ABI from '../../../abis/multicall/vaults/staking_rewards_vault_multicall_arbitrum.json'

import { calculateAprAndApy } from '../../MathUtil.js'
import { isZero } from '../../../utils'
import { FEE } from '../../../constants';
import { getWETH } from '../../Multichain.js';
import { cacheOnlyIfNumber, getPoolTVL, getTokenPrice } from '../../PriceCache';

const VAULT_MULTICALL_ADDRESS = "0x6f0175486f070D7b00d1A0B52DE8F18A167A46f9"

function cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, poolJsonInfo) {
    let lpSupply = vaultInfo.lpSupply;
    let lpStaked = vaultInfo.lpStaked;
    let ratioStaked = lpStaked / lpSupply;

    let totalTokenValue = getPoolTVL(vaultInfo.token0, vaultInfo.token1, vaultInfo.token0Bal, vaultInfo.token1Bal, vaultInfo.decimals0, vaultInfo.decimals1);
    cacheOnlyIfNumber(poolJsonInfo.lpAddress, totalTokenValue / lpSupply * 1e18, 1, true);
    return [totalTokenValue, totalTokenValue * ratioStaked]
}

export const getStakingRewardsVaultDataArbitrum = async (web3, myAddress, pools, stakingData, stakingDataMap, addyData, priceCache) => {

    const multicallContract = web3.eth.Contract(MULTICALL_ABI.abi, VAULT_MULTICALL_ADDRESS)

    var poolAddresses = [];
    var poolz = [];

    for (let i = 0; i < pools.length; i++) {
        poolAddresses.push(pools[i].vaultAddress);

        //Multicall contract will fail to return data if I try to get too many vaults at once
        if(i > 0 && i % 15 === 0) {
            let infoForPools = await multicallContract.methods.getInfoForVaults(myAddress, poolAddresses).call();
            //console.log("Loaded " + poolAddresses.length + " vaults")
            poolz = poolz.concat(infoForPools);
            poolAddresses = [];
        }
    }

    if(poolAddresses.length > 0) {
        let infoForPools = await multicallContract.methods.getInfoForVaults(myAddress, poolAddresses).call();
        //console.log("Loaded " + poolAddresses.length + " vaults")
        poolz = poolz.concat(infoForPools);
    }

    for (let i = 0; i < pools.length; i++) {
        pools[i].vaultAddress = pools[i].vaultAddress.toLowerCase();
        const lpAddress = pools[i].lpAddress.toLowerCase();

        let vaultInfo = poolz[i];
        //console.log(vaultInfo)
        if(vaultInfo == null) {
            return [];
        }

        let amountUserStaked = vaultInfo.amountUserStaked;
        let totalStaked = vaultInfo.totalShares * vaultInfo.ratio / 1e18;
        let lpTokenApproved = vaultInfo.lpTokenApproved;
        let userLpBalance = vaultInfo.userLpBalance;
        let ratio = vaultInfo.ratio;

        let rewardTokenPrice = 0;
        let harvestedToken = "ERROR";
        let pendingReward = vaultInfo.pendingReward * addyData.addyPerEth * (vaultInfo.rewardMultiplier / 1000);
        vaultInfo.totalPendingReward *= (vaultInfo.rewardMultiplier / 1000);
        let rewardRate = 0;

        let poolTvl_tvl = cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, pools[i]);
        const poolTvl = poolTvl_tvl[0]
        const tvl = poolTvl_tvl[1]

        if(pools[i].platform === "swapr") {
            rewardTokenPrice = getTokenPrice(pools[i].rewardToken);
            harvestedToken = pools[i].rewardTokenName;
            rewardRate = vaultInfo.rewardData[0].amount / vaultInfo.rewardData[0].duration;
        }
        let rewardsPerDay = 86400 * rewardRate / 10 ** 18;
        if(vaultInfo.rewardData && vaultInfo.rewardData[0].periodFinish > 0 && Date.now() > vaultInfo.rewardData[0].periodFinish * 1000) {
            rewardsPerDay = 0;
        }

        let boost = vaultInfo.boost != null ? vaultInfo.boost / 1e18: 0;
        let aprApyData = calculateAprAndApy(rewardTokenPrice, rewardsPerDay, tvl, addyData, FEE, vaultInfo.rewardMultiplier * (1 + boost));
        const lpPrice = getTokenPrice(lpAddress);

        let vaultData = {
            platform: pools[i].platform,
            exchange: pools[i].exchange,

            //Variables only defined in the json
            nameOverride: pools[i].nameOverride,
            tooltip: formatTooltip(pools[i]),
            highlight: pools[i].highlight,
            forceShow: pools[i].forceShow,
            customExchangeUrl: pools[i].customExchangeUrl,
            deprecatedYieldString: pools[i].deprecatedYieldString,
            harvestTwice: pools[i].harvestTwice,
            showApr: pools[i].showApr,
            addyRewards: pools[i].addyRewards,
            timestamp: pools[i].timestamp,

            //Variables defined in this function
            index: stakingData.length,
            harvestedToken: harvestedToken,

            token0Name: pools[i].token0Name,
            token1Name: pools[i].token1Name,
            token0: pools[i].token0,
            token1: pools[i].token1,
            lpAddress: pools[i].lpAddress,
            lpPrice: lpPrice,
            vaultAddress: pools[i].vaultAddress,
            strategyAddress: pools[i].strategyAddress,
            deprecated: pools[i].deprecated,
            hidden: pools[i].hidden,
            rewardsPerDay: rewardsPerDay,
            poolTvl: poolTvl, //The TVL of the entire liquidity pool
            tvl: tvl, //The vault's TVL
            boost: boost,
            boostable: vaultInfo.boostable,

            pendingReward: pendingReward, //Amount of ADDY for user to claim
            lastDepositTime: vaultInfo.lastDepositTime,

            //new post-Merlin variables
            rewardAllocation: vaultInfo.rewardAllocation,
            totalPendingReward: vaultInfo.totalPendingReward,
            withdrawPenaltyTime: vaultInfo.withdrawPenaltyTime,
            withdrawPenalty: vaultInfo.withdrawPenalty,
            lastHarvestTime: vaultInfo.lastHarvestTime,

            totalStaked: totalStaked,
            valueTotalStaked: totalStaked * lpPrice,
            amountUserStaked: amountUserStaked,
            valueUserStaked: amountUserStaked * lpPrice,
            lpTokenApproved: lpTokenApproved,
            userLpBalance: userLpBalance,
            rewardMultiplier: vaultInfo.rewardMultiplier,

            baseApy: aprApyData.baseApy,
            addyTokenApy: aprApyData.addyTokenApy,
            addyFeeShareApy: aprApyData.addyFeeShareApy,
            totalAddyApy: aprApyData.totalAddyApy,
            apy: aprApyData.totalApy,

            baseApr: aprApyData.baseApr,
            addyTokenApr: aprApyData.addyTokenApr,
            aprProfitToStakers: aprApyData.aprProfitToStakers,
            totalAddyApr: aprApyData.totalAddyApr,
            apr: aprApyData.totalApr,

            ratio: ratio
        }
        stakingDataMap[pools[i].vaultAddress] = vaultData;
        stakingData.push(vaultData)
    }
    return stakingData;
}

function formatTooltip(pool) {
    if(pool.tooltip == null) {
        return null;
    }
    return pool.tooltip;
}
