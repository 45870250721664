import React, { VFC } from 'react';
import arrowUp from '@assets/icons/arrow-up.svg';
import { AnimatePresence, motion } from 'framer-motion';
import VaultTile from '../VaultTile/VaultTile';
import Button from '../../common/Button/Button';
import { RoutingPaths } from '../../../paths';
import styles from './BoostTile.module.scss';
import { useUserContext } from '../../../contexts/UserContext';
import BoostTileActions from './BoostTileActions/BoostTileActions';

interface Props {
  bgParticles: string;
  vaults: any[];
  addyData: any;
}

const BoostTile: VFC<Props> = ({ bgParticles, vaults, addyData }) => {
  const { isWalletConnected } = useUserContext();

  const dataLoaded = !!addyData;

  const lockedAmount = dataLoaded
    ? addyData.balanceOf_basic / 1e18 + addyData.userLocked_lockedStaking / 1e18
    : null;

  return (
    <VaultTile
      title="My boost"
      isConnected={isWalletConnected}
      connectDesc="to see your boost"
      bgParticles={bgParticles}
      buttonElement={
        dataLoaded && lockedAmount! > 0 ? (
          <Button
            color="primary"
            to={RoutingPaths.STAKE_ADDY}
            startEnhancer={<img src={arrowUp} alt="arrow up" />}
          >
            Increase
          </Button>
        ) : null
      }
    >
      <AnimatePresence exitBeforeEnter>
        {dataLoaded ? (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={styles.content}>
            <BoostTileActions addyData={addyData} vaults={vaults} lockedAmount={lockedAmount!} />
          </motion.div>
        ) : (
          <motion.p exit={{ opacity: 0 }} className={styles.loadingText}>
            Loading...
          </motion.p>
        )}
      </AnimatePresence>
    </VaultTile>
  );
};

export default BoostTile;
