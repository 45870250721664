import React, { VFC } from 'react';
import dayjs from 'dayjs';
import timeDiffYearsDays from '../../_helpers/timeDiffYearsDays';
import { getAdamantTokenName } from '../../api/Multichain';
import styles from './BoostTopTiles.module.scss';

interface BoostTopTilesProps {
  totalLockedAmount: number;
  nextLockEndDate: dayjs.Dayjs;
  boostMultiplier: string;
  boostPoints: string;
  boostedInvestments: string;
}

const BoostTopTiles: VFC<BoostTopTilesProps> = ({
  totalLockedAmount,
  nextLockEndDate,
  boostMultiplier,
  boostPoints,
  boostedInvestments,
}) => {
  const adamantTokenName = getAdamantTokenName();

  return (
    <div className={styles.container}>
      <div className={styles.tile}>
        <h4 className={styles.title}>Total Locked</h4>
        <p className={styles.text}>
          <b>{totalLockedAmount.toFixed(2)}</b> {adamantTokenName}
        </p>
        <p className={styles.subtext}>
          The next lock will end in {timeDiffYearsDays(nextLockEndDate)}
        </p>
        <div className={styles.background} />
      </div>

      <div className={styles.tile}>
        <h4 className={styles.title}>My Boost</h4>
        <p className={styles.text}>
          up to <b>{boostMultiplier}x</b>
        </p>
        <p className={styles.subtext}>Using {boostPoints} Boost Points</p>
        <div className={styles.background} />
      </div>

      <div className={styles.tile}>
        <h4 className={styles.title}>Boosted Vaults Investments</h4>
        <p className={styles.text}>
          <b>{boostedInvestments}</b>
        </p>
        <div className={styles.background} />
      </div>
    </div>
  );
};

export default BoostTopTiles;
