//For multi-chain functions related to the Adamant platform
//Might want to put contract addresses in a json file

import { NetworkID, WETH, getArbyTokenAddress, getAddyTokenAddress, getCaddyTokenAddress } from '../constants/index.js';
import { getPolygonFeeDistTvl, getAddyTotalSupply, getAddyDollarPrice, getLockedAddyVaultPlusAddress, getLockedAddyVaultBasicAddress } from './polygon/AddyData.js'
import { getPolygonTestnetFeeDistTvl, getTestnetDollarPrice, getTestnetTotalSupply, getTestnetTokenAddress, getTestnetLockedBasicAddress, getTestnetLockedPlusAddress } from './polygon_testnet/AddyData.js'
import { getArbitrumFeeDistTvl, getArbyTotalSupply, getArbyDollarPrice, getLockedArbyVaultBasicAddress } from './arbitrum/AddyData.js';
import { getCronosFeeDistTvl, getCaddyTotalSupply, getCaddyDollarPrice, getLockedCaddyVaultPlusAddress, getLockedCaddyVaultBasicAddress } from './cronos/AddyData.js';

export function getWETH() {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return WETH.ARBITRUM;
    }
    return WETH.POLYGON;
}

export const getAdamantTokenTotalSupply = () => {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return getArbyTotalSupply()
    }
    else if (window.networkId === NetworkID.POLYGON_NETWORK_ID) {
        return getAddyTotalSupply()
    }
    else if (window.networkId === NetworkID.POLYGON_TESTNET_ID) {
        return getTestnetTotalSupply()
    }
    else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return getCaddyTotalSupply()
    }
    return getAddyTotalSupply();
}

export const getAdamantTokenDollarPrice = () => {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return getArbyDollarPrice()
    }
    else if (window.networkId === NetworkID.POLYGON_NETWORK_ID) {
        return getAddyDollarPrice()
    }
    else if (window.networkId === NetworkID.POLYGON_TESTNET_ID) {
        return getTestnetDollarPrice()
    }
    else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return getCaddyDollarPrice()
    }
    return getAddyDollarPrice();
}

export function getAdamantTokenName() {
    switch (window.networkId) {
        case NetworkID.ARBITRUM_NETWORK_ID:
            return "ARBY";
        case NetworkID.CRONOS_NETWORK_ID:
            return "CADDY";
        case NetworkID.POLYGON_NETWORK_ID:
        case NetworkID.POLYGON_TESTNET_ID:
            return "ADDY";
        default:
            return "ADDY";
    }
}

export const getAdamantTokenAddress = () => {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return getArbyTokenAddress();
    }
    else if (window.networkId === NetworkID.POLYGON_NETWORK_ID) {
        return getAddyTokenAddress();
    }
    else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return getCaddyTokenAddress();
    }
    else if (window.networkId === NetworkID.POLYGON_TESTNET_ID) {
        return getTestnetTokenAddress()
    }
    return getAddyTokenAddress();
}

//Fee dist
export const getFeeDistAddress = () => {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return "0x097b15dC3Bcfa7D08ea246C09B6A9a778e5b007B";
    }
    else if (window.networkId === NetworkID.POLYGON_NETWORK_ID) {
        return "0x920f22E1e5da04504b765F8110ab96A20E6408Bd";
    }
    else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return "0x3f04D6bD50A79c854EF42965471D34E389eB5CDd";
    }
    else if (window.networkId === NetworkID.POLYGON_TESTNET_ID) {
        return "0x520bD22A62241172633CE6fC498383DD666E575d"
    }
    return "0x920f22E1e5da04504b765F8110ab96A20E6408Bd";
}

export const getFeeDistTvl = () => {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return getArbitrumFeeDistTvl()
    }
    else if (window.networkId === NetworkID.POLYGON_NETWORK_ID) {
        return getPolygonFeeDistTvl()
    }
    else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return getCronosFeeDistTvl()
    }
    else if (window.networkId === NetworkID.POLYGON_TESTNET_ID) {
        return getPolygonTestnetFeeDistTvl()
    }
    return getPolygonFeeDistTvl();
}

//Locked Staking
export function getLockedPlusAddress() {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return "0x0000000000000000000000000000000000000000";
    }
    else if (window.networkId === NetworkID.POLYGON_NETWORK_ID) {
        return getLockedAddyVaultPlusAddress()
    }
    else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return getLockedCaddyVaultPlusAddress();
    }
    else if (window.networkId === NetworkID.POLYGON_TESTNET_ID) {
        return getTestnetLockedPlusAddress();
    }
    return getLockedAddyVaultPlusAddress();
}

export function getLockedBasicAddress() {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return getLockedArbyVaultBasicAddress();
    }
    else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return getLockedCaddyVaultBasicAddress();
    }
    else if (window.networkId === NetworkID.POLYGON_TESTNET_ID) {
        return getTestnetLockedBasicAddress();
    }
    return getLockedAddyVaultBasicAddress();
}

//Boost Handler
export function getBoostHandlerAddress() {
    if (window.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return "0x28f673788AB53BE52F9D933Ca11b58D58b30E667";
    }
    else if (window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return "0xEEFF3c0Fa37C905fD00EE9E79006988137deEcD7";
    }
    return "0x6813cD04DBBa948cAfc3E0e7282BA3A53f949945";
}

export const getPerformanceFee = () => {
    if (window.networkId === NetworkID.POLYGON_NETWORK_ID) {
        return 0.029;
    }
    return 0.3;
}
