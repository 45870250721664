import React, { useEffect, useRef, useState, VFC } from 'react';
import dayjs from 'dayjs';
import multiTransactionIcon from '@assets/icons/multi-transaction.svg';
import styles from './BoostLockForm.module.scss';
import CustomDurationPicker from './CustomDurationPicker/CustomDurationPicker';
import DurationButtons from './DurationButtons/DurationButtons';
import Button from '../common/Button/Button';
import BoostCalculation from './BoostCalculation/BoostCalculation';
import { useUserContext } from '../../contexts/UserContext';
import { useBoostLockForm } from './useBoostLockForm';
import { getAdamantTokenName } from '../../api/Multichain';
import LockAmountInput from './LockAmountInput/LockAmountInput';
import BoostLockPlusInfoBox from '../BoostLockPlusInfoBox/BoostLockPlusInfoBox';

const calculateBoostMultiplier = (lockTimeSeconds: number, isBoostPlus: boolean) => {
  if (lockTimeSeconds < 0) return 0;

  const lockedStakeMaxMultiplier = 1000000; // 6 decimals of precision. 1x = 1000000
  const lockedStakeTimeForMaxMultiplier = 4 * 365 * 86400; // 4 years

  let multiplier = lockTimeSeconds / lockedStakeTimeForMaxMultiplier;
  if (multiplier > lockedStakeMaxMultiplier) multiplier = lockedStakeMaxMultiplier;
  if (!isBoostPlus) {
    multiplier /= 2;
  }

  return multiplier;
};

const getSubmitButtonText = (
  isLockPlus: boolean,
  isExtend: boolean,
  hasLockedBasic: boolean,
  hasLockedPlus: boolean,
) => {
  if (isExtend) {
    return 'Extend your lock';
  }

  if ((isLockPlus && hasLockedPlus) || (!isLockPlus && hasLockedBasic)) {
    return 'Increase your boost';
  }

  return 'Lock you ADDY';
};

interface LockPeriodPickerProps {
  isLockPlus: boolean;
  userBalance: number;
  isLockPlusTokenApproved: boolean;
  isLocBasicTokenApproved: boolean;
  userLockedBasic?: number;
  userLockedPlus?: number;
  basicLockEndTimestamp?: number;
  plusLockEndTimestamp?: number;
  onTouched: () => void;
  userBoostPointsBasic: number;
  userBoostPointsPlus: number;
  onGoToLockPlus: () => void;
}

const BoostLockForm: VFC<LockPeriodPickerProps> = ({
  isLockPlus,
  userBalance,
  userLockedBasic,
  userLockedPlus,
  isLockPlusTokenApproved,
  isLocBasicTokenApproved,
  basicLockEndTimestamp,
  plusLockEndTimestamp,
  onTouched,
  userBoostPointsBasic,
  userBoostPointsPlus,
  onGoToLockPlus,
}) => {
  const {
    isExtend,
    hasLockedBasic,
    hasLockedPlus,

    lockDurationOptions,
    isTokenApproved,

    lockAmount,
    setLockAmount,
    lockDuration,
    setLockDuration,

    handleFormSubmit,
    isSubmitted,
    isSubmitting,
    isSubmitSuccessful,
    isFormValid,
    formErrors,
  } = useBoostLockForm(
    isLockPlus,
    userBalance,
    isLockPlusTokenApproved,
    isLocBasicTokenApproved,
    basicLockEndTimestamp,
    plusLockEndTimestamp,
    userLockedBasic,
    userLockedPlus,
  );

  const { isWalletConnected } = useUserContext();

  const [isCustomDurationPickerOpen, setIsCustomDurationPickerOpen] = useState(false);
  const [isCustomDuration, setIsCustomValue] = useState(false);
  const [customDuration, setCustomDuration] = useState(lockDurationOptions[0]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    onTouched();
  }, [
    onTouched,
    isCustomDurationPickerOpen,
    isCustomDuration,
    customDuration,
    lockDuration,
    lockAmount,
    isSubmitted,
  ]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setIsCustomValue(false);
      setCustomDuration(() => lockDurationOptions[0]);
    }
  }, [isSubmitSuccessful, lockDurationOptions]);

  const handleDurationPick = (duration: number) => {
    setIsCustomValue(false);
    setCustomDuration(lockDurationOptions[0]);
    setIsCustomDurationPickerOpen(false);
    setLockDuration?.(duration);
  };

  const handleCustomDurationSubmit = (duration: number) => {
    setIsCustomValue(true);
    setIsCustomDurationPickerOpen(false);
    setCustomDuration(duration);
    setLockDuration?.(duration);
  };

  const handleCustomDurationCancel = () => {
    setIsCustomDurationPickerOpen(false);
  };

  const handleCustomDurationOpen = () => {
    setIsCustomDurationPickerOpen(true);
  };

  const lockDurationSeconds = dayjs.duration(lockDuration ?? 0, 'days').asSeconds();
  const boostValue = calculateBoostMultiplier(lockDurationSeconds, isLockPlus);
  const boostPoints = Number(lockAmount) * boostValue;

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={styles.lockAmountInputSection}>
        <LockAmountInput
          balance={userBalance.toString()}
          lockAmount={lockAmount}
          disabled={isSubmitting}
          setLockAmount={setLockAmount}
          totalLocked={isLockPlus ? userLockedPlus : userLockedBasic}
          errorMsg={formErrors?.lockAmount?.message}
        />
      </div>

      <div className={styles.buttonsSection}>
        <DurationButtons
          disabled={isSubmitting}
          value={lockDuration ?? 0}
          options={lockDurationOptions}
          isCustomDuration={isCustomDuration}
          onDurationPickerOpen={handleCustomDurationOpen}
          isCustomDurationPickerOpen={isCustomDurationPickerOpen}
          onDurationPick={handleDurationPick}
        />
      </div>

      {isCustomDurationPickerOpen && (
        <div className={styles.customDurationPickerSection}>
          <CustomDurationPicker
            value={customDuration}
            min={lockDurationOptions[0] ?? 0}
            max={lockDurationOptions[lockDurationOptions.length - 1] ?? 0}
            onSubmit={(duration: number) => handleCustomDurationSubmit(duration)}
            onCancel={handleCustomDurationCancel}
          />
        </div>
      )}

      {formErrors?.lockDuration?.message && (
        <p className={styles.lockDurationError}>
          Choose the {getAdamantTokenName()} duration you want to lock
        </p>
      )}

      <div className={styles.boostCalculationSection}>
        <BoostCalculation boostPoints={boostPoints} />
      </div>

      {Boolean(lockAmount) && Boolean(lockDuration) && (
        <BoostLockPlusInfoBox
          addyToLock={(
            Number(lockAmount) + (isLockPlus ? userLockedPlus || 0 : userLockedBasic || 0)
          ).toFixed(2)}
          lockEndTimestamp={Math.max(
            isLockPlus ? plusLockEndTimestamp || 0 : basicLockEndTimestamp || 0,
            dayjs()
              .add(lockDuration || 0, 'days')
              .add(1, 'second')
              .unix(),
          )}
          userBoostPointsBasic={userBoostPointsBasic}
          userBoostPointsPlus={userBoostPointsPlus}
          isLockPlus={isLockPlus}
          newBoostPoints={boostPoints}
          onGoToLockPlus={onGoToLockPlus}
        />
      )}

      <div className={styles.submitSection}>
        <Button
          type="submit"
          color="primary"
          startEnhancer={
            isWalletConnected && !isTokenApproved && <img src={multiTransactionIcon} alt="" />
          }
          disabled={isCustomDurationPickerOpen || isSubmitting || (isSubmitted && !isFormValid)}
        >
          {isWalletConnected
            ? getSubmitButtonText(isLockPlus, isExtend, hasLockedBasic, hasLockedPlus)
            : 'Connect your wallet'}
        </Button>
      </div>
    </form>
  );
};

export default BoostLockForm;
