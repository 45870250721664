import React, { useEffect, useRef, useState, VFC } from 'react';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import FooterBrand from './FooterBrand/FooterBrand';
import FooterSocials from './FooterSocials/FooterSocials';
import FooterFeatured from './FooterFeatured/FooterFeatured';
import FooterLinks from './FooterLinks/FooterLinks';
import { RoutingPaths } from '../../paths';

import dropdownIcon from './dropdown-icon.svg';

import styles from './Footer.module.scss';

const Footer: VFC = () => {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const scrollAnchor = useRef<HTMLSpanElement>(null);
  const location = useLocation();

  const isLandingPage = location.pathname === RoutingPaths.LANDING_PAGE;

  const handleToggleExpand = () => {
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded && scrollAnchor.current) {
      smoothScrollIntoView(scrollAnchor.current, { duration: 1000 });
    }
  }, [isExpanded]);

  return (
    <footer className={classNames(styles.footer, !isLandingPage && styles.notLandingPage)}>
      <div className={styles.footerContent}>
        <div className={styles.footerMainContent}>
          <div className={styles.brand}>
            <FooterBrand />
          </div>

          <div className={styles.featured}>
            <FooterFeatured />
          </div>

          <div className={styles.links}>
            <FooterLinks />
          </div>
        </div>

        <div className={styles.socials}>
          <FooterSocials />

          <button type="button" className={styles.expandBtn} onClick={handleToggleExpand}>
            <img
              className={classNames(styles.expandBtnIcon, isExpanded && styles.expandBtnIconActive)}
              src={dropdownIcon}
              alt=""
            />
          </button>
        </div>

        {isExpanded && (
          <div className={styles.expandedContent}>
            <FooterFeatured />
            <FooterLinks />
          </div>
        )}
      </div>

      <span ref={scrollAnchor} />
    </footer>
  );
};

export default Footer;
