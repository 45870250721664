import React, { createContext, useContext, FC, useReducer, useCallback } from 'react';

export enum SortValuesEnum {
  TVL = 'Total Value Locked',
  APY = 'APY',
  MY_INVESTMENTS = 'My Investments',
  VAULT_NAME = 'Vault Name',
  REWARDS = 'Rewards',
  RELEASE_TIMESTAMP = 'Release Date',
}

type DirectionType = 'ASC' | 'DESC';

export type SortState = {
  value: SortValuesEnum;
  direction: DirectionType;
};

export type VaultsFiltersState = {
  token: string[];
  platform: string[];
  status: [string];
  boostable: boolean;
  degenerate: boolean;
  stablecoins: boolean;
  sort: SortState;
};

type VaultsFiltersActions = {
  field?: 'token' | 'platform' | 'status' | 'boostable' | 'degenerate' | 'stablecoins';
  type?: 'add' | 'remove' | 'clear' | 'clearAll' | 'sort';
  payload?: string;
};

const initialFiltersState: VaultsFiltersState = {
  token: [],
  platform: [],
  status: ['all'],
  boostable: false,
  degenerate: false,
  stablecoins: false,
  sort: {
    value: SortValuesEnum.TVL,
    direction: 'DESC',
  },
};

const reverseSortDirection = (direction: 'ASC' | 'DESC'): DirectionType => {
  if (direction === 'ASC') {
    return 'DESC';
  }
  return 'ASC';
};

const filtersReducer = (
  state: VaultsFiltersState,
  action: VaultsFiltersActions,
): VaultsFiltersState => {
  // Mutliple choice select
  if (action.field === 'token' || action.field === 'platform') {
    const currentField = action.field;

    if (action.type === 'remove') {
      return {
        ...state,
        [currentField]: state[currentField].filter((val) => val !== action.payload),
      };
    }
    if (action.type === 'add' && action.payload) {
      return { ...state, [currentField]: [...state[currentField], action.payload] };
    }
    if (action.type === 'clear') {
      return { ...state, [currentField]: [] };
    }
  }
  // Single choice select
  if (action.field === 'status' && action.payload) {
    return { ...state, [action.field]: [action.payload] };
  }
  // Toggles
  if (
    action.field === 'boostable' ||
    action.field === 'degenerate' ||
    action.field === 'stablecoins'
  ) {
    return { ...state, [action.field]: !state[action.field] };
  }
  // Clear all filters
  if (action.type === 'clearAll') {
    return initialFiltersState;
  }
  // Sort
  if (
    action.type === 'sort' &&
    action.payload &&
    Object.values(SortValuesEnum).includes(action.payload as SortValuesEnum)
  ) {
    const newDirection =
      state.sort.value === action.payload ? reverseSortDirection(state.sort.direction) : 'DESC';
    return { ...state, sort: { value: action.payload as SortValuesEnum, direction: newDirection } };
  }
  return state;
};

export const useVaultsFiltersController = () => {
  const [filtersState, dispatch] = useReducer(filtersReducer, initialFiltersState);

  const { token, status, boostable, degenerate } = filtersState;

  const isFiltered = token.length > 0 || status[0] !== 'all' || boostable || degenerate;

  const createOnMultipleSelectChange = useCallback(
    (field: 'token' | 'platform') => (value: string, isChecked: boolean) => {
      dispatch({ type: isChecked ? 'add' : 'remove', field, payload: value });
    },
    [],
  );

  const createOnClearMultipleSelect = useCallback(
    (field: 'token' | 'platform') => () => {
      dispatch({ type: 'clear', field });
    },
    [],
  );

  const createOnSelectChange = useCallback(
    (field: 'status') => (value: string) => {
      dispatch({ field, payload: value });
    },
    [],
  );

  const createOnToggleFilterChange = useCallback(
    (field: 'boostable' | 'degenerate' | 'stablecoins') => () => {
      dispatch({ field });
    },
    [],
  );

  const onClearAllFilters = useCallback(() => {
    dispatch({ type: 'clearAll' });
  }, []);

  const onSortClick = useCallback((value: string) => {
    dispatch({ type: 'sort', payload: value });
  }, []);

  return {
    filtersState,
    isFiltered,
    createOnMultipleSelectChange,
    createOnClearMultipleSelect,
    createOnSelectChange,
    createOnToggleFilterChange,
    onClearAllFilters,
    onSortClick,
  } as const;
};

export const VaultsFiltersContext = createContext<ReturnType<
  typeof useVaultsFiltersController
> | null>(null);

export const useVaultsFiltersContext = () => {
  const value = useContext(VaultsFiltersContext);
  if (value === null) {
    throw new Error(
      `[Vaults Filters Context] useVaultsFiltersContext must be called within VaultsFiltersContext tree`,
    );
  }
  return value;
};

export const VaultsFiltersContextProvider: FC = ({ children }) => {
  const contextController = useVaultsFiltersController();

  return (
    <VaultsFiltersContext.Provider value={contextController}>
      {children}
    </VaultsFiltersContext.Provider>
  );
};
