import React, { useState, VFC } from 'react';
import arrowUp from '@assets/icons/arrow-up.svg';
import { Link } from 'react-router-dom';

import styles from './VaultCardBodyStats.module.scss';
import { useUserContext } from '../../../../../contexts/UserContext';
import { RoutingPaths } from '../../../../../paths';
import { getAdamantTokenName } from '../../../../../api/Multichain';
import { useDailyVolume } from '../../../../../api/GraphApi';
import ToggleSwitch from '../../../../ToggleSwitch/ToggleSwitch';

interface Props {
  vault: any;
}

const VaultCardBodyStats: VFC<Props> = ({ vault }) => {
  const { isWalletConnected } = useUserContext();
  const dailyVolume = useDailyVolume(vault);

  const [showApy, setShowApy] = useState(true);

  const toggleApy = (checked: boolean) => {
    setShowApy(checked);
  };

  const apyWithFee = vault.apy + (dailyVolume.lpApyFee || 0);

  return (
    <div className={styles.container}>
      <div className={styles.aprApySection}>
        <ToggleSwitch
          size="large"
          checked={showApy}
          onChange={toggleApy}
          checkedText="APY"
          uncheckedText="APR"
        />
        <p className={styles.aprApyText}>{(showApy ? apyWithFee : vault.apr).toFixed(2)}%</p>
      </div>

      <div className={styles.statsGroup}>
        <p className={styles.statsGroupValue}>{vault.baseApr.toFixed(2)}%</p>
        <p className={styles.statsGroupText}>Base Vault APR</p>
      </div>

      {vault.addyRewards && (
        <div className={styles.statsGroup}>
          <p className={styles.statsGroupValue}>
            {(vault.baseApy + vault.baseApy * vault.boost).toFixed(2)}%
          </p>
          <p className={styles.statsGroupRewardsText}>{getAdamantTokenName()} Rewards</p>
          {isWalletConnected && (
            <div className={styles.statsGroupRewardsExtra}>
              <p className={styles.statsGroupRewardsExtraBoost}>
                {(vault.boost + 1).toFixed(2)}x Boost
              </p>
              <Link to={RoutingPaths.STAKE_ADDY} className={styles.statsGroupRewardsExtraButton}>
                <img src={arrowUp} alt="" />
                <span>Increase</span>
              </Link>
            </div>
          )}
        </div>
      )}

      {showApy && (
        <div className={styles.statsGroup}>
          <p className={styles.statsGroupValue}>
            {dailyVolume.isLoading && 'Loading ...'}
            {dailyVolume.isSuccess ? `${dailyVolume.lpApyFee?.toFixed(2)}%` : 'No data'}
          </p>
          <p className={styles.statsGroupRewardsText}>LP swap fee APY</p>
        </div>
      )}
    </div>
  );
};

export default VaultCardBodyStats;
