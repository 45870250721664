//Various math utility functions shared between classes

//APY from vested ADDY fee sharing, based on sum of arithmetic sequence formula (1/365, 2/365, ... , 365/365)
const ADDY_FEE_SHARE_CONSTANT = 0.50136986301369863013698630136986;

export const getCompoundingAPY = (tvl, rewardTokenPrice, dailyRewardAmount, fee, compoundFreq = 0) => {
    if (tvl === 0) return 0;
    if (compoundFreq <= 0) compoundFreq = 365 * 48;
    //daily reward rate * price * 365 / TVL
    let apr = dailyRewardAmount * rewardTokenPrice * 365 / tvl * (1 - fee);

    return 100 * (Math.pow(1 + apr / compoundFreq, compoundFreq) - 1); //rewardRate * price * 365 * 100 / tvl;
};


export const calculateAprAndApy = (rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, rewardMultiplier = 1000) => {
    if (tvl === 0) return {
        baseApy: 0,
        addyTokenApy: 0,
        addyFeeShareApy: 0,
        totalAddyApy: 0,
        totalApy: 0,

        baseApr: 0,
        aprProfitToStakers: 0,
        addyTokenApr: 0,
        totalAddyApr: 0,
        totalApr: 0
    }

    let apr = rewardTokenPrice * rewardsPerDay * 365.0 * 100 / tvl;
    const baseApr = apr * (1 - fee);
    const aprProfitToStakers = apr * fee;

    //Temporary while refactoring addyEthPrice references to addyNativePrice
    let addyNativePrice = addyData.addyNativePrice !== undefined ? addyData.addyNativePrice: addyData.addyEthPrice;

    const addyTokenApr = apr * fee * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    const addyFeeShareApr = addyTokenApr * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    const totalAddyApr = addyTokenApr + addyFeeShareApr;
    const totalApr = baseApr + addyTokenApr;

    //APY after fee
    const baseApy = getCompoundingAPY(tvl, rewardTokenPrice, rewardsPerDay, fee);
    //APY from ADDY emissions = fee * addy eth price * addy emitted per eth in fees
    const addyTokenApy = getCompoundingAPY(tvl, rewardTokenPrice, rewardsPerDay, 1 - fee) * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    //APY from vested ADDY fee sharing, based on sum of arithmetic sequence formula (1/365, 2/365, ... , 365/365)
    const addyFeeShareApy = addyTokenApy * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    //Total APY from the ADDY token
    const totalAddyApy = addyTokenApy + addyFeeShareApy;
    //Total APY
    const totalApy = baseApy + totalAddyApy;

    return {
        baseApy: baseApy,
        addyTokenApy: addyTokenApy,
        addyFeeShareApy: addyFeeShareApy,
        totalAddyApy: totalAddyApy,
        totalApy: totalApy,

        baseApr: baseApr,
        aprProfitToStakers: aprProfitToStakers,
        addyTokenApr: addyTokenApr,
        totalAddyApr: totalAddyApr,
        totalApr: totalApr
    }
}


export const calculateAprAndApyDual = (rewardTokenPriceA, rewardsPerDayA, rewardTokenPriceB, rewardsPerDayB, tvl, addyData, fee, rewardMultiplier = 1000) => {
    if (tvl === 0) return 0;

    let apr = (rewardTokenPriceA * rewardsPerDayA + rewardTokenPriceB * rewardsPerDayB) * 365.0 * 100 / tvl;
    const baseApr = apr * (1 - fee);
    const aprProfitToStakers = apr * fee;

    //Temporary while refactoring addyEthPrice references to addyNativePrice
    let addyNativePrice = addyData.addyNativePrice !== undefined ? addyData.addyNativePrice: addyData.addyEthPrice;

    const addyTokenApr = apr * fee * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    const addyFeeShareApr = addyTokenApr * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    const totalAddyApr = addyTokenApr + addyFeeShareApr;
    const totalApr = baseApr + addyTokenApr;

    //APY after fee
    const baseApy = getCompoundingAPY(tvl, rewardTokenPriceA, rewardsPerDayA, fee) + getCompoundingAPY(tvl, rewardTokenPriceB, rewardsPerDayB, fee);
    //APY from ADDY emissions = fee * addy eth price * addy emitted per eth in fees
    const addyTokenApy = (getCompoundingAPY(tvl, rewardTokenPriceA, rewardsPerDayA, 1 - fee) + getCompoundingAPY(tvl, rewardTokenPriceB, rewardsPerDayB, 1 - fee)) * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    //APY from vested ADDY fee sharing, based on sum of arithmetic sequence formula (1/365, 2/365, ... , 365/365)
    const addyFeeShareApy = addyTokenApy * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    //Total APY from the ADDY token
    const totalAddyApy = addyTokenApy + addyFeeShareApy;
    //Total APY
    const totalApy = baseApy + totalAddyApy;

    return {
        baseApy: baseApy,
        addyTokenApy: addyTokenApy,
        addyFeeShareApy: addyFeeShareApy,
        totalAddyApy: totalAddyApy,
        totalApy: totalApy,

        baseApr: baseApr,
        aprProfitToStakers: aprProfitToStakers,
        addyTokenApr: addyTokenApr,
        totalAddyApr: totalAddyApr,
        totalApr: totalApr
    }
}


export const calculateAprAndApyWithDepositFee = (rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, depositFee, rewardMultiplier = 1000) => {
    let apr = rewardTokenPrice * rewardsPerDay * 365.0 * 100 / tvl * (1 - depositFee);
    const baseApr = apr * (1 - fee);
    const aprProfitToStakers = apr * fee;

    //Temporary while refactoring addyEthPrice references to addyNativePrice
    let addyNativePrice = addyData.addyNativePrice !== undefined ? addyData.addyNativePrice: addyData.addyEthPrice;

    const addyTokenApr = apr * fee * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    const addyFeeShareApr = addyTokenApr * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    const totalAddyApr = addyTokenApr + addyFeeShareApr;
    const totalApr = baseApr + addyTokenApr;

    //APY after fee
    const baseApy = getCompoundingAPY(tvl, rewardTokenPrice, rewardsPerDay, fee + depositFee);
    //APY from ADDY emissions = fee * addy eth price * addy emitted per eth in fees
    const addyTokenApy = getCompoundingAPY(tvl, rewardTokenPrice, rewardsPerDay, 1 - fee) * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000) * (1 - depositFee);
    //APY from vested ADDY fee sharing, based on sum of arithmetic sequence formula (1/365, 2/365, ... , 365/365)
    const addyFeeShareApy = addyTokenApy * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    //Total APY from the ADDY token
    const totalAddyApy = addyTokenApy + addyFeeShareApy;
    //Total APY
    const totalApy = baseApy + totalAddyApy;

    return {
        baseApy: baseApy,
        addyTokenApy: addyTokenApy,
        addyFeeShareApy: addyFeeShareApy,
        totalAddyApy: totalAddyApy,
        totalApy: totalApy,

        baseApr: baseApr,
        aprProfitToStakers: aprProfitToStakers,
        addyTokenApr: addyTokenApr,
        totalAddyApr: totalAddyApr,
        totalApr: totalApr
    }
}

//#region new functions

export const _calculateAprAndApyWithCompoundingADDY = (rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, rewardMultiplier) => {
    if (tvl === 0) return {
        baseApy: 0,
        addyTokenApy: 0,
        addyFeeShareApy: 0,
        totalAddyApy: 0,
        totalApy: 0,

        baseApr: 0,
        aprProfitToStakers: 0,
        addyTokenApr: 0,
        totalAddyApr: 0,
        totalApr: 0
    }

    let apr = rewardTokenPrice * rewardsPerDay * 365.0 * 100 / tvl;
    const baseApr = apr * (1 - fee);
    const aprProfitToStakers = apr * fee;

    //Temporary while refactoring addyEthPrice references to addyNativePrice
    let addyNativePrice = addyData.addyNativePrice !== undefined ? addyData.addyNativePrice: addyData.addyEthPrice;

    let earlyWithdrawPenalty = 0.5;
    const addyTokenApr = apr * fee * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    const totalAddyApr = addyTokenApr;
    const totalApr = baseApr + addyTokenApr;
    const totalAprWithPenalty = baseApr + addyTokenApr * earlyWithdrawPenalty;

    let compoundFreq = 365 * 6;
    let totalApy = 100 * (Math.pow(1 + totalAprWithPenalty / 100 / compoundFreq, compoundFreq) - 1); //rewardRate * price * 365 * 100 / tvl;
    //let totalAddyApy = totalApy;

    //APY with fee sharing, which might be higher than APY with taking the penalty + compounding
    const baseApy = getCompoundingAPY(tvl, rewardTokenPrice, rewardsPerDay, fee);
    //APY from ADDY emissions = fee * addy eth price * addy emitted per eth in fees
    const addyTokenApy = getCompoundingAPY(tvl, rewardTokenPrice, rewardsPerDay, 1 - fee) * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    //APY from vested ADDY fee sharing, based on sum of arithmetic sequence formula (1/365, 2/365, ... , 365/365)
    const addyFeeShareApy = addyTokenApy * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    //Total APY from the ADDY token
    const totalAddyApy = addyTokenApy + addyFeeShareApy;
    //Total APY
    const totalApyWithFeeSharing = baseApy + totalAddyApy;

    return {
        //baseApy: baseApy,
        //addyTokenApy: addyTokenApy,
        //addyFeeShareApy: 0,
        //totalAddyApy: totalAddyApy,
        totalApy: totalApy,
        totalApyWithFeeSharing: totalApyWithFeeSharing,

        baseApr: baseApr,
        aprProfitToStakers: aprProfitToStakers,
        addyTokenApr: addyTokenApr,
        totalAddyApr: totalAddyApr,
        totalApr: totalApr
    }
}

//Calculates both min and max APR/APY for that vault, along with the user's APR/APY
export const calculateMinMaxAprAndApy = (rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, rewardMultiplier, baseRewardMultiplier = 1000) => {
    if (tvl === 0) return {
        baseApy: 0,
        addyTokenApy: 0,
        addyFeeShareApy: 0,
        totalAddyApy: 0,
        totalApy: 0,

        baseApr: 0,
        aprProfitToStakers: 0,
        addyTokenApr: 0,
        totalAddyApr: 0,
        totalApr: 0
    }

    let apr = rewardTokenPrice * rewardsPerDay * 365.0 * 100 / tvl;
    const baseApr = apr * (1 - fee);
    const aprProfitToStakers = apr * fee;

    //Temporary while refactoring addyEthPrice references to addyNativePrice
    let addyNativePrice = addyData.addyNativePrice !== undefined ? addyData.addyNativePrice: addyData.addyEthPrice;

    const minAprApyData = _calculateAprAndApyWithCompoundingADDY(rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, baseRewardMultiplier)
    const maxAprApyData = _calculateAprAndApyWithCompoundingADDY(rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, baseRewardMultiplier * 2)
    const userAprApyData = _calculateAprAndApyWithCompoundingADDY(rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, rewardMultiplier)

    const addyTokenApr = apr * fee * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    const addyFeeShareApr = addyTokenApr * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    const totalAddyApr = addyTokenApr + addyFeeShareApr;
    const totalApr = baseApr + addyTokenApr;

    //APY after fee
    const baseApy = getCompoundingAPY(tvl, rewardTokenPrice, rewardsPerDay, fee);
    //APY from ADDY emissions = fee * addy eth price * addy emitted per eth in fees
    const addyTokenApy = getCompoundingAPY(tvl, rewardTokenPrice, rewardsPerDay, 1 - fee) * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    //APY from vested ADDY fee sharing, based on sum of arithmetic sequence formula (1/365, 2/365, ... , 365/365)
    const addyFeeShareApy = addyTokenApy * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    //Total APY from the ADDY token
    const totalAddyApy = addyTokenApy + addyFeeShareApy;
    //Total APY
    const totalApy = baseApy + totalAddyApy;

    return {
        minAprApyData: minAprApyData,
        maxAprApyData: maxAprApyData,
        userAprApyData: userAprApyData,

        baseApy: baseApy,
        addyTokenApy: addyTokenApy,
        addyFeeShareApy: addyFeeShareApy,
        totalAddyApy: totalAddyApy,
        totalApy: totalApy,

        baseApr: baseApr,
        aprProfitToStakers: aprProfitToStakers,
        addyTokenApr: addyTokenApr,
        totalAddyApr: totalAddyApr,
        totalApr: totalApr
    }
}

//Calculates APR for a maximizer vault, along with the user's APR/APY
export const calculateMaximizerVaultAprAndApy = (rewardTokenPrice, rewardsPerDay, singleStakeRewardsPerDay, tvl, singleStakeTvl, addyData, fee, rewardMultiplier, baseRewardMultiplier = 1000) => {
    if (tvl === 0) return {
        baseApy: 0,
        addyTokenApy: 0,
        addyFeeShareApy: 0,
        totalAddyApy: 0,
        totalApy: 0,

        baseApr: 0,
        aprProfitToStakers: 0,
        addyTokenApr: 0,
        totalAddyApr: 0,
        totalApr: 0
    }

    let dailyIncomeFromVault = rewardTokenPrice * rewardsPerDay * 100 / tvl;
    let apr = rewardTokenPrice * rewardsPerDay * 365.0 * 100 / tvl;
    let singleStakeApr = rewardTokenPrice * singleStakeRewardsPerDay * 365.0 * 100 / singleStakeTvl;
    let dailyIncomeFromSingleStake = rewardTokenPrice * singleStakeRewardsPerDay * 100 / singleStakeTvl;
    let maximizerApy = 0;

    for(let i = 0; i < 365; i++) {
        maximizerApy += dailyIncomeFromSingleStake * maximizerApy / 100; //single-staking income from `harvestedToken` harvested the day before
        maximizerApy += dailyIncomeFromVault; //gains from `harvestedToken` from that day
    }
    //console.log(apr, singleStakeApr, dailyIncomeFromVault, dailyIncomeFromSingleStake, totalVvs)

    const baseApr = apr * (1 - fee);
    const aprProfitToStakers = apr * fee;

    //Temporary while refactoring addyEthPrice references to addyNativePrice
    let addyNativePrice = addyData.addyNativePrice !== undefined ? addyData.addyNativePrice: addyData.addyEthPrice;

    const minAprApyData = _calculateAprAndApyWithCompoundingADDY(rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, baseRewardMultiplier)
    const maxAprApyData = _calculateAprAndApyWithCompoundingADDY(rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, baseRewardMultiplier * 2)
    const userAprApyData = _calculateAprAndApyWithCompoundingADDY(rewardTokenPrice, rewardsPerDay, tvl, addyData, fee, rewardMultiplier)

    const addyTokenApr = apr * fee * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    const addyFeeShareApr = addyTokenApr * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    const totalAddyApr = addyTokenApr + addyFeeShareApr;
    const totalApr = baseApr + addyTokenApr;

    //APY after fee
    const baseApy = maximizerApy * (1 - fee);
    //APY from ADDY emissions = fee * addy eth price * addy emitted per eth in fees
    const addyTokenApy = baseApy * fee * addyNativePrice * addyData.addyPerEth * (rewardMultiplier / 1000);
    const minAddyTokenApy = baseApy * fee * addyNativePrice * addyData.addyPerEth * (baseRewardMultiplier / 1000);
    const maxAddyTokenApy = baseApy * fee * addyNativePrice * addyData.addyPerEth * (baseRewardMultiplier * 2 / 1000);

    //Calculations involve selling at end of year
    //todo: test various scenarios to see which results in higher APY
    //APY from vested ADDY fee sharing, based on sum of arithmetic sequence formula (1/365, 2/365, ... , 365/365)
    const addyFeeShareApy = 0; //addyTokenApy * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    //Total APY from the ADDY token
    const totalAddyApy = addyTokenApy;// + addyFeeShareApy;
    //Total APY
    const totalApy = baseApy + totalAddyApy;
    const minTotalApy = baseApy + minAddyTokenApy;// + minAddyTokenApy * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    const maxTotalApy = baseApy + maxAddyTokenApy;// + maxAddyTokenApy * addyData.feeSharingApy * ADDY_FEE_SHARE_CONSTANT;
    //console.log(totalApy, minTotalApy, maxTotalApy)

    return {
        minAprApyData: minAprApyData,
        maxAprApyData: maxAprApyData,
        userAprApyData: userAprApyData,

        baseApy: baseApy,
        addyTokenApy: addyTokenApy,
        addyFeeShareApy: addyFeeShareApy,
        totalAddyApy: totalAddyApy,
        totalApy: totalApy,
        minTotalApy: minTotalApy,
        maxTotalApy: maxTotalApy,
        singleStakeApr: singleStakeApr,

        baseApr: baseApr,
        aprProfitToStakers: aprProfitToStakers,
        addyTokenApr: addyTokenApr,
        totalAddyApr: totalAddyApr,
        totalApr: totalApr
    }
}