import { WETH } from '../../constants'
import ERC20_ABI from '../../abis/ERC20abi.json'
import PRESALE_ABI from '../../abis/presale_abi.json'
import { getNativeTokenAddress } from '../../constants/multichain/network.js';

export const PRESALE_ADDRESS = "0xA25c1b4851D6a1625ac3278e446d74A585ed4BA2"

export const getPresaleData = async (web3, account) => {
    let myAddress = account;

    const wethContract = web3.eth.Contract(ERC20_ABI.abi, getNativeTokenAddress())
    const presaleContract = web3.eth.Contract(PRESALE_ABI.abi, PRESALE_ADDRESS)

    let wethApproved = await wethContract.methods.allowance(myAddress, PRESALE_ADDRESS).call();
    let wethBalance = await wethContract.methods.balanceOf(myAddress).call();

    let presaleWhiteListOnly = await presaleContract.methods.whiteListOnly().call();
    let userPurchaseAmount = await presaleContract.methods.getPurchaseAmount(myAddress).call();
    let tokensAvailable = await presaleContract.methods.available().call();

    return {
        wethApproved: wethApproved,
        wethBalance: wethBalance,
        presaleWhiteListOnly: presaleWhiteListOnly,
        userPurchaseAmount: userPurchaseAmount,
        tokensAvailable: tokensAvailable
    };
}