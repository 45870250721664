import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { approve, deposit, depositAll, extendLock } from '../api/actions/LockedVaultFunctions.js'
import { getLockedPlusAddress, getLockedBasicAddress, getAdamantTokenAddress, getAdamantTokenName } from '../api/Multichain.js'
import { isZero } from '../utils'

const noBorderButton = {
    borderStyle: 'none',
    borderWidth: '0'
};

const MIN_LOCK_TIME = 91;
const MAX_LOCK_TIME = 1460;
const MIN_LOCK_TIME_SECONDS = MIN_LOCK_TIME * 86400;

//For the locked ADDY staking conreact
class LockedStakeModalAddy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stakeAmount: '0',
            lockDuration: '0'
        };
        this.handleStakeAmountChange = this.handleStakeAmountChange.bind(this);
        this.handleLockDurationChange = this.handleLockDurationChange.bind(this);
    }
    handleStakeAmountChange(event) {
        this.setState({
            stakeAmount: event.target.value
        });
    }
    handleLockDurationChange(event) {
        this.setState({
            lockDuration: event.target.value
        });
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        if (this.props.addyData == null) {
            return null;
        }
        //console.log(this.props.addyData)

        const addyData = this.props.addyData;
        const userLpBalance = addyData.myBalance;

        const isBoostPlus = this.props.isBoostPlus;
        const lockedBalance = (isBoostPlus ? addyData.balanceOf_locked: addyData.balanceOf_basic);
        const lockEndTime = (isBoostPlus ? addyData.endingTimestamp_locked: addyData.endingTimestamp_basic) * 1000;
        const existingLockTime = lockEndTime - Date.now();
        const tokenApproved = (isBoostPlus ? addyData.addyApproved_locked: addyData.addyApproved_basic) / 10 ** 18;
        let vaultAddress = isBoostPlus ? getLockedPlusAddress(): getLockedBasicAddress();
        let stakeTokenName = getAdamantTokenName();

        let lockTime = this.getLockTime(this.state.lockDuration);

        return (
            <Modal className="modal-dialog-centered modal-dialog-scrollable" show={this.props.show} onHide={(event) => {
                    this.setState({ stakeAmount: '0', lockDuration: '0' });
                    this.props.hideModal();
                }}>
                <Modal.Header className="modal-header">
                    <h4 className="mb-0 pb-0">Lock {stakeTokenName} {isBoostPlus ? "Plus": "Basic"}</h4>
                    <button className="close" aria-label="Close"
                        onClick={(event) => {
                            this.setState({ stakeAmount: '0', lockDuration: '0' });
                            this.props.hideModal();
                        }}>
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {isZero(lockedBalance) && <div id="modal-body-first-lock" className="col-12">
                        <LpBalance userLpBalance={userLpBalance} stakeTokenName={stakeTokenName} />

                        <div className="input-group mb-2">
                            <input type="number" min="0" step="0.1" className="form-control noValidate" placeholder="Amount to stake"
                                value={this.props.value} onChange={this.handleStakeAmountChange} />
                        </div>

                        <div className="input-group mb-2">
                            <input type="number" min="91" max="1460" step="1"
                                className="form-control noValidate"
                                placeholder="Lock duration (91 to 1460 days)"
                                value={this.props.value}
                                onChange={this.handleLockDurationChange} />
                        </div>

                        <p className="text-center mt-3 mb-2">
                            {!tokenApproved && <button className="btn btn-success btn-block mb-2" style={noBorderButton}
                                onClick={(event) => {
                                    event.preventDefault();
                                    approve(this.props.account, getAdamantTokenAddress(), vaultAddress);
                                }}
                            >Approve Tokens</button>}

                            <button className={"btn btn-block btn-success mb-2" + (tokenApproved && lockTime > 0 ? "" : " disabled")}
                                onClick={(event) => {
                                    event.preventDefault();
                                    deposit(this.props.account, vaultAddress, this.state.stakeAmount, lockTime);
                                }}
                            >Lock {this.state.stakeAmount} {stakeTokenName}</button>

                            <button className={"btn btn-block btn-success" + (tokenApproved && lockTime > 0 ? "" : " disabled")}
                                onClick={(event) => {
                                    event.preventDefault();
                                    depositAll(this.props.account, vaultAddress, lockTime);
                                }}
                            >Lock All {stakeTokenName}</button>
                        </p>

                        {lockTime === 0 && <small className="d-block">
                            {this.getLockDurationString(this.state.lockDuration)}
                            <hr />
                        </small>}

                        {lockTime > 0 && lockTime * 1000 > existingLockTime && <small className="d-block">
                            {this.getLockDurationString(this.state.lockDuration)}<br />
                            <b>
                                All your {stakeTokenName} in the boost contract will share the same unlock date.<br />
                                The unlock date cannot be decreased.
                            </b>
                            <hr />
                        </small>}

                        {lockTime > 0 && lockTime * 1000 < existingLockTime && <small className="d-block">
                            This stake will be grouped with the locked stake ending on {new Date(lockEndTime).toUTCString()}.
                            <hr />
                        </small>}
                        <small className="d-block mb-0">
                            {this.stakingMultiplier(lockTime, isBoostPlus, stakeTokenName)}
                        </small>
                    </div>}

                    {lockedBalance > 0 && <div id="modal-body-manage-lock" className="col-12">
                        <LpBalance userLpBalance={userLpBalance} stakeTokenName={stakeTokenName} />

                        <div className="input-group mb-2">
                            <input type="number" min="0" step="0.1" className="form-control noValidate" placeholder="Amount to stake"
                                value={this.props.value} onChange={this.handleStakeAmountChange} />
                        </div>

                        <p className="text-center mt-3 mb-2">
                            {!tokenApproved && <button className="btn btn-success btn-block mb-2" style={noBorderButton}
                                onClick={(event) => {
                                    event.preventDefault();
                                    approve(this.props.account, getAdamantTokenAddress(), vaultAddress);
                                }}
                            >Approve Tokens</button>}

                            <button className={"btn btn-block btn-success mb-2" + (tokenApproved ? "" : " disabled")}
                                onClick={(event) => {
                                    event.preventDefault();
                                    deposit(this.props.account, vaultAddress, this.state.stakeAmount, MIN_LOCK_TIME_SECONDS);
                                }}
                            >Increase Lock Amount by {this.state.stakeAmount} {stakeTokenName}</button>

                            <button className={"btn btn-block btn-success" + (tokenApproved ? "" : " disabled")}
                                onClick={(event) => {
                                    event.preventDefault();
                                    depositAll(this.props.account, vaultAddress, MIN_LOCK_TIME_SECONDS);
                                }}
                            >Increase Lock Amount by All {stakeTokenName}</button>
                        </p>

                        <small className="d-block mb-1">
                            Increasing your lock amount will set the unlock date to 91 days from now if the current lock would end in less than 91 days.
                        </small>
                        <hr />

                        <div className="input-group mb-2">
                            <input type="number" min="91" max="1460" step="1"
                                className="form-control noValidate"
                                placeholder="Lock duration (91 to 1460 days)"
                                value={this.props.value}
                                onChange={this.handleLockDurationChange} />
                        </div>

                        <p className="text-center mt-3 mb-2">
                            <button className={"btn btn-block btn-success mb-2" + (tokenApproved && lockTime > 0 ? "" : " disabled")}
                                onClick={(event) => {
                                    event.preventDefault();
                                    extendLock(this.props.account, vaultAddress, lockTime, !isBoostPlus);
                                }}
                            >Set New Lock Unlock Date</button>
                        </p>

                        {lockTime === 0 && <small className="d-block">
                            {this.getLockDurationString(this.state.lockDuration)}
                            <hr />
                        </small>}

                        {lockTime > 0 && lockTime * 1000 > existingLockTime && <small className="d-block">
                            {this.getLockDurationString(this.state.lockDuration)}<br />
                            <b>
                                All your {stakeTokenName} in the boost contract will share the same unlock date.<br />
                                The unlock date cannot be decreased.
                            </b>
                            <hr />
                        </small>}

                        {lockTime > 0 && lockTime * 1000 < existingLockTime && <small className="d-block">
                            You already have a locked stake ending on {new Date(lockEndTime).toUTCString()}.<br />
                            Your current vault unlock date will not change.
                            <hr />
                        </small>}
                        <small className="d-block mb-0">
                            {this.stakingMultiplier(lockTime, isBoostPlus, stakeTokenName)}
                        </small>
                        <small className="d-block mb-0">
                            Setting a longer lock duration will extend the lock time of all {stakeTokenName} you have already locked in the {isBoostPlus ? "Plus": "Basic"} contract.
                        </small>
                    </div>}
                </Modal.Body>
                <Modal.Footer className="modal-footer py-2">
                    <Button className="btn btn-secondary btn-sm m-0" style={noBorderButton} onClick={(event) => {
                        this.setState({ stakeAmount: '0', lockDuration: '0' });
                        this.props.hideModal();
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    //parses value in days and converts it to seconds
    //returns 0 if NaN or out of range
    getLockTime(value) {
        if(value === undefined || value.length === 0 || value.trim().length === 0) {
            return 0;
        }
        var time = parseInt(value);
        if(isNaN(time) || time < MIN_LOCK_TIME || time > MAX_LOCK_TIME) {
            return 0;
        }
        return time * 86400;
    }

    getLockDurationString(value) {
        if(value === undefined || value.length === 0 || value.trim().length === 0) {
            return "Please input a lock duration between 91 and 1460 days";
        }
        var time = parseInt(value);
        if(isNaN(time)) {
            return "Invalid lock duration";
        }
        if(time < MIN_LOCK_TIME) {
            return "Lock duration too low, min duration 91 days";
        }
        if(time > MAX_LOCK_TIME) {
            return "Lock duration too high, max duration 1460 days";
        }
        return "Lock duration: " + time + " days";
    }

    stakingMultiplier(secs, isBoostPlus, stakeTokenName) {
        if(secs < 0) return `${stakeTokenName} boost point multiplier: 0`;

        let locked_stake_max_multiplier = 1000000; // 6 decimals of precision. 1x = 1000000
        let locked_stake_time_for_max_multiplier = 4 * 365 * 86400; // 4 years

        let multiplier = secs / locked_stake_time_for_max_multiplier;
        if (multiplier > locked_stake_max_multiplier) multiplier = locked_stake_max_multiplier;
        if(!isBoostPlus) {
            multiplier /= 2;
        }
        return `${stakeTokenName} boost point multiplier: ` + multiplier.toFixed(4);
    }
}

LockedStakeModalAddy.propTypes = {
    show: PropTypes.bool
};

export default LockedStakeModalAddy;


class LpBalance extends Component {
    render() {
        return (
            <p className="mb-0">
                <b>Balance: </b>
                {(this.props.userLpBalance / 10 ** 18).toFixed(8)} {this.props.stakeTokenName}
            </p>
        );
    }
}
