import { calculateAprAndApy, calculateAprAndApyWithDepositFee, calculateAprAndApyDual } from '../../MathUtil.js'
import MASTER_CHEF_MULTICALL_ABI from '../../../abis/multicall/vaults/master_chef_multicall.json';
import { cacheCoingeckoPrice } from '../../external_json/CoingeckoData.js';
import { cacheOnlyIfNumber, getPoolTVL, getTokenPrice } from '../../PriceCache';
import { getPerformanceFee } from '../../Multichain.js';

const MASTER_CHEF_MULTICALL_ADDRESS = "0x9f50f78061605A4C3b0af6A848566d6407DB8A70";

const MAI = "0xa3fa99a148fa48d14ed51d610c367c61876997f1";
const SUSHI = "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a".toLowerCase();

let tradingFeeCache = {};
const SECONDS_PER_DAY = 86400;
const BLOCK_TIME = 2;

function cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, poolJsonInfo) {
    //don't attempt to cache the price for single asset vaults
    //otherwise the price will decrease each loop due to `totalTokenValue / lpSupply * 1e18`
    if(poolJsonInfo.singleAsset) return [0, 0];

    let lpSupply = vaultInfo.lpSupply;
    let lpStaked = vaultInfo.lpStaked;
    let ratioStaked = lpStaked / lpSupply;

    let totalTokenValue = getPoolTVL(vaultInfo.token0, vaultInfo.token1, vaultInfo.token0Bal, vaultInfo.token1Bal, vaultInfo.decimals0, vaultInfo.decimals1);
    cacheOnlyIfNumber(poolJsonInfo.lpAddress, totalTokenValue / lpSupply * 1e18, 1, true);
    return [totalTokenValue, totalTokenValue * ratioStaked]
}

export const getMasterChefStakingDataPolygon = async (web3, account, pools, stakingData, stakingDataMap, addyData, priceCache) => {

    //getWaultData(tradingFeeCache);
    //cacheCoingeckoPrice("synapse-2", "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695", priceCache);
    cacheCoingeckoPrice("meta", "0xF501dd45a1198C2E1b5aEF5314A68B9006D842E0", priceCache);
    //cacheOnlyIfNumber("0xB6c473756050dE474286bED418B77Aeac39B02aF".toLowerCase(), 1, 1); //Cache price of nUSD as $1

    let sushiPrice = getTokenPrice(SUSHI);

    const multicallContract = web3.eth.Contract(MASTER_CHEF_MULTICALL_ABI.abi, MASTER_CHEF_MULTICALL_ADDRESS)

    var poolAddresses = [];
    var poolz = [];

    for (let i = 0; i < pools.length; i++) {
        poolAddresses.push(pools[i].vaultAddress);

        //Multicall contract will fail to return data if I try to get too many vaults at once
        if(i > 0 && i % 60 === 0) {
            let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
            //console.log("Loaded " + poolAddresses.length + " vaults")
            poolz = poolz.concat(infoForPools);
            poolAddresses = [];
        }
    }

    if(poolAddresses.length > 0) {
        let infoForPools = await multicallContract.methods.getInfoForVaults(account, poolAddresses).call();
        //console.log("Loaded " + poolAddresses.length + " vaults")
        poolz = poolz.concat(infoForPools);
    }

    for (let i = 0; i < pools.length; i++) {
        pools[i].vaultAddress = pools[i].vaultAddress.toLowerCase();
        try {
            let vaultInfo = poolz[i];
            let stakingAddress = pools[i].stakingAddress;

            let totalStaked = vaultInfo.totalStaked;
            let amountUserStaked = vaultInfo.amountUserStaked;
            let lpTokenApproved = vaultInfo.lpTokenApproved;
            let userLpBalance = vaultInfo.userLpBalance;
            let ratio = totalStaked > 0 ? vaultInfo.ratio: 0; //ratio of LP/vault tokens

            let allocPoint = pools[i].allocPoint;
            let totalAllocPoint = pools[i].totalAllocPoint;

            let rewardPerBlock = 0;
            let rewardTokenPrice = 0;
            let rewardTokenPrice2 = 0;

            let rewardsPerDay = SECONDS_PER_DAY / BLOCK_TIME;
            let rewardsPerDay2 = 0;

            if(vaultInfo.rewardData !== undefined && vaultInfo.rewardData.length > 0 && vaultInfo.rewardData[0].totalAllocPoint > 0) {
                totalAllocPoint = vaultInfo.rewardData[0].totalAllocPoint;
                allocPoint = vaultInfo.rewardData[0].allocPoint;
                rewardPerBlock = vaultInfo.rewardData[0].rewardPerBlock / 1e18;
            }
            else if(!pools[i].deprecated) {
                console.debug("Pool has no data handler: " + pools[i].token0Name + "/" + pools[i].token1Name)
            }

            let poolTvl_tvl = cachePriceOfToken1AndReturnPoolTVL2(vaultInfo, pools[i]);
            let poolTvl = poolTvl_tvl[0]
            let tvl = poolTvl_tvl[1]

            //Handle calculations for non Uniswap fork LP tokens here
            if(pools[i].useVirtualPrice) {
                cacheOnlyIfNumber(pools[i].lpAddress, vaultInfo.tokenData[0].price / 1e18, 1, true);
                tvl = vaultInfo.tokenData[0].price / 1e18 * vaultInfo.lpSupply / 1e18;
                poolTvl = tvl * vaultInfo.lpStaked / vaultInfo.lpSupply;
            }
            else if(vaultInfo.tokenData !== undefined && vaultInfo.tokenData.length > 0) {
                poolTvl = 0;
                for (let j = 0; j < vaultInfo.tokenData.length; j++) {
                    //price might be retrieved from oracle i.e. from Curve forks
                    let tokenPrice = vaultInfo.tokenData[j].price > 0 ? vaultInfo.tokenData[j].price / 1e18 : getTokenPrice(vaultInfo.tokenData[j].token);
                    poolTvl += (tokenPrice * vaultInfo.tokenData[j].balance) / (10 ** vaultInfo.tokenData[j].decimals);
                }

                if(pools[i].singleAsset) {
                    tvl = poolTvl;
                }
                else {
                    let ratioStaked = vaultInfo.lpStaked / vaultInfo.lpSupply;
                    tvl = poolTvl * ratioStaked
                    cacheOnlyIfNumber(pools[i].lpAddress, poolTvl / vaultInfo.lpSupply * 1e18, 1, true);
                }
            }

            let harvestedToken = "ERROR";
            let pendingReward = vaultInfo.pendingReward * addyData.wmaticEthPrice * addyData.addyPerEth * (vaultInfo.rewardMultiplier / 1000);
            vaultInfo.totalPendingReward *= (vaultInfo.rewardMultiplier / 1000); //used by an external script, don't need to care about totalPendingReward in new website

            if(pools[i].platform === "sushi") {
                rewardTokenPrice = sushiPrice;
                harvestedToken = "SUSHI";
                rewardsPerDay = SECONDS_PER_DAY * rewardPerBlock; //sushi is reward/second

                if(vaultInfo.rewardData.length > 1) {
                    rewardTokenPrice2 = getTokenPrice(vaultInfo.rewardData[1].token);

                    //using an individual reward distributor
                    if(pools[i].rewardTokenName2) {
                        rewardsPerDay2 = SECONDS_PER_DAY * vaultInfo.rewardData[1].rewardPerBlock / 1e18;
                        harvestedToken += " + " + pools[i].rewardTokenName2;
                    }
                    //using the WMATIC reward distributor at 0xa3378Ca78633B3b9b2255EAa26748770211163AE
                    else {
                        rewardsPerDay2 = SECONDS_PER_DAY * vaultInfo.rewardData[1].rewardPerBlock / 1e18 * (allocPoint / totalAllocPoint);
                        harvestedToken += " + " + "MATIC";
                    }
                }
            }
            else if(pools[i].rewardToken !== undefined) {
                rewardTokenPrice = getTokenPrice(pools[i].rewardToken);
                harvestedToken = pools[i].rewardTokenName;
                rewardsPerDay *= rewardPerBlock;
                if(pools[i].rewardPerSecond) {
                    rewardsPerDay *= BLOCK_TIME;
                }

                //if there's multiple rewards, add a loop for them; no pool with more than two rewards has ever been seen, though
                if(vaultInfo.rewardData.length > 1) {
                    rewardTokenPrice2 = getTokenPrice(vaultInfo.rewardData[1].token);
                    rewardsPerDay2 = SECONDS_PER_DAY / BLOCK_TIME * vaultInfo.rewardData[1].rewardPerBlock / 1e18;

                    if(pools[i].rewardTokenName2) {
                        harvestedToken += " + " + pools[i].rewardTokenName2;
                    }
                    if(pools[i].rewardPerSecond) {
                        rewardsPerDay2 *= BLOCK_TIME;
                    }
                }
            }
            else if(!pools[i].deprecated) {
                console.debug("No reward token defined for ", pools[i].token0Name + "/" + pools[i].token1Name)
            }

            if(totalAllocPoint > 0) {
                rewardsPerDay *= (allocPoint / totalAllocPoint);
            }

            let boost = vaultInfo.boost != null ? vaultInfo.boost / 1e18: 0;
            let perfFee = pools[i].perfFee !== undefined ? pools[i].perfFee: getPerformanceFee();

            let aprApyData = calculateAprAndApy(rewardTokenPrice, rewardsPerDay, tvl, addyData, perfFee, vaultInfo.rewardMultiplier * (1 + boost));
            if(pools[i].platform === "mai") {
                aprApyData = calculateAprAndApyWithDepositFee(rewardTokenPrice, rewardsPerDay, tvl, addyData, perfFee, 0.005, vaultInfo.rewardMultiplier * (1 + boost));
            }
            else if(rewardsPerDay2) {
                aprApyData = calculateAprAndApyDual(rewardTokenPrice, rewardsPerDay, rewardTokenPrice2, rewardsPerDay2, tvl, addyData, perfFee, vaultInfo.rewardMultiplier * (1 + boost));
            }

            const lpPrice = getTokenPrice(pools[i].lpAddress);
            let tradingFeeApy = tradingFeeCache[pools[i].lpAddress.toLowerCase()] ? tradingFeeCache[pools[i].lpAddress.toLowerCase()] * 100: undefined;

            let vaultData = {
                //defined in vault json
                token0Name: pools[i].token0Name,
                token1Name: pools[i].token1Name,
                token0: pools[i].token0,
                token1: pools[i].token1,
                lpAddress: pools[i].lpAddress.toLowerCase(),
                stakingAddress: stakingAddress.toLowerCase(),
                vaultAddress: pools[i].vaultAddress,
                strategyAddress: pools[i].strategyAddress,

                platform: pools[i].platform,
                exchange: pools[i].exchange,
                customExchangeUrl: pools[i].customExchangeUrl,

                deprecated: pools[i].deprecated,
                hidden: pools[i].hidden,
                forceShow: pools[i].forceShow,

                addyRewards: pools[i].addyRewards,
                depositFee: pools[i].depositFee,
                singleAsset: pools[i].singleAsset,
                harvestBefore: pools[i].harvestBefore,
                nameOverride: pools[i].nameOverride,
                hiddenForNonStaked: pools[i].hiddenForNonStaked,
                depositDisabled: pools[i].depositDisabled,
                degen: pools[i].degen,
                deprecatedYieldTooltipString: pools[i].deprecatedYieldTooltipString,
                showApr: pools[i].showApr,
                highlight: pools[i].highlight,
                emergencyWithdraw: pools[i].emergencyWithdraw,
                timestamp: pools[i].timestamp,

                tooltip: formatTooltip(pools[i]),

                //Vault information that's computed/retrieved from the blockchain
                harvestedToken: harvestedToken,
                index: stakingData.length,
                lpPrice: lpPrice,
                rewardsPerDay: rewardsPerDay,
                pendingReward: pendingReward,
                poolTvl: poolTvl,
                tvl: tvl,
                boost: boost,
                boostable: vaultInfo.boostable,
                ratio: ratio,

                rewardAllocation: vaultInfo.rewardAllocation,
                totalPendingReward: vaultInfo.totalPendingReward,
                withdrawPenaltyTime: vaultInfo.withdrawPenaltyTime,
                withdrawPenalty: vaultInfo.withdrawPenalty,

                totalStaked: totalStaked,
                valueTotalStaked: totalStaked * lpPrice,
                amountUserStaked: amountUserStaked,
                valueUserStaked: amountUserStaked * lpPrice,
                lpTokenApproved: lpTokenApproved,
                userLpBalance: userLpBalance,
                lastDepositTime: vaultInfo.lastDepositTime,
                harvestUntil: vaultInfo.harvestUntil,
                rewardMultiplier: vaultInfo.rewardMultiplier,
                lastHarvestTime: vaultInfo.lastHarvestTime,

                //APY info
                baseApy: aprApyData.baseApy,
                //For any vaults which don't have ADDY rewards (maybe when launching on a new chain before the token starts being distributed)
                addyTokenApy: pools[i].addyRewards ? aprApyData.addyTokenApy: 0,
                addyFeeShareApy: pools[i].addyRewards ? aprApyData.addyFeeShareApy: 0,
                totalAddyApy: pools[i].addyRewards ? aprApyData.totalAddyApy: 0,
                apy: pools[i].addyRewards ? aprApyData.totalApy: aprApyData.baseApy,

                baseApr: aprApyData.baseApr,
                addyTokenApr: aprApyData.addyTokenApr,
                aprProfitToStakers: aprApyData.aprProfitToStakers,
                totalAddyApr: aprApyData.totalAddyApr,
                apr: aprApyData.totalApr,

                tradingFeeApy: tradingFeeApy
            }
            stakingDataMap[pools[i].vaultAddress] = vaultData;
            stakingData.push(vaultData)
        }
        catch {
            console.error("Failed to load MasterChef pool: " + i)
            console.error(pools[i])
        }
    }
    return stakingData;
}

function formatTooltip(pool) {
    if(pool.tooltip == null) {
        return null;
    }
    if(pool.platform === "mai") {
        return pool.tooltip.replace("{priceOfMai}", getTokenPrice(MAI).toFixed(4));
    }
    return pool.tooltip;
}