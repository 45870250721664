import React from 'react';
import Navbar from '../Navbar/Navbar';
import styles from './PageLayout.module.scss';

const PageLayout: React.FC = ({ children }) => (
  <main className={styles.container}>
    <Navbar />
    {children}
  </main>
);

export default PageLayout;
