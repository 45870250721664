import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import classnames from 'classnames';
import CheckboxChecked from './checkbox-checked.svg';
import CheckboxDefault from './checkbox-default.svg';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
  className?: string;
  value: string;
  checked: boolean;
  onChange: (value: string, isChecked: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = ({ className, children, value, checked, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleCheckboxChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      onChange(value, e.currentTarget.checked);
    },
    [onChange, value],
  );

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={classnames(styles.container, isFocused && styles.focused, className)}>
      <input
        type="checkbox"
        onChange={handleCheckboxChange}
        checked={checked}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <div className={styles.imageWrapper}>
        <img src={checked ? CheckboxChecked : CheckboxDefault} alt="" />
      </div>
      {children}
    </label>
  );
};

export default Checkbox;
