import React from 'react';
import Button from '../../components/common/Button/Button';
import { RoutingPaths } from '../../paths';
import styles from './Page404.module.scss';

const Page404 = () => (
  <div className={styles.container}>
    <h1 className={styles.header}>404</h1>
    <h2 className={styles.title}>Not found</h2>
    <p className={styles.text}>The page you requested could not be found</p>
    <Button to={RoutingPaths.VAULTS} color="primary">
      Go to Vaults
    </Button>
  </div>
);

export default Page404;
