import React, { RefObject, useEffect, useRef, useState, VFC } from 'react';

import { motion } from 'framer-motion';
import styles from './VaultCard.module.scss';
import VaultCardHeader from './VaultCardHeader/VaultCardHeader';
import VaultCardBody from './VaultCardBody/VaultCardBody';

interface Props {
  vault: any;
  onExpand?: (ref: RefObject<HTMLDivElement>) => void;
}

const VaultCard: VFC<Props> = ({ vault, onExpand }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isExpanded) {
      onExpand?.(cardRef);
    }
  }, [isExpanded, onExpand]);

  return (
    <motion.div layout className={styles.card} ref={cardRef}>
      <motion.div layout>
        <VaultCardHeader vault={vault} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </motion.div>

      {isExpanded && <VaultCardBody vault={vault} />}
    </motion.div>
  );
};

export default VaultCard;
