import { NetworkID } from '../../constants/index.js';
import { WETH } from '../../constants'

export const getProvider = (networkId) => {
    if (networkId === NetworkID.POLYGON_NETWORK_ID) {
        return "https://polygon-rpc.com/";
    }
    else if (networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return "https://arb1.arbitrum.io/rpc";
    }
    else if (networkId === NetworkID.CRONOS_NETWORK_ID) {
        return "https://evm-cronos.crypto.org";
    }
    else if (networkId === NetworkID.POLYGON_TESTNET_ID) {
        return "https://matic-mumbai.chainstacklabs.com";
    }
    else if (networkId === NetworkID.ETH_MAINNET_ID) {
        return "https://mainnet.infura.io/v3/43fc2b8b8bc440cfac4e996e90fe28d4";
    }
    return "https://mainnet.infura.io/v3/43fc2b8b8bc440cfac4e996e90fe28d4";
}

export const getNetworkName = (networkId) => {
    if (networkId === NetworkID.ETH_MAINNET_ID) {
        return "Ethereum";
    }
    else if (networkId === NetworkID.ARBITRUM_NETWORK_ID) {
        return "Arbitrum";
    }
    else if (networkId === NetworkID.POLYGON_NETWORK_ID || networkId === NetworkID.POLYGON_TESTNET_ID) {
        return "Matic";
    }
    else if (networkId === NetworkID.CRONOS_NETWORK_ID) {
        return "Cronos";
    }
    return "Unknown";
}

export function getNativeTokenName() {
    switch (window.networkId) {
        case NetworkID.ARBITRUM_NETWORK_ID:
            return "ETH";
        case NetworkID.CRONOS_NETWORK_ID:
            return "CRO";
        case NetworkID.POLYGON_NETWORK_ID:
        case NetworkID.POLYGON_TESTNET_ID:
            return "MATIC";
        default:
            return "ETH";
    }
}

export function getNativeTokenAddress() {
    switch (window.networkId) {
        case NetworkID.ARBITRUM_NETWORK_ID:
            return WETH.ARBITRUM;
        case NetworkID.CRONOS_NETWORK_ID:
            return "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23";
        case NetworkID.POLYGON_NETWORK_ID:
            return "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270";
        case NetworkID.POLYGON_TESTNET_ID:
            return "0xFac5C7819fdE19d006244E462Db02ed7Fe346E71";
        default:
            return "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270";
    }
}

export function getBlockExplorerData() {
    switch (window.networkId) {
        case NetworkID.ARBITRUM_NETWORK_ID:
            return {link: "https://arbiscan.io/tx/", name: "Arbiscan"};
        case NetworkID.CRONOS_NETWORK_ID:
            return {link: "https://cronos.crypto.org/explorer/tx/", name: "Cronos Explorer"};
        case NetworkID.POLYGON_NETWORK_ID:
            return {link: "https://polygonscan.com/tx/", name: "Polygonscan"};
        case NetworkID.POLYGON_TESTNET_ID:
            return {link: "https://mumbai.polygonscan.com/tx/", name: "Polygonscan"};
        default:
            return {link: "https://polygonscan.com/tx/", name: "Polygonscan"};
    }
}
