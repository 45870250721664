import React, { VFC } from 'react';

import Link from '../../common/Link/Link';

import certikLogoLight from './certik-logo-light.svg';
import certikLogoDark from './certik-logo-dark.svg';
import polygonLogo from './polygon-logo.svg';

import styles from './FooterFeatured.module.scss';
import { useUserContext } from '../../../contexts/UserContext';

const FooterFeatured: VFC = () => {
  const { isDarkMode } = useUserContext();

  return (
    <div>
      <p className={styles.title}>Featured</p>

      <div className={styles.list}>
        <Link href="https://polygon.technology/" newTab className={styles.brand}>
          <img className={styles.brandImg} src={polygonLogo} alt="Polygon" />
          <div className={styles.brandText}>
            <p className={styles.brandUpperText}>Born on</p>
            <p className={styles.brandLowerText}>Polygon</p>
          </div>
        </Link>

        <Link href="https://www.certik.org/projects/adamant" newTab className={styles.brand}>
          <img
            className={styles.brandImg}
            src={isDarkMode ? certikLogoDark : certikLogoLight}
            alt="Certik"
          />
          <div className={styles.brandText}>
            <p className={styles.brandUpperText}>Audited by</p>
            <p className={styles.brandLowerText}>Certik</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default FooterFeatured;
