import React, { VFC } from 'react';
import Loading from '../../components/Loading/Loading';
import StakePage from './StakePage';

export interface StakePageContainerProps {
  addyData: any;
}

const StakePageContainer: VFC<StakePageContainerProps> = ({ addyData }) => {
  if (!addyData) {
    return <Loading />;
  }

  return <StakePage addyData={addyData} />;
};

export default StakePageContainer;
