import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SwiperOptions } from 'swiper';

import bgParticles1 from './bg-stats-tiles-particles-1.png';
import bgParticles2 from './bg-stats-tiles-particles-2.png';
import bgParticles3 from './bg-stats-tiles-particles-3.png';

import styles from './VaultStatsTiles.module.scss';
import InvestmentsTile from './InvestmentsTile/InvestmentsTile';
import BoostTile from './BoostTile/BoostTile';
import RewardsTile from './RewardsTile/RewardsTile';

interface Props {
  addyData: any;
  vaults: any;
}

const breakpoints: {
  [width: number]: SwiperOptions;
  [ratio: string]: SwiperOptions;
} = {
  769: {
    slidesPerView: 3,
    spaceBetween: 22,
    enabled: false,
  },
  1025: {
    slidesPerView: 3,
    spaceBetween: 32,
    enabled: false,
  },
  1201: {
    slidesPerView: 3,
    spaceBetween: 64,
    enabled: false,
  },
};

const VaultStatsTiles: FC<Props> = ({ addyData, vaults }) => {
  return (
    <Swiper
      enabled
      centeredSlides
      centeredSlidesBounds
      slidesPerView={1.4}
      spaceBetween={22}
      breakpoints={breakpoints}
      className={styles.swiperContainer}
    >
      <SwiperSlide className={styles.swiperSlide}>
        <InvestmentsTile bgParticles={bgParticles1} vaults={vaults} />
      </SwiperSlide>

      <SwiperSlide className={styles.swiperSlide}>
        <BoostTile bgParticles={bgParticles2} vaults={vaults} addyData={addyData} />
      </SwiperSlide>

      <SwiperSlide className={styles.swiperSlide}>
        <RewardsTile bgParticles={bgParticles3} vaults={vaults} />
      </SwiperSlide>
    </Swiper>
  );
};

export default VaultStatsTiles;
