import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import Button from '../common/Button/Button';
import { RoutingPaths } from '../../paths';
import ChestDark from './Chest-dark.svg';
import ChestLight from './Chest-light.svg';
import ChartDark from './Chart-dark.svg';
import ChartLight from './Chart-light.svg';
import LightningDark from './Lightning-dark.svg';
import LightningLight from './Lightning-light.svg';
import styles from './ExplainerSection.module.scss';
import { useUserContext } from '../../contexts/UserContext';

const images = [
  {
    imageDark: ChestDark,
    imageLight: ChestLight,
    text: '100% of the penalty fees paid by users who withdraw vested tokens',
  },
  {
    imageDark: ChartDark,
    imageLight: ChartLight,
    text: '100% of ADDY from buybacks',
  },
  {
    imageDark: LightningDark,
    imageLight: LightningLight,
    text: 'A boost to the ADDY earned by staking in normal vaults',
  },
];

const ExplainerSection = () => {
  const { isDarkMode } = useUserContext();

  return (
    <div className={styles.container}>
      <SectionTitle>Boost your ADDY</SectionTitle>
      <p>
        Locking demonstrates a commitment to the long-term vision of Adamant. Along with receiving
        WMATIC performance fee dividends, users who lock ADDY receive:
      </p>
      <div className={styles.imagesSection}>
        {images.map(({ imageDark, imageLight, text }) => (
          <div key={text} className={styles.tile}>
            <div className={styles.imageWrapper}>
              <img src={isDarkMode ? imageDark : imageLight} alt="" className={styles.image} />
            </div>
            <span>{text}</span>
          </div>
        ))}
      </div>
      <Button color="primary" to={RoutingPaths.BOOST}>
        Boost ADDY
      </Button>
    </div>
  );
};

export default ExplainerSection;
