import stakingABI from '../../abis/staking_abi.json';
import strategyABI from '../../abis/strategy_abi.json';
import ERC20ABI from '../../abis/ERC20abi.json';
import { alertService } from '../../_services';
import { getDefaultGasPrice } from './GasHelper.js';

export const approve = (account, lpAddress, vaultContractAddress) => {
    const erc20 = window.web3.eth.Contract(ERC20ABI.abi, lpAddress);
    console.debug(lpAddress + " " + vaultContractAddress)

    erc20.methods.approve(vaultContractAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

export const stakeInPool = (account, vaultContractAddress, amount) => {
    const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress)

    var p = parseFloat(amount);
    console.debug('Deposit amount: ' + p);
    if (p >= 0.001) {
        vaultContract.methods.deposit(window.web3.utils.toWei(p.toString(), "ether")).send({
            from: account
        }, (e, output) => {
            if (e) {
                alertService.alertLog(e);
            } else {
                alertService.alertTx(output);
            }
        });
    }
    else {
        alertService.error("Amount too small, use the stake all button to avoid rounding errors: " + p);
    }
};

export const stakeMaxInPool = (account, vaultContractAddress) => {
  const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice() });

  vaultContract.methods.depositAll().send({
    from: account,
  }, (e, output) => {
    if (e) {
      alertService.alertLog(e);
    } else {
      alertService.alertTx(output);
    }
  });
};

export const exit = (account, vaultContractAddress) => {
  if (!window.web3.utils.isAddress(vaultContractAddress)) {
    alertService.alertLog(`Invalid address: ${vaultContractAddress}`);
    return;
  }

  const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice() });

  vaultContract.methods.withdrawAll().send({
    from: account,
  }, (e, output) => {
    if (e) {
      alertService.alertLog(e);
    } else {
      alertService.alertTx(output);
    }
  });
};

export const emergencyExit = (account, vaultContractAddress) => {
  if (!window.web3.utils.isAddress(vaultContractAddress)) {
    alertService.alertLog(`Invalid address: ${vaultContractAddress}`);
    return;
  }
  const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice() });

  vaultContract.methods.emergencyWithdraw().send({
    from: account,
  }, (e, output) => {
    if (e) {
      alertService.alertLog(e);
    } else {
      alertService.alertTx(output);
    }
  });
};

export const exitAllOldStaked = (account, stakingData) => {
  for (const vault of stakingData) {
    if (vault.amountUserStaked > 0 && vault.deprecated) {
      const pairName = `${vault.token0Name}/${vault.token1Name}`;
      console.debug(`Exiting from old vault: ${pairName}`);

      const vaultContract = window.web3.eth.Contract(stakingABI.abi, vault.vaultAddress);
      vaultContract.methods.withdrawAll().send({
        from: account,
      }, (e, output) => {
        if (e) {
          alertService.alertLog(e);
        } else {
          alertService.alertTx(output);
        }
      });
    }
  }
};

export const emergencyExitAllOldStaked = (account, stakingData) => {
  for (const vault of stakingData) {
    if (vault.amountUserStaked > 0 && vault.deprecated) {
      const pairName = `${vault.token0Name}/${vault.token1Name}`;
      console.debug(`Exiting from old vault: ${pairName}`);

      const vaultContract = window.web3.eth.Contract(stakingABI.abi, vault.vaultAddress);
      vaultContract.methods.emergencyWithdraw().send({
        from: account,
      }, (e, output) => {
        if (e) {
          alertService.alertLog(e);
        } else {
          alertService.alertTx(output);
        }
      });
    }
  }
};

export const claim = (account, vaultContractAddress) => {
  const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gasPrice: getDefaultGasPrice() });

  vaultContract.methods.claim().send({
    from: account,
  }, (e, output) => {
    if (e) {
      alertService.alertLog(e);
    } else {
      alertService.alertTx(output);
    }
  });
};

export const harvest = (account, strategyContractAddress) => {
  const strategyContract = window.web3.eth.Contract(strategyABI.abi, strategyContractAddress, { gasPrice: getDefaultGasPrice() });

  strategyContract.methods.harvest().send({
    from: account,
  }, (e, output) => {
    if (e) {
      alertService.alertLog(e);
    } else {
      alertService.alertTx(output);
    }
  });
};

export const harvestAndStakeMax = (account, vaultContractAddress, strategyContractAddress) => {
  const strategyContract = window.web3.eth.Contract(strategyABI.abi, strategyContractAddress, { gas: '5000000', gasPrice: getDefaultGasPrice() });
  const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gas: '5000000', gasPrice: getDefaultGasPrice() });

  alertService.info('Harvesting then depositing: 1/2');
  strategyContract.methods.harvest().send({
    from: account,
  }, (e, output) => {
    if (e) {
      alertService.alertLog(e);
    } else {
      vaultContract.methods.depositAll().send({
        from: account,
      }, (e2, output2) => {
        if (e2) {
          alertService.alertLog(e2);
        } else {
          alertService.alertTx(output2);
        }
      });
    }
  });
};

export const harvestAndExit = (account, vaultContractAddress, strategyContractAddress) => {
  const strategyContract = window.web3.eth.Contract(strategyABI.abi, strategyContractAddress, { gas: '5000000', gasPrice: getDefaultGasPrice() });
  const vaultContract = window.web3.eth.Contract(stakingABI.abi, vaultContractAddress, { gas: '5000000', gasPrice: getDefaultGasPrice() });

  alertService.info('Harvesting then exiting: 1/2');
  strategyContract.methods.harvest().send({
    from: account,
  }, (e, output) => {
    if (e) {
      alertService.alertLog(e);
    } else {
      vaultContract.methods.withdrawAll().send({
        from: account,
      }, (e, output) => {
        if (e) {
          alertService.alertLog(e);
        } else {
          alertService.alertTx(output);
        }
      });
    }
  });
};

// Calls the STRATEGY's deposit function
export const deposit = (account, strategyContractAddress) => {
    const strategyContract = window.web3.eth.Contract(strategyABI.abi, strategyContractAddress)

    strategyContract.methods.deposit().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}

// This calls the strategy's getReward() function, which is only visible for vaults where one cannot harvest and deposit at the same time (i.e. Cometh)
// Function removed from newer post-Merlin vaults
export const getReward = (account, strategyContractAddress) => {
    const strategyContract = window.web3.eth.Contract(strategyABI.abi, strategyContractAddress)

    strategyContract.methods.getReward().send({
        from: account
    }, (e, output) => {
        if (e) {
            alertService.alertLog(e);
        } else {
            alertService.alertTx(output);
        }
    });
}
