import React from 'react';
import { useVaultsFiltersContext } from '../../contexts/VaultsFiltersContext';
import Button from '../common/Button/Button';
import NoResultsImage from './no-results.svg';
import styles from './VaultsFiltersNoResults.module.scss';

const VaultsFiltersNoResults = () => {
  const { onClearAllFilters } = useVaultsFiltersContext();

  return (
    <div className={styles.container}>
      <img src={NoResultsImage} alt="" />
      <h3 className={styles.title}>No Results</h3>
      <p className={styles.text}>
        None of Vaults match the filters you selected. Try to change filters or clear them to see
        all Vaults
      </p>
      <Button kind="outlined" color="primary" onClick={onClearAllFilters}>
        Clear Filters
      </Button>
    </div>
  );
};

export default VaultsFiltersNoResults;
