import React, {
  createContext,
  useState,
  useContext,
  FC,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import Web3 from 'web3';
import { useLocalStorage } from 'react-use';
import { useVaultFunctions } from '../hooks/useVaultFunctions';
import { useStakeFunctions } from '../hooks/useStakeFunctions';
import { useBoostLockFunctions } from '../hooks/useBoostLockFunctions';
import { LocalStorageKeys } from '../enums/LocalStorageKeys.enum';

export const useUserContextController = () => {
  const rootElement = useRef(document.querySelector(':root'));

  const [account, setAccount] = useState('');
  const [networkId, setNetworkId] = useState(1);
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [isChooseWalletModalOpen, setIsChooseWalletModalOpen] = useState(false);
  const [showLpAmounts, setShowLpAmounts] = useState(false);
  const [isDarkMode, setIsDarkMode] = useLocalStorage<boolean>(LocalStorageKeys.IS_DARK_MODE, true);
  const vaultFunctions = useVaultFunctions(account, web3);
  const stakeFunctions = useStakeFunctions(account, web3);
  const boostLockFunctions = useBoostLockFunctions(account, web3);

  const isWalletConnected = account !== '0x0000000000000000000000000000000000000001';

  const showChooseWalletModal = useCallback(() => {
    setIsChooseWalletModalOpen(true);
  }, []);

  const hideChooseWalletModal = useCallback(() => {
    setIsChooseWalletModalOpen(false);
  }, []);

  useEffect(() => {
    rootElement?.current?.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [rootElement, isDarkMode]);

  return {
    account,
    setAccount,
    web3,
    setWeb3,
    networkId,
    setNetworkId,
    isWalletConnected,
    setShowLpAmounts,
    showLpAmounts,
    setIsDarkMode,
    isDarkMode,
    vaultFunctions,
    stakeFunctions,
    boostLockFunctions,
    isChooseWalletModalOpen,
    showChooseWalletModal,
    hideChooseWalletModal,
  } as const;
};

export const UserContext = createContext<ReturnType<typeof useUserContextController> | null>(null);

export const useUserContext = () => {
  const value = useContext(UserContext);
  if (value === null) {
    throw new Error(`[User Context] useUserContext must be called within UserContext tree`);
  }

  return value;
};

export const UserContextProvider: FC = ({ children }) => {
  const contextController = useUserContextController();

  return <UserContext.Provider value={contextController}>{children}</UserContext.Provider>;
};
