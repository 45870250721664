import React, { ReactNode, VFC } from 'react';
import Card from '../../Card/Card';
import styles from './StakeStatsTile.module.scss';
import { formatTokenAmount } from '../../../_helpers';

export interface StakeStatsTileProps {
  title: string;
  value: number;
  tokenName: string;
  buttonElement: ReactNode;
}

const StakeStatsTile: VFC<StakeStatsTileProps> = ({ title, value, tokenName, buttonElement }) => {
  return (
    <Card buttonElement={buttonElement}>
      <p className={styles.title}>{title}</p>
      <div className={styles.valueWrapper}>
        <p className={styles.amount}>{formatTokenAmount(value)}</p>
        <p className={styles.tokenName}>{tokenName}</p>
      </div>
    </Card>
  );
};

export default StakeStatsTile;
