import React, { VFC } from 'react';
import { Link } from 'react-router-dom';

import ExternalLink from '../../common/Link/Link';
import { RoutingPaths } from '../../../paths';
import { ExternalLinks } from '../../../constants';

import styles from './FooterLinks.module.scss';

const FooterLinks: VFC = () => (
  <div className={styles.groups}>
    <div className={styles.group}>
      <p className={styles.title}>Invest</p>

      <div className={styles.linksList}>
        <Link className={styles.link} to={RoutingPaths.VAULTS}>
          Vaults
        </Link>

        <Link className={styles.link} to={RoutingPaths.VESTING}>
          Vesting
        </Link>

        <Link className={styles.link} to={RoutingPaths.STAKE}>
          Stake
        </Link>

        <Link className={styles.link} to={RoutingPaths.BOOST}>
          Boost
        </Link>
      </div>
    </div>

    <div className={styles.group}>
      <p className={styles.title}>Support</p>

      <div className={styles.linksList}>
        <ExternalLink className={styles.link} href={ExternalLinks.docs} newTab>
          Documentation
        </ExternalLink>

        <ExternalLink className={styles.link} href={ExternalLinks.discord} newTab>
          Discord
        </ExternalLink>

        <ExternalLink className={styles.link} href={ExternalLinks.sourceCode} newTab>
          Source Code
        </ExternalLink>

        <ExternalLink className={styles.link} href={ExternalLinks.faq} newTab>
          FAQ
        </ExternalLink>

        <ExternalLink className={styles.link} href={ExternalLinks.legacy} newTab>
          Legacy Website
        </ExternalLink>
      </div>
    </div>

    <div className={styles.group}>
      <p className={styles.title}>Protocol</p>

      <div className={styles.linksList}>
        <Link className={styles.link} to="/">
          Introduction
        </Link>

        <ExternalLink className={styles.link} href={ExternalLinks.vote} newTab>
          Vote
        </ExternalLink>
      </div>
    </div>
  </div>
);

export default FooterLinks;
