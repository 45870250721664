import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import { approve } from '../api/actions/VaultFunctions.js';
import { getPresaleData, PRESALE_ADDRESS } from '../api/cronos/PresaleData.js'
import Loading from '../components/Loading.js';
import { NetworkID } from '../constants/index.js';
import { getAdamantTokenName } from '../api/Multichain.js'
import { getNativeTokenAddress } from '../constants/multichain/network.js';
import { alertService } from '../_services';
import PRESALE_ABI from '../abis/presale_abi.json'

class Presale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            presaleData: undefined,
            purchaseAmount: ''
        };
        this.handleAmountChange = this.handleAmountChange.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        if (window.networkId != NetworkID.CRONOS_NETWORK_ID) {
            return;
        }

        if (!this.state.loaded) {
            this.updateInfo();
            this.setState({
                loaded: true
            })
        }
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
    handleAmountChange(event) {
        this.setState({
            purchaseAmount: event.target.value
        });
    }

    render() {
        if (window.networkId != NetworkID.CRONOS_NETWORK_ID) {
            return (
                <div className="container my-5" id="presale">
                    <div className="col-6 mx-auto mb-4">
                        <Card className="shadow-sm">
                            <div className="card-body text-center">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        Please switch to the Cronos network to view this page.
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            );
        }

        if (this.state.presaleData == undefined) {
            return (
                <Loading />
            )
        }
        //console.log(this.state.presaleData)

        //need to show:
        //WETH balance
        //WETH approval for presale contract
        //amount remaining left to purchase for each user
        //amount remaining left to purchase

        const wethBalance = this.state.presaleData.wethBalance / 10 ** 18;
        const wethApproved = this.state.presaleData.wethApproved;

        const presaleWhiteListOnly = this.state.presaleData.presaleWhiteListOnly;
        const userPurchaseAmount = 50000.0 - (this.state.presaleData.userPurchaseAmount / 10 ** 18);
        const tokensAvailable = this.state.presaleData.tokensAvailable / 10 ** 18;

        let buttonText = presaleWhiteListOnly ? "Presale not started yet": `Buy ${getAdamantTokenName()}`

        return (
            <div className="container my-5" id="presale">
                <div className="eps-window white">
                    <div className="info-message danger-message">
                        The presale contract will only accept Wrapped CRO.<br/>
                        Limit: 50,000 WCRO per wallet
                    </div>

                    <Card id="exchangeAddy" className="mt-4 mb-4" style={{ backgroundColor: "rgb(123 120 120)" }}>
                        <Card.Header className="text-center" style={{ fontSize: "1.5rem" }}>Presale</Card.Header>

                        <Card.Body>
                            <div className="mt-1 mb-2"><span>Current WCRO balance in wallet: {wethBalance.toFixed(4)}</span></div>

                            <div><span>Remaining WCRO left to raise: {tokensAvailable.toFixed(4)}</span></div>

                            <div><span>Remaining user purchase limit: {userPurchaseAmount.toFixed(4)} WCRO</span></div>

                            <div className="mt-2">
                                <div className="input-group">
                                        <input type="string" className="form-control noValidate" placeholder="Amount to purchase" value={this.props.value}
                                        onChange={this.handleAmountChange} />
                                </div>

                                {wethApproved > 0 ?
                                    <button className={"btn btn-sm btn-primary col-12 col-md-6 mt-2 mb-2" + (presaleWhiteListOnly ? " disabled": "")}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.buyAddyPresale(this.state.purchaseAmount, this.props.account);
                                        }
                                        }>{buttonText}
                                    </button>
                                    :
                                    <div>
                                        <button className="btn btn-sm btn-primary col-12 col-md-6 mt-2 mb-2" onClick={(event) => {
                                            event.preventDefault();
                                            approve(this.props.account, getNativeTokenAddress(), PRESALE_ADDRESS);
                                        }
                                        }>Approve WCRO</button>
                                    </div>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }

    updateInfo() {
        try {
            getPresaleData(window.web3, this.props.account).then((presaleData) => {
                this.setState({
                    presaleData: presaleData
                })
                this.setState({
                    loaded: true
                })
                if (!this.timer) {
                    console.log("Refreshing info");
                    this.timer = setInterval(() => this.updateInfo(), 3000);
                }
            });
        }
        catch {
            console.log("Stats: Failed to load presale data")
            this.setState({
                loaded: false
            })
            setTimeout(() => this.updateInfo(), 500)
        }
    }

    buyAddyPresale(amount) {
        if(amount == null || amount == NaN || amount == 0) return;

        const presaleContract = window.web3.eth.Contract(PRESALE_ABI.abi, PRESALE_ADDRESS)

        var p = parseFloat(amount);
        console.log('Deposit amount: ' + p);

        let amountWei = window.web3.utils.toWei(p.toString(), "ether");
        presaleContract.methods.buyTokens(amountWei).send({ from: this.props.account }, (e, output) => {
            if (e) {
                alertService.alertLog(e);
            } else {
                alertService.alertTx(output);
            }
        });
      }
}

export default Presale;