export function getAddresses (list) {
  var addresses = [];
  for (let i = 0; i < list.length; i++) {
    addresses.push(list[i].vaultAddress);
  }
  return addresses;
}

export function concatArrays (a, b) {
  for(const pool of b) {
    a.push(pool)
  }
}

export function isZero (x) {
  if (typeof x.isZero !== 'undefined') {
    return x.isZero()
  } else {
    return x === 0
  }
}

export function nFormatter(num, digits = 2) {
  const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
      return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}