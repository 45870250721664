import { NetworkID } from '../../constants';

const DEFAULT_GAS_PRICE = '30000000000' //30 gwei
const DEFAULT_GAS_PRICE_ARBITRUM = '3000000000' //3 gwei
const DEFAULT_GAS_PRICE_CRONOS = '5000000000000' //5000 gwei

export function getDefaultGasPrice() {
    if(window.networkId === NetworkID.POLYGON_NETWORK_ID) {
        return DEFAULT_GAS_PRICE;
    }
    else if(window.networkId === NetworkID.CRONOS_NETWORK_ID) {
        return DEFAULT_GAS_PRICE_CRONOS;
    }
    return DEFAULT_GAS_PRICE_ARBITRUM;
}