import React, { useState, VFC } from 'react';
import { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import bgParticles1 from './bg-stats-tiles-particles-1.png';
import bgParticles2 from './bg-stats-tiles-particles-2.png';
import bgParticles3 from './bg-stats-tiles-particles-3.png';
import VestingStatsTile from './VestingStatsTile/VestingStatsTile';
import Button from '../common/Button/Button';
import { useCompletedVestTile } from './hooks/useCompletedVestTile';
import { getAdamantTokenName } from '../../api/Multichain';
import { useClaimableRewardsTile } from './hooks/useClaimableRewardsTile';
import { getNativeTokenName } from '../../constants/multichain/network';
import { useCurrentVestingTile } from './hooks/useCurrentVestingTile';
import { formatTokenAmount } from '../../_helpers';
import WithdrawVestingEarlyModal from '../WithdrawVestingEarlyModal/WithdrawVestingEarlyModal';
import styles from './VestingStatsTile/VestingStatsTile.module.scss';

export interface VestStatsTilesProps {
  addyData: any;
}

const breakpoints: {
  [width: number]: SwiperOptions;
  [ratio: string]: SwiperOptions;
} = {
  769: {
    slidesPerView: 3,
    spaceBetween: 22,
    enabled: false,
  },
  1025: {
    slidesPerView: 3,
    spaceBetween: 32,
    enabled: false,
  },
  1201: {
    slidesPerView: 3,
    spaceBetween: 64,
    enabled: false,
  },
};

const VestingStatsTiles: VFC<VestStatsTilesProps> = ({ addyData }) => {
  const completedVestTile = useCompletedVestTile(addyData);
  const claimableRewardsTile = useClaimableRewardsTile(addyData);
  const currentVestingTile = useCurrentVestingTile(addyData);

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const handleWithdrawButtonClick = () => {
    if (currentVestingTile.penaltyAmount === 0) return;
    setIsWithdrawModalOpen(true);
  };

  return (
    <>
      <Swiper
        enabled
        centeredSlides
        centeredSlidesBounds
        slidesPerView={1.4}
        spaceBetween={22}
        breakpoints={breakpoints}
      >
        <SwiperSlide>
          <VestingStatsTile
            bgImage={bgParticles1}
            title="Vesting"
            value={currentVestingTile.vestingAmount}
            tokenName={getAdamantTokenName()}
            desc={`+ ${formatTokenAmount(currentVestingTile.unlockedBalance)}
            ${getAdamantTokenName()} waiting to be vested
          `}
            buttonElement={
              <Button
                disabled={currentVestingTile.penaltyAmount === 0}
                onClick={handleWithdrawButtonClick}
                className={styles.withdrawButton}
              >
                Withdraw early - 50% penalty
              </Button>
            }
          />
        </SwiperSlide>

        <SwiperSlide>
          <VestingStatsTile
            bgImage={bgParticles2}
            title="Vest Complete"
            value={completedVestTile.completedVestAmount}
            tokenName={getAdamantTokenName()}
            buttonElement={
              <Button
                color="primary"
                onClick={completedVestTile.claim}
                disabled={
                  completedVestTile.isClaimPending || completedVestTile.completedVestAmount === 0
                }
              >
                Claim all
              </Button>
            }
          />
        </SwiperSlide>

        <SwiperSlide>
          <VestingStatsTile
            bgImage={bgParticles3}
            title="Claimable rewards"
            value={claimableRewardsTile.claimableWmatic}
            tokenName={getNativeTokenName()}
            buttonElement={
              <Button
                onClick={claimableRewardsTile.claim}
                disabled={
                  claimableRewardsTile.isClaimPending || claimableRewardsTile.claimableWmatic === 0
                }
                color="primary"
              >
                Claim all
              </Button>
            }
          />
        </SwiperSlide>
      </Swiper>

      {currentVestingTile.penaltyAmount > 0 && (
        <WithdrawVestingEarlyModal
          addyData={addyData}
          isOpen={isWithdrawModalOpen}
          onClose={() => setIsWithdrawModalOpen(false)}
        />
      )}
    </>
  );
};

export default VestingStatsTiles;
