import React, { FC, VFC } from 'react';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import boostIconDark from './boost-icon-dark.svg';
import boostIconLight from './boost-icon-light.svg';
import degenerateIcon from './degenerate-icon.svg';
import inactiveIcon from './archive-icon.svg';
import stablecoinsIconDark from './stablecoins-icon-dark.svg';
import stablecoinsIconLight from './stablecoins-icon-light.svg';
import styles from './VaultTag.module.scss';
import { RoutingPaths } from '../../../../paths';
import { getAdamantTokenName } from '../../../../api/Multichain';
import { useUserContext } from '../../../../contexts/UserContext';

interface Props {
  type: 'boostable' | 'degenerate' | 'inactive' | 'stablecoins';
  size?: 'small' | 'default';
}

const propsMap = {
  boostable: {
    tagClassName: styles.boostableTag,
    iconClassName: styles.boostableIcon,
    darkIcon: boostIconDark,
    lightIcon: boostIconLight,
    text: 'Boostable',
    popupBody: (
      <>
        Investments from this Vault are increased proportionally to the Boost Points you have. You
        can get Boost Points by locking your {getAdamantTokenName()} on the{' '}
        <Link to={RoutingPaths.STAKE_ADDY}>Boost page.</Link>
      </>
    ),
  },
  degenerate: {
    tagClassName: styles.degenerateTag,
    iconClassName: styles.degenerateIcon,
    darkIcon: degenerateIcon,
    lightIcon: degenerateIcon,
    text: 'Degen',
    popupBody:
      'Chad embodies the animal spirits of Degenerates. These vaults may be more volatile. Adamant helps to auto-harvest these vaults for you, saving you gas and maximizing your yields.',
  },
  inactive: {
    tagClassName: styles.inactiveTag,
    iconClassName: styles.inactiveIcon,
    darkIcon: inactiveIcon,
    lightIcon: inactiveIcon,
    text: 'Inactive',
    popupBody: null,
  },
  stablecoins: {
    tagClassName: styles.stablecoinsTag,
    iconClassName: styles.stablecoinsIcon,
    darkIcon: stablecoinsIconDark,
    lightIcon: stablecoinsIconLight,
    text: 'Stablecoins',
    popupBody: null,
  },
};

const VaultTagContent: VFC<Props> = ({ type, size }) => {
  const { isDarkMode } = useUserContext();

  const icon = isDarkMode ? propsMap[type].darkIcon : propsMap[type].lightIcon;

  return (
    <div
      className={classNames(
        styles.tag,
        propsMap[type].tagClassName,
        size === 'small' ? styles.sizeSmall : styles.sizeDefault,
      )}
    >
      <img className={classNames(styles.icon, propsMap[type].iconClassName)} src={icon} alt="" />

      {size === 'default' && propsMap[type].text}
    </div>
  );
};

const VaultTag: FC<Props> = (props) => {
  const { type } = props;

  return (
    <Popup
      disabled={!propsMap[type].popupBody}
      position="bottom left"
      on={['hover']}
      arrow={false}
      offsetY={8}
      trigger={
        <div>
          <VaultTagContent {...props} />
        </div>
      }
    >
      <div className={styles.popup}>{propsMap[type].popupBody}</div>
    </Popup>
  );
};

export default VaultTag;
