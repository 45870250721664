import React, { useCallback, VFC } from 'react';

import Button from '../common/Button/Button';
import { useUserContext } from '../../contexts/UserContext';

import NotConnectedImage from './not-connected-image.svg';
import ConnectedImage from './connected-image.svg';
import styles from './MyVaultsEmpty.module.scss';

interface MyVaultsEmptyProps {
  onGoToVaults: () => void;
}

const MyVaultsEmpty: VFC<MyVaultsEmptyProps> = ({ onGoToVaults }) => {
  const { isWalletConnected, showChooseWalletModal } = useUserContext();
  const handleCtaClick = useCallback(() => {
    if (isWalletConnected) {
      onGoToVaults();
      return;
    }
    showChooseWalletModal();
  }, [isWalletConnected, showChooseWalletModal, onGoToVaults]);

  return (
    <div className={styles.container}>
      <img src={isWalletConnected ? ConnectedImage : NotConnectedImage} alt="" />
      <h3 className={styles.title}>
        {isWalletConnected ? "This is where you'll see your investments" : 'Nothing here'}
      </h3>
      <p className={styles.subtitle}>
        {isWalletConnected
          ? 'Take a look at Vaults and choose which ones you want to invest in'
          : 'Connect wallet to view your Vaults'}
      </p>
      <Button color="primary" kind="outlined" onClick={handleCtaClick}>
        {isWalletConnected ? 'Go to Vaults' : 'Connect Wallet'}
      </Button>
    </div>
  );
};

export default MyVaultsEmpty;
