import React, { VFC } from 'react';
import classNames from 'classnames';

import SectionTitle from '../SectionTitle/SectionTitle';
import Link from '../common/Link/Link';
import { RoutingPaths } from '../../paths';
import { getAdamantTokenName } from '../../api/Multichain';
import { getNativeTokenName } from '../../constants/multichain/network';
import { useUserContext } from '../../contexts/UserContext';
import { NetworkID } from '../../constants';

import styles from './ComparisonSection.module.scss';

const CheckIcon = () => <img src="/icons/CheckIcon.svg" alt="Check icon" />;

interface ComparisonSectionProps {
  vestingPage?: boolean;
}

const ComparisonSection: VFC<ComparisonSectionProps> = ({ vestingPage }) => {
  const { networkId, isDarkMode } = useUserContext();
  const adamantTokenName = getAdamantTokenName();

  const getTokenName = () => {
    if (networkId === NetworkID.POLYGON_NETWORK_ID) {
      return 'WMATIC';
    }
    return getNativeTokenName();
  };

  const nativeTokenName = getTokenName();

  return (
    <div className={classNames(styles.container, vestingPage && styles.vestingPage)}>
      <img
        className={styles.bgLogo}
        src={isDarkMode ? '/assets/AdamantLogo-dark.svg' : '/assets/AdamantLogo-light.svg'}
        alt=""
      />

      {vestingPage ? (
        <h4 className={styles.titleVesting}>
          Comparison of Vesting, Staking and Locking {adamantTokenName}
        </h4>
      ) : (
        <SectionTitle>
          Comparison of {adamantTokenName} Vesting, Stake and Lock for Boost
        </SectionTitle>
      )}
      <p>
        Vested, Staked, and Locked for Boost {adamantTokenName} all earn the same % of performance
        fees.
        <br />
        This means that 1 vested or staked {adamantTokenName} will earn the same % of the
        performance fee as 1 locked for boost {adamantTokenName}.
      </p>
      <div className={classNames(styles.comparisonWrapper, vestingPage && styles.vestingPage)}>
        <div className={classNames(styles.rowHeaders, styles.textWrapper)}>
          <span>Lock duration</span>
          <span>Early withdrawal penalty</span>
          <span>Earn performance fees in {nativeTokenName}</span>
          <span>Earn early withdrawal penalty fees</span>
          <span>Boost {adamantTokenName} earnings in Vaults</span>
        </div>
        <div className={styles.card}>
          <h5 className={styles.cardTitle}>Vesting</h5>
          <div className={styles.textWrapper}>
            <span>90 days</span>
            <span>50%</span>
            <span>
              <CheckIcon />
            </span>
            <span>-</span>
            <span>-</span>
          </div>
        </div>
        <div className={styles.card}>
          <h5 className={styles.cardTitle}>Stake</h5>
          <div className={styles.textWrapper}>
            <span>-</span>
            <span>No penalty</span>
            <span>
              <CheckIcon />
            </span>
            <span>-</span>
            <span>-</span>
          </div>
          {vestingPage && (
            <Link href={RoutingPaths.STAKE} color="primary" className={styles.subCardLink}>
              Check Stake
            </Link>
          )}
        </div>
        <div className={classNames(styles.card, styles.cardBig)}>
          <h5 className={styles.cardTitle}>
            Lock for{' '}
            <b>
              Boost{' '}
              <img
                className={styles.cardTitleUnderline}
                src={
                  isDarkMode
                    ? '/assets/boost-underline-dark.svg'
                    : '/assets/boost-underline-light.svg'
                }
                alt=""
              />
            </b>
          </h5>
          <div className={styles.textWrapper}>
            <span>91 days - 4 years</span>
            <span>Cannot withdraw at all</span>
            <span>
              <CheckIcon />
            </span>
            <span>
              <CheckIcon />
            </span>
            <span>
              <CheckIcon />
            </span>
          </div>
          {vestingPage && (
            <Link href={RoutingPaths.BOOST} color="primary" className={styles.subCardLink}>
              Check Boost
            </Link>
          )}
        </div>
      </div>
      {!vestingPage && (
        <div className={styles.linkWrapper}>
          <Link
            href="https://adamantfinance.gitbook.io/adamant-finance/guides/comparison-of-vesting-staking-locking"
            newTab
            color="primary"
          >
            Read more in our Documentation
          </Link>
        </div>
      )}
    </div>
  );
};

export default ComparisonSection;
