import React, { VFC } from 'react';
import dayjs from 'dayjs';
import Tooltip from '../common/Tooltip/Tooltip';
import styles from './VestingGroupsInfoBox.module.scss';
import { getAdamantTokenName } from '../../api/Multichain';
import { useUserContext } from '../../contexts/UserContext';
import getTimeOffset from '../../_helpers/getTimeOfffset';
import { formatTokenAmount } from '../../_helpers';

interface VestingGroupsInfoBoxProps {
  addyData: any;
}

const VestingGroupsInfoBox: VFC<VestingGroupsInfoBoxProps> = ({ addyData }) => {
  const { isWalletConnected } = useUserContext();

  const unlockedBalance = addyData.unlockedBalance / 10 ** 18;

  const nextGroupStartTimestamp = addyData.nextLockStart;
  const nextGroupStartDate = dayjs.unix(nextGroupStartTimestamp);

  const timeZone = `${getTimeOffset()} time zone`;

  return (
    <div className={styles.container}>
      <p className={styles.title}>Vests are grouped by week</p>

      <div className={styles.text}>
        Next vesting group starts on <b>{nextGroupStartDate.format('DD MMM YYYY [at] hh:mm a')}</b>
        {isWalletConnected && (
          <>
            , so far you have <b>{formatTokenAmount(unlockedBalance)}</b> {getAdamantTokenName()} in
            it
          </>
        )}
        <Tooltip content={timeZone}>
          <img src="/icons/InfoIcon.svg" alt="Info icon" />
        </Tooltip>
      </div>

      <p className={styles.postscript}>
        Invest in Vaults and Claim the rewards to add them to the closest starting vesting group
      </p>
    </div>
  );
};

export default VestingGroupsInfoBox;
