import React, { VFC } from 'react';

import styles from './VaultCardBodyMain.module.scss';
import VaultCardBodyStats from './VaultCardBodyStats/VaultCardBodyStats';
import VaultCardBodyActions from './VaultCardBodyActions/VaultCardBodyActions';

interface Props {
  vault: any;
}

const VaultCardBodyMain: VFC<Props> = ({ vault }) => {
  return (
    <div>
      <div className={styles.statsSection}>
        <VaultCardBodyStats vault={vault} />
      </div>
      <div className={styles.divider}>
        <p className={styles.dividerText}>{vault.tooltip}</p>
        <div className={styles.dividerBar} />
      </div>
      <div className={styles.actionsSection}>
        <VaultCardBodyActions vault={vault} />
      </div>
    </div>
  );
};

export default VaultCardBodyMain;
