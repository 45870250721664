import React, { Component } from 'react';
import '../css/Main.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faMinusSquare, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { getReward } from '../api/actions/StakingRewardFunctions.js'
import { isZero } from '../utils'

class FurnacePoolCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '0',
            expanded: false
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({
            input: event.target.value
        });
    }
    scrollTo(event) {
        event.target.scrollIntoView({block: "center"});
    }

    toggleExpander = (event) => {
        if (!this.state.expanded) {
            this.setState({ expanded: true });
            //this.scrollTo(event);
        }
        else {
            this.setState({ expanded: false });
        }
    }

    render() {
        //console.log(this.props.pool)
        // const isLoggedIn = this.props.isLoggedIn;
        const pool = this.props.pool;

        const ratio = 1;
        let roi = pool.roi;
        if(roi === undefined) {
            roi = 0;
        }
        else {
            roi = roi.toFixed(2);
        }

        // const stakingRewards = pool.stakingRewards;
        const totalStaked = pool.totalShares;
        const userLpBalance = pool.userLpBalance;
        const lpPrice = pool.lpPrice;
        const amountUserStaked = pool.amountUserStaked;
        const rewardsTokenName = pool.rewardsTokenName;

        const deprecated = pool.deprecated || pool.rewardsPerDay === 0;
        const pendingReward = pool.pendingReward / 10 ** 18;
        const claimButtonVisible = amountUserStaked > 0 && pendingReward > 0;

        let poolYieldString = "ROI: " + (roi > 0 ? "+": "") + roi + "%";

        if(deprecated) {
            poolYieldString = "Rewards Ended";
        }
        else if(roi < 0) {
            poolYieldString = "Deposits Closed";
        }

        let buttonText = "Claim " + (pendingReward >= 0.01 ? pendingReward.toFixed(2): "< 0.01") + " " + rewardsTokenName;

        var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', });

        let poolName = pool.nameOverride ? pool.nameOverride: rewardsTokenName;

        return [
            <div key="collapsed" className={"farms-card-item no-select clickable" + (this.state.expanded ? " expanded": " collapsed")} onClick={this.toggleExpander}>
                <div className="icon">
                    <div className="card-icon no-select">
                        <FontAwesomeIcon className="poolExpand" icon={this.state.expanded ? faMinusSquare : faPlusSquare} />
                        <div className="poolRowTokenLeft">
                          <div className="square">
                            <img src={"/img/" + pool.rewardsTokenName + ".png"} alt="token icon"></img>
                          </div>
                        </div>
                    </div>
                </div>

                <div className="label">
                    <span>
                        {poolName}
                    </span>
                </div>

                <div className="rates">
                    <span className={deprecated ? "deprecated": "apy"}>{poolYieldString}</span>
                </div>

                <div className="details return">
                    <span className="label">Earn</span>
                    <span className="value">{rewardsTokenName}</span>
                </div>

                <div className="details balance">
                    <span className="label">User Smelted</span>
                    <span className="value">
                        {this.props.showLpAmounts ?
                            (amountUserStaked * ratio / 10 ** 18).toFixed(4) + " ADDY":
                            formatter.format(lpPrice * amountUserStaked * ratio / 10 ** 18)
                        }
                    </span>
                </div>

                <div className="details total">
                    <span className="label">Total ADDY Smelted</span>
                    <span className="value">
                        {this.props.showLpAmounts ?
                            (totalStaked / 10 ** 18).toFixed(4) + " ADDY":
                            formatter.format(lpPrice * totalStaked / 10 ** 18)
                        }
                    </span>
                </div>
            </div>,
            this.state.expanded && (
                <div key="expanded" className="farms-card-expandable">
                    <div className="pool-row-background" colSpan={6}>
                        <div className="row align-items-center text-center">
                            <div id="tableRowLeft" className="col-12 col-md-6 mb-4 pb-3">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <LpBalance userLpBalance={userLpBalance} rewardsTokenName={rewardsTokenName} />
                                    </div>

                                    <div className="col-6 pl-2">
                                        <a href={pool.website} target="_blank" rel="noopener noreferrer"
                                                className={"btn btn-block btn-primary"}>Partner Website <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                                    </div>

                                    <div className="col-6 pl-2">
                                        <button className={"btn btn-block btn-success" + (userLpBalance > 0 && !deprecated && (roi > 0 || totalStaked == 0) ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.props.showStakingModal(-1, pool.vaultAddress);
                                            }
                                        }>Smelt ADDY</button>
                                    </div>
                                </div>
                            </div>

                            <div id="tableRowRight" className="col-12 col-md-6 mb-4 pb-3">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <RemainingDuration periodFinish={pool.periodFinish} />
                                    </div>

                                    {claimButtonVisible && <div className="col-12 mb-2 mt-2">
                                        <button className="btn btn-block btn-primary"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                getReward(this.props.account, pool.vaultAddress);
                                            }
                                            }>{buttonText}</button>
                                    </div>}

                                    {!claimButtonVisible && <div className="col-12 mb-2 mt-4"><p></p></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        ];
    }

    getExchangeUrl(pool) {
        return "https://quickswap.exchange/#";
    }
}

export default FurnacePoolCard;


class LpBalance extends Component {
    getMyLpBalanceString(userLpBalance) {
        if(isZero(userLpBalance)) {
            return "0";
        }
        if(userLpBalance / 10 ** 18 > 10 ** -8) {
            return (userLpBalance / 10 ** 18).toFixed(8);
        }
        return userLpBalance + "";
    }

    render() {
        let small = this.props.userLpBalance > 0 && this.props.userLpBalance / 10 ** 18 < 10 ** -8 ;

        return (
            <div className="pool-row-desc">
                <span className="big">{this.getMyLpBalanceString(this.props.userLpBalance)}</span>
                {small ?
                    <div>My ADDY Balance (* 10<sup>-18</sup>)</div>:
                    <div>My ADDY Balance</div>
                }
            </div>
        );
    }
}

class RemainingDuration extends Component {
    getMyTokensStakedString(balance) {
        if(isZero(balance)) {
            return "0";
        }
        if(balance / 10 ** 18 > 10 ** -8) {
            return (balance / 10 ** 18).toFixed(8);
        }
        return balance + "";
    }

    msToTime(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }

        var days = Math.floor(s / 86400000)
        s = s % 86400000;
        var hrs = Math.floor(s / 3600000);
        s = s % 3600000;
        var mins = Math.floor(s / 60000);
        s = s % 60000;
        var secs = Math.floor(s / 1000);
        var ms = s % 1000;

        if (hrs < 0) {
            return pad(mins) + ' mins, ' + pad(secs) + ' secs';
        }
        if (days < 0) {
            return pad(hrs) + ' hrs, ' + pad(mins) + ' mins';
        }
        return days + ' days, ' + pad(hrs) + ' hrs, ' + pad(mins) + ' mins';
    }


    render() {
        let diff = (this.props.periodFinish * 1000) - Date.now();
        let diffString;
        if(diff < 0) {
            diffString = "Ended";
        }
        else {
            diffString = this.msToTime(diff);
        }

        return (
            <div className="pool-row-desc">
                <span className="big">{diffString}</span>
                <div>Until Rewards End</div>
            </div>
        );
    }
}
