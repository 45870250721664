import React, { Component } from 'react';
import '../css/Main.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { exit, getReward, approve, stake, withdraw, withdrawLocked, migrateLockedStake } from '../api/actions/StakingRewardFunctions.js'

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {DailyVolume} from '../api/GraphData.js'
import { isZero } from '../utils'

class AddyWethPoolCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '0',
            expanded: false
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({
            input: event.target.value
        });
    }
    scrollTo(event) {
        event.target.scrollIntoView({block: "center"});
    }

    toggleExpander = (event) => {
        if (!this.state.expanded) {
            this.setState({ expanded: true });
            //this.scrollTo(event);
        }
        else {
            this.setState({ expanded: false });
        }
    }

    render() {
        //console.log(this.props.pool)
        // const isLoggedIn = this.props.isLoggedIn;
        const pool = this.props.pool;

        const ratio = 1;
        const apr = pool.apr >= 0 ? pool.apr.toFixed(2): "Error";
        const maxAPR = pool.apr >= 0 ? (pool.apr*3).toFixed(2): "Error";

        const apy_base = pool.apy_base >= 0 ? pool.apy_base.toFixed(2): "Error";
        const apy_boosted = pool.apy_boosted >= 0 ? pool.apy_boosted.toFixed(2): "Error";

        // const stakingRewards = pool.stakingRewards;
        const totalStaked = pool.totalShares;
        const userLpBalance = pool.userLpBalance;
        const lpPrice = pool.lpPrice;
        const lpTokenApproved = pool.lpTokenApproved;
        const amountUserStaked = pool.amountUserStaked;
        const stakeTokenName = pool.token0Name + "/" + pool.token1Name + " LP";
        //console.log(lpTokenApproved)

        const token0 = pool.token0;
        const token1 = pool.token1;
        const deprecated = pool.deprecated || pool.rewardsPerDay === 0;
        const pendingReward = pool.pendingReward / 10 ** 18;
        const claimButtonVisible = amountUserStaked > 0 && pendingReward > 0;

        // const baseApy = pool.baseApy;
        const addyFeeShareApr = pool.addyFeeShareApr;
        const totalAddyApr = pool.totalAddyApr.toFixed(2);

        let poolYieldString = apy_boosted + "% Max APY";
        let poolRateString = apy_base + "% Base APY";
        let poolYieldTooltipString = "APR:\r\n"
                                    + `Max: ${maxAPR}% \r\n`
                                    + `Base: ${apr}%\r\n`
                                    + "APR can be boosted by up to 3x by locking.\r\n"
                                    + "The ADDY/WETH pool does not auto-compound. However, users can manually compound to achieve the projected APY.\r\n\r\n"
                                    + "The number shown here does not include income from LP fees or compounding fee-sharing dividends into more LP tokens.\r\n"
                                    + "Multipliers from other ADDY boost contracts do not affect this vault.";

        let description = "ADDY LP Rewards";

        if(deprecated) {
            poolYieldString = "Pool Replaced";
            poolYieldTooltipString = "This pool has been replaced with a new one. Please migrate to the new pool.";
        }

        let buttonText = "Claim + Vest " + (pendingReward >= 0.01 ? pendingReward.toFixed(2): "< 0.01") + " ADDY";
        let earnString = "ADDY";

        let exchangeURL = this.getExchangeUrl(pool);

        var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', });
        const renderTooltip = props => (
            <Tooltip className="tooltip-yield" {...props} show={props.show.toString()}>
                {poolYieldTooltipString}
                {<DailyVolume id={pool.lpAddress} tvl={pool.poolTvl} pool={pool} />}
            </Tooltip>
        );

        let poolName = stakeTokenName;

        return [
            <div key="collapsed" className={"farms-card-item highlight no-select clickable" + (this.state.expanded ? " expanded": " collapsed")} onClick={this.toggleExpander}>
                <div className="icon">
                    <div className="card-icon no-select">
                        <FontAwesomeIcon className="poolExpand" icon={this.state.expanded ? faMinusSquare : faPlusSquare} />
                        <div className="poolRowTokenLeft">
                          <div className="square">
                            <img src={"/img/" + pool.token0Name + ".png"} alt="token icon"></img>
                          </div>
                        </div>
                        <div className="poolRowTokenRight">
                          <div className="square">
                            <img src={"/img/" + pool.token1Name + ".png"} alt="token icon"></img>
                          </div>
                        </div>
                    </div>
                </div>

                <div className="label">
                    <span>
                        {poolName}
                    </span>
                    <div className="tag-group">
                        {<div className="tag">
                            {pool.platform}
                        </div>}
                    </div>
                </div>

                <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <div className="rates">
                        <span className={deprecated ? "deprecated": "apy"}>{poolYieldString}</span>
                        {!deprecated && <span className="apr">{poolRateString}</span>}
                        <span className="description">{description}</span>
                    </div>
                </OverlayTrigger>

                <div className="details return">
                    <span className="label">Earn</span>
                    <span className="value">{earnString}</span>
                </div>

                <div className="details balance">
                    <span className="label">User Staked</span>
                    <span className="value">
                        {this.props.showLpAmounts ?
                            (amountUserStaked * ratio / 10 ** 18).toFixed(4) + " " + stakeTokenName:
                            formatter.format(lpPrice * amountUserStaked * ratio / 10 ** 18)
                        }
                    </span>
                </div>

                <div className="details total">
                    <span className="label">Total Staked</span>
                    <span className="value">
                        {this.props.showLpAmounts ?
                            (totalStaked / 10 ** 18).toFixed(4) + " " + stakeTokenName:
                            formatter.format(lpPrice * totalStaked / 10 ** 18)
                        }
                    </span>
                </div>
            </div>,
            this.state.expanded && (
                <div key="expanded" className="farms-card-expandable highlight">
                    <div className="pool-row-background" colSpan={6}>
                        <div className="row align-items-center text-center">

                            <div id="tableRowLeft" className="col-12 col-md-6 mb-4 pb-3">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <LpBalance userLpBalance={userLpBalance} stakeTokenName={stakeTokenName} />
                                    </div>

                                    <div className="col-6 pl-2">
                                        <a href={exchangeURL + "/add/" + token0 + "/" + token1} target="_blank" rel="noopener noreferrer"
                                                className={"btn btn-block btn-primary"}>+/- Liquidity</a>
                                    </div>

                                    {isZero(lpTokenApproved) && !pool.newPool && <div className="col-6 pl-2">
                                        <button className={"btn btn-block btn-success" + (userLpBalance > 0 && !deprecated ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                approve(this.props.account, pool.stakingRewards, pool.lpAddress);
                                            }
                                        }>Approve</button>
                                    </div>}

                                    {lpTokenApproved > 0 && !pool.newPool && <div className="col-6 pl-2">
                                        <button className={"btn btn-block btn-success" + (userLpBalance > 0 && !deprecated ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                stake(this.props.account, pool.stakingRewards, userLpBalance, true);
                                            }
                                        }>Stake All</button>
                                    </div>}

                                    {pool.newPool && <div className="col-6 pl-2">
                                        <button className="btn btn-block btn-success disabled"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.props.showAddyStakingModal(pool.index, pool.vaultAddress);
                                            }
                                        }>Stake</button>
                                    </div>}

                                    {claimButtonVisible && <div className="col-12 mb-2 mt-4"><p></p></div>}
                                </div>
                            </div>

                            <div id="tableRowRight" className="col-12 col-md-6 mb-4 pb-3">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <StakedBalance amountUserStaked={amountUserStaked} ratio={ratio} stakeTokenName={stakeTokenName} />
                                    </div>

                                    {!pool.newPool && <div id="exitButton" className="col-12 mb-2 mt-2">
                                        <button className={"btn btn-block btn-warning" + (amountUserStaked > 0 ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                exit(this.props.account, pool.stakingRewards);
                                            }
                                            }>Exit</button>
                                    </div>}

                                    {pool.newPool && <div id="exitButton" className="col-12 mb-2 mt-2">
                                        <button className={"btn btn-block btn-warning" + (amountUserStaked > 0 ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                withdraw(this.props.account, pool.stakingRewards, pool.unlockedBalance);
                                            }
                                            }>Exit</button>
                                    </div>}

                                    {claimButtonVisible && <div className="col-12 mb-2 mt-2">
                                        <button className="btn btn-block btn-primary"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                getReward(this.props.account, pool.stakingRewards);
                                            }
                                            }>{buttonText}</button>
                                    </div>}
                                </div>
                            </div>

                            {pool.lockedStakes.length > 0 && <div id="lockedStakes" className="col-12 mb-4 pb-3">
                                <div className="row align-items-center">
                                    <div className="info-message gentle-message mb-3 no-select" style={{maxWidth: "80%"}}>
                                        Each LP token can be exchanged for roughly 86 ADDY staked in a new Lock Plus contract.<br />
                                        The new lock duration will be up to six months shorter than the remaining duration of your locked LP stake.<br />
                                        The ADDY will earn ADDY + MATIC dividends.<br />
                                        It will take some time for the ADDY to be credited to your Lock Plus stake due to the timelock.<br />
                                        Exchanging your LP tokens is <b>optional.</b>
                                    </div>

                                    <div className="info-message gentle-message mb-3 no-select">
                                        Please claim any pending ADDY before clicking the Exchange button.
                                    </div>

                                    <div className="col-12 mb-2">
                                        <LockedBalance totalLocked={pool.totalLocked} stakeTokenName={stakeTokenName} />
                                    </div>

                                    <div className="col-1" />
                                    <table className="table table-dark col-12 col-md-10">
                                        <thead>
                                            <tr className="pointer">
                                                <th scope="col">Amount</th>
                                                <th scope="col">Multiplier</th>
                                                <th scope="col">APR</th>
                                                <th scope="col" colSpan="2">Expiry</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {pool.lockedStakes.map((data, key) => {
                                                let amount = data.amount / 10 ** 18;
                                                let multiplier = data.multiplier / 1e6;
                                                let dateUnlocked = new Date(data.ending_timestamp * 1000);
                                                if(isZero(amount)) {
                                                    return (<div key={"hidden" + key}></div>)
                                                }

                                                if(dateUnlocked <= Date.now()) {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{amount.toFixed(4)}</td>
                                                            <td>{(multiplier).toFixed(4)}</td>
                                                            <td>{(apr * multiplier).toFixed(2)}%</td>
                                                            <td><button className="btn btn-sm btn-primary"
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        withdrawLocked(this.props.account, pool.stakingRewards, data.kek_id);
                                                                    }
                                                                    }>Withdraw Stake
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                return (
                                                    <tr key={key}>
                                                        <td>{amount.toFixed(4)}</td>
                                                        <td>{(multiplier).toFixed(4)}</td>
                                                        <td>{(apr * multiplier).toFixed(2)}%</td>
                                                        <td>{dateUnlocked.toUTCString()}</td>
                                                        {<td><button className="btn btn-sm btn-primary"
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    migrateLockedStake(this.props.account, pool.stakingRewards, data.kek_id);
                                                                }
                                                                }>Exchange
                                                            </button>
                                                        </td>}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="col-1" />
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            )
        ];
    }

    getExchangeUrl(pool) {
        return "https://quickswap.exchange/#";
    }
}

export default AddyWethPoolCard;


class LpBalance extends Component {
    getMyLpBalanceString(userLpBalance) {
        if(isZero(userLpBalance)) {
            return "0";
        }
        if(userLpBalance / 10 ** 18 > 10 ** -8) {
            return (userLpBalance / 10 ** 18).toFixed(8);
        }
        return userLpBalance + "";
    }

    render() {
        let small = this.props.userLpBalance > 0 && this.props.userLpBalance / 10 ** 18 < 10 ** -8 ;

        return (
            <div className="pool-row-desc">
                <span className="big">{this.getMyLpBalanceString(this.props.userLpBalance)}</span>
                {small ?
                    <div>My {this.props.stakeTokenName} Balance (* 10<sup>-18</sup>)</div>:
                    <div>My {this.props.stakeTokenName} Balance</div>
                }
            </div>
        );
    }
}

class StakedBalance extends Component {
    getMyTokensStakedString(balance) {
        if(isZero(balance)) {
            return "0";
        }
        if(balance / 10 ** 18 > 10 ** -8) {
            return (balance / 10 ** 18).toFixed(8);
        }
        return balance + "";
    }

    render() {
        let balance = this.props.amountUserStaked * this.props.ratio;
        let small = balance > 0 && balance / 10 ** 18 < 10 ** -8 ;

        return (
            <div className="pool-row-desc">
                <span className="big">{this.getMyTokensStakedString(balance)}</span>
                {small ?
                    <div>My {this.props.stakeTokenName} Staked (* 10<sup>-18</sup>)</div>:
                    <div>My {this.props.stakeTokenName} Staked</div>
                }
            </div>
        );
    }
}

class LockedBalance extends Component {
    getMyTokensStakedString(balance) {
        if(isZero(balance)) {
            return "0";
        }
        if(balance > 10 ** -8) {
            return balance.toFixed(8);
        }
        return balance + "";
    }

    render() {
        let balance = this.props.totalLocked;
        let small = balance > 0 && balance < 10 ** -8 ;

        return (
            <div className="pool-row-desc">
                <span className="big">{this.getMyTokensStakedString(balance)}</span>
                {small ?
                    <div>My {this.props.stakeTokenName} Locked (* 10<sup>-18</sup>)</div>:
                    <div>My {this.props.stakeTokenName} Locked</div>
                }
            </div>
        );
    }
}
