import Web3 from 'web3';
import { getDefaultGasPrice } from './GasHelper';
import ERC20ABI from '../../abis/ERC20abi.json';
import stakingABI from '../../abis/locked_vault.json';
import stakingABIbasic from '../../abis/locked_vault_basic.json';
import { NetworkID } from '../../constants';
import { alertService } from '../../_services';

export const approve = (
  web3: Web3,
  account: string,
  lpAddress: string,
  vaultContractAddress: string,
) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(ERC20ABI.abi as any[], lpAddress, {
      gasPrice: getDefaultGasPrice(),
    } as any);

    vaultContract.methods
      .approve(
        vaultContractAddress,
        '115792089237316195423570985008687907853269984665640564039457584007913129639935',
      )
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const deposit = (
  web3: Web3,
  account: string,
  amount: number | string,
  durationSeconds: number,
  vaultContractAddress: string,
) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(stakingABI.abi as any[], vaultContractAddress, {
      gasPrice: getDefaultGasPrice(),
    } as any);

    vaultContract.methods
      .deposit(web3.utils.toWei(amount.toString(), 'ether'), durationSeconds)
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const depositAll = (
  web3: Web3,
  account: string,
  vaultContractAddress: string,
  durationSeconds: number,
) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(stakingABI.abi as any[], vaultContractAddress, {
      gasPrice: getDefaultGasPrice(),
    } as any);

    vaultContract.methods
      .depositAll(durationSeconds)
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const exit = (web3: Web3, account: string, vaultContractAddress: string) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(stakingABI.abi as any[], vaultContractAddress, {
      gasPrice: getDefaultGasPrice(),
    } as any);

    vaultContract.methods
      .withdrawAll()
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const emergencyExit = (web3: Web3, account: string, vaultContractAddress: string) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(stakingABI.abi as any[], vaultContractAddress, {
      gasPrice: getDefaultGasPrice(),
    } as any);

    vaultContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const claim = (web3: Web3, account: string, vaultContractAddress: string) => {
  return new Promise<void>((resolve, reject) => {
    const vaultContract = new web3.eth.Contract(stakingABI.abi as any[], vaultContractAddress, {
      gasPrice: getDefaultGasPrice(),
    } as any);

    vaultContract.methods
      .claim()
      .send({ from: account })
      .once('confirmation', () => resolve())
      .once('error', () => reject());
  });
};

export const extendLock = (
  web3: Web3,
  account: string,
  durationSeconds: number,
  vaultContractAddress: string,
  isBasic: boolean,
) => {
  return new Promise<void>((resolve, reject) => {
    if (isBasic || window.networkId !== NetworkID.POLYGON_NETWORK_ID) {
      const vaultContract = new web3.eth.Contract(
        stakingABIbasic.abi as any[],
        vaultContractAddress,
        { gasPrice: getDefaultGasPrice() } as any,
      );

      vaultContract.methods
        .setLockEndingDate(durationSeconds)
        .send({ from: account })
        .once('confirmation', () => resolve())
        .once('error', () => reject());
    } else {
      const vaultContract = new web3.eth.Contract(stakingABI.abi as any[], vaultContractAddress, {
        gasPrice: getDefaultGasPrice(),
      } as any);

      vaultContract.methods
        .deposit(0, durationSeconds)
        .send({ from: account })
        .once('confirmation', () => resolve())
        .once('error', () => reject());
    }
  });
};
