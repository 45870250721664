export const getUserVestingGroups = (addyData: any) => {
  if (addyData.earningsData.length === 0) {
    return [];
  }

  return addyData.earningsData
    .map((group: any) => {
      const [amount, timestamp] = group;

      return {
        unlockAmount: amount / 10 ** 18,
        unlockTimestamp: timestamp,
      };
    })
    .sort((a: any, b: any) => a.unlockTimestamp - b.unlockTimestamp);
};
