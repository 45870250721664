import React from 'react';
import Button from '../common/Button/Button';
import { useUserContext } from '../../contexts/UserContext';
import { getAdamantTokenName } from '../../api/Multichain';
import styles from './VestingConnectWallet.module.scss';

const VestingConnectWallet = () => {
  const { isWalletConnected, showChooseWalletModal } = useUserContext();

  const adamantTokenName = getAdamantTokenName();

  if (isWalletConnected) return null;

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Vesting</h4>
      <p className={styles.text}>
        In order to see your Vesting {adamantTokenName} you have to Connect Wallet
      </p>
      <Button kind="outlined" color="primary" onClick={showChooseWalletModal}>
        Connect Wallet
      </Button>
    </div>
  );
};

export default VestingConnectWallet;
