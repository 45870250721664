import dayjs from 'dayjs';

const timeDiffYearsDays = (date: dayjs.Dayjs) => {
  const diffInDays = date.diff(dayjs(), 'day');

  if (diffInDays < 365) {
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''}`;
  }

  const diffInYears = Math.floor(diffInDays / 365);
  const remainDays = diffInDays - diffInYears * 365;

  return `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ${
    remainDays > 0 ? `${remainDays} day${remainDays !== 1 ? 's' : ''}` : ''
  }`;
};

export default timeDiffYearsDays;
