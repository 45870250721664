import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { exit, harvest, claim, harvestAndExit, emergencyExit } from '../../api/actions/VaultFunctions.js'

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import LpBalance from './sub/LpBalance'
import TokenIcons from './sub/TokenIcons'
import VaultLabel from './sub/VaultLabel'
import StakedBalance from './sub/StakedBalance'
import RatesLabel from './sub/RatesLabel'
import {DailyVolume, getCachedSwapFeeApy} from '../../api/GraphData.js'

import '../../css/VaultCard.css'
import { NetworkID } from '../../constants/index.js';
import { getAdamantTokenName } from '../../api/Multichain.js'
import { nFormatter } from '../../utils.js';

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', });

class FarmCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '0',
            expanded: false
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({
            input: event.target.value
        });
    }
    //wanted to make vault card scroll to center of screen, but Harvest doesn't do this, not a priority issue
    scrollTo(event) {
        event.target.scrollIntoView({block: "center"});
    }

    toggleExpander = (event) => {
        if (!this.state.expanded) {
            this.setState({ expanded: true });
            //this.scrollTo(event);
        }
        else {
            this.setState({ expanded: false });
        }
    }

    render() {
        const isLoggedIn = this.props.isLoggedIn;
        let adamantTokenName = getAdamantTokenName();

        const pool = this.props.pool;

        const { totalStaked, userLpBalance, lpPrice, amountUserStaked, index, withdrawPenaltyTime } = pool;

        const ratio = pool.ratio / 10 ** 18;
        let swapFeeApy = getCachedSwapFeeApy(pool.vaultAddress);
        //for vaults which get trading fees from a source that isn't `graphData`
        if(pool.tradingFeeApy !== undefined && pool.tradingFeeApy > -1) {
            swapFeeApy = pool.tradingFeeApy;
        }
        //set a sanity check in case the source is returning wrong data so I don't get people spamming why the pools are 1 billion APY
        //also exclude swap fee apy if it isn't available (which would make it -1)
        if(swapFeeApy > 1000 || swapFeeApy === -1) {
            swapFeeApy = 0;
        }
        const apy = pool.apy >= 0 ? (pool.apy + swapFeeApy).toFixed(2): "Loading";

        let stakeTokenName = pool.singleAsset ? pool.token0Name: (pool.token0Name + '/' + pool.token1Name + ' LP');
        if(pool.nameOverride) stakeTokenName = pool.nameOverride; //used for Curve vault to set custom name

        const lastDepositTime = pool.lastDepositTime ? pool.lastDepositTime * 1000: 0;
        const deprecated = pool.deprecated || pool.rewardsPerDay === 0;
        const depositDisabled = pool.depositDisabled || deprecated;
        const withdrawPenaltyTimeDays = withdrawPenaltyTime / 3600 / 24;

        const hasEarlyWithdrawPenalty = (Date.now() - (lastDepositTime + withdrawPenaltyTime * 1000)) < 0;
        const pendingReward = pool.pendingReward / 10 ** 18 * (pool.boost > 0 ? 1 + pool.boost: 1);
        const pendingHarvested = pool.pendingHarvested / 1e18;
        const claimButtonVisible = amountUserStaked > 0 && pool.addyRewards && pendingReward > 0 && !deprecated;

        const baseApy = pool.baseApy;
        const baseApr = pool.baseApr;
        const addyTokenApy = pool.addyTokenApy ? pool.addyTokenApy: 0;
        const addyFeeShareApy = pool.addyFeeShareApy ? pool.addyFeeShareApy: 0;

        let poolYieldString = (apy > 1000000000 ? ">1 BILLION": apy) + "% APY";
        let poolRateString = pool.apr.toFixed(2) + "% APR";

        let poolYieldTooltipString = baseApy.toFixed(2) + "%: Auto-compounded " + pool.harvestedToken + "\r\n"
                                    + addyTokenApy.toFixed(2) + `%: ${adamantTokenName} rewards (not compounded)\r\n`
                                    + addyFeeShareApy.toFixed(2) + `%: Fee share dividend (claiming ${adamantTokenName} daily)\r\n`

        if(swapFeeApy > 0) {
            poolYieldTooltipString += swapFeeApy.toFixed(2) + "%: LP swap fees\r\n"
                                    + `The displayed APR/APY numbers do not include additional income from compounding fee-sharing dividends into more ${adamantTokenName} or LP tokens. LP swap fee data may be out of date.\r\n\r\n`;
        }
        else {
            poolYieldTooltipString += `The displayed APR/APY numbers do not include income from LP swap fees (aka ''pool fees'') that stakers will also earn, or compounding fee-sharing dividends into more ${adamantTokenName} or LP tokens. LP swap fee data may be out of date.\r\n\r\n`;
        }

        if(pool.showApr) {
            poolYieldTooltipString = "APR:\r\n"
                                    + baseApr.toFixed(2) + "%: Base APR from " + pool.harvestedToken + " after perf. fee\r\n"
                                    + pool.addyTokenApr.toFixed(2) + `%: ${adamantTokenName} rewards\r\n\r\n`
                                    + "APY:\r\n"
                                    + "Total APY: " + apy + "%\r\n"
                                    + poolYieldTooltipString;
        }

        //currently only true for Cronos vault data classes that call `calculateMinMaxAprAndApy()`
        if(pool.aprApyData && pool.aprApyData.minAprApyData) {
            let minAddyApr = pool.aprApyData.minAprApyData.addyTokenApr;
            let maxAddyApr = pool.aprApyData.maxAprApyData.addyTokenApr;
            let minApr = pool.aprApyData.minAprApyData.totalApr;
            let maxApr = pool.aprApyData.maxAprApyData.totalApr;

            let minApy = pool.aprApyData.minAprApyData.totalApy.toFixed(2);
            let maxApy = pool.aprApyData.maxAprApyData.totalApy.toFixed(2);
            let userApy = pool.aprApyData.userAprApyData.totalApy.toFixed(2);
            let singleStakeApr;
            if(pool.maximizer) {
                minApy = pool.aprApyData.minTotalApy.toFixed(2);
                maxApy = pool.aprApyData.maxTotalApy.toFixed(2);
                userApy = pool.aprApyData.totalApy.toFixed(2);
                singleStakeApr = pool.aprApyData.singleStakeApr.toFixed(2);
            }

            //Due to fee sharing revenue from vesting ADDY, not taking the early exit penalty might lead to more gains than taking the penalty + compounding
            let minApyWithDividends = pool.aprApyData.minAprApyData.totalApyWithFeeSharing.toFixed(2);
            let maxApyWithDividends = pool.aprApyData.maxAprApyData.totalApyWithFeeSharing.toFixed(2);
            let userApyWithDividends = pool.aprApyData.userAprApyData.totalApyWithFeeSharing.toFixed(2);
            minApy = Math.max(minApy, minApyWithDividends)
            maxApy = Math.max(maxApy, maxApyWithDividends)
            userApy = Math.max(userApy, userApyWithDividends)

            if(pool.maximizer) {
                poolYieldTooltipString = "Base APR of vault:\r\n"
                    + baseApr.toFixed(2) + "%: Auto-harvested " + pool.harvestedToken + " after perf. fee\r\n"
                    + `${minAddyApr.toFixed(2)} to ${maxAddyApr.toFixed(2)}% APR: ${adamantTokenName} rewards\r\n\r\n`
                    + `Maximizer vaults automatically stake ${pool.harvestedToken} in the single-staking pool, which earns ${singleStakeApr}% APR.\r\n\r\n`
                    + "APY:\r\n"
                    + `${minApy} to ${maxApy}% based on ${adamantTokenName} boost power\r\n`
                    + (amountUserStaked > 0 ? `Your APY: ${userApy}%\r\n`: "");
            }
            else {
                poolYieldTooltipString = "APR:\r\n"
                    + baseApr.toFixed(2) + "%: Auto-harvested " + pool.harvestedToken + " after perf. fee\r\n"
                    //+ pool.addyTokenApr.toFixed(2) + `%: ${adamantTokenName} rewards\r\n\r\n`
                    + `${minAddyApr.toFixed(2)} to ${maxAddyApr.toFixed(2)}% APR: ${adamantTokenName} rewards\r\n\r\n`
                    + "APY:\r\n"
                    + `${minApy} to ${maxApy}% based on ${adamantTokenName} boost power\r\n`
                    + (amountUserStaked > 0 ? `Your APY: ${userApy}%\r\n`: "");
            }

            if(pool.boost > 0) {
                poolYieldTooltipString += `${adamantTokenName} rewards boosted by: ` + (pool.boost * 100).toFixed(2) + "%\r\n";
            }
            if(userApy < maxApy) {
                poolYieldTooltipString += `\r\nThe maximum APR and APY can be reached by locking ${adamantTokenName} (up to 100% more ${adamantTokenName} earned from vaults)\r\n\r\n`;
            }

            //need to refactor this spaghetti
            if(maxApy > 1000000000) {
                poolYieldString = ">1 BILLION% APY";
            }
            else {
                //poolYieldString = (maxApy > 1000000000 ? ">1 BILLION": maxApy) + "% APY";
                poolYieldString = `${nFormatter(minApy, 2)}% → ${nFormatter(maxApy, 2)}% APY`;
            }
            //poolRateString = pool.apr.toFixed(2) + "% APR";
            poolRateString = `${nFormatter(minApr, 2)}% → ${nFormatter(maxApr, 2)}% APR`;
        }
        else {
            if(pool.boost > 0) {
                poolYieldTooltipString = `${adamantTokenName} rewards boosted by: ` + (pool.boost * 100).toFixed(2) + "%\r\n" + poolYieldTooltipString;
            }
        }

        if(pool.rewardsPerDay === 0 && !pool.deprecated) { //should only be used if rewards ended
            poolYieldString = "Rewards Ended";
            poolYieldTooltipString = "The reward duration for the pool this vault was farming is over. If the staking pool owner (i.e. Quickswap) does not distribute new rewards for the pool within the next few hours, rewards for the pool this vault was farming have ended.";
        }
        else if(deprecated) {
            poolYieldString = "Vault Replaced";
            poolYieldTooltipString = "This vault contract is being replaced with a new one. Please migrate to the new vault for this LP token if one is available.";
            if(pool.deprecatedYieldString) {
                poolYieldString = pool.deprecatedYieldString;
            }
            if(pool.deprecatedYieldTooltipString) {
                poolYieldTooltipString = pool.deprecatedYieldTooltipString;
            }
        }

        const description = pool.maximizer ? "Maximizer": "Auto-Compounding";
        let buttonText = "Claim + Vest " + (pendingReward >= 0.0001 ? pendingReward.toFixed(4): "< 0.0001") + ` ${adamantTokenName}`;
        if(pool.maximizer && pendingHarvested > 0) {
            buttonText += " + " + (pendingHarvested >= 0.0001 ? pendingHarvested.toFixed(4): "< 0.0001") + ` ${pool.harvestedToken}`;
        }
        let harvestButtonText = "Manual Compound";

        let earnString = stakeTokenName;
        if(pool.earnString) {
            earnString = pool.earnString;
        }
        else if(pool.addyRewards) {
            earnString = pool.maximizer ? `${pool.harvestedToken} + ${adamantTokenName}`: `${stakeTokenName} + ${adamantTokenName}`;
        }

        let vaultTypeTooltip = pool.maximizer ? this.maximizerTooltip: this.autoCompoundTooltip;

        let canHarvest = isLoggedIn && totalStaked > 0;

        let exchangeURL = pool.customExchangeUrl ? pool.customExchangeUrl: this.getExchangeUrl(pool);

        const poolYieldTooltip = props => (
            <Tooltip className="tooltip-yield" {...props}>
                {poolYieldTooltipString}
                {<DailyVolume id={pool.lpAddress} tvl={pool.poolTvl? pool.poolTvl: pool.tvl} pool={pool} />}
            </Tooltip>
        );
        const depositTimeTooltip = props => (
            <Tooltip className="tooltip-yield" {...props}>
                {"Last deposit on: " + new Date(lastDepositTime).toLocaleString() +
                (hasEarlyWithdrawPenalty ? "\r\n" + pool.withdrawPenalty / 100 + "% early withdrawal penalty for withdrawing before " + withdrawPenaltyTimeDays + " days": "\r\nNo withdrawal penalty")}
            </Tooltip>
        );

        let poolName = this.getPoolName(pool, stakeTokenName);

        return [
            <div key="collapsed" className={"farms-card-item no-select clickable" + (this.state.expanded ? " expanded": " collapsed") + (pool.highlight ? " highlight": "")} onClick={this.toggleExpander}>
                <TokenIcons pool={pool} expanded={this.state.expanded} />

                <VaultLabel poolName={poolName} apy={apy} overlay={this.chadTooltip} tooltip={pool.tooltip} pool={pool} />

                <div className="hidden-element" id="load daily volume for pair">
                    <DailyVolume id={pool.lpAddress} tvl={pool.poolTvl? pool.poolTvl: pool.tvl} pool={pool} />
                </div>

                <RatesLabel overlay={poolYieldTooltip}
                    pool={pool}
                    deprecated={deprecated}
                    poolRateString={poolRateString}
                    poolYieldString={poolYieldString}
                    vaultTypeTooltip={vaultTypeTooltip}
                    description={description} />

                <div className="details return">
                    <span className="label">Earn</span>
                    <span className="value">{earnString}</span>
                </div>
                <div className="details balance">
                    <span className="label">User Staked</span>
                    <span className="value">
                        {this.props.showLpAmounts ?
                            (amountUserStaked * ratio / 10 ** 18).toFixed(4) + " " + stakeTokenName:
                            currencyFormatter.format(lpPrice * amountUserStaked * ratio / 10 ** 18)
                        }
                    </span>
                </div>

                <div className="details total">
                    <span className="label">Total Staked</span>
                    <span className="value">
                        {this.props.showLpAmounts ?
                            (totalStaked / 10 ** 18).toFixed(4) + " " + stakeTokenName:
                            currencyFormatter.format(lpPrice * totalStaked / 10 ** 18)
                        }
                    </span>
                </div>
            </div>,
            this.state.expanded && (
                <div key="expanded" className={"farms-card-expandable" + (pool.highlight ? " highlight": "")}>
                    <div className="pool-row-background" colSpan={6}>
                        <div className="row align-items-center text-center">

                            <div id="tableRowLeft" className="col-12 col-md-6 mb-4 pb-3">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <LpBalance userLpBalance={userLpBalance} stakeTokenName={stakeTokenName} />
                                    </div>

                                    <div className="col-6 pl-2">
                                        <a href={exchangeURL} target="_blank" rel="noopener noreferrer"
                                                className={"btn btn-block btn-primary"}>+/- Liquidity</a>
                                    </div>

                                    <div className="col-6 pl-2">
                                        <button className={"btn btn-block btn-success" + (userLpBalance > 0 && !deprecated && !depositDisabled ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.props.showStakingModal(index, pool.vaultAddress);
                                            }
                                        }>{depositDisabled ? "Deposits Disabled": "Stake"}</button>
                                    </div>

                                    {claimButtonVisible && <div className="col-12 mb-2 mt-4"><p></p></div>}
                                </div>
                            </div>

                            <div id="tableRowRight" className="col-12 col-md-6 mb-4 pb-3">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <StakedBalance amountUserStaked={amountUserStaked} ratio={ratio} stakeTokenName={stakeTokenName} />
                                    </div>

                                    <div id="harvestButton" className="col-6">
                                        <button className={"btn btn-block btn-primary" + (canHarvest ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                harvest(this.props.account, pool.strategyAddress);
                                            }
                                            }>{harvestButtonText}&nbsp;
                                            <OverlayTrigger placement="top" overlay={this.autoCompoundButtonTooltip}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </OverlayTrigger></button>
                                    </div>

                                    <div id="exitButton" className="col-6 pl-2">
                                        {pool.emergencyWithdraw &&
                                            <button className={"btn btn-block btn-danger" + (amountUserStaked > 0 ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                emergencyExit(this.props.account, pool.vaultAddress);
                                            }
                                            }>Emergency Exit (No Penalty)
                                            </button>
                                        }

                                        {lastDepositTime > 0 && hasEarlyWithdrawPenalty && !pool.emergencyWithdraw && !pool.hideExit &&
                                            <button className={"btn btn-block btn-danger" + (amountUserStaked > 0 ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if(pool.harvestBefore && !deprecated) {
                                                    harvestAndExit(this.props.account, pool.vaultAddress, pool.strategyAddress);
                                                }
                                                else {
                                                    exit(this.props.account, pool.vaultAddress);
                                                }
                                            }
                                            }>Claim + Vest + Exit&nbsp;
                                                <OverlayTrigger placement="top" overlay={depositTimeTooltip}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                </OverlayTrigger>
                                            </button>
                                        }

                                        {lastDepositTime > 0 && !hasEarlyWithdrawPenalty && !pool.emergencyWithdraw && !pool.hideExit &&
                                            <button className={"btn btn-block btn-warning" + (amountUserStaked > 0 ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if(pool.harvestBefore && !deprecated) {
                                                    harvestAndExit(this.props.account, pool.vaultAddress, pool.strategyAddress);
                                                }
                                                else {
                                                    exit(this.props.account, pool.vaultAddress);
                                                }
                                            }
                                            }>Claim + Vest + Exit&nbsp;
                                                <OverlayTrigger placement="top" overlay={depositTimeTooltip}>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </OverlayTrigger>
                                            </button>
                                        }

                                        {lastDepositTime === 0 &&  !pool.emergencyWithdraw && !pool.hideExit && <button className={"btn btn-block btn-warning" + (amountUserStaked > 0 ? "" : " disabled")}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if(pool.harvestBefore && !deprecated) {
                                                    harvest(this.props.account, pool.strategyAddress);
                                                }
                                                exit(this.props.account, pool.vaultAddress);
                                            }
                                        }>Claim + Exit</button>}

                                        {pool.hideExit && <button className="btn btn-block btn-danger disabled"
                                            onClick={(event) => {
                                                event.preventDefault();
                                            }
                                        }>Exit Disabled</button>}
                                    </div>

                                    {claimButtonVisible && <div className="col-12 mb-2 mt-2">
                                        <button className="btn btn-block btn-primary"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                claim(this.props.account, pool.vaultAddress);
                                            }
                                            }>{buttonText}&nbsp;
                                            <OverlayTrigger placement="top" overlay={this.claimTooltip}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </OverlayTrigger>
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        ];
    }

    getExchangeUrl(pool) {
        let exchangeURL;

        if(this.props.networkId === NetworkID.POLYGON_NETWORK_ID) {
            if(pool.exchange === "cometh") {
                exchangeURL = "https://swap.cometh.io/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "sushi") {
                exchangeURL = "https://app.sushi.com/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "elk") {
                exchangeURL = "https://app.elk.finance/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "polyzap") {
                exchangeURL = "https://swap.polyzap.finance/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "curve") {
                exchangeURL = "https://polygon.curve.fi/aave/deposit"
            }
            else if(pool.exchange === "wault") {
                exchangeURL = "https://swap.wault.finance/polygon/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "ape") {
                exchangeURL = "https://app.apeswap.finance/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "jet") {
                exchangeURL = "https://polygon-exchange.jetswap.finance/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "dfyn") {
                exchangeURL = "https://exchange.dfyn.network/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "gravity") {
                exchangeURL = "https://gravityfinance.io/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "polycat") {
                exchangeURL = "https://polycat.finance/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "cafe") {
                exchangeURL = "https://polygondex.cafeswap.finance/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "dino") {
                exchangeURL = "https://trade.dinoswap.exchange/#/add/" + pool.token0 + "/" + pool.token1
            }
            else {
                exchangeURL = "https://quickswap.exchange/#/add/" + pool.token0 + "/" + pool.token1
            }
        }
        else if(this.props.networkId === NetworkID.ARBITRUM_NETWORK_ID) {
            if(pool.exchange === "sushi") {
                exchangeURL = "https://app.sushi.com/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "swapr") {
                exchangeURL = "https://swapr.eth.link/#/add/" + pool.token0 + "/" + pool.token1 + "?chainId=42161"
            }
        }
        else if(this.props.networkId === NetworkID.CRONOS_NETWORK_ID) {
            if(pool.exchange === "cronaswap") {
                exchangeURL = "https://app.cronaswap.org/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "vvs") {
                exchangeURL = "https://vvs.finance/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "crodex") {
                exchangeURL = "https://swap.crodex.app/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "photonswap") {
                exchangeURL = "https://photonswap.finance/#/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "mmfinance") {
                exchangeURL = "https://mm.finance/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "chronoswap") {
                exchangeURL = "https://chronoswap.org/add/" + pool.token0 + "/" + pool.token1
            }
            else if(pool.exchange === "elk") {
                exchangeURL = "https://app.elk.finance/#/add/" + pool.token0 + "/" + pool.token1
            }
        }
        else if(this.props.networkId === NetworkID.POLYGON_TESTNET_ID) {
            exchangeURL = "https://quickswap.exchange/#/add/" + pool.token0 + "/" + pool.token1
        }
        return exchangeURL;
    }

    getPoolName(pool, stakeTokenName) {
        if(pool.beta) {
            return stakeTokenName + " (Beta)";
        }
        return stakeTokenName;
    }

    chadTooltip = props => (
        <Tooltip {...props}>{"Chad embodies the animal spirits of Degens. These vaults may be more volatile. Adamant helps to auto-harvest these vaults for you, saving you gas and maximizing your yields."}</Tooltip>
    );
    compoundInfoTooltip = props => (
        <Tooltip {...props}>{"All vaults are automatically compounded and harvested." +
                            " Users can also manually compound/harvest for the vault if desired by clicking both Compound buttons in order."}</Tooltip>
    );
    autoCompoundButtonTooltip = props => (
        <Tooltip {...props}>{"All vaults are automatically compounded and harvested. Users can also manually compound/harvest for the vault if desired."}</Tooltip>
    );
    autoCompoundTooltip = props => (
        <Tooltip {...props}>{"Auto-Compound vaults automatically sell the harvested reward token and compound it into more of your LP token."}</Tooltip>
    );
    maximizerTooltip = props => (
        <Tooltip {...props}>{"Maximizer vaults automatically deposit harvested tokens in the platform's single-staking pool. This allows stakers to get a much higher yield."}</Tooltip>
    );
    claimTooltip = props => (
        <Tooltip className="tooltip-yield" {...props}>
            {"Claimed tokens will automatically be vested into the fee distribution contract for you and earn income from vault fees.\r\n" +
            "You can see your vested, staked, and locked tokens at the Stake + Vest link at the top of the page.\r\n" +
            "The amount shown is an estimate based on the current price of the token and will only be realized on claim or exit."}</Tooltip>
    );
}

export default FarmCard;
