import { useState } from 'react';
import { useUserContext } from '../../../contexts/UserContext';

export const usePerformanceFeesTile = (addyData: any) => {
  const { stakeFunctions } = useUserContext();
  const [isClaimPending, setIsClaimPending] = useState(false);

  const claim = () => {
    if (isClaimPending) return;
    setIsClaimPending(true);
    stakeFunctions.claimRewards().finally(() => setIsClaimPending(false));
  };

  const claimableWmaticAmt = addyData.claimableWmaticAmt / 10 ** 18;

  return {
    claim,
    isClaimPending,
    claimableWmaticAmt,
  };
};
